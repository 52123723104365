import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { StorageManager } from 'src/app/common/storage-manager.class';

import type { CanActivate } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ShowMapasGuardService implements CanActivate {
    public nombre = StorageManager.getUser().nombre;
    public apellidos = StorageManager.getUser().apellidos ? StorageManager.getUser().apellidos : '';
    public appName = environment.appName; 

    constructor(public router: Router) {}

    canActivate(): boolean {
        if (  (this.appName === 'manezylozano' 
    && this.nombre.toLowerCase() === 'quique' 
    && this.apellidos.toLowerCase() === 'lozano') 
        ){
            return true;
        }
    
        if (!environment.features.showMapas ) {
            this.router.navigate(['login']);

            return false;
        }

        return true;
    }
}
