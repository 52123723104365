import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {BaseForm} from '../../../../base-form';
import {TareasApiService} from '../../../../../service/api/tareas-api.service';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {build, list, pool} from '../../../../../common/classes/request-builder';
import { environment } from '../../../../../../environments/environment';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { Utils } from '../../../../../common/utils';
import { ProductosApiService } from '../../../../../service/api/productos-api';
import { TratamientosApiService } from '../../../../../service/api/tratamientos-api.service';
import { ClientesApiService } from '../../../../../service/api/clientes-api.service';
import { StorageManager } from '../../../../../common/storage-manager.class';
import { FormRequestTypes } from '../../../../../common/classes/form-request';
import { VisitasVariedadMesApiService } from '../../../../../service/api/visitas_variedad_mes-api.service';
import { Filtering } from '../../../../../service/filtering/filtering';
import { TrabajadoresApiService } from '../../../../../service/api/trabajadores-api.service';
import moment from 'moment';
import { SiexApiService } from '../../../../../service/api/siex-api.service';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
@Component({
    selector: 'app-nueva-carga',
    templateUrl: './siembra-form.component.html',
    styleUrls: ['./siembra-form.component.scss']
})
export class SiembraFormComponent extends BaseForm implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
    requestButton: RequestButtonComponent = new RequestButtonComponent();

    public isAdmin = (StorageManager.getUser().rol === 'admin');
    public appName = environment.appName;
    public applicationType =  environment.features.applicationType;

    public clienteName = environment.features.clienteName;
    public hasClients = environment.features.hasClients;
    public canSeeClientes = (
        environment.features.hasClients
        && environment.features.userCanSeeAllRecords
        && environment.features.userCanSeeClientes
    ) ? true : this.isAdmin;
    public fincaName = environment.features.fincaName;
    public sectorName = environment.features.sectorName;
    public parcelasName = environment.features.parcelasName;
    public horaFechaFinActualPorDefecto = environment.features.horaFechaFinActualPorDefecto;    
    public tipoSuperficieParcelas = environment.features.hasSuperficieCultivada ? 'S.\xa0C.' : 'S.\xa0Sigpac';
    public showTareasSiembraProductos = environment.features.showTareasSiembraProductos;
    public textoSuperficie = environment.features.textoSuperficie;
    public showTareasSiembraMaquinarias = environment.features.showTareasSiembraMaquinarias;

    public override model: any = {};
    public requestType: any;
    public formRequest: AppFormRequest = new AppFormRequest();

    public distributionOrder = [
        3, 3, 3, 3
    ];

    public distributionOrderViverogest = [
        4, 4, 4, 4, 4, 4, 4
    ];

    public clientes = list(['...', null]);
    public fincas = list(['...', null]);
    public sectores = list(['...', null]);
    public parcelas = list(['...', null]);
    public trabajadores = list(['...', null]);
    public cultivos = list(['...', null]);
    public variedades = list(['...', null]);
    public cultivosPrecedentes = list(['...', null]);
    public distinctCultivosVariedades = list(['...', null]);
    public productos = list(['...', null]);
    public tratamientos = list(['...', null]);
    public tipoRiego = list('...', 'Cintas', 'Gotero', 'Aspersión');
    public listProductoArmentia = list('...', 'Systiva', 'Raxil Plus');

    public listSemilla = list(['...', null], 'R1', 'R2', 'S');
    public listSiembra = list ('...', 'Seco', 'Agua');

    public maquinarias: TypeAheadInterface = list();
    public rpmValues: TypeAheadInterface = list(['...', null], '1000', '1100', '1200', '1300', '1400', 
        '1500', '1600', '1700', '1800', '1900', '2000');

    public formFields: ItemInterface <any>[] = [
        {
            field: 'id_cliente',
            label: this.clienteName,
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.clientes,
            valuePrimaryKey: 'id',
            visible: this.canSeeClientes && !StorageManager.getClient() && this.hasClients,
            required: true
        },
        {
            label: 'NO < Tarea previa a la siembra > SÍ',
            field: 'check_previa',
            inputType: {
                type: InputType.SWITCH
            }
        },
        // {
        //     label: 'Fecha de siembra',
        //     field: 'fecha_siembra',
        //     inputType: {
        //         type: InputType.CALENDAR
        //     }
        // },
        {
            field: 'fecha_inicio',
            label: 'Fecha de inicio',
            inputType: {type: InputType.CALENDAR},
            visible: this.appName !== 'cartagenafresh'
        },
        {
            field: 'fecha_fin',
            label: this.appName === 'cartagenafresh' ? 'Fecha Plantación' : 'Fecha de fin',
            inputType: {type: InputType.CALENDAR}
        },
        {field: 'hora_inicio', label: 'Hora Inicio', inputType: {type: InputType.TIME}},
        {field: 'hora_fin', label: 'Hora Fin', inputType: {type: InputType.TIME}},          
        {
            field: 'horas_totales', 
            label: 'Horas', 
            inputType: {type: InputType.ONLY_TEXT}, 
            visible: environment.features.showTareasMto || environment.features.tareasHorasTotales 
        },
        {field: 'facturado', label: 'Facturado', inputType: {type: InputType.SWITCH}, visible: this.appName === 'agrosalvi' },
        {field: 'factura_albaran', label: 'Factura/Albarán', inputType: {type: InputType.EDIT_TEXT}, visible: this.appName === 'agrosalvi'},
        {
            field: 'id_finca',
            label: this.fincaName,
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id',
            filter: (it: { value: { id_cliente: any; }; }) => {
                if (!this.canSeeClientes || (this.requestType === FormRequestTypes.DUPLICATE)) {
                    return true;
                } else if (this.clientes.selected) {
                    return it.value.id_cliente === (this.clientes.selected || {} as any).id;
                } else if (StorageManager.getClient()) {
                    return it.value.id_cliente === (StorageManager.getClient() || {} as any).id;
                }
                return true;
            }
        },
        {
            field: 'id_sector',
            label: this.sectorName,
            inputType: {type: InputType.DROPDOWN_MULTISELECT_SEARCH},
            values: this.sectores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            canSelectAll: true,
            filter: (it: { value: { id_finca: any; }; }) => {
                if (this.fincas.selected) {
                    return it.value.id_finca === (this.fincas.selected || {} as any).id;
                } else {
                    return false;
                }
            }
        },
        {
            field: 'ids_parcelas',
            label: this.parcelasName,
            inputType: {type: InputType.DROPDOWN_MULTISELECT_SEARCH},
            multiSelect: true,
            canSelectAll: true,
            values: this.parcelas,
            valuePrimaryKey: 'id',
            filter: (it: { value: { activo: string; id_sector: any; id_finca: any; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                if (this.sectores.selected !== undefined && this.sectores.selected.length > 0) {
                    return this.sectores.selected.map((sector: { id: any; }) => sector.id).includes(it.value.id_sector);
                } else if (this.fincas.selected) {
                    return it.value.id_finca === (this.fincas.selected || {} as any).id;
                }
            },
            required: true
        },

        // {
        //     label: 'Cultivo',
        //     field: 'id_cultivo',
        //     inputType: {
        //         type: InputType.DROPDOWN_SEARCH
        //     },
        //     values: this.cultivos,
        //     valuePrimaryKey: 'id_cultivo',
        //     filter: it => {
        //         if (this.parcelas.selected) {
        //             return (this.parcelas.selected || []).map(p => p.id).includes(it.value.id_parcela);
        //         }
        //     }
        // },
        // {
        //     label: 'Variedad',
        //     field: 'id_variedad',
        //     inputType: {
        //         type: InputType.DROPDOWN
        //     },
        //     values: this.variedades,
        //     valuePrimaryKey: 'id_variedad',
        //     filter: (it) => {
        //         if (this.cultivos.selected) {
        //             return this.cultivos.selected.cultivo === it.value.cultivo;
        //         }
        //     }
        // },
        {
            label: 'Cultivo precedente',
            field: 'id_cultivo_precedente',
            inputType: {
                type: InputType.DROPDOWN_SEARCH
            },
            values: this.cultivosPrecedentes,
            valuePrimaryKey: 'id',
            visible: this.appName !== 'cartagenafresh',
            filter: Filtering.distinctBy.bind({field: 'label'}),
            // filter: it => {
            //     if (this.parcelas.selected) {
            //         return (this.parcelas.selected || []).map(p => p.id).includes(it.value.id_parcela);
            //     }
            // }
        },
        {
            field: 'tipo_tratamiento',
            label: 'Tipo de tratamiento',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], 'Semillas', 'Plantones', 'Cosecha'),
            visible: environment.appName === 'pyf'
        },
        {
            field: 'tipo_riego',
            label: 'Tipo riego',
            inputType: {
                type: InputType.DROPDOWN
            },
            values: this.tipoRiego,
            visible: environment.appName === 'cartagenafresh'            
        },         
        {
            field: 'motivo',
            label: 'Motivo',
            inputType: {
                type: InputType.EDIT_TEXT
            },
            visible: environment.appName === 'pyf'            
        },   
        {
            label: 'Producto/Tratamiento',
            field: 'id_producto',
            inputType: {
                type: InputType.DROPDOWN_SEARCH
            },
            values: this.appName === 'armentia' ? this.listProductoArmentia : this.productos,
            valuePrimaryKey: 'id',
            visible: this.showTareasSiembraProductos,
            filter: (it: { value: { tipo: string; }; }) => {
                if ( (it.value.tipo as string).localeCompare('fitosanitario') === 0  ) {
                    return it;
                } else {
                    return false;
                }
            }
        },
        {
            field: 'dosis_siembra',
            label: this.applicationType === 'cropgest' ? 'Dosis Kg' : 'Dosis Kg/Ha',
            inputType: {
                type: InputType.EDIT_TEXT
            }
        },
        {
            label: 'Tipo de labor',
            field: 'tipo_labor',
            inputType: {
                type: InputType.DROPDOWN
            },
            values: this.appName !== 'cartagenafresh' ? 
                list(
                    ['...', null], 
                    'CHISEL', 
                    'GRADA', 
                    'LABRAR', 
                    'SEMBRAR', 
                    'RECOLECTAR'
                ) : 
                list(['SEMBRAR', 'SEMBRAR'])
        },

        {
            field: 'semilla',
            label: 'Semilla',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], ['R - Reempleo', 'R'], ['C - Certificada', 'C'], ['N - Nada', 'N'])
        },
        {
            field: 'tipo_semilla',
            label: (this.appName === 'sequiador'  || this.appName === 'SIEX') ? 'Tipo de siembra' : 'Tipo de semilla',
            inputType: {type: InputType.DROPDOWN},
            values: (this.appName === 'sequiador'  || this.appName === 'SIEX') ? this.listSiembra : this.listSemilla,
            visible: this.appName !== 'cartagenafresh'
        },
        {
            field: 'lote_semilla',
            label: 'Lote Semilla',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName === 'cartagenafresh',
        },
        {
            label: 'Observaciones',
            field: 'observaciones',
            inputType: {
                type: InputType.EDIT_TEXTAREA
            }
        },
        /*{
            field: 'fecha_inundacion_arroz',
            label: 'Fecha Inundacion Arroz',
            inputType: { type: InputType.CALENDAR },
            visible: this.appName == 'sequiador'
        },*/
    ];
    
    public formFieldsViverogest: ItemInterface <any>[] = [
        {
            field: 'ids_trabajadores',
            label: 'Personal Siembra',
            inputType: {type: InputType.MULTISELECT},
            values: this.trabajadores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: { value: { activo: string; }; }) => {
                console.log(it);
                if (it.value.activo !== '1') {
                    return false;
                }
                return true;
            }
        },
        {
            field: 'id_finca',
            label: this.fincaName,
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id',
            filter: (it: { value: { id_cliente: any; }; }) => {
                if (!this.canSeeClientes || (this.requestType === FormRequestTypes.DUPLICATE)) {
                    return true;
                } else if (this.clientes.selected) {
                    return it.value.id_cliente === (this.clientes.selected || {} as any).id;
                } else if (StorageManager.getClient()) {
                    return it.value.id_cliente === (StorageManager.getClient() || {} as any).id;
                }
                return true;
            }
        },
        {
            field: 'id_sector',
            label: this.sectorName,
            inputType: {type: InputType.MULTISELECT},
            values: this.sectores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            canSelectAll: true,
            filter: (it: { value: { id_finca: any; }; }) => {
                if (this.fincas.selected) {
                    return it.value.id_finca === (this.fincas.selected || {} as any).id;
                } else {
                    return false;
                }
                
            }
        },
        {
            field: 'ids_parcelas',
            label: this.parcelasName,
            inputType: {type: InputType.MULTISELECT},
            multiSelect: true,
            canSelectAll: true,
            values: this.parcelas,
            valuePrimaryKey: 'id',
            filter: (it: { value: { activo: string; id_sector: any; id_finca: any; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                if (this.sectores.selected) {
                    return it.value.id_sector === (this.sectores.selected || {} as any).id;
                } else if (this.fincas.selected) {
                    return it.value.id_finca === (this.fincas.selected || {} as any).id;
                }
                return false;
            }
        },
        {field: '', label: '', inputType: {type: InputType.EMPTY}},
        {field: 'cantidad_semilla', label: 'Cantidad Semilla', inputType: {type: InputType.EDIT_TEXT}},
        {field: 'fecha_siembra', label: 'Fecha Siembra', inputType: {type: InputType.CALENDAR}},
        {field: 'sustrato', label: 'Sustrato', inputType: {type: InputType.DROPDOWN}, values: list('...', 'BVB', 'BRILL', 'COCO')},
        {field: 'color', label: 'Color', inputType: {type: InputType.EDIT_TEXT}},
        {field: 'formato', label: 'Formato', inputType: {type: InputType.EDIT_TEXT}},
        {field: 'semana_siembra', label: 'Semana Siembra', inputType: {type: InputType.EDIT_TEXT}},
        {field: 'tamizado', label: 'Tamizado', inputType: {type: InputType.EDIT_TEXT}},
        {field: 'lote', label: 'Lote', inputType: {type: InputType.EDIT_TEXT}},         
        {field: 'cantidad_bandejas', label: 'Cantidad Bandejas', inputType: {type: InputType.EDIT_TEXT}         },        
        {
            label: 'Cámara',
            field: 'camara',
            inputType: {
                type: InputType.DROPDOWN
            },
            values: list('...', 'Calor', 'Frio', '4º', '30º', 'Descarga Directa (no pasa por cámara)')
        },
        {field: 'tratamiento', label: 'Tratamiento', inputType: {type: InputType.EDIT_TEXT}},
        {field: '', label: '', inputType: {type: InputType.EMPTY}},
        {field: 'completado', label: 'Completado', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Fecha Descarga', field: 'fecha_descarga', inputType: {type: InputType.CALENDAR}},
        {field: 'observaciones', label: 'Observaciones', inputType: {type: InputType.EDIT_TEXTAREA}},
        {field: '', label: '', inputType: {type: InputType.EMPTY}},
        {field: 'punta', label: 'Punta', inputType: {type: InputType.EDIT_TEXT}},
        {field: 'semana_entrega', label: 'Semana Entrega', inputType: {type: InputType.EDIT_TEXT}},
        {field: '', label: '', inputType: {type: InputType.EMPTY}},
        {field: '', label: '', inputType: {type: InputType.EMPTY}},
        {field: 'porcentaje_germinacion', label: '% Germinación', inputType: {type: InputType.EDIT_TEXT}},
        {field: 'remesa', label: 'Remesa', inputType: {type: InputType.EDIT_TEXT}},
        {
            label: 'No Está',
            field: 'no_esta',
            inputType: {
                type: InputType.SWITCH
            }
        },
    ];

    public formFieldsMaquinarias: ItemInterface<any>[] = [
        {
            field: this.appName !== 'bravosol' ? 'id_maquinaria' : 'ids_maquinaria',
            label: 'Maquinaria',
            inputType: {type: this.appName !== 'bravosol' ? InputType.DROPDOWN_SEARCH : InputType.MULTISELECT },
            values: this.maquinarias,
            valuePrimaryKey: 'id',
            visible: this.appName !== 'bravosol' ? true : false,
            filter: (it: { value: { activo: string; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                return true;
            }
        },
        {
            field: 'velocidad',
            label: 'Velocidad\xa0(Km/h)',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'velocidad_esponja',
            label: 'Velocidad Esponja',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'marcha',
            label: 'Marcha',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'rpm',
            label: 'RPM',
            inputType: {type: InputType.DROPDOWN},
            values: this.rpmValues
        },
        {
            field: 'presion',
            label: 'Presión\xa0(atm-bar)',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'boquillas',
            label: 'Boquillas',
            inputType: {type: InputType.EDIT_TEXT}
        }
    ];

    constructor(
        public override route: ActivatedRoute,
        public override router: Router,
        public override dashboard: DashboardService,
        public tareasApi: TareasApiService,
        public clientesApi: ClientesApiService,
        public fincasApi: FincasApiService,
        public trabajadoresApi: TrabajadoresApiService,
        public siexApi: SiexApiService,
        public productosApi: ProductosApiService,
        public tratamientosApi: TratamientosApiService,
        public visitasVariedadMesApi: VisitasVariedadMesApiService
    ) {
        super(
            route,
            router,
            dashboard,
            tareasApi.siembra.PUT,
            tareasApi.siembra.POST,
            'tareas-siembra',
            'Actualizar Tarea Siembra',
            'Realizar Tarea Siembra',
        );
    }

    ngOnInit() {
        if (this.getType() === FormRequestTypes.CREATE) {
            this.model.id_cliente = (StorageManager.getClient() || {} as any).id;
        }

        const fieldsToSend = (['id', 'id_usuario', 'cultivo', 'variedad', 'cultivo_precedente', 'sup_cultivada', 'horas_totales'] as any)
            .concat(this.formFields.map(it => it.field))
            .concat(this.formFieldsViverogest.map(it => it.field))
            .concat(this.formFieldsMaquinarias.map(it => it.field));

        if (this.canSeeClientes) {
            fieldsToSend.push('id_cliente');
        }
        if (  this.getType() === 0 ) {
            this.fincasApi.fincasGET.toPromise().then( (resp_fincas: any) => {
             
                if (resp_fincas.length === 1 ) {
                    this.model['id_finca'] = resp_fincas[0].id;
                }
            });
        }
        this.initFormData();
        this.expandFormRequest();

        this.formRequest
            .setType(this.getType())
            .isGeneric(false)
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.tareasApi.siembra.GET)
            .setPostRequest(this.tareasApi.siembra.POST)
            .setPutRequest(this.tareasApi.siembra.PUT)
            .setFormFields(Array.prototype
                .concat(
                    this.formFields.map(it => it.field),
                    this.formFieldsViverogest.map(it => it.field),
                    this.formFieldsMaquinarias.map(it => it.field)
                )
            )
            .setFieldsToSend(fieldsToSend);

        this.formRequest.load();

        this.softInit(this.getType());
    }

    public submit() {
        if (this.appName !== 'agrosalvi'){
            delete this.model['facturado'];
            delete this.model['factura_albaran'];
        }// if();
        
        if (!this.model.id_maquinaria && this.showTareasSiembraMaquinarias){
            this.model.id_maquinaria = null;
        }
       
        if ( this.model['id_sector'] == null ){
            this.model['id_sector'] = null;
        }
    
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send();

        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    formatoHorasMinutos(horaEntrada: any, horaSalida: string) {    
        let timeStr: any = horaEntrada;
        timeStr = timeStr.split(':');
      
        const h = timeStr[0],
            m = timeStr[1];
      
        const newTime = moment( horaSalida + ' 2021-4-5')
            .subtract({'hours': h, 'minutes': m})
            .format('HH:mm');
      
      
        // console.log({str});
        return newTime;
       
    }

    public formChanges(tag: string) {

        if (environment.features.showTareasMto || environment.features.tareasHorasTotales) {
            if (this.model['hora_inicio'] != null && this.model['hora_fin'] != null) {
                let horaFin = moment(this.model.hora_fin).format('HH:mm');
                let horaInicio = moment(this.model.hora_inicio).format('HH:mm');
                const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
                if (!timeFormat.test(horaInicio)) {
                    horaInicio = this.model.hora_inicio;
                }

                if (!timeFormat.test(horaFin)) {
                    horaFin = this.model.hora_fin;
                }

                const diferencia = this.formatoHorasMinutos(horaInicio, horaFin);
                this.model['horas_totales'] = diferencia;
                this.model['hora_inicio'] = horaInicio;
                this.model['hora_fin'] = horaFin;
            } else {
                this.model['horas_totales'] = '';
            }
        }

        if (tag === 'id_cliente') {
            this.model.id_finca = null;
            this.model.id_sector = null;
            this.model.ids_parcelas = null;
            this.model.id_cultivo_precedente = null;
            // this.model.id_cultivo = null;
            // this.model.id_variedad = null;
        }
        if (tag === 'id_finca') {
            this.model.id_sector = null;
            this.model.ids_parcelas = null;
            this.model.id_cultivo_precedente = null;
            // this.model.id_cultivo = null;
            // this.model.id_variedad = null;
        }
        if (tag === 'id_sector') {
            this.model.ids_parcelas = null;
            this.model.id_cultivo_precedente = null;
            // this.model.id_cultivo = null;
            // this.model.id_variedad = null;
        }
        if (tag === 'ids_parcelas') {
            this.model.id_cultivo_precedente = null;
            // this.model.id_cultivo = null;
            // this.model.id_variedad = null;
        }
        // if (tag === 'id_cultivo') {
        //     this.model.id_variedad = null;
        // }

        this.countSuperficieParcelas();
        this.requestButton.error = '';
    }

    public selectAll() {
        this.countSuperficieParcelas();
    }

    public countSuperficieParcelas() {
        const ids = (this.model.ids_parcelas || '').split(';');
        this.model.parcelas_superficie = 0;

        (this.parcelas.filtered || []).forEach(parcela => {
            if (parcela && parcela.value && ids.includes(parcela.value.id)) {
                this.model.parcelas_superficie += parseFloat(
                    (parcela.value.superficie_cultivada || parcela.value.superficie_sigpac || '0.00').replace(',', '.')
                );
            }
        });
        this.model.parcelas_superficie = Utils.decimalFormat(this.model.parcelas_superficie, 2, '.', ',', 3);

        const idxParcelas = this.getItemPosition(this.formFields, 'ids_parcelas');
        if (this.formFields[idxParcelas]) {
            this.formFields[idxParcelas]!.label = this.parcelasName + ' (' + this.tipoSuperficieParcelas
            + ':\xa0' + this.model.parcelas_superficie + '\xa0' + this.textoSuperficie + ')';
        }
    }

    public getObservaciones( event: any ) {
        this.model.observaciones = event;
    }

    private initFormData() {
        pool([
            build(this.clientesApi.clientesGET, this.clientes, 'nombre'),
            build(this.fincasApi.fincasGET, this.fincas, 'nombre', true),
            build(this.fincasApi.sectoresGET, this.sectores, 'nombre', true),
            build(this.fincasApi.parcelasGET, this.parcelas, 'nombre', true),
            build(this.trabajadoresApi.trabajadoresGET, this.trabajadores, 'nombre', true, true),
            build(this.trabajadoresApi.maquinariaGET,
                this.maquinarias, 'nombre', true),
            // build(this.visitasVariedadMesApi.distinctCultivosGET, this.cultivos, 'cultivo', true),
            // build(this.visitasVariedadMesApi.distinctVariedadesGET, this.variedades, 'variedad', true),
            build(this.siexApi.cultivosGET, this.cultivosPrecedentes, 'cultivo', true),
            build(this.productosApi.productos.GET, this.productos, 'nombre', true),
            build(this.tratamientosApi.tratamientosList, this.tratamientos, 'nombre')
        ], () => {
            this.countSuperficieParcelas();
            this.formRequest.update();
        });
    }

    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {
            if (this.appName === 'agrosalvi') {
                this.model.facturado = (+this.model.facturado === 1);                
            }
            
            if (this.appName === 'viverospereira') {
                this.model.no_esta = (+this.model.no_esta === 1);                
            }

            this.model.check_previa = this.model.check_previa === '1';

            if (this.horaFechaFinActualPorDefecto && this.getType() !== FormRequestTypes.EDIT) {
                const fechaActual = new Date();

                if (this.appName !== 'sirga') {
                    this.model['fecha_fin'] = fechaActual;
                }

                this.model['hora_fin'] = fechaActual;
            }

            if (this.showTareasSiembraProductos) {
                const intervalo = setInterval( () => {
                    if (this.productos.filtered.length <= 1) {

                    }else {
                        this.productos.filtered.forEach( producto => {
                            if (producto.value && producto.value.fecha_caducidad) {
                                const auxFechaCad = (producto.value.fecha_caducidad as String).split('/');

                               
                                const fechaCaducidad = auxFechaCad[0] + '/' + auxFechaCad[1] + '/' + auxFechaCad[2]; 

                                if (moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment())) {
                                    producto.label = '** ' + producto.label;
                                } else if (
                                    moment(fechaCaducidad, 'DD/MM/YYYY').isSameOrAfter(moment()) &&
                                    moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment().add(3, 'months'))
                                ) {
                                    producto.label = '* ' + producto.label;
                                }

                            }
                        });
                        clearInterval(intervalo);
                    }
                });
            }


            if (this.showTareasSiembraMaquinarias){
                const intervalo = setInterval( () => {
                    if ( this.maquinarias.filtered.length <= 1 ) {
    
                    } else {
                        this.maquinarias.filtered.forEach(row => {
                            if (row.value && row.value.fecha_ultima_inspeccion) {
                                const auxFechaCad = (row.value.fecha_ultima_inspeccion as string).split('/');
        
                                if (parseInt(auxFechaCad[0] ?? '', 10) >= 2020 ) {
                                    auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + 3).toString();
                                } else {
                                    auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + 5).toString();
                                } 
        
                                const fechaCaducidad = auxFechaCad[0] + '/' + auxFechaCad[1] + '/' + auxFechaCad[2]; 
    

                                if (moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment())) {
                                    row.label = '** ' + row.label;
                                } else if (
                                    moment(fechaCaducidad, 'DD/MM/YYYY').isSameOrAfter(moment()) &&
                                    moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment().add(3, 'months'))
                                ) {
                                    row.label = '* ' + row.label;
                                }
                            }
                        });
                        clearInterval(intervalo);
                    }  
                }, 500);
            }
            setTimeout(() => {
                this.countSuperficieParcelas();
            }, 100);
            resolve(true);
        });

        this.formRequest.beforeSend(resolve => {
            const clientIdFroFinca = (this.fincas.selected || {} as any).id_cliente;
            const clientIdFromDropdown = (this.clientes.selected || {} as any).id;
            const clientIdFromForm = this.model.id_cliente;
            const clientId = (StorageManager.getClient() || {} as any).id;

            this.model.id_cliente =
                clientIdFroFinca ? clientIdFroFinca :
                    clientIdFromDropdown ? clientIdFromDropdown :
                        clientIdFromForm ? clientIdFromForm :
                            clientId ? clientId : ''; // DEFAULT

            const userIdFromFinca = (this.clientes.selected || {} as any).id_usuario;
            const userIdFromDropdown = (this.clientes.selected || {} as any).id_usuario;
            const userIdFromForm = this.model.id_usuario;
            const userId = (StorageManager.getUser() || {} as any).id;

            this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromDropdown ? userIdFromDropdown :
                        userIdFromForm ? userIdFromForm :
                            userId ? userId : '';

            // this.model.fecha_siembra = this.model.fecha_siembra instanceof Date
            //     ? Utils.formatDate(this.model.fecha_siembra)
            //     : this.model.fecha_siembra;

            if (this.applicationType !== 'viverogest'){
                this.model.fecha_inicio = this.model.fecha_inicio instanceof Date
                    ? Utils.formatDate(this.model.fecha_inicio)
                    : this.model.fecha_inicio;

                this.model.fecha_fin = this.model.fecha_fin instanceof Date
                    ? Utils.formatDate(this.model.fecha_fin)
                    : this.model.fecha_fin;
            }
            else{
                this.model.fecha_siembra = this.model.fecha_siembra instanceof Date
                    ? Utils.formatDate(this.model.fecha_siembra)
                    : this.model.fecha_siembra;

                this.model.fecha_descarga = this.model.fecha_descarga instanceof Date
                    ? Utils.formatDate(this.model.fecha_descarga)
                    : this.model.fecha_descarga;
            }

            // this.model.cultivo = (this.cultivos.selected || {} as any).cultivo;
            // this.model.variedad = (this.variedades.selected || {} as any).variedad;
            this.model.cultivo_precedente = (this.cultivosPrecedentes.selected || {} as any).cultivo;
            this.model.cultivo = (this.parcelas.selected || []).map((it: { cultivo: any; }) => it.cultivo).join(';');
            this.model.variedad = (this.parcelas.selected || []).map((it: { variedad: any; }) => it.variedad).join(';');
            this.model.sup_cultivada = (this.parcelas.selected || []).map((it: { superficie_cultivada: any; }) => it.superficie_cultivada).join(';');
           
            resolve(true);
        });
    }
}

interface TypeAheadInterface {
    selected: any;
    values: any[];
    filtered: any[];
    filterCallback?: Filtering<any>;
}
