import { Injectable } from '@angular/core';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';

@Injectable({
    providedIn: 'root'
})
export class TratamientosApiService {

    public tratamientosGET: RequestHandler = new RequestHandler();
    public tratamientosDELETE: RequestHandler = new RequestHandler();
    public tratamientosPUT: RequestHandler = new RequestHandler();
    public tratamientosPOST: RequestHandler = new RequestHandler();
    public tratamientosControl = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };
    public tratamientosManez = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };
  
    public unidadesMedida = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()    
    };

    public tratamientosList: RequestHandler = new RequestHandler();

    public enviarRecetaCaptura: RequestHandler = new RequestHandler();    

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineTratamientosGET();
        this.defineTratamientosPOST();
        this.defineTratamientosDELETE();
        this.defineTratamientosPUT();
        this.defineTratamientosList();
        this.defineTratamientosControl();
        this.defineTratamientosManez();

        this.defineEnviarRecetaCaptura();        
    }

    public defineEnviarRecetaCaptura() {
        this.enviarRecetaCaptura.fetch(HttpRequestType.POST, 'tareas/enviar_receta_captura');
        this.api.registerRequest(this.enviarRecetaCaptura);
        this.syncService.register(this.syncService.baseSyncPolicy(this.enviarRecetaCaptura));
    }    

    public defineTratamientosGET() {
        this.tratamientosGET
            .fetch(HttpRequestType.GET, 'generic/tratamientos');

        this.api
            .registerRequest(this.tratamientosGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.tratamientosGET));
    }

    public defineTratamientosList() {
        this.tratamientosList
            .fetch(HttpRequestType.GET, 'tratamientos/tratamientos_get');

        this.api
            .registerRequest(this.tratamientosList);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.tratamientosList));
    }

    public defineTratamientosPOST() {
        this.tratamientosPOST
            .fetch(HttpRequestType.POST, 'generic/tratamientos');

        this.api
            .registerRequest(this.tratamientosPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.tratamientosPOST));
    }

    public defineTratamientosDELETE() {
        this.tratamientosDELETE
            .fetch(HttpRequestType.DELETE, 'generic/tratamientos');

        this.api
            .registerRequest(this.tratamientosDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.tratamientosDELETE));
    }

    public defineTratamientosPUT() {
        this.tratamientosPUT
            .fetch(HttpRequestType.PUT, 'generic/tratamientos');

        this.api
            .registerRequest(this.tratamientosPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.tratamientosPUT));
    }

    public defineTratamientosControl() {
        this.tratamientosControl.GET.fetch(HttpRequestType.GET, 'tratamientos/tratamientos_control_get');
        this.tratamientosControl.POST.fetch(HttpRequestType.POST, 'generic/tratamientos_control');
        this.tratamientosControl.PUT.fetch(HttpRequestType.PUT, 'generic/tratamientos_control');
        this.tratamientosControl.DELETE.fetch(HttpRequestType.DELETE, 'generic/tratamientos_control');

        this.api.registerRequest(this.tratamientosControl.GET);
        this.api.registerRequest(this.tratamientosControl.POST);
        this.api.registerRequest(this.tratamientosControl.PUT);
        this.api.registerRequest(this.tratamientosControl.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.tratamientosControl.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.tratamientosControl.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.tratamientosControl.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.tratamientosControl.DELETE));
    }

    public defineTratamientosManez() {
        this.tratamientosManez.GET.fetch(HttpRequestType.GET, 'tratamientos/tratamientos_manez_get');
        this.tratamientosManez.POST.fetch(HttpRequestType.POST, 'generic/tratamientos_manez');
        this.tratamientosManez.PUT.fetch(HttpRequestType.PUT, 'generic/tratamientos_manez');
        this.tratamientosManez.DELETE.fetch(HttpRequestType.DELETE, 'generic/tratamientos_manez');

        this.api.registerRequest(this.tratamientosManez.GET);
        this.api.registerRequest(this.tratamientosManez.POST);
        this.api.registerRequest(this.tratamientosManez.PUT);
        this.api.registerRequest(this.tratamientosManez.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.tratamientosManez.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.tratamientosManez.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.tratamientosManez.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.tratamientosManez.DELETE));
    }

    public defineUnidadesMedida() {
        this.unidadesMedida.GET.fetch(HttpRequestType.GET, 'tratamientos/unidadesMedida_get');
        this.api.registerRequest(this.unidadesMedida.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.unidadesMedida.GET));
    }
}
