import { BaseApi } from './base-api.class';
import { UserModel } from '../../models/user.model';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UserApiService extends BaseApi<UserModel> {

    constructor(_: HttpClient) {
        super(_);
    }

    public postUser(user: UserModel): Promise<UserModel> {
        return this.post('user/register', user)
            .then<UserModel>(this.getEntityData.bind(this));
    }

    public sendEmailConfirmation(data: any): Promise<any> {
        return this.post('user/sendEmailConfirmation', {
            id: data.id,
            usuario: data.usuario,
            email: data.email,
            estado: data.estado
        });
    }
}
