<div id="login-logo" class="form-shadow">
  <img [src]="logoUrl" style="
    background-color: white;
    display: block;
    margin: 0 auto;
    width: auto;
    height: 100%;" alt="">
</div>

<div *ngIf="!accountCreated" style="width: 90vw; max-width: 500px;" class="mx-auto mt-0 mb-5">
  <p-panel>
    <h3 class="text-center mb-4">Registrar una cuenta</h3>
    <div class="mb-2 small text-center">
      Todos los campos son obligatorios.
    </div>

    <hr />

    <div>
      <div class="mb-4">
        <h6 class="font-weight-bold mb-1">Datos de la cuenta</h6>
        <div class="custom-form-fieldset col-sm-6">
          <app-form-builder
            [model]="model"
            [distributionColumns]="1"
            [fields]="formFieldsAccount"
            (formChanges)="formChanges($event)">
          </app-form-builder>
        </div>
      </div>

      <div class="mb-4">
        <h6 class="font-weight-bold">Datos personales</h6>
        <div class="custom-form-fieldset mb-3">
          <app-form-builder
            [model]="model"
            [distributionColumns]="2"
            [fields]="formFieldsLeft"
            (formChanges)="formChanges($event)">
          </app-form-builder>
        </div>
      </div>

      <div *ngIf="!errors.usernameIsValid || !errors.emailIsValid" class="alert alert-danger">
        <span *ngIf="!errors.usernameIsValid">
          El nombre de usuario ya está en uso.
          <br />
        </span>
        <span *ngIf="!errors.emailIsValid">
          La dirección de email ya está en uso o es incorrecta.
          <br />
        </span>
      </div>
    </div>
    <br />
    <div class="d-flex justify-content-center">
      <div>
        <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        <button class="btn btn-primary mx-2" (click)="register()"
          [disabled]="!errors.usernameIsValid || !errors.emailIsValid">
          Registrarse
        </button>
      </div>
    </div>
  </p-panel>
</div>

<div *ngIf="accountCreated" style="max-width: 500px;" class="mx-auto mt-0 mb-5">
  <p-panel>
    <h3 class="text-center mb-4">Usuario registrado</h3>

    <hr />
    
    <p class="text-center p-2">
      Ha sido registrado con éxito.
      <br />
      Se le notificará vía email cuando un administrador valide su cuenta.
    </p>
    
    <br />
    
    <div class="d-flex justify-content-center">
      <div>
        <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
      </div>
    </div>
  </p-panel>
</div>