<p-panel>
  <h4>Datos del producto de ganadería</h4>
  <div class="form-row">
      <div class="form-group col-sm-12">
          <app-form-builder
                  [model]="model"
                  [distributionColumns]="2"
                  [fields]="form.topFormFields">
          </app-form-builder>
      </div>
  </div>
  <div class="form-row">
      <div class="form-group col-sm-6">
          <app-form-builder
                  [model]="model"
                  [distributionColumns]="2"
                  [fields]="form.bottomFormFieldsLeft">
          </app-form-builder>
      </div>
      <div class="form-group col-sm-6">
          <app-form-builder
                  [model]="model"
                  [distributionColumns]="1"
                  [fields]="form.bottomFormFieldsRight">
          </app-form-builder>
      </div>
  </div>
  <div class="form-row">
      <div class="col-sm-12">
          <app-form-builder
                  [model]="model"
                  [distributionColumns]="1"
                  [fields]="form.baselineFormFields">
          </app-form-builder>
      </div>
  </div>
  <br>

  <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
      <app-request-button
              (click)="submit()"
              [mode]="requestButtonType"
              [lockOnSuccess]="true"
              [request]="request">
      </app-request-button>
  </div>
</p-panel>
<br>
