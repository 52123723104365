<br>

<!-- FORM FIELDS -->
<p-panel>
    <div style="display: flex;">
        <h4>Datos de riego</h4>
     
    </div>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="2" 
            [fields]="form.formFields">
        </app-form-builder>
        <h4>Horario Riego</h4>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="2" 
            [fields]="form.formFieldsHorarioRiego">
        </app-form-builder>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="2"
            [fields]="form.formFieldsHoraRiego">
        </app-form-builder>
        <h5>Sectores</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="2"
            [fields]="form.formFieldsSectores">
        </app-form-builder>
        <h4 [style.display]="!creacion ? 'block' : 'none'">Sustitución</h4>
        <h4 [style.display]="creacion ? 'block' : 'none'">Instalación</h4>
        <h5>Aspersores</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsAspersores">
        </app-form-builder>  
        <h5>Difusores</h5>
        <div id="flex">
            <div id="difusor">
                <app-form-builder
                    [model]="model"
                    (formChanges)="form.formChanges($event); formChanges($event)"
                    [distributionColumns]="2" 
                    [fields]="form.formFieldsDifusores">
                </app-form-builder>
            </div>
            <div id="arqueta">
                <app-form-builder
                    [style.display]="!creacion ? 'block' : 'none'"
                    [model]="model"
                    (formChanges)="form.formChanges($event); formChanges($event)"
                    [distributionColumns]="1" 
                    [fields]="form.formFieldsArqueta">
                </app-form-builder>  
            </div>
        </div>
        <h5>Arquetas</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsArquetas">
        </app-form-builder>  
        <h5>Electroválvulas 9v Latch</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3"
            [fields]="form.formFieldsElectrovalvulas9vLatch">
        </app-form-builder>
        <h5>Electroválvulas 24v</h5>  
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsElectrovalvulas24v">
        </app-form-builder>  
        <h5>Solenoides</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsSolenoides">
        </app-form-builder>  
        <h5>Programador Pilas</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsProgramadorPilas">
        </app-form-builder>  
        <h5>Programador 220v (estaciones)</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsProgramador220v">
        </app-form-builder>  
        <h5>Caja Conexión (estaciones)</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="4" 
            [fields]="form.formFieldsCajaConexion">
        </app-form-builder> 
        <h5>Tubería Goteo - Gotero a: (ml)</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsTuberiaGoteo">
        </app-form-builder>
        <h5>Tubería Lisa (ml)</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsTuberiaLisa">
        </app-form-builder>
        <h5>Pilas</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsPilas">
        </app-form-builder>
        <h4 [style.display]="!creacion ? 'block' : 'none'">Reparación Fugas</h4>
        <h4 [style.display]="creacion ? 'block' : 'none'">Instalación</h4>
        <h5>Tubería P.E. (Diámetro)</h5>
        <app-form-builder
            [style.display]="!creacion ? 'block' : 'none'"
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            0distributionColumns]="3" 
            [fields]="form.formFieldsTuberiaPE01">
        </app-form-builder>
        <app-form-builder
            [style.display]="creacion ? 'block' : 'none'"
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            0distributionColumns]="3" 
            [fields]="form.formFieldsTuberiaPE02">
        </app-form-builder>
        <h5>Tubería PVC (Diámetro - m)</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsTuberiaPVC">
        </app-form-builder>
        <h5>Riego con Manguera</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsRiegoManguera">
        </app-form-builder>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsTrabajadores">
        </app-form-builder>                              
        <br>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <app-form-builder 
                    [model]="model" 
                    (formChanges)="form.formChanges($event);" 
                    [distributionColumns]="1"
                    [fields]="form.formFieldsOrdenTrabajo">
                </app-form-builder>
            </div>
            <div class="col-lg-1 col-md-1">
                <div class="tareas-camara">
                    <app-upload-images
                    [model]="model"
                    folder="riego"
                    tipo="form"
                    (sendImageName)="updateImageModel($event)"
                    ></app-upload-images>
                </div>
            </div>
        </div>                
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <app-form-builder
                  [model]="model"
                  (formChanges)="form.formChanges($event); formChanges($event)"
                  [distributionColumns]="1" 
                  [fields]="form.formFieldsObs">
                </app-form-builder> 
            </div>
            <div class="col-lg-1 col-md-1">
                <div class="tareas-camara">
                    <app-upload-images 
                      [model]="model" 
                      folder="riego"
                      nombre_imagen = 'image_after' 
                      tipo="form" 
                      (sendImageName)="updateAfterImageModel($event)"
                    ></app-upload-images>
                </div>
            </div>
        </div>                                
    <br>
    <div class="distribution_camera_signature" style="display: flex;
    gap: 50px;
    align-items: center;" *ngIf="this.model['signature_img']">
        <div>
            <div *ngIf="this.model['signature_img']" style="display: flex; flex-direction: column;">
                <label>Firma</label>
                <img *ngIf="!this.model['signature_img'].includes('base64')" [src]="serverUrl + 'images/mto_riego/'+this.model['signature_img'] " class="info-firma-img">
                <img *ngIf="this.model['signature_img'].includes('base64')" [src]="this.model['signature_img'] " class="info-firma-img">
            </div>
        </div>  
        <app-signature (sendFile)="getSignature($event)" (sendTempImg)="updateFirma($event);"></app-signature>
    </div>
    <div class="distribution_camera_signature"  *ngIf="!this.model['signature_img']">
         
        <app-signature (sendFile)="getSignature($event)" (sendTempImg)="updateFirma($event);"></app-signature>
    </div>         
   <div style="height: 50px;">

   </div>
    <!-- ACTION BUTTONS -->
    <div class="d-flex justify-content-center">
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>
<br>