import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';

@Injectable({
    providedIn: 'root'
})
export class AnalisisApiService {

    public analisis = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };


    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineAnalisis();

    }

    private defineAnalisis() {
        this.analisis.GET.fetch(HttpRequestType.GET, 'analisis/get');
        this.analisis.POST.fetch(HttpRequestType.POST, 'analisis/post');
        this.analisis.PUT.fetch(HttpRequestType.PUT, 'generic/analisis');
        this.analisis.DELETE.fetch(HttpRequestType.DELETE, 'generic/analisis');

        this.api.registerRequest(this.analisis.GET);
        this.api.registerRequest(this.analisis.POST);
        this.api.registerRequest(this.analisis.PUT);
        this.api.registerRequest(this.analisis.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.analisis.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.analisis.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.analisis.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.analisis.DELETE));
    }
}
