
<p-panel>
  <div style="max-width: 60%; position: absolute; right: 2rem;">
    <div  style="display: flex;">
      <input type="file" #adjuntarCRM (change)="fileChange($event)" style="display: none;"/>
      <button *ngIf="model?.archivo" class="btn btn-link" (click)="verArchivo()">Ver</button>
      <input type="button" class="btn btn-success" value="Adjuntar Documento" (click)="readUrl(adjuntarCRM)" />
    </div>
  </div>
    
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">Actualizar Tarea comercial</h4>
      <h4 *ngIf="flag === 'new'">Crear Tarea comercial</h4>
    </div>
  </div>
  
  <app-form-builder #mainForm
          [model]="model"
          [undoEnabled]="undoEnabled"
          [distributionColumns]="3"
          [fields]="formFields"
          >
  </app-form-builder>
  

  <br>


  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            (click)="submit()"
            [mode]="requestButtonType"
            [lockOnSuccess]="true"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>

