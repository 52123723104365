import { Injectable } from '@angular/core';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';

@Injectable({
    providedIn: 'root'
})
export class OtrasDenominacionesApiService {

    public otrasDenominacionesGET: RequestHandler = new RequestHandler();

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineOtrasDenominacionesGET();
    }

    public defineOtrasDenominacionesGET() {
        this.otrasDenominacionesGET
            .fetch(HttpRequestType.GET, 'mg/otrasdenominaciones');

        this.api
            .registerRequest(this.otrasDenominacionesGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.otrasDenominacionesGET));
    }
}
