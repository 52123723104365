import {RequestHandler} from './request-handler';

export class SyncPolicy {

    private request: RequestHandler;

    private load: Function = new Function();
    private onlineBack: Function = new Function();
    private customPolicy: Function = new Function();

    constructor(request: RequestHandler){
        this.request = request;
    }

    onLoad(callback: Function): SyncPolicy {
        this.load = callback;
        return this;
    }

    onOnlineBack(callback: Function): SyncPolicy {
        this.onlineBack = callback;
        return this;
    }

    setCustomPolicy(callback: Function): SyncPolicy {
        this.customPolicy = callback;
        return this;
    }

    emitLoad(){
        this.load(this.request);
    }

    emitOnlineBack(){
        this.onlineBack(this.request);
    }

    emitCustomPolicy(){
        this.customPolicy(this.request);
    }

}
