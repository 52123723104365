import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MessageService } from 'primeng/api';
import { first, interval, concat } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {

  constructor(appRef: ApplicationRef, private updates: SwUpdate, private messageService: MessageService) {
    if ('serviceWorker' in navigator) {
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(async () => {
        try {
          const updateFound = await this.updates.checkForUpdate();
          if (updateFound) {

            // alert('Hay una nueva versión disponible. Por favor, actualiza la página.')

            this.messageService.add({ key:'confirm', severity: 'info', summary: 'Nueva versión disponible', detail: 'Acualiza la aplicacion para obtener el nuevo contenido'})
            
          } else {
            console.log('Ya estás en la última versión.');
          }
        } catch (err) {
          console.error('Error al verificar actualizaciones:', err);
        }
      });
    } else {
      console.warn('Los service workers no son compatibles con este navegador');
    }
  }
}
