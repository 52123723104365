import {Injectable} from '@angular/core';
import {RequestHandler} from 'src/app/service/OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';

@Injectable({
    providedIn: 'root'
})
export class RecogidasApiService {

    public recogidasGET: RequestHandler = new RequestHandler();
    public recogidasPUT: RequestHandler = new RequestHandler();
    public recogidasPOST: RequestHandler = new RequestHandler();
    public recogidasDELETE: RequestHandler = new RequestHandler();

    public plazoSeguridadGET: RequestHandler = new RequestHandler();

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineRecogidasGET();
        this.defineRecogidasPUT();
        this.defineRecogidasPOST();
        this.defineRecogidasDELETE();
        this.definePlazoSeguridadGET();
    }

    private defineRecogidasGET() {
        this.recogidasGET
            .fetch(HttpRequestType.GET, 'recogidas/get');

        this.api
            .registerRequest(this.recogidasGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.recogidasGET));
    }

    private defineRecogidasPUT() {
        this.recogidasPUT
            .fetch(HttpRequestType.PUT, 'generic/recogidas');

        this.api
            .registerRequest(this.recogidasPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.recogidasPUT));
    }

    private defineRecogidasPOST() {
        this.recogidasPOST
            .fetch(HttpRequestType.POST, 'generic/recogidas');

        this.api
            .registerRequest(this.recogidasPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.recogidasPOST));
    }

    private defineRecogidasDELETE() {
        this.recogidasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/recogidas');

        this.api
            .registerRequest(this.recogidasDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.recogidasDELETE));
    }

    private definePlazoSeguridadGET() {
        this.plazoSeguridadGET.fetch(HttpRequestType.GET, 'recogidas/plazo_seguridad_get');
        this.api.registerRequest(this.plazoSeguridadGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.recogidasGET));
    }
}


