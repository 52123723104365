import { Component, ViewChild } from '@angular/core';
import {TareasApiService} from '../../../../../service/api/tareas-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { environment } from 'src/environments/environment';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { build, list, pool } from 'src/app/common/classes/request-builder';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { StorageManager } from 'src/app/common/storage-manager.class';
import { Utils } from 'src/app/common/utils';
import { SiexApiService } from 'src/app/service/api/siex-api.service';
import moment from 'moment';
import { TrabajadoresApiService } from 'src/app/service/api/trabajadores-api.service';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-ecorregimes-form',
    templateUrl: './ecorregimes-form.component.html',
    styleUrls: ['./ecorregimes-form.component.css']
})
export class EcorregimesFormComponent  extends BaseForm  implements OnInit{
  
    @ViewChild(RequestButtonComponent)
    requestButton: RequestButtonComponent = new RequestButtonComponent();

    public formRequest: AppFormRequest = new AppFormRequest();

    public parcelasName = environment.features.parcelasName;
    public tipoSuperficieParcelas = environment.features.hasSuperficieCultivada
        ? 'Sup.\xa0Cultivada'
        : 'S.\xa0Sigpac';
    public textoSuperficie = environment.features.textoSuperficie;
    public appName = environment.appName;

    public override model: any = {};
    public tratamientosList = list(['...', null]);
    public unidadList = list(['...', null]);

    public listFincas = list();
    public listSectores = list();
    public listParcelas = list();

    public listMaquinaria = list();
    public tareas_labor = list(['...', null],
        'Fangueo',
        'Hacer Márgenes',
        'Trailla',
        'Láser',
        'Cultivador',
        'Rotovator',
        'Multiapero',
        'Paquete de trabajo 1',
        'Paquete de trabajo 2',
        'Desbroce Cursos de Agua'
    );

    public actividadesSobreCubierta = list();

    public formFieldsPlaces: ItemInterface<any>[] = [
        { 
            field: 'id_finca', 
            label: 'Finca', 
            inputType: {type: InputType.DROPDOWN_SEARCH }, 
            values: this.listFincas,
            valuePrimaryKey: 'id',
            required: true,
     
        },
        { 
            field: 'id_sector',
            label: 'Sector',
            inputType: { type: InputType.DROPDOWN_MULTISELECT_SEARCH },
            valuePrimaryKey: 'id',
            multiSelect: true,
            canSelectAll: true,
            values: this.listSectores,
            filter: (it: { value: { id_finca: any; }; }) => {
                if (this.listFincas.selected) {
                    return it.value.id_finca === (this.listFincas.selected || {}).id;
                } else {
                    return false;
                }
            },
        },
        { 
            field: 'id_parcelas', 
            label: this.parcelasName, 
            inputType: {type: InputType.MULTISELECT }, 
            multiSelect: true,
            canSelectAll: true,
            values: this.listParcelas,
            valuePrimaryKey: 'id',
            filter: (it: { value: { activo: string; id_sector: any; id_finca: any; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                if (this.listSectores.selected) {
                    return this.listSectores.selected.map((sector: { id: any; }) => sector.id).includes(it.value.id_sector);        
                } else if (this.listFincas.selected) {
                    return it.value.id_finca === (this.listFincas.selected || {} as any).id;
                }
            },
            required: true
        }
    ];

    public formFields: ItemInterface<any>[] = [
    
        { 
            field: 'fecha_establecimiento_cv', 
            label: 'Fecha Establecimiento Cubierta Vegetal', 
            inputType: { type: InputType.CALENDAR } 
        },
        { 
            field: 'anchura_cv', 
            label: 'Anchura Cubierta Vegetal (m)', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            field: 'tipo_mantenimiento_cv', 
            label: 'Tipo Mantenimiento Cubierta Vegetal', 
            inputType: { type: InputType.DROPDOWN },
            values: this.actividadesSobreCubierta,
            valuePrimaryKey: 'codigo'
        },
        {
            field: 'anchura_libre_proyeccion_1',
            label: 'Anchura Libre Proyeccion Copa (m)',
            inputType: { type: InputType.EDIT_NUMERIC } 
        },

    ];

    public formFieldsCubiertasInertes: ItemInterface<any>[] = [
        { field: 'fecha_establecimiento_ci', label: 'Fecha Establecimiento Cubierta Inerte', inputType: { type: InputType.CALENDAR } },
        { field: 'anchura_ci', label: 'Anchura Cubierta Inerte (m)', inputType: { type: InputType.EDIT_NUMERIC } },
        { 
            field: 'anchura_libre_proyeccion_2', 
            label: 'Anchura libre de la Proyección Copa (m) ', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
    ];

    public formFieldsArroz: ItemInterface<any>[] = [
        { field: 'fecha_arroz', label: 'Fecha Arroz', inputType: { type: InputType.CALENDAR } },
        { field: 'labor', label: 'Labor', inputType: { type: InputType.DROPDOWN }, values: this.tareas_labor },
        { 
            field: 'tipo_operacion', 
            label: 'Tipo Operación', 
            inputType: { type: InputType.DROPDOWN },
            values: list('', 'Escarda química', 'Desbroce mecánico'), 
        },
        { 
            field: 'id_maquinaria', 
            label: 'Maquinaria', 
            inputType: { type: InputType.DROPDOWN }, 
            values: this.listMaquinaria,
            valuePrimaryKey: 'id'
        },
        { field: 'observaciones', label: 'Observaciones', inputType: { type: InputType.EDIT_TEXTAREA } },
    ];


    requestType: FormRequestTypes = 0;


    constructor(public override dashboard: DashboardService,                 
        public tareasApi: TareasApiService,
        public override route: ActivatedRoute,
        public override router: Router,
        public fincasApi: FincasApiService,
        public siexApi: SiexApiService,
        public trabajadoresApi: TrabajadoresApiService
   
    ) { 
        super(
            route,
            router,
            dashboard,
            tareasApi.ecorregimenes.PUT,
            tareasApi.ecorregimenes.POST,
            'tareas-ecorregimenes',
            'Editar Tarea Ecorregímenes',
            'Añadir datos Ecorregímenes',
            'Duplicar tareas Ecorregímenes'
        ); 
    }

    ngOnInit() {
        this.initFormData();
        this.expandFormRequest();

        this.formRequest
            .setType(this.getType())
            .setModel(this.model)
            .setRegisterId(this.getRegisterId())
            .setGetRequest(this.tareasApi.ecorregimenes.GET)
            .setPostRequest(this.tareasApi.ecorregimenes.POST)
            .setPutRequest(this.tareasApi.ecorregimenes.PUT)
            .setFormFields(this.formFields)
            .setFieldsToSend(
                (['id', 'nombre_mantenimiento'] as any)
                    .concat(
                        this.formFieldsPlaces.map(it => it.field)
                    )
                    .concat(
                        this.formFields.map(it => it.field)
                    ).concat(
                        this.formFieldsCubiertasInertes.map(it => it.field)
                    ).concat(
                        this.formFieldsArroz.map(it => it.field)
                    )
            );

        this.formRequest.load();
        this.softInit(this.getType());

        this.countSuperficieParcelas();
    }

  
    ngAfterView(){
    
    }

    formChanges(){
        this.countSuperficieParcelas();
    }


    initFormData() {
        return pool([
            build(this.fincasApi.fincasGET, this.listFincas, 'nombre'),
            build(this.fincasApi.sectoresGET, this.listSectores, ['nombre'], true),
            build(this.fincasApi.parcelasGET, this.listParcelas, 'nombre', true, true),
            build(this.siexApi.actividadesSobreCubiertaGET, this.actividadesSobreCubierta, 'actividad'),
            build(this.trabajadoresApi.maquinariaGET, this.listMaquinaria, 'nombre')
        ], () => {

            if (this.listFincas.filtered.length === 2 && this.getType() === 0) {
                this.model['id_finca'] = this.listFincas.filtered[1].value.id;
            }
            this.formRequest.update();

        });
    }

    submit() {

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
            console.log('Error');
            return;
        }
        if ((typeof this.model.fecha_establecimiento_ci !== 'string')) {

            if ((this.model.fecha_establecimiento_ci != null)) {
                this.model.fecha_establecimiento_ci = moment(this.model.fecha_establecimiento_ci).format('DD/MM/YYYY');
            }
            // La forma antigua para rellerafecha cuando este vacia
            // this.model.fecha_exp = moment(this.model.fecha_exp || new Date()).format('DD/MM/YYYY');
        }
        if ((typeof this.model.fecha_establecimiento_cv !== 'string')) {

            if ((this.model.fecha_establecimiento_cv != null)) {
                this.model.fecha_establecimiento_cv = moment(this.model.fecha_establecimiento_cv).format('DD/MM/YYYY');
            }
        }
        if ((typeof this.model.fecha_arroz !== 'string')) {

            if ((this.model.fecha_arroz != null)) {
                this.model.fecha_arroz = moment(this.model.fecha_arroz).format('DD/MM/YYYY');
            }
        }

        if (this.actividadesSobreCubierta.selected) {
            this.model['nombre_mantenimiento'] = this.actividadesSobreCubierta.selected.actividad;
        }
    
        this.formRequest.send();
    }

    public selectAll() {
        this.countSuperficieParcelas();
    }

    public getObservaciones( event: any ) {
        this.model.observaciones = event; 
    }

    public countSuperficieParcelas() {
        const ids = (this.model.id_parcelas || '').split(';');
        this.model.parcelas_superficie = 0;

        (this.listParcelas.filtered || []).forEach(parcela => {
            if (parcela && parcela.value && ids.includes(parcela.value.id)) {
                this.model.parcelas_superficie += parseFloat((parcela.value.superficie_cultivada || '').replace(',', '.') || '0.00');
            }
        });
        this.model.parcelas_superficie = Utils.decimalFormat(this.model.parcelas_superficie, 2, '.', ',', 3);

        const idxParcelas = this.getItemPosition(this.formFieldsPlaces, 'id_parcelas');
        
        if (this.formFieldsPlaces[idxParcelas]) {
            this.formFieldsPlaces[idxParcelas]!.label = this.parcelasName + ' (' + this.tipoSuperficieParcelas
            + ':\xa0' + this.model.parcelas_superficie + '\xa0' + this.textoSuperficie + ')';
        }
    }

  
    private expandFormRequest() {

        this.formRequest.beforeLoad(resolve => {
            this.initFormData().then(() => {
                resolve(true);
            });
        });

        this.formRequest.beforeSend(resolve => {
            this.model['fecha_entrada'] = this.model['fecha_entrada'] instanceof Date
                ? Utils.formatDate(this.model['fecha_entrada'])
                : this.model['fecha_entrada'];

            this.model['fecha_salida'] = this.model['fecha_salida'] instanceof Date
                ? Utils.formatDate(this.model['fecha_salida'])
                : this.model['fecha_salida'];

            this.model['fecha_recoleccion_rastrojo'] = this.model['fecha_recoleccion_rastrojo'] instanceof Date
                ? Utils.formatDate(this.model['fecha_recoleccion_rastrojo'])
                : this.model['fecha_recoleccion_rastrojo'];
        
            this.model['fecha_operacion'] = this.model['fecha_operacion'] instanceof Date
                ? Utils.formatDate(this.model['fecha_operacion'])
                : this.model['fecha_operacion'];

            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() || {} as any).id;
            const userIdFromForm = this.model['id_usuario'];

            this.model.id_usuario =
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';            
      
            setTimeout(() => {
                this.countSuperficieParcelas();
            }, 100);  
            resolve(true);
        });

        // this.formRequest.afterFinish(resolve => {
        //   const type = this.getType();
        //     if (type === FormRequestTypes.DUPLICATE || FormRequestTypes.EDIT || FormRequestTypes.CREATE) {         
        //         setTimeout(() => {
        //             window.location.reload();
        //         }, 200);
        //     }
        //     resolve(true);
        // });

    }  
}


