import { createCsv } from 'src/app/common/createCsv';
import {environment} from '../../../../../environments/environment';
import {TareasApiService} from '../../../../service/api/tareas-api.service';
import {TratamientosApiService} from '../../../../service/api/tratamientos-api.service';
import { Component, ViewChild, ElementRef } from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {Filtering} from '../../../../service/filtering/filtering';
import {Utils} from '../../../../common/utils';
import {FormCommonApiService} from '../../../../service/api/formcommon-api.service';
import {BaseView} from '../../../base-view';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {StorageManager} from '../../../../common/storage-manager.class';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { TrabajadoresApiService } from 'src/app/service/api/trabajadores-api.service';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import moment from 'moment';
import { MessageService } from 'primeng/api';

import type { OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-albaranes',
    templateUrl: './albaranes.component.html',
    styleUrls: ['./albaranes.component.scss']
})

export class AlbaranesComponent extends BaseView implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('passport', {static: true}) passport: ElementRef | undefined;

    public showBtnTratamiento = environment.features.showBtnTratamiento;
    public years = [
        {label: '...', value: 0},
    ];
    public selectedYears = 0;
    public allFitoProducts: any[] = [];
    public showClimatologia = environment.features.showClimatologia;
    public showAllTareas: boolean = false;
    public distributionOrderFormTratamientos = [2, 3, 2];

    public rolUser = StorageManager.getUser().rol;
    public hiddenButtonDelete = false;

    public showAplicados = environment.features.showAplicados;
    public hasFincas = environment.features.hasFincas;
    public appName: string = environment.appName;
    public serverUrl = environment.serverUrl;
    public logoUrl: string = environment.logoUrl;
    public showEnviarWhatsapp = environment.features.showEnviarWhatsapp;
    public showEnviarTelegram = environment.features.showEnviarTelegram;
    public showEnviarCorreo = environment.features.showEnviarCorreo;
    public showPrescripcionTratamiento = environment.features.showPrescripcionTratamiento;
    public showRecetaTratamiento = environment.features.showRecetaTratamiento;
    public showRecetaBuitech = environment.features.showRecetaBuitech;
    public showFichaTratamiento = environment.features.showFichaTratamiento;
    public showRegistroTratamiento = environment.features.showRegistroTratamiento;
    public showRegistroTratamientoShort = environment.features.showRegistroTratamientoShort;
    public showRegistroHernandorena = environment.features.showRegistroHernandorena;
    public showRecetaVGiner = environment.features.showRecetaVGiner;
    public showRecetaGlobalGAP = environment.features.showRecetaGlobalGAP;
    public showFitoValidado = environment.features.showFitoValidado
        ? environment.features.showFitoValidado && (StorageManager.getUser().rol === 'admin')
        : false;

    public showTractorMarcha = environment.features.showTractorMarcha;
    public showTractorPresion = environment.features.showTractorPresion;
    public showTractorRPM = environment.features.showTractorRPM;
    public showTractorBoquillas = environment.features.showTractorBoquillas;
    public showTractorVelocidad = environment.features.showTractorVelocidad;
    public showTractorKmRecorridos = environment.features.showTractorKmRecorridos;
    public showTractorConfig = (
        this.showTractorMarcha ||
        this.showTractorPresion ||
        this.showTractorRPM ||
        this.showTractorBoquillas ||
        this.showTractorVelocidad ||
        this.showTractorKmRecorridos
    );

    public tooltipForce = false;
    public tooltipClass = this.tooltipForce ? 'telegram-passport-tooltip telegram-passport-tooltip-shown' : 'telegram-passport-tooltip';

    public showTipoPlaga = environment.features.showTipoPlaga;
    public showMotivoFito = environment.features.showMotivoFito;
    public showTareasFitoObservaciones = environment.features.showTareasFitoObservaciones;
    public showRecetaPascualCabedo = environment.features.showRecetaPascualCabedo;
    public showRecetaLaplana = environment.features.showRecetaLaplana;
    public showPhCaldo = environment.features.showPhCaldo;
    public showRecetaFirmas = environment.features.showRecetaFirmas;
    public showCampaign = environment.features.showTareasFitosanitariasCampaign;
    public clienteName = environment.features.clienteName;
    public showTareasFitoPuntoKm = environment.features.showTareasFitoPuntoKm;
    public showTareasProcura = environment.features.showTareasProcura;      
    public showMomentoAplicacion = environment.features.showMomentoAplicacion;    
    public textoSuperficie = environment.features.textoSuperficie;  
    public showProductosMaxAplicacion = environment.features.showProductosMaxAplicacion;
    public betweenDates = environment.features.showTareasFitoBetweenDates;

    public showTareasBioExportCsv = environment.features.showTareasBioExportCsv;

    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public empresaName = environment.features.empresaName;
    public canAddTareas = environment.features.canAddTareas;
    public canDuplicateTareas = environment.features.canDuplicateTareas;
    public showTareasBio = environment.features.showTareasBio;
    public showTareasFertilizantes = environment.features.showTareasFertilizantes;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
        (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));
    public showTareasFincas = environment.features.showTareasFincas;
    public showTareasSector = environment.features.showTareasSector;
    public showTareasFitoSectorMultiple = environment.features.showTareasFitoSectorMultiple;
    public showTareasParcela = environment.features.showTareasParcela;
    public showTareasMaquinaria = environment.features.showTareasMaquinaria;
    public showTareasTratamientos = environment.features.showTareasTratamientos;
    public showTareasMateriaActiva = environment.features.showTareasMateriaActiva;
    public tareasTratamientosLabel = environment.features.tareasTratamientosLabel;
    public showFitoFechaReceta = environment.features.showFitoFechaReceta;
    public showFitoMomentoAplicacion = environment.features.showFitoMomentoAplicacion;
    public showFitoCosteTratamiento = environment.features.showFitoCosteTratamiento;
    public showFitoTipoTratamiento = environment.features.showFitoTipoTratamiento;
    public showTareasFitoIT = environment.features.showTareasFitoIT;
    public showTareasFitoLote = environment.features.showTareasFitoLote;
    public showTareasFitoMaquinarias = environment.features.showTareasFitoMaquinarias;
    public showTareasFitoHoras = environment.features.showTareasFitoHoras;
    public showAplicadoSwitch = environment.features.showAplicadoSwitch;
    public rol = StorageManager.getUser().rol;

    public showFitoEficacia = environment.features.showFitoEficacia;
    public showFitoProductosNombres = environment.features.showFitoProductosNombres;
    public showDesplazamientoObra = environment.features.showDesplazamientoObra;
    public showTareasFitoCarretera = environment.features.showTareasFitoCarretera;
    public showTareasFitoPuntoKM = environment.features.showTareasFitoPuntoKM;
    public showTareasFitoDuracion = environment.features.showTareasFitoDuracion;
    public showTareasFitoMotivoJustificacion = environment.features.showTareasFitoMotivoJustificacion;

    public showUsuariosFirma = environment.features.showUsuariosFirma;
    public showClientesFirma = environment.features.showClientesFirma;
    public showTrabajadoresFirma = environment.features.showTrabajadoresFirma;
    public showTareasFitoSoleado = environment.features.showTareasFitoSoleado;
    public showTareasFitoLitrosAplicados = environment.features.showTareasFitoLitrosAplicados;
    public showTareasFitoMochila = environment.features.showTareasFitoMochila;
    public showTareasFitoAplicacion = environment.features.showTareasFitoAplicacion;
    public showClientesNotas = environment.features.showClientesNotas;

    public isGeneratingCanvas: boolean = false;
    public firmaPath: string = '';

    public getRequest: RequestHandler = this.tareasApi.fitosanitariosGET;
    public deleteRequest: RequestHandler = this.tareasApi.fitosanitariosDELETE;
    public pathToForm = 'crear-albaran';
    public menuItemId = 'albaranes';
    public aplicado = {
        selected: ''
    };
    public validado = {
        selected: ''
    };

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: { [key: string]: any } = {};
    public override model: any = {};

    public showReceta = false;
    public isShowingGlobalGAP = false;
    public receta: any;
    public arrayProdsNom: any[] = [];
    public arrayProdsUds: any[] = [];
    public arrayCantsReceta: any[] = [];
    public arrayUdsMedida: string[] = [];
    public ps = 0;
    public currentUser = StorageManager.getUser();
    public clienteSelected = '';
    public telefClienteSelected = '';
    public observaciones = '';
    public empresa = 'Locatec Aplicaciones Informáticas';

    public mostrarTratamientosAplicados = true;
    public override filtering: Filtering<any> = new Filtering<any>();
    public currentYear: string = '';

    public btnAnadir = 'Añadir Tarea';

    public distributionOrder = [4];

    public nameReceta: string = environment.features.showRecetaPascualCabedo && 
        this.appName !== 'pascualcabedo' ? 
        'Parte de trabajo' : 
        'Generar Receta';

    public tareasBio: any[] = [];
    public parcelas: ParcelasModel[] = [];
    public fincas: any[] = [];
    public parcelasFilter: ParcelasModel[] = [];
    public trabajadores: MantenimientoModel[] = [];
    public trabajadoresFilter: MantenimientoModel[] = [];
    public sm = localStorage.getItem('sm') ? true : false;

    public formFieldsTiempo: ItemInterface<any>[] = [
        {
            field: 'fecha_inicio',
            name: (environment.appName === 'armentia') ? 'Fecha inicio' : 'Fecha Receta',
            label: (environment.appName === 'armentia') ? 'Fecha inicio' : 'Fecha Receta',
            inputType: {type: InputType.TEXT},
            visible: !environment.features.showTareasMto
        },
        /*{
            field: 'fecha_seca_cultivo_agua', 
            label: 'Fecha Seca Arroz', 
            inputType: {type: InputType.TEXT},
            visible: this.appName == 'sequiador'
        }*/
    ];
    
    public formFieldsKM: ItemInterface<any>[] = [
        {
            field: 'punto_km',
            name: 'Punto Km',
            label: 'Punto Km',
            inputType: {type: InputType.TEXT},
            visible: this.showTareasFitoPuntoKM && !this.showTareasFitoPuntoKm
        },
        {
            field: 'punto_km_inicio',
            name: 'Punto Km inicial',
            label: 'Punto Km inicial',
            inputType: {type: InputType.TEXT},
            visible: this.showTareasFitoPuntoKm
        },
        {
            field: 'punto_km',
            name: 'Punto Km final',
            label: 'Punto Km final',
            inputType: {type: InputType.TEXT},
            visible: this.showTareasFitoPuntoKm
        }
    ];
    
    public formFieldsLugar: ItemInterface<any>[] = [
        {
            field: 'finca',
            name: this.fincaName,
            label: this.fincaName,
            inputType: {type: InputType.TEXT},
            visible: this.hasFincas && this.showTareasFincas
        },
        {
            field: 'sectores_nombres',
            name: this.sectorName,
            label: this.sectorName,
            inputType: {type: InputType.TEXT},
            visible: this.showTareasSector,
        },
        {
            field: 'parcelas_nombres',
            name: this.parcelasName,
            label: this.parcelasName,
            inputType: {type: InputType.TEXT},
            visible: this.showTareasParcela
        }
    ];

    public formFieldsTratamiento: ItemInterface<any>[] = [
        {
            field: 'trabajadores_nombres',
            name: 'Profesionales',
            label: 'Profesionales',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'momento_aplicacion',
            name: 'Momento de aplicación',
            label: 'Momento de aplicación',
            inputType: {type: InputType.TEXT},
            visible: this.showFitoMomentoAplicacion
        },
        {
            field: 'coste_tratamiento',
            name: 'Coste (€)',
            label: 'Coste (€)',
            inputType: {type: InputType.TEXT},
            visible: this.showFitoCosteTratamiento
        },
        {
            field: 'tipo_tratamiento',
            name: 'Tipo de tratamiento',
            label: 'Tipo de tratamiento',
            inputType: {type: InputType.TEXT},
            visible: this.showFitoTipoTratamiento
        },
        {
            field: 'tratamiento_nombre',
            name: 'Tratamientos',
            label: 'Tratamientos',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'ph_caldo',
            name: 'pH caldo',
            label: 'pH caldo',
            inputType: {type: InputType.TEXT},
            visible: this.showPhCaldo
        },
        {
            field: 'litros',
            name: this.appName === 'onda' ? 'Litros' : 
                this.appName === 'hernandorena' ? 'Caldo (litros)' : 
                    this.appName !== 'innovia' ? 
                        'Litros/' + this.textoSuperficie + (this.appName === 'vginer' ? ' (recomendación)' : '') : 
                        'Litros Caldo',
            label: this.appName === 'onda' ? 'Litros' : 
                this.appName === 'hernandorena' ? 'Caldo (litros)' : 
                    this.appName !== 'innovia' ? 'Litros/' + this.textoSuperficie + (this.appName === 'vginer' ? ' (recomendación)' : '') : 
                        'Litros Caldo',
            inputType: {type: InputType.TEXT},
            visible: this.appName !== 'momparler'
        },
        {
            field: 'litros',
            name: 'Litros/m²',
            label: 'Litros/m²',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'momparler'
        },
        {
            field: 'mochila',
            name: 'Es mochila',
            label: 'Es mochila',
            inputType: {type: InputType.RO_SWITCH},
            visible: this.showTareasFitoMochila
        },
        {
            field: 'sup_tratada',
            name: 'Superficie tratada',
            label: 'Superficie tratada',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'pyf'
        },        
        {
            field: 'orden_asesor',
            name: 'Orden asesor',
            label: 'Orden asesor',
            inputType: {type: InputType.RO_SWITCH},
            visible: this.appName === 'pyf'
        },
        {
            field: 'aplicacion',
            name: 'Modo de aplicación',
            label: 'Modo de aplicación',
            inputType: {type: InputType.TEXT},
            visible: this.showTareasFitoAplicacion
        },
        {
            field: 'procura',
            name: 'Procura',
            label: 'Procura',
            inputType: { type: InputType.TEXT },
            visible: this.showTareasProcura
        },        
        {field: 'tipo_plaga', name: 'Tipo de plaga', label: 'Tipo de plaga', inputType: {type: InputType.TEXT},
            visible: this.showTipoPlaga},
        {field: 'motivo', name: 'Motivo', label: 'Motivo', inputType: {type: InputType.TEXT},
            visible: this.showMotivoFito},
        {
            field: 'codigo_justificacion',
            name: 'Justificación de la Actuación',
            label: 'Justificación de la Actuación',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'desplazamiento',
            name: 'Desplazamiento',
            label: 'Desplazamiento',
            inputType: {type: InputType.TEXT},
            visible: this.showDesplazamientoObra
        },
        {
            field: 'mano_obra',
            name: 'Mano Obra',
            label: 'Mano Obra',
            inputType: {type: InputType.TEXT},
            visible: this.showDesplazamientoObra
        },
        {
            field: 'observaciones',
            name: 'Recomendación',
            label: 'Recomendación',
            inputType: {type: InputType.TEXTAREA},
            visible: this.showTareasFitoObservaciones
        },
        {
            field: 'momento_aplicacion',
            name: 'Momento de la aplicación',
            label: 'Momento de aplicación',
            inputType: { type: InputType.TEXT },
            visible: this.showMomentoAplicacion
        },
        {
            field: 'limpieza_epis',
            name: 'Limpieza de EPIs',
            label: 'Limpieza de EPIs',
            inputType: {type: InputType.RO_SWITCH},
            visible: this.appName === 'vginer'
        },
        {
            field: 'dia_limpieza',
            name: 'Día de limpieza',
            label: 'Día de limpieza',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'vginer'
        },
        {
            field: 'it',
            name: 'Instrucción técnica',
            label: 'Instrucción técnica',
            inputType: {type: InputType.TEXT},
            visible: this.showTareasFitoIT
        },
        {
            field: 'lote',
            name: 'Lote',
            label: 'Lote',
            inputType: {type: InputType.TEXT},
            visible: this.showTareasFitoLote
        },
        {
            field: 'tecnica',
            name: 'Técnica',
            label: 'Técnica',
            inputType: {type: InputType.TEXT},
            visible: environment.appName === 'pyf'
        },        
        {
            field: 'notas_cliente',
            name: 'Notas cliente',
            label: 'Notas cliente',
            inputType: {type: InputType.TEXTAREA},
            visible: this.showClientesNotas
        },
        {
            field: 'estado_tarea', 
            name: 'Estado de la Tarea',
            label: 'Estado de la Tarea', 
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'onda' || this.appName === 'lacooperativa'
        },
        {
            field: 'empresa_pagadora', 
            name: 'Empresa Pagadora',
            label: 'Empresa Pagadora', 
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'onda' || this.appName === 'lacooperativa'
        },
        /*{
            field: 'fecha_seca_arroz',
            label: 'Fecha Seca Arroz',
            inputType: {type: InputType.TEXT},
            visible: this.appName == 'sequiador'
        },*/
    ];

    public formFieldsTractor: ItemInterface<any>[] = [
        {
            field: 'maquinaria',
            name: 'Maquinaria',
            label: 'Maquinaria',
            inputType: {type:  InputType.TEXT  },
            visible: this.showTareasFitoMaquinarias,
        },
        {
            field: 'velocidad',
            name: 'Velocidad\xa0(Km/h)',
            label: 'Velocidad\xa0(Km/h)',
            inputType: {type: InputType.TEXT},
            visible: this.showTractorVelocidad},
        {
            field: 'marcha',
            name: 'Marcha',
            label: 'Marcha',
            inputType: {type: InputType.TEXT},
            visible: this.showTractorMarcha
        },
        {
            field: 'rpm',
            name: 'RPM',
            label: 'RPM',
            inputType: {type: InputType.TEXT},
            visible: this.showTractorRPM
        },
        {
            field: 'presion',
            name: 'Presión\xa0(atm-bar)',
            label: 'Presión\xa0(atm-bar)',
            inputType: {type: InputType.TEXT},
            visible: this.showTractorPresion
        },
        {
            field: 'boquillas',
            name: 'Boquillas',
            label: 'Boquillas',
            inputType: {type: InputType.TEXT},
            visible: this.showTractorBoquillas
        },
        {
            field: 'km_recorridos',
            name: 'Km recorridos',
            label: 'Km recorridos',
            inputType: {type: InputType.TEXT},
            visible: this.showTractorKmRecorridos
        }
    ];

    public formFieldsInnovia: ItemInterface<any>[] = [
        {
            field: 'litros1', 
            name: 'Litros 1',
            label: 'Litros ', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'litros2', 
            name: 'Litros 2',
            label: 'Litros ', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'litros3',
            name: 'Litros 3', 
            label: 'Litros ', 
            inputType: {type: InputType.TEXT}
        },

    ];

    public formFieldsFinal: ItemInterface<any>[] = [
        {
            field: 'hora_inicio', 
            name: 'Hora Inicio',
            label: 'Hora Inicio', 
            inputType: {type: InputType.TEXT},
            visible: environment.features.showTareasMto || this.showTareasFitoHoras
        },
        {
            field: 'hora_fin',
            name: 'Hora Fin', 
            label: 'Hora Fin', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.features.showTareasMto || this.showTareasFitoHoras
        },          
        {
            field: 'horas_totales', 
            name: 'Horas',
            label: 'Horas', 
            inputType: {type: InputType.ONLY_TEXT}, 
            visible: environment.features.showTareasMto || environment.features.tareasHorasTotales 
        },
        {
            field: 'fecha_fin', 
            name: (environment.appName === 'armentia') ? 'Fecha fin' : 'Fecha Aplicación',
            label: (environment.appName === 'armentia') ? 'Fecha fin' : 'Fecha Aplicación', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fin_fecha',
            name: 'Fecha fin',
            label: 'Fecha fin', 
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'fotoagricultura'
        },
        {
            field: 'hora_fin',
            name: 'Hora fin',
            label: 'Hora Fin',
            inputType: {type: InputType.TEXT},
            visible: false
        },
        {
            field: 'fecha_recoleccion',
            name: 'Fecha Recolección', 
            label: 'Fecha Recolección',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'vginer'
        },
        {
            field: 'eficacia',
            name: 'Eficacia del Tratamiento', 
            label: 'Eficacia del Tratamiento',
            inputType: {type: InputType.TEXT},
            visible: true
        },
        {
            field: 'aplicado', 
            name: 'Aplicado',
            label: 'Aplicado', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.showAplicadoSwitch && (this.appName === 'agrosalvi' && this.rol !== 'user')
        },
        { field: 'horas_totales', name: 'Horas',
            label: 'Horas', inputType: {type: InputType.TEXT}, visible: this.appName === 'fotoagricultura' },
        {
            field: 'validado',
            name: 'Validado',
            label: 'Validado', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.showFitoValidado && (this.rol === 'admin')
        },
        {
            field: 'soleado',
            name: 'Soleado',
            label: 'Soleado',
            inputType: {type: InputType.TEXT},
            visible: this.showTareasFitoSoleado
        },
        {
            field: 'cubas_totales',
            name: 'Cubas totales',
            label: 'Cubas totales',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'vginer'
        },
        {
            field: 'litros_aplicados',
            name: 'Litros aplicados',
            label: 'Litros aplicados',
            inputType: {type: InputType.TEXT},
            visible: this.showTareasFitoLitrosAplicados
        },
        {
            field: 'litros_aplicados_ha',
            name: 'Litros aplicados/Ha',
            label: 'Litros aplicados/Ha',
            inputType: {type: InputType.TEXT},
            visible: this.showTareasFitoLitrosAplicados && !environment.features.showCostes
        },
        {
            field: 'facturado',
            name: 'Facturado',
            label: 'Facturado',
            inputType: {type: InputType.RO_SWITCH},
            visible: this.appName === 'agrosalvi'
        },
        {
            field: 'factura_albaran',
            name: 'Factura/Albarán',
            label: 'Factura/Albarán',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'agrosalvi'
        }
    ];

    public formFieldsClima: ItemInterface<any>[] = [
        {
            field: 'temperatura',
            name: 'Temperatura ℃',
            label: 'Temperatura ℃',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'hr',
            name: this.appName === 'torrepacheco' ? 'Humedad %' : 'Humedad R.', //'H.\xa0R,
            label: this.appName === 'torrepacheco' ? 'Humedad %' : 'Humedad R.', //'H.\xa0R
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'viento',
            name: 'Viento',
            label: 'Viento Km/h',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'precip',
            name: 'Precipit. L/m²',
            label: 'Precipit. L/m²',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'clima',
            name: 'Clima',
            label: 'Clima',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'vginer'
        },
        {
            field: 'climatologia',
            name: 'Climatología',
            label: '',
            inputType: {type: InputType.TEXT},
            visible: this.showClimatologia
        },
    ];

    public formFieldsMezclasSobrantes: ItemInterface<any>[] = [
        {
            field: 'cubeto_evaporacion',
            name: 'Cubeto evaporación',
            label: 'Cubeto evaporación',
            inputType: {type: InputType.RO_SWITCH}
        },
        {
            field: 'punto_carga',
            name: 'Punto carga',
            label: 'Punto carga',
            inputType: {type: InputType.RO_SWITCH}
        },
        {
            field: 'litros_sobrante',
            name: 'Litros caldo sobrante',
            label: 'Litros caldo sobrante',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'dia_tratamiento',
            name: 'Día tratamiento',
            label: 'Día tratamiento',
            inputType: {type: InputType.TEXT}
        },
    ];

    public formFieldsOrdenTrabajo: ItemInterface<any>[] = [
        {
            field: 'orden_trabajo', name: 'Orden de trabajo', label: 'Orden de trabajo', inputType: { type: InputType.TEXTAREA },
        },
    ];

    public formFieldsObs: ItemInterface<any>[] = [
        { field: 'observaciones', name: 'Observaciones', label: 'Observaciones', inputType: { type: InputType.TEXTAREA } },

    ];


    public totalHoras: any = '00:00';
    public auxTotalHoras: any = '00:00';

    public cols = [
        { field: 'referencia_receta', header: 'Orden', filter: '', width: '60px', visible: this.appName === 'pyf' },
        { field: 'id', header: 'Nº\xa0Receta', filter: '', width: '80px', visible: this.appName !== 'pyf' && this.appName !== 'donjardin' },
        { 
            field: 'fecha_inicio', 
            header: (environment.appName === 'armentia') ? 'Fecha\xa0ini.' : 'F.\xa0Receta', 
            filter: '', 
            width: '90px', 
            visible: this.showFitoFechaReceta && !environment.features.showTareasMto 
        },
        { field: 'fecha_fin', header: (environment.appName === 'armentia') ? 'Fecha\xa0fin' : 'F.\xa0Apl.', filter: '', width: '90px' },
        { field: 'fin_fecha', header: 'Fecha fin', filter: '', width: '90px', visible: this.appName === 'fotoagricultura' },
        { field: 'duracion', header: 'Duración', filter: '', width: '90px', visible: this.showTareasFitoDuracion },
        { field: 'cliente_nombre', header: this.clienteName, filter: '', visible: !environment.features.showTareasMto && this.hasClients },
        { field: 'finca', header: this.fincaName, filter: '', visible: this.showTareasFincas },
        {
            field: 'sectores_nombres',
            header: this.sectoresName,
            filter: '',
            visible: this.showTareasSector && !environment.features.showTareasMto
        },
        { field: 'trabajadores_nombres', header: 'Empleado', visible: environment.features.showTareasMto },
        { 
            field: 'hora_inicio', 
            header: 'Hora Inicio', 
            visible: environment.features.showTareasMto || environment.features.showTareasFitoHoras 
        },
        { field: 'hora_fin', header: 'Hora Fin', visible: environment.features.showTareasMto || environment.features.showTareasFitoHoras },
        { 
            field: 'horas_totales', 
            header: 'Horas', 
            filter: '', 
            visible: environment.features.tareasHorasTotales || environment.features.showTareasMto 
        },
        { 
            field: 'parcelas_nombres', 
            header: this.parcelasName, 
            filter: '', 
            visible: !environment.features.showTareasMto && this.showTareasParcela 
        },
        { field: 'tratamiento_nombre', header: this.tareasTratamientosLabel, filter: '', visible: this.showTareasTratamientos },
        { field: 'materias_activas', header: 'Materia activa', filter: '', visible: this.showTareasMateriaActiva },
        { field: 'productos_nombres', header: 'Nombre comercial', filter: '', visible: this.showFitoProductosNombres },
        { 
            field: 'maquinaria', 
            header: 'Maquinaria', 
            filter: '', 
            visible: !environment.features.showTareasMto && this.showTareasMaquinaria 
        },
        { field: 'litros', header: this.appName === 'onda' ? 'Litros' : 'Litros/' + this.textoSuperficie, filter: '', width: '100px' },
        { field: 'lote', header: 'Lote', filter: '', width: '100px', visible: this.showTareasFitoLote },
        { 
            field: 'eficacia', 
            header: 'Eficacia', 
            filter: '', 
            width: '60px', 
            visible: this.showFitoEficacia && this.appName !== 'pyf' && !environment.features.showTareasMto 
        },
        { field: 'punto_km_inicio', header: 'Punto Km inicial', filter: '', width: '60px', visible: this.showTareasFitoPuntoKm },
        { field: 'punto_km', header: 'Punto Km final', filter: '', width: '60px', visible: this.showTareasFitoPuntoKm },
        { field: 'facturado', header: 'Facturado', filter: '', width: '60px', visible: this.appName === 'agrosalvi' },
        { field: 'aplicado', header: 'Aplicado', filter: '', width: '60px', visible: environment.features.showTareasMto },
        { 
            field: 'nombres_asignados', 
            header: 'Asignador', 
            filter: '', 
            visible: environment.features.showAssignUserTasksFitosanitario || environment.features.showAssignUserInAllTasks 
        },
    ];
    public arrayProductosTodos: any = [
        {id: '', nombre: '', ps: ''}
    ];

    public arrayTratamientos: any = [
        {id: '', ids_productos: '', cantidades_real: ''}
    ];

    public str: string = '';

    public modalShowCampaign  = false;

    public digitosDecimalesDosis: number = (environment.appName === 'vginer') ? 3 : 2 ;
    public parts: any[] = [];
 

    public fecha: {inicio: any; fin: any} = {
        inicio: '',
        fin: '',
    };


    constructor(public tareasApi: TareasApiService,
        public tratamientosApi: TratamientosApiService,
        private formApi: FormCommonApiService,
        private dashboard: DashboardService,
        private fincasApi: FincasApiService,
        private trabajadoresApi: TrabajadoresApiService,
        private router: Router,
        private messageService: MessageService) {
        super(dashboard, tareasApi.fitosanitariosGET);

    }

    ngOnInit() {
        if (environment.features.showTareasMto){
            if (this.rolUser !== 'admin'){
                this.hiddenButtonDelete = true;
            }
            
        }
        if (this.appName === 'ava') {
            this.aplicado.selected = 'no-aplicados';
        }

        this.tareasApi.enviarRecetaCaptura.response(() => {
            if (!this.tareasApi.enviarRecetaCaptura.hasError && this.showReceta) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Email enviado',
                    detail: 'Se ha enviado el impreso de la receta correctamente.'
                });
                this.showReceta = false;
            }
        });

        this.dashboard.setSubmenuItem(this.menuItemId);

        if (this.showTareasBio) {
            this.tareasApi.bio.GET.toPromise().then(res => {
                this.tareasBio = res;
            });
        }

        this.expandFormFields();

        this.appName = environment.appName;
        this.clienteSelected = '';
        if (StorageManager.getClient()) {
            this.clienteSelected = (StorageManager.getClient() || {} as any).nombre;
        }
        this.telefClienteSelected = (StorageManager.getClient() || {} as any).telefono;

        if (this.empresaName !== '') {
            this.empresa = this.empresaName;
        }

        if (this.appName === 'melia') {
            this.empresa = this.currentUser.apellidos;
        }

        this.filtering.addFilter((copy: any[]) => copy.filter(row => {
            switch (this.aplicado.selected) {
                case 'aplicados':
                    return [true, '1', 1].includes(row.aplicado);
                case 'no-aplicados':
                    return [null, undefined, '0', 0].includes(row.aplicado);
                default:
                    return true;
            }
        }));

        if ( this.showCampaign ) {
            this.fillCampaign();
            this.filtering.addFilter((copy: any[]) => copy.filter(row => {
                if ( this.selectedYears === 0 ){
                    return true;
                } else {
                    return this.compareDates(row);
                }
            }));
        }
       

        if (this.showFitoValidado) {
            this.filtering.addFilter((copy: any[]) => copy.filter(row => {
                switch (this.validado.selected) {
                    case 'validados':
                        return [true, '1', 1].includes(row.validado);
                    case 'no-validados':
                        return [null, undefined, '0', 0].includes(row.validado);
                    default:
                        return true;
                }
            }));
        }

        this.filtering.addFilter((data: any[]) => data.sort((a, b) =>
            parseInt(a.id, 10) > parseInt(b.id, 10) ? -1 : 1
        ));


        this.arrayProductosTodos = [];
        this.formApi.productos.safePerform();
        this.formApi.productos.response(data2 => {
            this.arrayProductosTodos = [];
            data2.forEach((element: { id: any; nombre: any; ps: any; unidades: any; }) => {
                if (element) {
                    this.arrayProductosTodos.push({id: element.id, nombre: element.nombre, ps: element.ps, uds: element.unidades});
                }
            });
        });

        this.fincasApi.fincasGET.safePerform();
        this.fincasApi.fincasGET.response(fincas => {
            this.fincas = fincas;
        });

        this.fincasApi.parcelasGET.safePerform();
        this.fincasApi.parcelasGET.response(parcelas => {
            this.parcelas = parcelas;
        });

        this.trabajadoresApi.trabajadoresGET.safePerform();
        this.trabajadoresApi.trabajadoresGET.response(trabajadores => {
            this.trabajadores = trabajadores;
        });
        

        this.arrayTratamientos = [];
        this.tratamientosApi.tratamientosGET.safePerform();
        this.tratamientosApi.tratamientosGET.response(data3 => {
            this.arrayTratamientos = [];
            data3.forEach((element2: { id: string; ids_productos: any; cantidades_cuaderno: any; cantidades_real: any; }) => {
                
                if (element2.id !== '') {
                    this.arrayTratamientos.push({
                        id: element2.id,
                        ids_productos: element2.ids_productos,
                        cantidades_real: this.sm ? element2.cantidades_cuaderno : element2.cantidades_real
                    });
                }
            });
        });

        if (environment.features.showTareasMto) {
            /*** 
            *  En el caso de que el usuario no se admin,
            * filtrará por su id de usuario que coincide,
            *  con el id trabajador   
            */
            if (StorageManager.getUser().rol !== 'admin') {
                this.filtering.addFilter((copy: any[]) => copy.filter(row => {

                    if (row.ids_trabajadores != null) {

                        const idTrabajador = StorageManager.getUser().id;
                        const listaTrabajadores = (row.ids_trabajadores as string).split(';');
                        if (listaTrabajadores.includes(idTrabajador)) {
                            return true;
                        }

                    }
                    return false;
                }));
            }

            /*** 
            *  Fin de filtrar por id_usuario = trabajador,
            *   
           */
            this.filtering.addFilter((copy: any[]) => copy.filter(row => {
                if (this.fecha.inicio && this.fecha.fin ) {
                    if (row.fecha_fin == null ){
                        return false;
                    } else {
                        const arrayFecha = row.fecha_fin.split('/');
                        const fechaAux = arrayFecha[2] + '-' + arrayFecha[1] + '-' + arrayFecha[0];
              
                        if (
                            (moment(moment(this.fecha.inicio).format('YYYY-MM-DD')).diff(fechaAux) <= 0) && 
                            (moment(moment(this.fecha.fin).format('YYYY-MM-DD')).diff(fechaAux) >= 0)
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    
                } else {
                    return true;
                }
            }));
        }

        /**
             *  Activado la asignacion de usuarios en la tabla tareas
             *  Filtraremos las tareas si en caso de que el usuario se encuentre como asignado en la tarea
             */
        // if (environment.features.showAssignUserTasksFertilizante || environment.features.showAssignUserInAllTasks) {
        if (this.appName === 'ava') {
            /** Solo el que tiene el rol de admin podra ver todas las tareas */
            if (StorageManager.getUser().rol !== 'admin') {
                this.filtering.addFilter((copy: any[]) => copy.filter(row => {

                    if (row.referencia_usuario != null) {

                        const idTrabajador = StorageManager.getUser().id;
                        const listaTrabajadores = (row.referencia_usuario as string).split(';');
                        if (listaTrabajadores.includes(idTrabajador)) {
                            return true;
                        }
                    }
                    return false;
                }));
            }
        }// Fin filtro asignados
    }

    ngAfterViewInit() {
        this.tareasApi.fitosanitariosGET.response(() => {
            this.updateFooter();
            this.sum();
        });
    }

    getFechaInicio( event: any) {
        this.fecha.inicio = event;
        this.filtering.filter();
        this.updateFooter();
        
    }
    getFechaFin( event: any) {
        this.fecha.fin = event;
        this.filtering.filter();
        this.updateFooter();
    }

    formatParcelas() {
        let parcelasId: Array<string>;
        this.model.parcelas_superficie = 0;
        if (this.model.ids_parcelas) {
            parcelasId = this.model.ids_parcelas.split(';') || [];
        } else {
            parcelasId = [];
        }
        this.parcelasFilter = [];
        if (parcelasId.length > 0) {
            this.parcelas.forEach(parcela => {
                if (parcelasId.includes(parcela.id.toString())) {
                    this.parcelasFilter.push(parcela);
                }
            });
            this.model.parcelas = '';
            this.parcelasFilter.forEach(parcela => {
                if (parcela) {
                    this.model.parcelas += `- ${parcela.nombre}\n`;
                    this.model.parcelas_superficie += parseFloat(parcela.superficie_cultivada || '0.00');
                }
            });
            this.model.parcelas_superficie = this.model.parcelas_superficie.toFixed(2);
            this.model.parcelas = this.model.parcelas.substring(0, this.model.parcelas.length - 1);
        } else {
            this.model.parcelas = [];
        }
    }

    decode(utftext: string) {
        let string = '', i = 0, c = 0, c2 = 0, c3 = 0;

        while (i < utftext.length) {
            c = utftext.charCodeAt(i);
            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            } else if ((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i + 1);
                // eslint-disable-next-line no-bitwise
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            } else {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                // eslint-disable-next-line no-bitwise
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }

        return string;
    }

    modalCampaign() {
        this.modalShowCampaign = true;
    }

    closeModalShowCampaign() {
        this.modalShowCampaign = false;
    }

    selectedCampaign(){
        this.filtering.filter();
    }

    compareDates( row: any ) {
        const auxFecha = (row['fecha_inicio'] as string).split('/');
        const nuevaFecha = moment(auxFecha[2] + '-' + auxFecha[1] + '-' + auxFecha[0] );
        const fechaComparar = moment('20' + (this.selectedYears).toString() + '-07' + '-01' );
        const fechaMax = moment('20' + (this.selectedYears + 1 ).toString() + '-06' + '-30' );

        if ( (nuevaFecha > fechaComparar) && (nuevaFecha < fechaMax) ){
            return true;
        } else {
            return false;
        }
    }
    
    /**
     *  Rellena el listado de campañas
     */
    fillCampaign () {
        const fechaActual = new Date().getFullYear();
        let fechaDosDigitos = 0;
        
        for (let index = 2020; index <= fechaActual; index++) {
            fechaDosDigitos = parseInt( (index.toString()).substring(2, 4) , 10);
            this.years.push({ 
                label: environment.features.applicationType === 'cropgest' ? 
                    index.toString() : 
                    (index.toString() + '-' + (fechaDosDigitos + 1 ).toString()), 
                value: fechaDosDigitos 
            });
            
        }
    }
    
    public filtrarTratamientos(value: string, from = 'switch_aplicados') {
        if (from === 'switch_aplicados') {
            this.aplicado.selected = this.aplicado.selected === value ? '' : value;
        } else if (from === 'switch_validados') {
            if (this.showFitoValidado) {
                this.validado.selected = this.validado.selected === value ? '' : value;
            }
        }

        this.filtering.filter();
        if (['donjardin', 'coliberica', 'fotoagricultura'].includes(this.appName) ){
            this.sum();
        }
    }

    public add() {
        this.router.navigate(['dashboard', this.pathToForm]);
    }

    public edit(data: any) {
        this.router.navigate(['dashboard', 'editar-albaran', data.id]);
    }

    public duplicate(data: any) {
        this.router.navigate(['dashboard', 'duplicar-albaran', data.id]);
    }

    public override deleteRegister() {
        if (this.model['id']) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public override show(modal: string, data: any) {
        super.show(modal, data);

        this.formatParcelas();
        this.model.activo = [1, '1', true, 'true'].includes(this.model.activo);
        this.model.aplicado = [1, '1', true, 'true'].includes(this.model.aplicado);

        if (this.showFitoValidado) {
            this.model.validado = [1, '1', true, 'true'].includes(this.model.validado);
        }
        if (this.showTareasFitoMochila) {
            this.model.mochila = [1, '1', true, 'true'].includes(this.model.mochila);
        }

        if (this.model.tratamiento) {
            this.model.tratamiento.linea.forEach((it: { producto: { fecha_caducidad: string; caducado: string; }; }) => {
                const diff = moment(new Date()).diff(Utils.toDate(it.producto.fecha_caducidad), 'months');
                const diff2 = moment(new Date()).diff(Utils.toDate(it.producto.fecha_caducidad), 'days');
    
                if (diff >= 12) {
                    it.producto.caducado = '#222222';
                } else if (diff >= 6) {
                    it.producto.caducado = '#ff7f7f';
                } else if (diff >= 0) {
                    if ((diff === 0) && diff2 < 0) {
                        it.producto.caducado = '#f8f9fa';
                    } else {
                        it.producto.caducado = '#ffcf78';
                    }
                } else {
                    it.producto.caducado = '#f8f9fa';
                }
            });
        }
        this.formFieldsTratamiento.forEach( (form) => {
            if (form.field === 'orden_asesor'){
                if (this.model.referencia_receta != null || this.model.referencia_receta?.length() === 0){
                    form.label = this.model.referencia_receta;
                    this.model.orden_asesor = true;
                }
                else{
                    form.label = 'Orden Asesor';
                }
            }
        });
    }

    public override hide(modal: string) {
        this.model = null;
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public override ngOnDestroy() {
        Utils.unsuscribe([
            this.tareasApi.fitosanitariosGET,
            this.tareasApi.fitosanitariosDELETE,
            this.tratamientosApi.tratamientosGET,
            this.formApi.productos
        ]);
    }

    public enviarCorreo() {
        this.isGeneratingCanvas = true;
        const config = this.getPrintConfig();

        html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement(), {scale: config.canvasScale, logging: false}).then(canvas => {
            this.isGeneratingCanvas = false;
            const pdf = new jsPDF(config.orientation, 'mm', 'a4');
            const data = canvas.toDataURL('image/png');

            if (canvas.width > canvas.height) {
                pdf.addImage(data, 'png', 0, 0, config.width, (canvas.height / (canvas.width / config.width)), 'alias', 'FAST');
            } else {
                pdf.addImage(data, 'png', 0, 0, (canvas.width / (canvas.height / config.height)), config.height, 'alias', 'FAST');
            }

            const adjunto = encodeURIComponent(window.btoa(pdf.output()));

            let email = '';
            if (this.receta.finca_asociada && this.receta.finca_asociada.email) {
                email = this.receta.finca_asociada.email;
            } else if (this.receta.cliente_email) {
                email = this.receta.cliente_email;
            } else {
                email = '';
            }

            if (email !== '') {
                this.tareasApi.enviarRecetaCaptura.perform({
                    email,
                    generado: adjunto,
                    finca: this.receta.finca,
                    parcelas: this.receta.parcelas_nombres,
                    tratamiento: this.receta.tratamiento_nombre,
                });
                if (this.appName === 'pascualcabedo') {
                    this.tareasApi.enviarRecetaCaptura.perform({
                        email: 'pascualcabedo@pascualcabedo.es',
                        generado: adjunto,
                        finca: this.receta.finca,
                        tratamiento: this.receta.tratamiento_nombre,
                    });
                    this.tareasApi.enviarRecetaCaptura.perform({
                        email: 'mariajose@pascualcabedo.es',
                        generado: adjunto,
                        finca: this.receta.finca,
                        tratamiento: this.receta.tratamiento_nombre,
                    });
                }
                if (this.appName === 'saviatropical') {
                    this.tareasApi.enviarRecetaCaptura.perform({
                        email: 'saviatropical@gmail.com',
                        generado: adjunto,
                        finca: this.receta.finca,
                        tratamiento: this.receta.tratamiento_nombre,
                    });
                }
                if (this.appName === 'esparragosgranada') {
                    switch (this.receta.cliente.cooperativa) {
                        case 'AGROMESIA SCA':
                            this.tareasApi.enviarRecetaCaptura.perform({
                                email: 'agromesia@wanadoo.es',
                                generado: adjunto,
                                finca: this.receta.finca,
                                tratamiento: this.receta.tratamiento_nombre,
                            });
                            break;
                        case 'ESPAFRON SCA':
                            this.tareasApi.enviarRecetaCaptura.perform({
                                email: 'espafron.s.c.a@gmail.com',
                                generado: adjunto,
                                finca: this.receta.finca,
                                tratamiento: this.receta.tratamiento_nombre,
                            });
                            break;
                        case 'ESPALORQUIANA SCA':
                            this.tareasApi.enviarRecetaCaptura.perform({
                                email: 'espalorquianagranada@hotmail.com',
                                generado: adjunto,
                                finca: this.receta.finca,
                                tratamiento: this.receta.tratamiento_nombre,
                            });
                            break;
                        case 'VEGACHAUCHINA SCA':
                            this.tareasApi.enviarRecetaCaptura.perform({
                                email: 'pilargranadaesp@hotmail.com',
                                generado: adjunto,
                                finca: this.receta.finca,
                                tratamiento: this.receta.tratamiento_nombre,
                            });
                            break;
                        default:
                            break;
                    }
                    this.tareasApi.enviarRecetaCaptura.perform({
                        email: 'alipioa@esparragosgranada.es',
                        generado: adjunto,
                        finca: this.receta.finca,
                        tratamiento: this.receta.tratamiento_nombre,
                    });
                    this.tareasApi.enviarRecetaCaptura.perform({
                        email: 'tecnico@esparragosgranada.es',
                        generado: adjunto,
                        finca: this.receta.finca,
                        tratamiento: this.receta.tratamiento_nombre,
                    });
                }
            } else {
                this.tareasApi.enviarRecetaCaptura.hasError = true;
                this.tareasApi.enviarRecetaCaptura['error'] = 'El cliente o finca no tiene un email asociado';
            }
        }).then(() => {
            // console.log('Adjuntos generados.');
        }, () => {
            alert('Ha habido un error al enviar el email. Si este error persiste, por favor, contacte con un administrador.');
            // console.log(error);

            this.isGeneratingCanvas = false;
            this.tareasApi.enviarRecetaCaptura.hasError = true;
            this.tareasApi.enviarRecetaCaptura['error'] = 'Error al enviar email';
        });
    }


    public enviarWhatsapp() {
        window.open('https://web.whatsapp.com/', '_blank');

        const config = this.getPrintConfig();

        html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement(), {scale: config.canvasScale, logging: false}).then(canvas => {
            this.isGeneratingCanvas = false;
                
            const pdf = new jsPDF(config.orientation, 'mm', 'a4');
            const data = canvas.toDataURL('image/png');
                    
            if (canvas.width > canvas.height) {
                if ( this.appName === 'pyf' ) {
                    pdf.addImage(data, 'png', 0, 20, 200, 140 , 'alias', undefined);
                } else {
                    pdf.addImage(data, 'png', 0, 0, config.width, (canvas.height / (canvas.width / config.width)), 'alias', 'FAST');
                }
            } else {
                if ( this.appName === 'pyf' ) {
                    pdf.addImage(data, 'png', 0, 20, 200, 140 , 'alias', undefined);
                } else {
                    pdf.addImage(data, 'png', 0, 0, (canvas.width / (canvas.height / config.height)), config.height, 'alias', 'FAST');
                }           
            }

            const file = pdf.output('blob');
          
            const formData = new FormData();                
            formData.append('document', file, 'receta.pdf');
            formData.append('phoneNumber', '+34' + this.receta.cliente_telefono);

            function sendDocument() {        
                const url = `${environment.serverUrl}ws/tareas/enviar_receta_whatsapp.php`;

                const xht = new XMLHttpRequest();

                xht.open('POST', url);
                xht.send(formData);
            }

            sendDocument();        
        });

        this.showReceta = false;
    }

    public generarReceta(data: any, showAfter = true) {
        this.tareasApi.enviarRecetaCaptura.hasError = false;
        this.tareasApi.enviarRecetaCaptura['error'] = null;

        this.receta = data;
        
        this.currentYear = new Date().getFullYear().toString();
        this.receta.fecha_completa = moment(this.receta.fecha_fin, 'DD/MM/YYYY').isValid()
            ? moment(this.receta.fecha_fin, 'DD/MM/YYYY').format('LL')
            : '___/___/______';

        // Vicente Giner
        this.receta.fecha_hoy = moment().format('LL');
        if (this.receta.fecha_fin) {
            const fechaAplicacionParts = this.receta.fecha_fin.split('/');
            this.receta.fecha_objeto = {
                dia: parseInt(fechaAplicacionParts[0], 10),
                mes: parseInt(fechaAplicacionParts[1], 10),
                anyo: fechaAplicacionParts[2]
            };
        }
        if (this.receta.fecha_inicio) {
            const mes = moment(this.receta.fecha_inicio, 'DD/MM/YYYY').get('month');
            const anyo = moment(this.receta.fecha_inicio, 'DD/MM/YYYY').get('year');
            this.receta.campanya_citricola = (mes >= 8) ? (anyo + '/' + (anyo + 1)) : ((anyo - 1) + '/' + anyo);
        }

        this.receta.anyo = this.receta.fecha_fin ? moment(this.receta.fecha_fin, 'DD/MM/YYYY').year() : '';
        this.receta.finca_asociada = this.fincas.find(it => it.id === this.receta.id_finca);
        
        if (this.appName === 'laplana'){
            this.receta.finca_localidad = this.receta.finca_asociada.localidad;
        }
        this.receta.cliente_nif = (data.cliente || {} as any).nif;
        this.receta.cliente_telefono = data.cliente_telefono;
        this.receta.usuario_nombre = '';
        if (this.currentUser.apellidos == null){
            this.currentUser.apellidos = '';
        }
        if (this.currentUser) {
            this.receta.usuario_nombre = this.currentUser.nombre + ' ' + this.currentUser.apellidos;
            this.receta.usuario_firma = this.currentUser.firma ?? '';
        }
        this.receta.usuario_nif = this.currentUser.nif;
        this.receta.usuario_ropo = this.currentUser.ropo;

        /*** OBTENER NOMBRE Y VARIEDAD DE LA PARCELA ***/
        // SIMAT
        let parcelasId: Array<string> = [];
        this.receta.superficie_parcelas = 0;
        this.receta.variedades = [];

        if (this.receta.ids_parcelas) {
            if (this.receta.ids_parcelas) {
                parcelasId = this.receta.ids_parcelas.split(';') || [];
            } else {
                parcelasId = [];
            }
            this.parcelasFilter = [];
    
            if (parcelasId.length > 0) {
                this.parcelas.forEach(parcela => {
                    if (parcelasId.includes(parcela.id.toString())) {
                        this.parcelasFilter.push(parcela);
                        this.receta.superficie_parcelas += parseFloat((parcela.superficie_cultivada || '').replace(',', '.') || '0.00');
                        if (!this.receta.variedades.includes(parcela.variedad)) {
                            this.receta.variedades.push(parcela.variedad);
                        }
                    }
                });
                this.receta.superficie_parcelas = this.receta.superficie_parcelas.toFixed(2);
    
                this.receta.parcelas = this.parcelasFilter;
            } else {
                this.receta.parcelas = [];
            }
        }

        this.receta.caldo_aplicado = parseFloat(this.receta.litros) * parseFloat(this.receta.superficie_parcelas);
        if ([NaN, Infinity].includes(this.receta.caldo_aplicado)) {
            this.receta.caldo_aplicado = 0;
        }
        this.receta.litros_aplicados_ha = parseFloat(this.receta.litros) * parseFloat(this.receta.superficie_parcelas);
        if ([NaN, Infinity].includes(this.receta.litros_aplicados_ha)) {
            this.receta.litros_aplicados_ha = 0;
        }

        // LA FAST
        if (this.showTareasBio) {
            this.receta.bios = [];
            this.tareasBio.forEach(bio => {
                if (
                    bio.id_sector === this.receta.id_sector &&
                    parcelasId.includes(bio.ids_parcelas)
                ) {
                    bio.fecha_aplicacion = moment(bio.fecha_aplicacion, 'YYYY-MM-DD').format('DD/MM/YYYY');
                    this.receta.bios.push(bio);
                }
            });
        }

        /***********************************************/

        // SIMAT
        moment.locale('es');
        this.receta.ropo = this.currentUser.ropo || '';
        
        // arrayProds
        this.str = 'ú';
        this.str = this.decode(this.str);

        this.arrayProdsNom = [];
        this.arrayProdsUds = [];
        this.arrayCantsReceta = [];

        const idTratamiento: string = data.id_tratamiento;
        let productosTratamiento = '';
        let cantidadesTratamiento = '';

        for (let i = 0; i < this.arrayTratamientos.length; i++) {
            if (this.arrayTratamientos[i].id === idTratamiento) {

                productosTratamiento = this.arrayTratamientos[i].ids_productos;
                cantidadesTratamiento =  this.arrayTratamientos[i].cantidades_real;
            }
        }

        const arrayProdsReceta = (productosTratamiento || '').split(';');
        this.arrayCantsReceta = (cantidadesTratamiento || '').split(';');
        this.ps = 0;

        for (let j = 0; j < arrayProdsReceta.length; j++) {
            for (let i = 0; i < this.arrayProductosTodos.length; i++) {
                if (this.arrayProductosTodos[i].id === arrayProdsReceta[j]) {
                    this.arrayProdsNom[j] = this.arrayProductosTodos[i].nombre;
                    this.arrayProdsUds[j] = this.arrayProductosTodos[i].uds;
                    if (
                        ![
                            'oliverfutur', 
                            'melia', 
                            'ebro', 
                            'torrepacheco', 
                            'lacooperativa', 
                            'covidai', 
                            'ferticinca', 
                            'agricar'
                        ].includes(this.appName)
                    ) {
                        this.arrayCantsReceta[j] = (((this.arrayCantsReceta[j] || 0) * (this.receta.litros || 0)) / 100).toFixed(2);
                    }
 
                    if (this.appName === 'ferticinca') {
                        this.arrayCantsReceta[j] = (
                            ((this.arrayCantsReceta[j] || 0) * (this.receta.superficie_parcelas || 0)) / 100
                        ).toFixed(2);
                    }
 
                    if (this.arrayProductosTodos[i].ps > this.ps) {
                        this.ps = this.arrayProductosTodos[i].ps;
                    }

                    if (Utils.isNumber(this.arrayCantsReceta[j]) && this.arrayCantsReceta[j] < 1) {
                        if (
                            ![
                                'oliverfutur', 
                                'melia', 
                                'ebro', 
                                'torrepacheco', 
                                'lacooperativa', 
                                'covidai', 
                                'agricar'
                            ].includes(this.appName)) {
                            this.arrayCantsReceta[j] = (this.arrayCantsReceta[j] * 1000).toFixed(2);
                        }
                        if (this.arrayProdsUds[j] === 'Litros') {
                            this.arrayUdsMedida[j] = 'c.c.';
                        } else {
                            this.arrayUdsMedida[j] = 'gr.';
                        }
                    } else {
                        if (this.arrayProdsUds[j] === 'Litros') {
                            this.arrayUdsMedida[j] = 'Litros';
                        } else {
                            this.arrayUdsMedida[j] = 'Kilos';
                        }

                    }
                                       
                }
            }
        }

        this.parts = [];
        // En la nueva versión, `uds_medida` viene siempre del Tratamiento
        this.parts = (this.receta.tratamiento.uds_medida || '').split(';');

        let k = 0;
        this.receta.tratamiento.linea.map((it: { producto: { unidades: any; }; }) => {
            if (it.producto) {
                it.producto.unidades = this.parts[k++];
            }
        });

        // HERNANDORENA
        if (this.showRegistroHernandorena && this.receta.tratamiento) {
            this.receta.agentes = (this.receta.tratamiento.nombres_agentes || '').split(';');
        }

        if ((this.appName === 'agromainsa') || (this.appName === 'fitogest')) {
            this.observaciones = this.receta.observaciones;
        }

        if (this.showRecetaFirmas) {

            if (this.showUsuariosFirma) {
                this.firmaPath = this.pathToFirmas + '_usuarios/' + this.receta.id_usuario + '.png';
            
                if (this.receta.usuario_firma !== '') {
                    this.firmaPath = this.pathToFirmas + '_asesor/' + this.receta.usuario_firma;
                }
            }
            /*if (this.showTrabajadoresFirma) {
                const id_trabajador = (this.receta.ids_trabajadores || '').split(';');
                fetch(this.pathToFirmas + '_trabajadores/' + id_trabajador + '.png').then(res => {
                    if (res.status === 200) {
                        res.blob().then(blob => {
                            this.spTrabajadores.fromDataURL(urlCreator.createObjectURL(blob));
                        });
                    }
                });
            }*/
        }

        // VICENTE GINER
        if (this.showRecetaVGiner) {
            this.receta.cubeto_evaporacion = [1, '1', true, 'true'].includes(this.receta.cubeto_evaporacion);
            this.receta.punto_carga = [1, '1', true, 'true'].includes(this.receta.punto_carga);
        }

        if (showAfter) {
            this.showReceta = true;
        }
    }

    public generarGlobalGAP(data: any) {
        this.generarReceta(data, false);

        if (this.showUsuariosFirma) {
            this.firmaPath = this.pathToFirmas + '_usuarios/' + this.receta.id_usuario + '.png';
            
            if (this.receta.usuario_firma !== '') {
                this.firmaPath = this.pathToFirmas + '_asesor/' + this.receta.usuario_firma;
            }
        }

        this.isShowingGlobalGAP = true;
    }

    public getPrintConfig() {
        const config: {canvasScale: number; orientation: 'p' | 'l'; width: number; height: number} = {
            canvasScale: 2,
            orientation: 'p',
            width: 210,
            height: 290
        };
        // Si se está mostrando el documento Global GAP en vez de la receta normal, no se cambia la configuración.
        // if (this.isShowingGlobalGAP) {
        //     return {
        //         canvasScale: 2,
        //         orientation: 'l',
        //         width: 210,
        //         height: 290
        //     };
        // }
        
        switch (this.appName) {
            case 'laplana':
                config.canvasScale = 1.04;
                config.width = 197;
                config.height = 290;
                break;
            case 'pascualcabedo':
                config.canvasScale = 0.86;
                break;
            case 'vginer':
                config.orientation = 'l';
                config.width = 290;
                config.height = 210;
                break;
            case 'esparragosgranada':
                config.orientation = 'l';
                config.width = 290;
                config.height = 187;
                break;
            case 'fitogest':
                config.orientation = 'l';
                config.width = 290;
                config.height = 210;
                break;
            case 'agrogest':
                config.orientation = 'l';
                config.width = 290;
                config.height = 210;
                break;
            case 'armentia':
                config.orientation = 'l';
                config.width = 290;
                config.height = 210;
                break;
            case 'melia':
                config.width = 320;
                config.height = 250;               
                break;
            case 'saviatropical':
                config.orientation = 'l';
                config.width = 290;
                config.height = 210;
                break;
            case 'gragon':
                config.orientation = 'l';
                config.width = 290;
                config.height = 210;
                break;
            default:
                break;
        }
        return config;
    }

    public imprimirReceta() {
        // let o;
        // let fileName = '';
        // fileName = this.clienteSelected;
        // if (fileName == null) {
        //     fileName = '';
        // }

        // if (this.appName !== 'oliverfutur') {
        //     o = {
        //         filename: 'receta_' + fileName.replace('.', '') + this.receta.id + '.doc',
        //         area: 'div.googoose',
        //         display: 'Print',
        //         lang: 'es-ES',
        //         download: true
        //     };
        // }

        // if (this.appName === 'oliverfutur') {
        //     o = {
        //         filename: 'receta_' + fileName.replace('.', '') + this.receta.id + '.doc',
        //         area: 'div.googoose',
        //         margins: '0.5in',
        //         headermargin: '0.5in',
        //         display: 'Print',
        //         lang: 'es-ES',
        //         download: true
        //     };
        // }

        /** Habilitar para permitir descargar la receta en formato 'doc'. */
        // if (this.showRecetaTratamiento) {
        //     $(document).googoose(o);
        // } else {
        this.isGeneratingCanvas = true;
        const config = this.getPrintConfig();

        html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement(), {scale: config.canvasScale, logging: false}).then(canvas => {
            this.isGeneratingCanvas = false;
            
            const pdf = new jsPDF(config.orientation, 'mm', 'a4');
            const data = canvas.toDataURL('image/png');
                
            if (canvas.width > canvas.height) {
                if ( this.appName === 'pyf' ) {
                    pdf.addImage(data, 'png', 0, 20, 200, 140 , 'alias', undefined);
                } else {
                    pdf.addImage(data, 'png', 0, 0, config.width, (canvas.height / (canvas.width / config.width)), 'alias', 'FAST');
                }
            } else {
                if ( this.appName === 'pyf' ) {
                    pdf.addImage(data, 'png', 0, 20, 200, 140 , 'alias', undefined);
                } else {
                    pdf.addImage(data, 'png', 0, 0, (canvas.width / (canvas.height / config.height)), config.height, 'alias', 'FAST');
                }
                   
            }

            // console.log('Config: ' + config.width + ' x ' + config.height);
            // console.log('Canvas: ' + canvas.width + ' x ' + canvas.height);
            // console.log('PDF to print: ' + (canvas.width / (canvas.height / config.height)) + ' x ' + config.height);

            pdf.save((this.receta.tratamiento_nombre || '.').replaceAll('.', '_') + '_ '
                    + (this.receta.finca || '.').replaceAll('.', '_'));
        });
        // }
    }

    public enviarTelegram() {
        const idTelegram = StorageManager.getUser().id_telegram;

        if (!idTelegram) {
            const elementPassport = this.passport?.nativeElement;
    
            const passport = document.createElement('script');
            const key = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA72n4YZpEZfgholYLVavB
mtDw3uLgHHxg6Ed6HdNipVPt8pvI+PqFlBlg3Bkpkz+Mxh5GOLy45aF/5AIqk/6A
qk1C5h9j7kgKyAWVlGDvkDiF+CBSrTTdw3CJRog+d1Vm6bvGk7SsUK//aO79u5Fc
9hsidWP0C4ngkK2lOX0+xK/DreZqlSYjt0BecYTV+ldI+Bys5D1TI6365N1SniHB
DjHddB/7DCreFB+MD+ZMcMDq6H59B2kNmI8TI7SHKk3q6x60HajsHXnQBuqm2y5E
TuC1fnwt9yxpLXOTHXxGL0fTuqlv847pLvpBCsZvpug4Zckn/N14hbho9JTbYwD0
fwIDAQAB
-----END PUBLIC KEY-----`;

            const authButton = document.getElementById('telegram_passport_auth');
            passport.text = `var auth_button = document.getElementById('telegram_passport_auth');
            var auth_params = {
              bot_id:        6449999161,
              scope:         {data: [{type: 'id_document', selfie: true}, 'address_document', 'phone_number', 'email'], v: 1},
              public_key:    ` + '`' + key + '`' + `,
              nonce:         '${environment.serverUrl}',
              callback_url:  '${environment.serverUrl}#/dashboard/fitosanitarios'
            };
            auth_button.addEventListener('click', function() {            
                Telegram.Passport.auth(auth_params, function(show) {
                    if (show) {
                        
                    } else {
                        
                    }
                });
            }
            , false);`;
    
            elementPassport.parentElement.replaceChild(passport, elementPassport);      
    
            authButton?.click();
            this.tooltipForce = true;
            this.tooltipClass = this.tooltipForce ? 
                'telegram-passport-tooltip telegram-passport-tooltip-shown' : 
                'telegram-passport-tooltip';
        } else {

            this.isGeneratingCanvas = true;
            const config = this.getPrintConfig();
    
            html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement, {scale: config.canvasScale, logging: false}).then(canvas => {
                this.isGeneratingCanvas = false;
                    
                const pdf = new jsPDF(config.orientation, 'mm', 'a4');
                const data = canvas.toDataURL('image/png');
                        
                if (canvas.width > canvas.height) {
                    if ( this.appName === 'pyf' ) {
                        pdf.addImage(data, 'png', 0, 20, 200, 140 , 'alias', undefined);
                    } else {
                        pdf.addImage(data, 'png', 0, 0, config.width, (canvas.height / (canvas.width / config.width)), 'alias', 'FAST');
                    }
                } else {
                    if ( this.appName === 'pyf' ) {
                        pdf.addImage(data, 'png', 0, 20, 200, 140 , 'alias', undefined);
                    } else {
                        pdf.addImage(data, 'png', 0, 0, (canvas.width / (canvas.height / config.height)), config.height, 'alias', 'FAST');
                    }           
                }
    
                const blob = pdf.output('blob');
                const file = new File([blob], 'receta.pdf');
                    
                const tg = {
                    token: '6449999161:AAFpVGCLY2bF4NAOs98mU4Brw7KVI9hZRBY',
                    chat_id: StorageManager.getUser().id_telegram
                };
                        
                const formData = new FormData();
                formData.append('document', file, 'receta.pdf');
    
                function sendDocument() {
                    const url = `https://api.telegram.org/bot${tg.token}/sendDocument?chat_id=${tg.chat_id}`;
                    const xht = new XMLHttpRequest();
    
                    xht.open('POST', url);
                    xht.send(formData);
                }
    
                sendDocument();
            
            });
        }
    }

    public globalConditionalStyle(value: string, col: { field: string; }, row: { fecha_caducidad: string; tratamiento: { linea: any[]; }; }): any {
        
        let estiloVademecum: any = {};

        if (col.field === 'trabajadores_nombres') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        if (col.field === 'observaciones') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }

        if (environment.features.showTareasMto){
            if (col.field === 'aplicado') {
            
                if (value === '0') {
                    return {
                        rowStyle: {
                            backgroundColor: '#f44242',
                        }
                    };
                }
                else if (value === '1') {
                    return {
                        rowStyle: {
                            backgroundColor: '#00ff00'
                        }
                    };
                }
            }}

        // if (Utils.isNumber(value) && col.field !== 'sector' && col.field !== 'facturado') {
        if (col.field !== 'sector' && col.field !== 'facturado') {
            if (col.field === 'id' || col.field === 'horas_totales') {
                return {textAlign: 'center'};
            } else {
                return {textAlign: 'right', paddingRight: '40px'};
            }

        }

        if (['id', 'fecha_inicio', 'fecha_fin'].includes(col.field)) {
            return {textAlign: 'center'};
        }
        
        if (row.fecha_caducidad) {
            estiloVademecum = {
                rowStyle: {
                    color: 'black',
                    backgroundColor:  '#ffffff',
                    borderBottom: '1px solid #dddddd'
                },
                textAlign: 'center'
            };

            const diff = moment(new Date()).diff(Utils.toDate(row.fecha_caducidad), 'months');
            const diff2 = moment(new Date()).diff(Utils.toDate(row.fecha_caducidad), 'days');

            if (diff >= 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'white',
                        backgroundColor: '#222222',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            } else if (diff >= 6) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ff7f7f',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            } else if (diff >= 0) {
                if ((diff === 0) && (diff2 < 0)) {
                    estiloVademecum = {
                        rowStyle: {
                            color: 'black',
                            backgroundColor: '#f8f9fa',
                            borderBottom: '1px solid #dddddd'
                        },
                        textAlign: 'center'
                    };
                } else {
                    estiloVademecum = {
                        rowStyle: {
                            color: 'black',
                            backgroundColor: '#ffcf78',
                            borderBottom: '1px solid #dddddd'
                        },
                        textAlign: 'center'
                    };
                }
            }
        }

        // si se supera el maximo de aplicaciones se pone la letra en rojo
        row.tratamiento.linea.forEach(element => {
            if (element.producto.num_maximo_aplicaciones !== 0 && element.producto.num_maximo_aplicaciones != null ){
                if (element.producto.num_maximo_aplicaciones < element.producto.veces_aplicado ) {
                    estiloVademecum.rowStyle.color = 'red';
                    return estiloVademecum;
                }
            }
        }); 


        return estiloVademecum;
    }

    public globalVisualTransform(value: string, col: { field: string; }, row: { hora_fin: moment.MomentInput; hora_inicio: moment.MomentInput; }) {

        if (col.field === 'aplicado') {
            if (value === '0'){
                return 'No aplicado';
            }else {
                return 'Aplicado';
            }
        }

        if (col.field === 'parcelas_nombres') {
            return (value && value.length > 50) ? value.slice(0, 50 - 1) + '…' : value;
        }
        if (col.field === 'eficacia') {
            switch (value) {
                case '1':
                    return 'Buena';
                case '2':
                    return 'Regular';
                case '3':
                    return 'Mala';
                default:
                    return value;
            }
        }

        if (col.field === 'facturado') {
            switch (value) {
                case '0':
                    return 'No';
                case '1':
                    return 'Sí';
                default:
                    return 'No';
            }
        }

        if (col.field === 'duracion') {
            const fin = moment(row.hora_fin, 'HH:mm');
            const inicio = moment(row.hora_inicio, 'HH:mm');

            if (!inicio.isValid() || !fin.isValid()) {
                return '';
            }

            const duracion = fin.subtract(inicio.get('hours'), 'hours').subtract(inicio.get('minutes'), 'minutes').format('HH:mm');
            return duracion;
        }

        if (Utils.isNumber(value) && (col.field !== 'id')) {
            if ( col.field !== 'finca' ) {
                if (col.field === 'litros' && environment.features.showTareasMto){
                    return Utils.decimalFormat((value || '').replace(',', '.'), 4, '.', ',');
                }
                else if (col.field === 'litros' && this.appName === 'onda'){
                    return Utils.decimalFormat((value || '').replace(',', '.'), 0, '.', ',');
                }
                else{
                    return Utils.decimalFormat((value || '').replace(',', '.'), 2, '.', ',');
                }
            } 
        }

        return value;
    }

    public formatDate(date: string) {
        moment.locale('es');
        return moment(date, 'DD/MM/YYYY').format('LL');
    }

    public getFinPlazoSeguridad() {
        let mayor = 0;
        
        for (const linea of this.receta.tratamiento.linea) {
            if (linea.producto && !isNaN(linea.producto.ps) && (parseInt(linea.producto.ps, 10) > mayor)) {
                mayor = linea.producto.ps;
            }
        }
        const fechaFin = moment(this.receta.fecha_fin, 'DD/MM/YYYY');
        fechaFin.add(mayor, 'days');
        return fechaFin.isValid() ? fechaFin.format('DD/MM/YYYY') : '';
    }

    public createNavigation(route: string) {
        this.router.navigate(['dashboard', route]);
    }


    public toggleRowValidation(event: any, row: any) {
        this.tareasApi.fitosanitariosPUT.safePerform({
            id: row.id,
            validado: event.checked              
        }).toPromise().then(() => {
            this.getRequest.safePerform();
        });
    }


    public async downloadCsv() {
  
        const arrayFormFields = [
            this.formFieldsTiempo,
            this.formFieldsKM,
            this.formFieldsLugar,
            this.formFieldsTratamiento,
            this.formFieldsTractor,
            this.formFieldsFinal
        ];
        let campoFinal = 'factura_albaran';
        if (this.appName === 'innovia' ) {
            arrayFormFields.push(this.formFieldsInnovia);
            campoFinal = 'litros3';
        }
        if (this.appName === 'vginer' || this.showClimatologia) {
            arrayFormFields.push(this.formFieldsClima);
            campoFinal = 'climatologia';
        }
        if (this.appName === 'vginer') {
            arrayFormFields.push(this.formFieldsMezclasSobrantes);
            campoFinal = 'dia_tratamiento';
        }
        if (this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') {
            arrayFormFields.push(this.formFieldsObs);
            arrayFormFields.push(this.formFieldsOrdenTrabajo);
            campoFinal = 'orden_trabajo';
        }

        const csvContent = createCsv(arrayFormFields, this.filtering.value, campoFinal);
      
        const csvLink: any = document.getElementById('csvLink');
        csvLink.download = 'Fitosanitarios.csv';
        csvLink.href = encodeURI(csvContent);
        csvLink.click();
      
    }

    public override updateFooter() {
        this.sum();
    }
      
    public override sum() {
        this.totalHoras = moment(new Date('October 1, 2014 00:00:00'));
        this.auxTotalHoras = moment(new Date('October 1, 2014 00:00:00'));
        let contador = 0;
        (this.filtering.value || []).forEach((tareas, index ) => {
      
            if ( tareas.horas_totales != null ) {
         
                if (tareas.horas_totales.length > 0) {
                    contador++;
                    const horaMinuto = (tareas.horas_totales as string).split(':');
                    this.totalHoras = moment(this.totalHoras).add(horaMinuto[0], 'hours').add(horaMinuto[1], 'minutes');
                }
            }
      
            if ( index + 1 >= this.filtering.value.length ){
        
                let minutos: any = parseInt(moment(this.totalHoras).format('mm'), 10);
            
                let differencia = moment(this.totalHoras).diff(this.auxTotalHoras);
            
                if ( minutos.toString().length === 0) {
                    minutos = '00';
                }
      
                if ( minutos.toString().length === 1) {
                    minutos = '0' + minutos.toString();
                }

                differencia = differencia / (1000 * 60 * 60);
                differencia = Math.abs(Math.floor(differencia));
      
                this.totalHoras = differencia + ':' + minutos;
            
                if ( contador === 0){
                    this.totalHoras = '00:00';
                }
      
            }
        
          
        });
      
        if ( contador === 0){
            this.totalHoras = '00:00';
        }
        this.filtering.filter();
    }
      
    public changeShowAll(){
        if (this.showAllTareas) {
            this.tareasApi.fitosanitariosGET.toPromise({p3: 1}).then(res => {
                this.filtering.setValue(res);
            });
        } else {
            this.tareasApi.fitosanitariosGET.toPromise({p3: 0}).then(res => {
                this.filtering.setValue(res);
            });
        }
        this.filtering.filter();
    }      

    private expandFormFields() {
    }
}
