import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {StorageManager} from '../../common/storage-manager.class';

import type { CanActivate } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ShowRetoGuardService implements CanActivate {

    constructor(public router: Router) {}

    canActivate(): boolean {
    
        if (environment.appName === 'melia' && StorageManager.getUser().nombre === 'agrogibe' ) {
            return true;
        }

        if (!environment.features.showReto ) {
            this.router.navigate(['login']);
            return false;
        }

        return true;
    }
}
