import {Component, ViewChild} from '@angular/core';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import {ProductosApiService} from '../../../../../service/api/productos-api';
import {ProductosFertilizantesFormDefinition} from './form-control/productos-fertilizantes-form-definition';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { environment } from 'src/environments/environment';
import { VentasApiService } from 'src/app/service/api/ventas-api.service';
import { build, pool } from 'src/app/common/classes/request-builder';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import type { OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-producto-fertilizante-form',
    templateUrl: './producto-fertilizante-form.component.html',
    styleUrls: ['./producto-fertilizante-form.component.scss']
})
export class ProductoFertilizanteFormComponent extends BaseForm implements OnInit, OnDestroy {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
    requestButton: RequestButtonComponent = new RequestButtonComponent();


    public override model: any = {};
    public serverUrl: string = environment.serverUrl;
    public formRequest: AppFormRequest = new AppFormRequest();
    public form: ProductosFertilizantesFormDefinition = new ProductosFertilizantesFormDefinition();
    public showFieldsEmpresa = false;
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public fincas =  [];

    public adjunto: any;
    
    constructor(public override route: ActivatedRoute,
        public dashboardEvents: DashboardService,
        public formApi: FormCommonApiService,
        public fincasApi: FincasApiService,
        public productApi: ProductosApiService,
        private ventasApi: VentasApiService,
        public override router: Router,
        public http: HttpClient) {
        super(
            route,
            router,
            dashboardEvents,
            productApi.productos.PUT,
            productApi.productos.POST,
            'productos-fertilizantes',
            'Actualizar Fertilizante',
            'Crear Fertilizante'
        );
        
    }

    ngOnInit() {
        
        this.initFormData();
        
        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setPostRequest(this.productApi.productos.POST)
            .setPutRequest(this.productApi.productos.PUT)
            .setGetRequest(this.productApi.productos.GET)
            .setFormFields(
                this.form.topFormFields
                    .concat(this.form.bottomFormFieldsLeft)
                    .concat(this.form.bottomFormFieldsRight)
                    .concat(this.form.baselineFormFields)
            )
            .setFieldsToSend(
                (['id', 'id_cliente'] as any).concat(
                    this.form.topFormFields.map(it => it.field),
                    this.form.bottomFormFieldsLeft.map(it => it.field),
                    this.form.bottomFormFieldsRight.map(it => it.field),
                    this.form.baselineFormFields.map(it => it.field),
                    this.form.formFieldsMaterialFerti.map(it => it.field),
                    this.form.formFieldsMetalesPesados.map(it => it.field),
                )
            );

        this.formRequest.load();       
        this.softInit(this.getType());
    }

    submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }

        this.formRequest.beforeSend(resolve => {
            this.model['tipo'] = 'fertilizante';
            resolve(true);
        });

        this.formRequest.send();
    }
    
    public formChanges(tag: string) {
        this.requestButton.error = '';

        if (tag === 'material_ferti'){
            switch (this.model.material_ferti){

                case'1':
                case'2':
                case'3':
                case'4':
                case'5':
                case'6':
                case'7':
                case'8':
                case'10':
                    this.form.formFieldsMaterialFerti.map(it => {
                        if (it.field === 'material_ferti'){
                        }else if (it.placeholder === 'macronutriente_1'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_3'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_4'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_6'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_9'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_14'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_15'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else {it.inputType = {type: InputType.EMPTY}; }

                    }); 
                    break;
                case '9':
                case'11':
                case'12':
                case'17':
                    this.showFieldsEmpresa = false; 
                    this.form.formFieldsMaterialFerti.map(it => {            
                        if (it.field === 'material_ferti'){
                        }else if (it.placeholder === 'macronutriente_12'){
                            it.inputType = {type: InputType.EDIT_TEXT };
                        }else if (it.placeholder === 'macronutriente_13'){
                            it.inputType = {type: InputType.EDIT_TEXT };
                        }else {
                            it.inputType = {type: InputType.EMPTY};
                        }
                    });
                    break;
                
                case'13':
                case'18':
                case'19':
                case'20':
                case'21':
                case'22':
                    this.showFieldsEmpresa = false;
                    this.form.formFieldsMaterialFerti.map(it => {
                        if (it.field === 'material_ferti'){
                        }else if (it.placeholder === 'macronutriente_1'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_3'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_4'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_6'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_10'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_14'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else {it.inputType = {type: InputType.EMPTY}; }
                    });
                    break;

                case '14':
                case '16':
                    
                    this.showFieldsEmpresa = false;
                    this.form.formFieldsMaterialFerti.map(it => {
                        if (it.field === 'material_ferti'){

                        }else if (it.placeholder === 'macronutriente_1') {
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_2') {
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_4') {
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_5') {
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_6') {
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_7') {
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_8') {
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_9') {
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_12') {
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else {
                            it.inputType = {type: InputType.EMPTY};
                        }
                    });
                    break;
                case '15':
                    
                    this.showFieldsEmpresa = false;
                    this.form.formFieldsMaterialFerti.map(it => {
                        if (it.field === 'material_ferti'){
                        }else if (it.placeholder === 'macronutriente_1'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_3'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_4'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_6'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_10'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_14'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else {it.inputType = {type: InputType.EMPTY}; }
                    });
                    break;

                case'23':
                    this.showFieldsEmpresa = false;
                    this.form.formFieldsMaterialFerti.map(it => {
                        if (it.field === 'material_ferti'){
                        }else if (it.placeholder === 'macronutriente_11'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else if (it.placeholder === 'macronutriente_14'){
                            it.inputType = {type: InputType.EDIT_TEXT};
                        }else {it.inputType = {type: InputType.EMPTY}; }
                    });
                    break;

                default:
                    this.form.formFieldsMaterialFerti.map(it => {
                        if (it.field === 'material_ferti'){
                            it.inputType = {type: InputType.DROPDOWN};
                        }else {it.inputType = {type: InputType.TEXT}; }
                    });
            }
        }
    }

    public readUrl(input: HTMLInputElement) {
        input.click();
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.adjunto = e.target.result;
            };
            reader.readAsDataURL(input.files[0]);
            const r = this.http.post(environment.serverUrl + 'ws/trabajadores/upload_file.php', this.adjunto).subscribe(res => {
                const file: any = res;
                this.model.adjunto = file.fileName;
                r.unsubscribe();
            });
        }
    }

    public fileChange(event: any ) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0] ?? new File([], '');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');

            const name: string[] = file.name.split('.');
            const ext = name[name.length - 1];
            const accepted = ['gif', 'jpeg', 'jpg', 'jiff', 'png', 'svg', 'tiff'];
            let cond = false;

            for (const e of accepted) {
                if (e === ext) {
                    cond = true;
                }
            }
            
            if (cond) {
                this.http.post(`${environment.serverUrl}ws/productos/upload_file.php`, formData, {headers: headers}).subscribe(data => {
                    const res: any = data;
                    this.model.imagen = res.fileName;
                }, error => {
                    console.log(error);
                }
                );
            } else {
                alert('Formato de imágen no válido.');
                this.model.imagen = null;
            }
        }
    }

    public override goBack() {
        this.router.navigate(['dashboard', this.pathToGoBack]);
    }
    
    private initFormData(): Promise<any> {
        const builds = [
            build(this.fincasApi.fincasGET, this.fincas, ['nombre']),
            build(this.ventasApi.compradoresGET, this.form.empresa, ((it: { tipo: string; razon_social: any; }) => it.tipo === 'Proveedor' ? it.razon_social : ''))
        ];
        
        return pool(builds, () => {
            this.formRequest.update();
        });
    }
}

