import { Component, ViewChild } from '@angular/core';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { environment } from 'src/environments/environment';
import { BaseForm } from 'src/app/view/base-form';
import { AppFormRequest } from 'src/app/view/app-common/form-request/app-form-request';
import { list } from 'src/app/common/classes/request-builder';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { UsuariosApiService } from 'src/app/service/api/usuarios-api.service';
import moment from 'moment';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import paises from 'src/app/common/country';
import { StorageManager } from 'src/app/common/storage-manager.class';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { UserModel } from 'src/app/models/user.model';

@Component({
    selector: 'app-usuarios-form',
    templateUrl: './usuarios-form.component.html',
    styleUrls: ['./usuarios-form.component.scss']
})
export class UsuariosFormComponent extends BaseForm implements OnInit {

    @ViewChild(RequestButtonComponent, { read: false, static: false })
        requestButton: RequestButtonComponent = new RequestButtonComponent();

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public clienteName: string = environment.features.clienteName;
    public showPerfilUnidades = environment.features.showPerfilUnidades;
    public lista_paises = list( [ '...', null ] ); 

    public override model: UserModel = {id: '', nombre: '', password: '', apellidos: '', rol: '', username: ''};
    public formRequest: AppFormRequest = new AppFormRequest();
    public nifErrors = '';
    public passErrors = '';
    public showPassErrors = false;
    public isShowingPassword = false;

    public canRegister = environment.features.canRegister;


    public userRoles = list(
        ['Usuario normal', 'user'],
        ['Administrador', 'admin']
    );

    public userRolesDQAgro = list(
        ['Usuario normal', 'user'],
        ['Trabajador DQAgro', 'admin']
    );

    public userRolesCropGest = list(
        ['...', null],
        [this.clienteName, 'comunero'],
        ['Aplicador', 'responsable'],
    );

    public formFieldsDatosCuenta: ItemInterface<object>[] = [
        {
            label: 'Nombre de Usuario', 
            name: 'Nombre de Usuario', 
            field: 'username', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Contraseña',
            field: 'password', 
            inputType: {type: InputType.PASSWORD}
        },
        {
            label: 'Repetir contraseña', 
            field: 'password_repeat', 
            inputType: {type: InputType.PASSWORD}
        },
        {
            hasLabel: false, 
            field: 'sin_contraseña', 
            inputType: {type: InputType.ONLY_TEXT},
            style: 'color: red'
        },
        {
            label: 'Tipo', 
            name: 'Tipo', 
            field: 'tipo', 
            inputType: {type: InputType.DROPDOWN},
            values: this.userRolesCropGest,
        }
    ];

    public formFieldsDatosPersonales: ItemInterface<object>[] = [
        {
            label: 'Nombre', 
            name: 'Nombre', 
            field: 'nombre', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Apellidos', 
            name: 'Apellidos', 
            field: 'apellidos', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Email', 
            name: 'Email', 
            field: 'email', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Teléfono', 
            name: 'Teléfono', 
            field: 'telefono', 
            inputType: {type: InputType.EDIT_TEXT_MASK}, 
            mask: '999-999-999'
        },
        {
            label: 'NIF', 
            field: 'nif', 
            inputType: {type: InputType.EDIT_TEXT_MASK}, 
            mask: '99999999-a'
        },
        {
            label: 'CIF', 
            field: 'nif', 
            inputType: {type: InputType.EDIT_TEXT_MASK}, 
            mask: 'a-99999999'
        },
    ];

    public formFields: ItemInterface<object>[] = [
        {
            field: 'nombre', 
            label: 'Nombre', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'apellidos', 
            label: 'Apellidos', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'tipo_carnet',
            label: 'Tipo de carnet',
            inputType: {type: InputType.DROPDOWN}, 
            values: list(['...', null], 'BÁSICO', 'CUALIFICADO', 'FUMIGADOR', 'PILOTO', 'ASESOR')
        },
        {
            field: 'fecha_caducidad', 
            label: 'Fecha de caducidad', 
            inputType: {type: InputType.CALENDAR}
        },
        {
            field: 'nif', 
            label: 'NIF', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'ropo', 
            label: 'ROPO', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'imei', 
            label: 'IMEI', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: environment.appName === 'manezylozano'
        },
        {
            field: 'pais', 
            label: 'País', 
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.lista_paises,
            visible: environment.appName === 'manezylozano'
        },
    ];

    public formFields2: ItemInterface<object>[] = [
        {
            field: 'uds_medida',
            label: 'Unidad de medida',
            inputType: {type: InputType.DROPDOWN},
            values: list('Hectáreas', 'Hanegadas', 'Taullas'),
            visible: this.showPerfilUnidades
        },
        {
            field: 'ratio_ha',
            label: 'Relación con una hectárea',
            inputType: {type: InputType.EDIT_NUMERIC},
            placeholder: '1 Hectárea = X unidades',
            visible: this.showPerfilUnidades
        },
        {
            field: 'tipo_mando',
            label: 'Tipo de mando',
            inputType: {type: InputType.DROPDOWN}, 
            values: list(['...', null], 'SISTRONIC', 'TRONIC', 'SISTROMATIC', 'OZONO'),
            visible: this.appName === 'manezylozano'
        },
    ];

    public loginFormFields: ItemInterface<object>[] = [
        {
            field: 'email', 
            label: 'Email', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'password', 
            label: 'Contraseña', 
            inputType: {type: InputType.PASSWORD}
        },
        {
            field: 'password_repeat', 
            label: 'Repetir contraseña', 
            inputType: {type: InputType.PASSWORD}
        }
    ];

    public roleFormFields: ItemInterface<object>[] = [
        {
            field: 'rol', 
            label: 'Rol del usuario', 
            inputType: {type: InputType.DROPDOWN}, 
            values: this.applicationType === 'cropgest' ? this.userRolesCropGest : this.userRoles 
        },
    ];

    public fieldsToSend = (this.canRegister || this.applicationType === 'cropgest') ? 
        ([
            'id', 
            'rol', 
            'email', 
            'tipo'
        ] as string[])
            .concat(this.formFieldsDatosCuenta.map(it => it.field ?? ''))
            .concat(this.formFieldsDatosPersonales.map(it => it.field ?? '')) :
        (['id', 'rol', 'email'] as string[])
            .concat(this.formFields.map(it => it.field ?? ''))
            .concat(this.formFields2.map(it => it.field ?? '')) 
        ;

    constructor(
        public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public usuariosApi: UsuariosApiService,
        public override router: Router
    ) {
        super(
            route,
            router,
            dashboard,
            usuariosApi.usuariosPUT,
            usuariosApi.usuariosPOST,
            'usuarios',
            'Actualizar Usuario',
            'Crear Usuario'
        );
    }

    async ngOnInit() {
        if (this.appName === 'manezylozano') {
            await this.fillCountry();
        }

        if (this.appName === 'dqvademecum' && this.roleFormFields[0]) {
            this.roleFormFields[0].values = this.userRolesDQAgro;
        }

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .isGeneric(false)
            .setGetRequest(this.usuariosApi.usuariosGET)
            .setPostRequest(this.usuariosApi.usuariosPOST)
            .setPutRequest(this.usuariosApi.usuariosPUT)
            .setFormFields(
                this.canRegister ? 
                    this.formFieldsDatosCuenta.concat(this.formFieldsDatosPersonales) : 
                    this.formFields.concat(this.formFields2)
            )
            .setFieldsToSend(this.fieldsToSend);
      
        this.formRequest.beforeSend(resolve => {
            if (!this.model.rol) {
                this.model.rol = 'user';
            }

            if (moment(this.model.fecha_caducidad).isValid()) {
                this.model.fecha_caducidad = moment(this.model.fecha_caducidad).format('DD/MM/YYYY');
            }

            if (this.showPerfilUnidades) {
                if ((this.model.uds_medida === 'Hectárea') && !this.model.ratio_ha) {
                    this.model.ratio_ha = 1;
                } 
            }

            if (!this.validatePassword() || (this.canRegister && !this.validateNif())) {
                this.showPassErrors = true;
            } else {
                resolve(true);
            }
        });

        this.formRequest.afterLoad(() => {
            this.userRolesCropGest.selected = 'comunero';
        });
    
        this.formRequest.load();
        this.softInit(this.getType());
    }

    public submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
    
        delete this.model.sin_contraseña;

        this.formRequest.send();
    }

    public formChanges() {
    
    
    }

    public validateNif() {
        if (this.formRequest.type === FormRequestTypes.CREATE) {
            const nifSearch = ((this.formRequest.model || {}).nif || '').toLowerCase();

            if ((nifSearch || '').length === 0) {
                return true;
            }

            const existeNif = false;
            /*existeNif = (this.usuarios.values || [])
            .filter(it => it && it.value)
            .map(it => it.value)
            .find((nif) => (nif.nif || '').toLowerCase() === nifSearch);*/

            if (existeNif) {
                this.nifErrors = 'El usuario ya existe';
            } else {
                this.nifErrors = '';
            }

            return !existeNif;
        }

        return true;
    }

    public validatePassword() {
        this.showPassErrors = false;
        this.passErrors = '';

        if (this.model.password && (this.model.password.trim().length > 0)) {
            if (!this.model.password_repeat) {
                this.passErrors += 'Por favor, repita la contraseña.\n';
                return false;
            }
            if (this.model.password !== this.model.password_repeat) {
                this.passErrors += 'Las contraseñas no coinciden.\n';
                return false;
            }
            this.formRequest.setFieldsToSend(this.fieldsToSend.concat('password'));
            return true;
        }
        return true;
    }

    public togglePassword() {
        if (this.formRequest.type === FormRequestTypes.CREATE) {
            return;
        }
        if (!this.model.password) {      
            this.usuariosApi.usuariosGET.toPromise({  id: this.model.id, p3: StorageManager.getUser().id}).then(result => {
                if (result) {
                    this.model.password_old = result[0].password === null ? 'Sin Contraseña' : result[0].password;
                }
                return;
            }
            ).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }
        this.isShowingPassword = !this.isShowingPassword;
    }


    /**
     * Rellenamos el listado de países.
     * @returns 
     */
    fillCountry() {
        return new Promise( (resolve)  => {
            for (let index = 0; index < paises.length; index++) {
                const pais = { label: paises[index]?.label, value: paises[index]?.value} ;
                this.lista_paises.filtered.push( pais );
                if ( (index + 1 ) >= paises.length  ) { 
                    resolve(true);
                }
            }
        });
    }

}