import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StorageManager} from '../../storage-manager.class';

import type { OnInit } from '@angular/core';

export enum RequestButtonTypes {
    NEW,
    EDIT,
    DELETE
}
@Component({
    selector: 'app-request-button',
    templateUrl: './request-button.component.html',
    styleUrls: ['./request-button.component.scss']
})


export class RequestButtonComponent implements OnInit {

    @Input() request: any;
    @Input() lockOnSuccess: boolean = false;
    @Input() error: string | null = null;
    @Input() warning: any;
    @Input() mode: RequestButtonTypes = 0;
    @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

    public failMessage: string[] = [];

    public REQUEST_BUTTON_TYPES = RequestButtonTypes;

    constructor() {
    }

    ngOnInit() {
            
        if (!this.mode) {
            this.mode = RequestButtonTypes.NEW;
        }

        if ((StorageManager.getUser() || {} as any).rol === 'demo') {
            this.failMessage[0] = 'Operación no permitida';
            this.failMessage[1] = '';
        } else {
            this.failMessage[0] = 'Ha ocurrido un error...';
            this.failMessage[1] = 'Volver a intentar';
        }
    }

    public requestClick() {
        //this.requestClick.emit();
    }

}
