import {BaseResponseModel} from '../../models/base-response.model';
import {Utils} from '../../common/utils';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export enum HttpRequestType {
    GET, POST, PUT, DELETE
}

export class BaseApi<T> {
    public inject: any = {
        params: {},
        payload: {}
    };

    public proxyCheck = (() => true) as Function;

    private BASE_URL: string = environment.serverUrl + 'ws/';
    private BASE_URL_SIEX = 'https://locatec.es/proyectos/SIEX/ws/';
    private BASE_URL_AUX: string = environment.serverUrl + 'ws/';
    private __: HttpClient;

    constructor(http: HttpClient) {
        this.__ = http;
    }
    protected get(endpoint: string, params?: any): Promise<BaseResponseModel> {
        switch (endpoint) {
            case 'cultivo_variedad/get_cultivos':
                this.BASE_URL = this.BASE_URL_SIEX;
                break;
            case 'cultivo_variedad/get_variedades':
                this.BASE_URL = this.BASE_URL_SIEX;
                break;
            case 'generic/vista_cultivo_variedad':
                this.BASE_URL = this.BASE_URL_SIEX;
                break;
            case 'parcelas/get_portainjertos':
                this.BASE_URL = this.BASE_URL_SIEX;
                break;
            case 'parcelas/get_sistemas_riego':
                this.BASE_URL = this.BASE_URL_SIEX;
                break;
            case 'tareas/justificaciones_get':
                this.BASE_URL = this.BASE_URL_SIEX;
                break;
            case 'tareas/actividades_sobre_cubierta_get':
                this.BASE_URL = this.BASE_URL_SIEX;
                break;
            default:
                this.BASE_URL = this.BASE_URL_AUX;
                break;
        }
        return this.proxy(HttpRequestType.GET, endpoint, params, () => this.__
            .get(this.BASE_URL + this.injectParams(this.changeURL(endpoint), params), {
                params: ((p) => {
                    const filteredParams: any = {};

                    for (const i in p) {
                        if (p[i] !== undefined && p[i] !== null) {
                            filteredParams[i] = p[i];
                        }
                    }

                    return filteredParams;
                })(params)
            })
            .toPromise()
            .then<BaseResponseModel, never>(
            this.handleResponse,
            this.handleError));
    }

    protected put(endpoint: string, data: any): Promise<BaseResponseModel> {
        this.BASE_URL = this.BASE_URL_AUX;
        return this.proxy(HttpRequestType.PUT, endpoint, data, () => this.__
            .put(this.BASE_URL + this.injectParams(this.changeURL(endpoint), data), JSON.stringify(this.injectPayload(data, endpoint)))
            .toPromise()
            .then<BaseResponseModel, never>(
            this.handleResponse,
            this.handleError));
    }

    protected delete(endpoint: string, data: any): Promise<BaseResponseModel> {
        this.BASE_URL = this.BASE_URL_AUX;
        return this.proxy(HttpRequestType.DELETE, endpoint, data, () => this.__
            .delete(this.BASE_URL + this.injectParams(this.changeURL(endpoint), data), data)
            .toPromise()
            .then<BaseResponseModel, never>(
            this.handleResponse,
            this.handleError));
    }

    protected post(endpoint: string, data?: any): Promise<BaseResponseModel> {
        this.BASE_URL = this.BASE_URL_AUX;
        return this.proxy(HttpRequestType.POST, endpoint, data, () => this.__
            .post(this.BASE_URL + this.injectParams(this.changeURL(endpoint), data), JSON.stringify(this.injectPayload(data, endpoint)))
            .toPromise()
            .then<BaseResponseModel, never>(
            this.handleResponse,
            this.handleError));
    }

    protected handleResponse(response: any): BaseResponseModel {
        if (Utils.isSet(response)) {
            return response as BaseResponseModel;
        } else {
            return ({}) as BaseResponseModel;
        }
    }

    protected handleError(error: any): any {
        throw error;
    }

    protected getEntityData(baseResponse: BaseResponseModel): T {
        return baseResponse ?
            baseResponse.data ?
                baseResponse.data.length > 0 ?
                    baseResponse.data[0] as T
                    : null as T
                : null as T
            : null as T;
    }

    protected getEntityDataList(baseResponse: BaseResponseModel): T[] {
        return baseResponse ?
            baseResponse.data ?
                baseResponse.data as T[]
                : [] as T[]
            : [] as T[];
    }


    private changeURL(endpoint: string): string {
        const route = endpoint.split('/');
        endpoint = 'index.php?';

        for (let i = 0; i < route.length; i++) {
            const element = route[i];

            endpoint += 'p' + (i + 1) + '=' + element;

            if (i < route.length - 1) {
                endpoint += '&';
            }
        }

        return endpoint;
    }

    private injectParams(endpoint: string, data?: any) {
        for (const x in this.inject.params) {
            if (this.inject.params.hasOwnProperty(x)) {
                const value = this.inject.params[x](endpoint, data);

                if (value !== undefined && value !== null) {
                    if (['string', 'number', 'boolean'].indexOf(typeof value) !== -1) {
                        endpoint += '&' + x + '=' + value;
                    } else {
                        console.warn('BaseApi @ Inyect Params: UNSUPORTED TYPE > '
                            + value.constructor.name
                            + ' FOR ' + x + ' < supported types: [string | number | boolean]'
                            + ' request: ' + endpoint
                        );
                    }
                } else {
                    console.warn('BaseApi @ Inyect Params: UNDEFINED OR NULL VALUE FOR > '
                        + x + ' < request: '
                        + endpoint
                    );
                }
            }
        }

        return endpoint;
    }

    private proxy(type: HttpRequestType, endpoint: string, data: any, callback: { (): Promise<BaseResponseModel>; (): Promise<BaseResponseModel>; (): Promise<BaseResponseModel>; (): Promise<BaseResponseModel>; (): Promise<any>; }): Promise<any> {
        if (this.proxyCheck(type, endpoint, data)) {
            return callback();
        } else {
            return new Promise((_resolve, reject) => reject());
        }
    }

    private injectPayload(data: any, endpoint: string) {
        if (data) {
            for (const x in this.inject.payload) {
                if (this.inject.payload.hasOwnProperty(x)) {
                    const value = this.inject.payload[x](endpoint, data);

                    if (value !== undefined && value !== null) {
                        data[x] = value;
                    } else {
                        console.warn('BaseApi @ Inyect Payload: UNDEFINED OR NULL VALUE FOR > '
                            + x + ' < request: '
                            + endpoint
                        );
                    }
                }
            }
        }

        return data;
    }
}

