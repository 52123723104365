import {Component} from '@angular/core';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {Router} from '@angular/router';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {Utils} from '../../../../common/utils';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {VentasApiService} from '../../../../service/api/ventas-api.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {BaseView} from '../../../base-view';
import { environment } from 'src/environments/environment';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
@Component({
    selector: 'app-compradores',
    templateUrl: './compradores.component.html',
    styleUrls: ['./compradores.component.scss']
})
export class CompradoresComponent extends BaseView implements OnInit {

    public getRequest: RequestHandler = this.ventasApi.compradoresGET;
    public deleteRequest: RequestHandler = this.ventasApi.compradoresDELETE;
    public pathToForm = 'compradores-form';
    public menuItemId = 'compradores';
    public canAddCompradores = environment.features.canAddCompradores;
    public showVentasComercioProveedor = environment.features.showVentasComercioProveedor;
    public textoAnyadir = environment.appName === 'covidai' ? 'Añadir Proveedor' : 'Añadir Comercio';


    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: { [key: string]: any } = {};
    public override model: { [key: string]: any } = {};

    public formFields: ItemInterface<any>[] = [
        {field: 'razon_social', label: 'Razón Social', inputType: {type: InputType.TEXT}},
        {field: 'nif', label: 'NIF', inputType: {type: InputType.TEXT}},
        {field: 'codigo_postal', label: 'Código Postal', inputType: {type: InputType.TEXT}},
        {field: 'poblacion', label: 'Población', inputType: {type: InputType.TEXT}},
        {field: 'provincia', label: 'Provincia', inputType: {type: InputType.TEXT}},
        {field: 'pais', label: 'País', inputType: {type: InputType.TEXT}},
        {field: 'rgseaa', label: 'RGSEAA', inputType: {type: InputType.TEXT}},
        {field: 'tipo', label: 'Tipo', inputType: {type: InputType.TEXT}, visible: this.showVentasComercioProveedor }
    ];

    public cols = [
        {field: 'razon_social', header: 'Razón Social', filter: '',  width: '240px'},
        {field: 'nif', header: 'NIF'},
        {field: 'codigo_postal', header: 'Código Postal'},
        {field: 'poblacion', header: 'Población.', filter: ''},
        {field: 'provincia', header: 'Provincia', filter: '', visible: environment.appName !== 'cartagenafresh'},
        {field: 'pais', header: 'País', filter: '', visible: environment.appName === 'cartagenafresh'},
        {field: 'rgseaa', header: 'RGSEAA', filter: ''},
        {field: 'tipo', header: 'Tipo', visible: this.showVentasComercioProveedor }
    ];

    constructor(private dashboard: DashboardService,
        private ventasApi: VentasApiService,
        private router: Router) {
        super(dashboard, ventasApi.compradoresGET);

    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);
    }


    public add() {
        this.router.navigate(['dashboard', 'crear-comprador']);
    }

    public edit(data: any) {
        this.router.navigate(['dashboard', 'editar-comprador', data.id]);
    }

    public override deleteRegister() {
        if (this.model['id']) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }
    
    public globalConditionalStyle(value: string, col: { field: string; }, _row: any): any {

        if (Utils.isNumber(value) && col.field === 'rgseaa' ) {
            return {
                textAlign: 'right',
                paddingRight: '70px',
            };
        }
        if (col.field === 'razon_social') {
            return {
                textAlign: 'left'
            };
            
        }

        else{
            return {textAlign: 'center'};
        }
    }

    public override show(modal: string, data: any) {
        let x;

        this.model = data;
        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow.hasOwnProperty(x)) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

}
