<p-panel>
  <h4>Registrar Salida</h4>
  <app-form-builder
    [model]="model"
    [undoEnabled]="undoEnabled"
    [distributionColumns]="3"
    [distributionOrder]="[3,2,2,2,2,1]"
    [fields]="formFields">
  </app-form-builder>
  <br>
  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
      (click)="submit()"
      [lockOnSuccess]="true"
      [mode]="requestButtonType"
      [request]="request">
    </app-request-button>
  </div>
  <br>
</p-panel>
