import { Injectable } from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ClientesApiService {
    public clientesGET: RequestHandler = new RequestHandler();
    public clientesPOST: RequestHandler = new RequestHandler();
    public clientesPUT: RequestHandler = new RequestHandler();
    public clientesDELETE: RequestHandler = new RequestHandler();

    public cooperativasGET: RequestHandler = new RequestHandler();

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineClientesGET();
        this.defineClientesPOST();
        this.defineClientesPUT();
        this.defineClientesDELETE();

        this.defineCooperativasGET();
    }

    private defineClientesGET() {
        this.clientesGET
            .fetch(HttpRequestType.GET, 'clientes/get');

        this.api
            .registerRequest(this.clientesGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.clientesGET));
    }

    private defineClientesPOST() {
        this.clientesPOST
            .fetch(HttpRequestType.POST, environment.features.applicationType !== 'cropgest' ? 'generic/clientes' : 'clientes/post');

        this.api
            .registerRequest(this.clientesPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.clientesPOST));
    }

    private defineClientesPUT() {
        this.clientesPUT
            .fetch(HttpRequestType.PUT, 'generic/clientes');

        this.api
            .registerRequest(this.clientesPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.clientesPUT));
    }

    private defineClientesDELETE() {
        this.clientesDELETE
            .fetch(HttpRequestType.DELETE, 'generic/clientes');

        this.api
            .registerRequest(this.clientesDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.clientesDELETE));
    }

    private defineCooperativasGET() {
        this.cooperativasGET.fetch(HttpRequestType.GET, 'clientes/cooperativas_get');
        this.api.registerRequest(this.cooperativasGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.cooperativasGET));
    }
}
