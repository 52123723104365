import {InputType} from '../../../../../../common/components/form-builder/form-builder.component';
import {FormRequestTypes} from '../../../../../../common/classes/form-request';
import { environment } from 'src/environments/environment';
import { StorageManager } from 'src/app/common/storage-manager.class';
import {
    list,
} from '../../../../../../common/classes/request-builder';

import type { ItemInterface } from '../../../../../../common/components/form-builder/form-builder.component';

export class ControlSanitarioFormDefinition {

    public hasClients = environment.features.hasClients;    
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;      
    
    public lastFieldChanged: string = '';

    public requestType: FormRequestTypes = 0;

    public model = {};
    public fincas = list();  
    public clientes = list();        

    public tiposTratamientos = list(
        ['...', null],
        'ENFERMEDADES INFECCIOSAS',
        'INMUNIZACIÓN',
        'VACUNACIÓN',
        'DESPARASITACIÓN'
    );
    
    
    public formFields: ItemInterface<any>[] = 
        [
            {
                field: 'id_finca',
                label: this.fincaName,
                inputType: {type: InputType.DROPDOWN},
                values: this.fincas,
                valuePrimaryKey: 'id',
                required: true,
                filter: (it: { value: { id_cliente: any; }; }) => {
                    if (!this.hasClients || (this.requestType === FormRequestTypes.DUPLICATE)) {
                        return true;
                    } else if (StorageManager.getClient()) {
                        return it.value.id_cliente === (StorageManager.getClient() || {} as any).id;
                    } else if (this.clientes.selected) {
                        return it.value.id_cliente === (this.clientes.selected || {}).id;
                    }
                    return true;
                },
            
            },         
            {field: 'fecha_inicio', label: 'Fecha inicio', inputType: {type: InputType.CALENDAR}},
            {field: 'fecha_fin', label: 'Fecha fin', inputType: {type: InputType.CALENDAR}},
            {field: 'fecha_plazo', label: 'Plazo de seguridad', inputType: {type: InputType.EDIT_TEXT}},
            {
                field: 'tipo_tratamiento', 
                label: 'Tipo de tratamiento', 
                values: this.tiposTratamientos, 
                inputType: {type: InputType.DROPDOWN}
            },
            {field: 'veterinario', label: 'Veterinario', inputType: {type: InputType.EDIT_TEXT}},
            {field: 'diagnostico', label: 'Diagnóstico', inputType: {type: InputType.EDIT_TEXT}},
            {field: 'producto', label: 'Producto', inputType: {type: InputType.EDIT_TEXT}},
            {field: 'animales_tratados', label: 'Animales tratados', inputType: {type: InputType.EDIT_TEXT}},
            {field: 'observaciones', label: 'Observaciones', inputType: {type: InputType.EDIT_TEXTAREA}}

        ];

    public formChanges(tag: string) {
        this.lastFieldChanged = tag;
    }
}


