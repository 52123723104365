<br>

<div style="width: auto; overflow: auto">
<!-- <common-list-table [request]="getRequest" [cols]="cols" action-button-text="Añadir formulaio 2" (action-button-click)="add()" [global-conditional-style]="globalConditionalStyle"> -->
    <app-common-list-table 
            [request]="getRequest"
            [filtering]="filtering"
            [cols]="cols"
            [globalConditionalStyle]="globalConditionalStyle"
            [globalVisualTransform]="globalVisualTransform"
            actionButtonText="Añadir "
            (actionButtonClick)="add()">

        <ng-template #header>
            <div style="margin-left: 20px;">
                <button class="mx-2 btn btn-info" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem;">
                    <i class="fa fa-download"></i>
                    Exportar listado
                </button>
            </div>
        </ng-template>
        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center" style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons  (editRowButtonEvent)="edit(data)" (deleteRowButtonEvent)="show('delete', data)" (showRowButtonEvent)="show('info', data)">
                </app-action-buttons>
            </td>
        </ng-template>
    </app-common-list-table>
</div>

<p-dialog header="Detalle del registro" [(visible)]="canShow['info']" (onShow)="scrollToTop()">

    <div *ngIf="canShow['info']" style="width: 80vw !important">
        <div class="d-flex justify-content-between">
            <app-form-builder style="width: 30%"
                [model]="model" 
                [distributionColumns]= "1" 
                [fields]="formFieldsLeft">
            </app-form-builder>
            <app-form-builder style="width: 40%; margin-left: 5%; margin-right: 5%"
                [model]="model" 
                [distributionColumns]= "1" 
                [fields]="formFieldsMiddle">
            </app-form-builder>
            <app-form-builder style="width: 20%;"
                [model]="model" 
                [distributionColumns]= "1" 
                [fields]="formFieldsRight">
            </app-form-builder>
          </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
        <div *ngIf="imagenAdjunto" class="adjunto-container">
            <p>Foto</p>
            <div class="img-container">
                <button (click)="verAdjunto()">
                    <img [src]="imagenAdjunto" alt="">
                </button>
            </div>
        </div>
    </div>
    <br>

</p-dialog>

<p-dialog header="Eliminar registro" [(visible)]="canShow['delete']" (onShow)="scrollToTop()">

    <div *ngIf="canShow['delete']" style="width: 80vw !important">
        <div class="d-flex justify-content-between">
            <app-form-builder style="width: 30%"
                [model]="model" 
                [distributionColumns]= "1" 
                [fields]="formFieldsLeft">
            </app-form-builder>
            <app-form-builder style="width: 40%; margin-left: 5%; margin-right: 5%"
                [model]="model" 
                [distributionColumns]= "1" 
                [fields]="formFieldsMiddle">
            </app-form-builder>
            <app-form-builder style="width: 20%;"
                [model]="model" 
                [distributionColumns]= "1" 
                [fields]="formFieldsRight">
            </app-form-builder>
          </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button
                    (click)="deleteRegister()"
                    [request]="deleteRequest"
                    [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading" style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>

</p-dialog>