export class FormRequest {

    public state: string = '';

    private events: any = {
        load: {
            before: [],
            on: [],
            after: []
        },
        update: {
            before: [],
            on: [],
            after: []
        },
        send: {
            before: [],
            on: [],
            after: []
        },
        finish: {
            before: [],
            on: [],
            after: []
        }
    };

    // REQUISITES
    constructor() {

    }

    // EVENTS
    public beforeLoad(callback: (resolve: any) => void) {
        this.events.load.before.push(callback);
    }

    public onLoad(callback: (resolve: any) => void) {
        this.events.load.on.push(callback);
    }

    public afterLoad(callback: (resolve: any) => void) {
        this.events.load.after.push(callback);
    }

    public beforeUpdate(callback: (resolve: any) => void) {
        this.events.update.before.push(callback);
    }

    public onUpdate(callback: (resolve: any) => void) {
        this.events.update.on.push(callback);
    }

    public afterUpdate(callback: (resolve: any) => void) {
        this.events.update.after.push(callback);
    }

    public beforeSend(callback: (resolve: any) => void) {
        this.events.send.before.push(callback);
    }

    public onSend(callback: (resolve: any) => void) {
        this.events.send.on.push(callback);
    }

    public afterSend(callback: (resolve: any) => void) {
        this.events.send.after.push(callback);
    }

    public beforeFinish(callback: (resolve: any) => void) {
        this.events.finish.before.push(callback);
    }

    public onFinish(callback: (resolve: any) => void) {
        this.events.finish.on.push(callback);
    }

    public afterFinish(callback: (resolve: any) => void) {
        this.events.finish.after.push(callback);
    }
    
    // ROUTINES
    public load() {
        this.state = 'load-before';
        this.executeQueue(this.events.load.before.slice())
            .then((next) => {
                if (next) {
                    this.state = 'load-on';
                    return this.executeQueue(this.events.load.on.slice());
                } else {
                    return;
                }
            })
            .then((next) => {
                if (next) {
                    this.state = 'load-after';
                    return this.executeQueue(this.events.load.after.slice());
                } else {
                    return;
                }
            })
            .catch(err => {
                console.error('FormRequest @ Processing Load Stack: ', err);
            });
    }

    public update() {
        this.state = 'update-before';
        this.executeQueue(this.events.update.before.slice())
            .then((next) => {
                if (next) {
                    this.state = 'update-on';
                    return this.executeQueue(this.events.update.on.slice());
                } else {
                    return;
                }
            })
            .then((next) => {
                if (next) {
                    this.state = 'update-after';
                    return this.executeQueue(this.events.update.after.slice());
                } else {
                    return;
                }
            })
            .catch(err => {
                console.error('FormRequest @ Processing Update Stack: ', err);
            });
    }

    public send() {
        this.state = 'send-before';
        this.executeQueue(this.events.send.before.slice())
            .then((next) => {
                if (next) {
                    this.state = 'send-on';
                    return this.executeQueue(this.events.send.on.slice());
                } else {
                    return;
                }
            })
            .then((next) => {
                if (next) {
                    this.state = 'send-after';
                    return this.executeQueue(this.events.send.after.slice());
                } else {
                    return;
                }
            })
            .catch(err => {
                console.error('FormRequest @ Processing Send Stack: ', err);
            });
    }

    public finish() {
        this.state = 'finish-before';
        this.executeQueue(this.events.finish.before.slice())
            .then((next) => {
                if (next) {
                    this.state = 'finish-on';
                    return this.executeQueue(this.events.finish.on.slice());
                } else {
                    return;
                }
            })
            .then((next) => {
                if (next) {
                    this.state = 'finish-after';
                    return this.executeQueue(this.events.finish.after.slice());
                } else {
                    return;
                }
            })
            .catch(err => {
                console.error('FormRequest @ Processing Finish Stack: ', err);
            });
    }

    private executeQueue(queue: any[]): Promise<any> {
        return new Promise(queue[0] || ((resolve) => resolve(null)))
            .then((next) => {
                if (next === true) {
                    (queue || []).shift();
                    if ((queue || []).length > 0) {
                        return this.executeQueue(queue);
                    } else {
                        return new Promise(resolve => resolve(next)).then(() => next);
                    }
                } else if (next === false) {
                    return new Promise(resolve => resolve(next)).then(() => next);
                } else {
                    return new Promise(resolve => resolve(next)).then(() => true);
                }
            });
    }
}

export enum FormRequestTypes {
    CREATE, EDIT, DUPLICATE
}
