import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { environment } from 'src/environments/environment';

import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';

export class ProductosGanaderiaFormDefinition {

    public appName = environment.appName;
    public showPrecio = environment.features.showPrecio;

    public fincas = {
        selected: null,
        filtered: [{label: 'Cargando...', value: null}],
        values: []
    };

    public productos = {
        selected: null,
        filtered: [{label: 'Cargando...', value: null}],
        values: []
    };

    public estado = {
        selected: null,
        filtered: [
            {label: '...', value: null},
            {label: 'Activo', value: 'Activo'},
            {label: 'No Activo', value: 'No Activo'},
        ],
        values: []
    };

    public unidades = {
        selected: null,
        filtered: [
            {label: '...', value: null},
            {label: 'unidades', value: 'unidades'},
            {label: 'sobres', value: 'sobres'},
            {label: 'sacos', value: 'sacos'},
            {label: 'toneladas', value: 'toneladas'},
            {label: 'gr', value: 'gr'},
            {label: 'kilos', value: 'kilos'},
            {label: 'litros', value: 'litros'},
            {label: 'c.c.', value: 'c.c.'},
        ],
        values: []
    };


    public topFormFields: ItemInterface<any>[] = [
        {label: 'Nombre', field: 'nombre', inputType: {type: InputType.EDIT_TEXT}, required: true},
    ];

    public bottomFormFieldsLeft: ItemInterface<any>[] = [
        {label: 'Precio', field: 'precio', inputType: {type: InputType.EDIT_NUMERIC}, visible: this.showPrecio},
        {label: 'Unidades medida', field: 'unidades', inputType: {type: InputType.DROPDOWN}, values: this.unidades},
    ];

    public bottomFormFieldsRight: ItemInterface<any>[] = [
    ];

    public baselineFormFields: ItemInterface<any>[] = [
        {label: 'Observaciones', field: 'observaciones', inputType: {type: InputType.EDIT_TEXTAREA}}
    ];

}