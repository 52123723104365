import { environment } from 'src/environments/environment';
import {Injectable} from '@angular/core';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';

@Injectable({
    providedIn: 'root'
})
export class TrabajadoresApiService {

    public mantenimiento: RequestHandler = new RequestHandler();

    public trabajadoresGET: RequestHandler = new RequestHandler();
    public trabajadoresPOST: RequestHandler = new RequestHandler();
    public trabajadoresPUT: RequestHandler = new RequestHandler();
    public trabajadoresDELETE: RequestHandler = new RequestHandler();

    public maquinariaGET: RequestHandler = new RequestHandler();
    public maquinariaPOST: RequestHandler = new RequestHandler();
    public maquinariaPUT: RequestHandler = new RequestHandler();
    public maquinariaDELETE: RequestHandler = new RequestHandler();

    public boquillas = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public fichajesGET: RequestHandler = new RequestHandler();
    public fichajesPOST: RequestHandler = new RequestHandler();
    public fichajesPUT: RequestHandler = new RequestHandler();
    public fichajesDELETE: RequestHandler = new RequestHandler();

    public cargosGET: RequestHandler = new RequestHandler();
    public cargosPOST: RequestHandler = new RequestHandler();
    public cargosPUT: RequestHandler = new RequestHandler();
    public cargosDELETE: RequestHandler = new RequestHandler();


    // OLD VERSION
    public fichajes: RequestHandler = new RequestHandler();
    public fichajesAll = {
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public partes: RequestHandler = new RequestHandler();

    public cuadrillas = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {


        // OLD VERSION
        this.defineFichajes();
        
        this.definePartes();

        // NEW VERSION
        this.defineFichajesAllPOST();
        this.defineFichajesAllPUT();
        this.defineFichajesAllDELETE();
        
        this.defineTrabajadoresGET();
        this.defineTrabajadoresPOST();
        this.defineTrabajadoresPUT();
        this.defineTrabajadoresDELETE();

        this.defineMaquinasGET();
        this.defineMaquinasPOST();
        this.defineMaquinasPUT();
        this.defineMaquinasDELETE();

        this.defineBoquillas();

        this.defineFichajesGET();
        this.defineFichajesPOST();
        this.defineFichajesPUT();
        this.defineFichajesDELETE();

        this.defineCargosGET();
        this.defineCargosPOST();
        this.defineCargosPUT();
        this.defineCargosDELETE();

        this.defineCuadrillasGET();
    }

    // OLD VERSION
    private defineFichajes() {
        this.fichajes
            .fetch(HttpRequestType.GET, 'trabajadores/fichajes');

        this.api
            .registerRequest(this.fichajes);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.fichajes));
    }
    

    private defineFichajesGET() {
        if ( localStorage.getItem('sm') != null && environment.appName === 'covidai') {
            this.fichajesGET
                .fetch(HttpRequestType.GET, 'trabajadores/fichajes_get');
        } else {
            if ( environment.appName === 'covidai' ) {
                this.fichajesGET
                    .fetch(HttpRequestType.GET, 'trabajadores/fichajes_get_b');
            } else {
                this.fichajesGET
                    .fetch(HttpRequestType.GET, 'trabajadores/fichajes_get');
            }
           
        }
        

        this.api
            .registerRequest(this.fichajesGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.fichajesGET));
    }

    private defineFichajesPOST() {
        this.fichajesPOST
            .fetch(HttpRequestType.POST, 'trabajadores/fichajes_post');

        this.api
            .registerRequest(this.fichajesPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.fichajesPOST));
    }

    private defineFichajesPUT() {
        this.fichajesPUT
            .fetch(HttpRequestType.PUT, 'generic/fichajes');

        this.api
            .registerRequest(this.fichajesPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.fichajesPUT));
    }

    private defineFichajesDELETE() {
        this.fichajesDELETE
            .fetch(HttpRequestType.DELETE, 'generic/fichajes');

        this.api
            .registerRequest(this.fichajesDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.fichajesDELETE));
    }

    // OLD VERSION
    private defineFichajesAllPOST() {
        this.fichajesAll.POST
            .fetch(HttpRequestType.POST, 'generic/fichajes');

        this.api
            .registerRequest(this.fichajesAll.POST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.fichajesAll.POST));
    }

    private defineFichajesAllPUT() {
        this.fichajesAll.PUT
            .fetch(HttpRequestType.PUT, 'generic/fichajes');

        this.api
            .registerRequest(this.fichajesAll.PUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.fichajesAll.PUT));
    }

    private defineFichajesAllDELETE() {
        this.fichajesAll.DELETE
            .fetch(HttpRequestType.DELETE, 'generic/fichajes');

        this.api
            .registerRequest(this.fichajesAll.DELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.fichajesAll.DELETE));
    }
    

    private definePartes() {
        if ( environment.appName === 'covidai' && localStorage.getItem('sm') != null ){
            this.partes
                .fetch(HttpRequestType.GET, 'trabajadores/partesB');
        } else {
            this.partes
                .fetch(HttpRequestType.GET, 'trabajadores/partes');
        }
        
        this.api
            .registerRequest(this.partes);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.partes));
    }
    
    private defineTrabajadoresGET() {
        this.trabajadoresGET
            .fetch(HttpRequestType.GET, 'trabajadores/trabajadores');

        this.api
            .registerRequest(this.trabajadoresGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.trabajadoresGET));
    }

    private defineTrabajadoresPOST() {
        this.trabajadoresPOST
            .fetch(HttpRequestType.POST, 'generic/trabajadores');

        this.api
            .registerRequest(this.trabajadoresPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.trabajadoresPOST));
    }

    private defineTrabajadoresPUT() {
        this.trabajadoresPUT
            .fetch(HttpRequestType.PUT, 'generic/trabajadores');

        this.api
            .registerRequest(this.trabajadoresPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.trabajadoresPUT));
    }

    private defineTrabajadoresDELETE() {
        this.trabajadoresDELETE
            .fetch(HttpRequestType.DELETE, 'generic/trabajadores');

        this.api
            .registerRequest(this.trabajadoresDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.trabajadoresDELETE));
    }

    private defineMaquinasGET() {
        this.maquinariaGET
            .fetch(HttpRequestType.GET, 'trabajadores/maquinarias');

        this.api
            .registerRequest(this.maquinariaGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.maquinariaGET));
    }

    private defineMaquinasPOST() {
        this.maquinariaPOST
            .fetch(HttpRequestType.POST, 'generic/maquinarias');

        this.api
            .registerRequest(this.maquinariaPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.maquinariaPOST));
    }

    private defineMaquinasPUT() {
        this.maquinariaPUT
            .fetch(HttpRequestType.PUT, 'generic/maquinarias');

        this.api
            .registerRequest(this.maquinariaPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.maquinariaPUT));
    }

    private defineMaquinasDELETE() {
        this.maquinariaDELETE
            .fetch(HttpRequestType.DELETE, 'generic/maquinarias');

        this.api
            .registerRequest(this.maquinariaDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.maquinariaDELETE));
    }

    private defineBoquillas() {
        this.boquillas.GET.fetch(HttpRequestType.GET, 'trabajadores/boquillas_get');
        this.boquillas.POST.fetch(HttpRequestType.POST, 'generic/boquillas');
        this.boquillas.PUT.fetch(HttpRequestType.PUT, 'generic/boquillas');
        this.boquillas.DELETE.fetch(HttpRequestType.DELETE, 'generic/boquillas');

        this.api.registerRequest(this.boquillas.GET);
        this.api.registerRequest(this.boquillas.POST);
        this.api.registerRequest(this.boquillas.PUT);
        this.api.registerRequest(this.boquillas.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.boquillas.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.boquillas.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.boquillas.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.boquillas.DELETE));
    }


    private defineCargosGET() {
        this.cargosGET
            .fetch(HttpRequestType.GET, 'trabajadores/cargos');
        /* this.cargosGET
            .fetch(HttpRequestType.GET, 'generic/cargos'); */

        this.api
            .registerRequest(this.cargosGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.cargosGET));
    }

    private defineCargosPOST() {
        this.cargosPOST
            .fetch(HttpRequestType.POST, 'generic/cargos');

        this.api
            .registerRequest(this.cargosPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.cargosPOST));
    }

    private defineCargosPUT() {
        this.cargosPUT
            .fetch(HttpRequestType.PUT, 'generic/cargos');

        this.api
            .registerRequest(this.cargosPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.cargosPUT));
    }

    private defineCargosDELETE() {
        this.cargosDELETE
            .fetch(HttpRequestType.DELETE, 'generic/cargos');

        this.api
            .registerRequest(this.cargosDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.cargosDELETE));
    }
    
    private defineCuadrillasGET() {
        this.cuadrillas.GET.fetch(HttpRequestType.GET, 'trabajadores/cuadrillas_get');
        this.api.registerRequest(this.cuadrillas.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.cuadrillas.GET));
    }
}
