<p-panel>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">Actualizar Visita 
        <a href="https://www.google.es/maps/search/?api=1&query={{ model.lat }},{{ model.lng}}" target="_blank"><img
            src="assets/icon/maps2.png" height="35px" 
            style="position: relative;
            bottom: 10px;"
            *ngIf="showLocationLatLng && model.lat?.length && model.lng?.length"
            alt="Icono Mapa">
            </a>
          </h4> 
      <h4 *ngIf="flag === 'new'">Crear Visita</h4>
    </div>
  </div>
  <app-form-builder [model]="model" [undoEnabled]="undoEnabled" [distributionColumns]="3" [fields]="formFields"
    (formChanges)="formChanges($event)">
  </app-form-builder>
  <br>
  <div *ngIf="showVisitaCarenciasMultiple">
    <br>
    <h4>Carencias</h4>
    <app-form-builder [model]="model" [undoEnabled]="undoEnabled" 
    [distributionColumns]="6" [fields]="formFieldsCarencias">
    </app-form-builder>
    <br>
  </div>
  <br>
  <div *ngIf="showVisitasPlagas">
    <br>
    <h4>Plagas</h4>
    <app-form-builder [model]="model" [undoEnabled]="undoEnabled" [distributionColumns]="3" [fields]="plagasFormFields">
    </app-form-builder>
    <br>
  </div>

 <!--  <div class="col-lg-1 col-md-1" style="margin-top: 20px;" *ngIf="showLocationLatLng && model.lat.length > 1 && model.lng.length > 1">

    <a href="https://www.google.es/maps/search/?api=1&query={{ model.lat }},{{ model.lng}}" target="_blank"><img
        src="assets/icon/maps2.png" height="35px"></a>

  </div> -->

  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button (click)="submit()" [mode]="requestButtonType" [lockOnSuccess]="true" [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>