<br>
<p-panel>
  <div style="display: flex; max-width: 40%;gap:20px;  position: absolute; right: 2rem;">
    <div>
      <input type="file" #adjuntarFoto (change)="fileChange($event)" style="display: none;" accept="image/*"/>
      <button *ngIf="model?.adjunto" class="btn btn-link" (click)="verAdjunto()">Ver</button>
      <input type="button" class="btn"
        style='border-radius: 5px;
          font-size: 1em;
          padding: 6px 12px;
          font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif; background-color: #27A745;color:white;'
        value="Añadir foto" (click)="readUrl(adjuntarFoto)" />
    </div>
    <div *ngIf="this.showMaquinariaAdjuntoPdf">
      <input type="file" #adjuntarPdf (change)="fileChangePdf($event)" style="display: none;"/>
      <button *ngIf="model?.adjunto_pdf" class="btn btn-link" (click)="verAdjuntoPdf()">Ver</button>
      <input type="button" class="btn btn-info"
        style='border-radius: 5px;
          font-size: 1em;
          padding: 6px 12px;
          font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;'
        value="Añadir documento" (click)="readUrl(adjuntarPdf)" />
    </div>
  </div>


  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
      <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
    </div>
  </div>
  <br>
  <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          [fields]="formFields"
          (formChanges)="formChanges($event)">
  </app-form-builder>
  <br>
  <!-- SUBMÓDULO BOQUILLAS (Máñez y Lozano) -->
  <div *ngIf="appName === 'manezylozano'" class="mb-4">
    <h5>BOQUILLAS ATOMIZADOR: HCI60/AMT</h5>
    <div class="mb-2 small">
      <button class="btn btn-sm btn-success" (click)="showAddBoquilla()">Añadir</button>
    </div>

    <div *ngIf="!boquillasList.length" class="alert alert-light">
      Esta máquina no tiene boquillas.
    </div>
    <div *ngIf="boquillasList.length > 0" class="alert alert-light text-dark">
      <ul class="boquilla-list p-1 m-0">
        <li *ngFor="let boquilla of boquillasList; let i = index"
          class="d-flex justify-content-between align-items-center">
          <div class="boquilla-label" (click)="showAddBoquilla(boquilla)">
            <b class="mr-2 px-2 py-1 small text-dark text-center">#{{i + 1}}</b>
            <div>
              {{boquilla?.hci_format}}
              <br>
              {{boquilla?.amt_format}}
              <br>
              <span class="text-secondary small">{{boquilla?.nombre || '(sin nombre)'}}</span>
            </div>
          </div>
          <button class="btn btn-sm btn-link text-secondary" (click)="deleteBoquilla(boquilla)">
            <i class="fa fa-times"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>

  <div class="my-3 d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            (click)="submit()"
            [lockOnSuccess]="true"
            [mode]="requestButtonType"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>

<p-dialog [(visible)]="isShowingBoquilla" header="Boquilla" (onShow)="scrollToTop()">
    <div class="mt-4 bg-white p-3" style="border: 1px solid #d2d2d2; border-radius: 2px;">
      <div class="my-3" style="max-width: 300px;">
        <label for="nombre_boquilla">Nombre o identificador:<b style="color: red;">*</b></label>
        <input pInputText type="text" placeholder="..." name="nombre_boquilla" [(ngModel)]="boquillaModel.nombre" />
      </div>

      <table class="mb-4 table table-bordered text-center">
        <tr>
          <td rowspan="2" class="font-weight-bold" style="width: 160px; vertical-align: middle;">
            <span class="px-1 py-2">HCI60</span>
          </td>
          <td>
            <span class="px-1 py-2">COLOR</span>
          </td>
          <td style="background-color: #333399;"></td>
          <td style="background-color: #d3929c;"></td>
          <td style="background-color: #eb5f22;"></td>
          <td style="background-color: #00844f;"></td>
          <td style="background-color: #fdeb01;"></td>
          <td style="background-color: #673384;"></td>
          <td style="background-color: #0c5894;"></td>
          <td style="background-color: #b42046;"></td>
          <td style="background-color: #de2025;"></td>
          <td style="background-color: #9c491e;"></td>
          <td></td>
          <td>TOTAL&nbsp;HCI&nbsp;60</td>
        </tr>
        <tr>
          <td class="text-center text-underline">
            <span class="px-1 py-2">Nº&nbsp;BOQUILLAS</span>
          </td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.hci['h005']"   (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.hci['h0075']"  (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.hci['h01']"    (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.hci['h015']"   (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.hci['h02']"    (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.hci['h025']"   (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.hci['h03']"    (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.hci['h035']"   (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.hci['h04']"    (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.hci['h05']"    (change)="getTotales()" /></td>
          <td></td>
          <td>
            <span class="px-1 py-2">{{totalHCI || 0}}</span>
          </td>
        </tr>
        <tr>
          <td colspan="14" class="p-2"></td>
        </tr>
        <tr>
          <td rowspan="3" class="font-weight-bold" style="vertical-align: middle;">
            <span class="px-1 py-2">CERÁMICA</span>
          </td>
          <td>
            <span class="px-1 py-2">DIÁMETRO</span>
          </td>
          <td>0,8</td>
          <td>1</td>
          <td>1</td>
          <td>1,2</td>
          <td>1,2</td>
          <td>1,5</td>
          <td>1,5</td>
          <td>1,8</td>
          <td>1,8</td>
          <td>2</td>
          <td>2</td>
        </tr>
        <tr>
          <td>
            <span class="px-1 py-2">DIFUSOR</span>
          </td>
          <td>-</td>
          <td>-</td>
          <td>1</td>
          <td>-</td>
          <td>1,2</td>
          <td>-</td>
          <td>1,5</td>
          <td>-</td>
          <td>1,8</td>
          <td>-</td>
          <td>2</td>
          <td>
            <span class="px-1 py-2">TOTAL AMT</span>
          </td>
        </tr>
        <tr>
          <td class="text-center text-underline">
            <span class="px-1 py-2">Nº&nbsp;BOQUILLAS</span>
          </td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.amt['a08']"    (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.amt['a1']"     (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.amt['a11']"    (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.amt['a12']"    (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.amt['a1212']"  (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.amt['a15']"    (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.amt['a1515']"  (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.amt['a18']"    (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.amt['a1818']"  (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.amt['a2']"     (change)="getTotales()" /></td>
          <td><input pInput type="number" [(ngModel)]="boquillaModel.amt['a22']"    (change)="getTotales()" /></td>
          <td>
            <span class="px-1 py-2">{{totalAMT || 0}}</span>
          </td>
        </tr>
      </table>
      <table class="table-presion mb-4 table table-bordered text-center">
        <tr>
          <td class="font-weight-bold" style="width: 160px;">PRESIÓN (BAR)</td>
          <td *ngFor="let i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]">
            {{i}}
          </td>
        </tr>
        <tr class="font-weight-bold">
          <td>Caudal (l/min)</td>
          <td *ngFor="let i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]">
            {{totalCaudales[i] || 0.00}}
          </td>
        </tr>
      </table>
      <div class="w-100 d-flex justify-content-center">
        <button class="btn btn-dark" (click)="cancelar()">Cancelar</button>
        <button class="btn btn-primary mx-2" (click)="addBoquilla()" [disabled]="isPerformingBoquilla">
          {{isPerformingBoquilla ? 'Guardando...' : 'Guardar'}}
        </button>
        <button *ngIf="boquillaModel?.id" class="btn btn-danger btn-sm" (click)="deleteBoquilla(boquillaModel)">Eliminar</button>
      </div>
    </div>
</p-dialog>
