
import {Injectable} from '@angular/core';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class RopoApiService {

    public ropo = {
        GET: new RequestHandler(),
    };    

    constructor(private syncService: SyncService,
        private api: ApiRequestService,
        private http: HttpClient
    ) {

        this.defineRopo();
    }



    getDatosDni(dni: string): Observable<any[]> {

        const url = 'https://locatec.es/proyectos/fitogest/ws/index.php?p1=ropo&p2=getDni&dni=';
        return this.http.get(url + dni).pipe(map((resp: any) => resp)
        );
    }  
    
    getROPO(dni: string): Observable<any[]>{
      
        const url = 'https://locatec.es/proyectos/fitogest/ws/index.php?p1=ropo&p2=getRopo&dni=';
        return this.http.get(url + dni).pipe(map((resp: any) => resp)
        );
    };   

    getREGEPA(dni: string): Observable<any[]>{
      
        const url = 'https://locatec.es/proyectos/fitogest/ws/index.php?p1=ropo&p2=getRegepa&dni=';
        return this.http.get(url + dni).pipe(map((resp: any) => resp)
        );
    };   
    
    imprimirConsulta(dni: string) {
        return this.http.get('https://locatec.es/proyectos/fitogest/ws/index.php?p1=ropo&p2=genListaDniCsvDown&dni=' + dni);
    }    

    private defineRopo() {
        this.ropo.GET.fetch(HttpRequestType.GET, 'ropo/getDni');

    
        this.api.registerRequest(this.ropo.GET);

    
        this.syncService.register(this.syncService.baseSyncPolicy(this.ropo.GET));

    }
}
