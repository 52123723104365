import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '../../../../base-form';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {ProductosApiService} from '../../../../../service/api/productos-api';
import {TrabajadoresApiService} from '../../../../../service/api/trabajadores-api.service';
import {TareasApiService} from '../../../../../service/api/tareas-api.service';
import {build, pool, buildParams} from '../../../../../common/classes/request-builder';
import {Utils} from '../../../../../common/utils';
import {OtrasFormDefinition} from './form-control/form-definition';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { FormRequestTypes } from '../../../../../common/classes/form-request';
import { environment } from '../../../../../../environments/environment';
import { StorageManager } from '../../../../../common/storage-manager.class';
import { InputType } from '../../../../../common/components/form-builder/form-builder.component';
import { ClientesApiService } from '../../../../../service/api/clientes-api.service';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuariosApiService } from '../../../../../service/api/usuarios-api.service';

import type { OnInit } from '@angular/core';

@Component({
    selector: 'app-otros-form',
    templateUrl: './otros-form.component.html',
    styleUrls: ['./otros-form.component.scss'],
    providers: [MessageService]
})
export class OtrosFormComponent extends BaseForm implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
    requestButton: RequestButtonComponent = new RequestButtonComponent();

    public appName = environment.appName;
    public hasClients = environment.features.hasClients;
    public clienteName = environment.features.clienteName;
    public userCanSeeClientes = environment.features.userCanSeeClientes
        ? true
        : (StorageManager.getUser().rol === 'admin');
    public horaFechaFinActualPorDefecto = environment.features.horaFechaFinActualPorDefecto;
    public tipoSuperficieParcelas = environment.features.hasSuperficieCultivada ? 'Sup.\xa0Cultivada' : 'S.\xa0Sigpac';
    public parcelasName = environment.features.parcelasName;
    public showTareasOtrosDuracion = environment.features.showTareasOtrosDuracion;
    public showTareasOtrosImagen = environment.features.showTareasOtrosImagen;
    public textoSuperficie = environment.features.textoSuperficie;
    public activateRequireHourForRolUser = this.appName === 'ava' ? StorageManager.getUser().rol !== 'admin' ? true : false : false;


    public seleccionados: number = 0;

    public distributionOrder = (this.appName !== 'ava') ? [3, 4, 4, 4] : [3, 4, 3];

    public form: OtrasFormDefinition = new OtrasFormDefinition();
    public formRequest: AppFormRequest = new AppFormRequest();
    public override model: any = {};

    public idxMaquinaria: any;
    public adjunto: any = [];

    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public clientesApi: ClientesApiService,
        public usersApi: UsuariosApiService,
        public fincasApi: FincasApiService,
        public productosApi: ProductosApiService,
        public trabajadoresApi: TrabajadoresApiService,
        public tareasApi: TareasApiService,
        private messageService: MessageService,
        public override router: Router,
        public http: HttpClient
    ) {
        super(
            route,
            router,
            dashboard,
            tareasApi.otras.PUT,
            tareasApi.otras.POST,
            'tareas-otros',
            'Actualizar Tarea',
            'Realizar Tarea Genérica',
            'Duplicar Tarea'
        );
    }

    ngOnInit() {
        this.expandFormFields();
        this.expandFormRequest();
        let fieldsToSend = [''];
        if ( this.showTareasOtrosImagen) {
            fieldsToSend = (['id', 'id_usuario', 'cultivo', 'variedad', 'sup_cultivada', 'adjunto'] as any).concat(
                this.form.formFields.map(it => it.field)
            );
        } else {
            fieldsToSend = (['id', 'id_usuario', 'cultivo', 'variedad', 'sup_cultivada'] as any).concat(
                this.form.formFields.map(it => it.field)
            );
        }

        if (this.hasClients) {
            fieldsToSend.push('id_cliente');
        }

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setPostRequest(this.tareasApi.otras.POST)
            .setPutRequest(this.tareasApi.otras.PUT)
            .setGetRequest(this.tareasApi.otras.GET)
            .setFormFields(this.form.formFields)
            .setFieldsToSend(Array.prototype.concat(this.form.FormFieldsObs.map(it => it.field)).concat(fieldsToSend));

        this.formRequest.load();
        this.softInit(this.getType());
    }

    public submit() {
        if ( this.showTareasOtrosDuracion && this.activateRequireHourForRolUser) {
            if ( this.comprobarHoraInicioFin(this.model) ) {
                return;
            };
        };
        this.formRequest.beforeSend(resolve => {
            const clientId = (StorageManager.getClient() || {} as any).id;
            const clientIdFromFinca = (this.form.fincas.selected || {} as any).id_cliente;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
                clientIdFromFinca ? clientIdFromFinca :
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() || {} as any).id;
            const userIdFromFinca = (this.form.fincas.selected || {}).id_usuario;
            const userIdFromForm = this.model['id_usuario'];

            this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';

            this.model['fecha'] = this.model['fecha'] instanceof Date ?
                Utils.formatDate(this.model['fecha'])
                : this.model['fecha'];

            this.model['fecha_fin'] =
                this.model['fecha_fin'] instanceof Date ?
                    Utils.formatDate(this.model['fecha_fin']) :
                    this.model['fecha_fin'];

            if (this.form.showTareasOtrosDuracion) {
                if (this.model.hora_inicio && moment(this.model.hora_inicio).isValid()) {
                    this.model.hora_inicio = moment(this.model.hora_inicio).format('HH:mm');
                }
                if (this.model.hora_fin && moment(this.model.hora_fin).isValid()) {
                    this.model.hora_fin = moment(this.model.hora_fin).format('HH:mm');
                }
            }

            const horaFin = this.model.hora_fin.indexOf(':') > -1 ?  moment(this.model.hora_fin, 'HH:mm').format('HH:mm') : '00:00';
            const horaInicio =
                this.model.hora_inicio.indexOf(':') > -1 ?
                    moment(this.model.hora_inicio, 'HH:mm').format('HH:mm') :
                    '00:00';
            const horas = this.model.horas.indexOf(':') > -1 ?  moment(this.model.horas, 'HH:mm').format('HH:mm') : '00:00';

            this.model.hora_inicio = horaInicio;
            this.model.hora_fin = horaFin;

            if ( this.appName !== 'fotoagricultura' ){
                delete this.model['fecha_fin'];
                delete this.model['fecha_inicio'];
                this.model.horas = horas;
            }
            this.model.cultivo = (this.form.parcelas.selected || []).map((it: { cultivo: any; }) => it.cultivo).join(';');
            this.model.variedad = (this.form.parcelas.selected || []).map((it: { variedad: any; }) => it.variedad).join(';');
            this.model.sup_cultivada = (this.form.parcelas.selected || []).map((it: { superficie_cultivada: any; }) => it.superficie_cultivada).join(';');

            if (this.appName !== 'onda' && this.appName !== 'lacooperativa'){
                delete this.model['estado_tarea'];
                delete this.model['empresa_pagadora'];
            }// if();

            resolve(true);
        });

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send();

        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    formatoHorasMinutos(horaEntrada: string, horaSalida: string) {
        let timeStr: any = horaEntrada;
        timeStr = timeStr.split(':');

        const h = timeStr[0],
            m = timeStr[1];

        const newTime = moment( horaSalida + ' 2021-4-5')
            .subtract({'hours': h, 'minutes': m})
            .format('HH:mm');


        return newTime;

    }


    public formChanges(tag: string) {
        if (tag === 'hora_inicio' || tag === 'hora_fin') {
            if (
                environment.features.showTareasMto ||
                environment.features.tareasHorasTotales ||
                environment.features.showTareasOtrosDuracion
            ) {
                if (this.model['hora_inicio'] != null && this.model['hora_fin'] != null) {
                    let horaFin = moment(this.model.hora_fin).format('HH:mm');
                    let horaInicio = moment(this.model.hora_inicio).format('HH:mm');
                    const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
                    if (!timeFormat.test(horaInicio)) {
                        horaInicio = this.model.hora_inicio;
                    }

                    if (!timeFormat.test(horaFin)) {
                        horaFin = this.model.hora_fin;
                    }

                    const diferencia = this.formatoHorasMinutos(horaInicio, horaFin);
                    this.model['horas_totales'] = diferencia;
                } else {
                    this.model['horas_totales'] = '';
                }
            }
        }


        if ( this.model['id_maquinaria'] == null ) {
            this.model['id_maquinaria'] = null;
        }

        this.requestButton.error = '';

        if (tag === 'id_finca') {
            this.model.id_sector = null;
            this.model.ids_parcelas = null;
        }
        if (tag === 'id_sector') {
            this.model.ids_parcelas = null;
        }
        if (tag === 'id_cliente') {
            this.model.id_finca = null;
            this.model.id_sector = null;
            this.model.ids_parcelas = null;

            pool([
                buildParams(this.fincasApi.fincasGET, {id_cliente: this.model.id_cliente}, this.form.fincas,
                    'nombre', true),
                buildParams(this.fincasApi.sectoresGET, {id_cliente: this.model.id_cliente}, this.form.sectores,
                    'nombre', true),
                buildParams(this.fincasApi.parcelasGET, {id_cliente: this.model.id_cliente}, this.form.parcelas,
                    'nombre', true),
            ], () => {
                this.form.fincas.filtered = this.form.fincas.values;
                this.form.sectores.filtered = [];
                this.form.parcelas.values = this.form.parcelas.values.filter(it => (it.label !== '...'));
                this.formRequest.update();
            }).then(() => {
                this.formRequest.update();
            });

        }

        if (tag === 'id_maquinaria') {
            //this.model.ids_trabajadores = '';
            const idxTrabajador = this.getItemPosition(this.form.formFields, 'ids_trabajadores');

            if (this.model.id_maquinaria) {
                let contadorMaquinaria = 0;
                for (const trabajador of this.form?.formFields[idxTrabajador]?.values.filtered) {
                    if (trabajador.value.id_maquina === this.model.id_maquinaria) {
                        if ( trabajador.selected != null ) {
                        } else {
                            //this.model.ids_trabajadores += trabajador.value.id + ';';
                            contadorMaquinaria++;
                        }

                    }
                }
                if ( contadorMaquinaria > 0 ) {
                    //this.model.ids_trabajadores = this.model.ids_trabajadores.slice(0, -1);
                }


            }
        }
        if (tag === 'ids_trabajadores') {
            const idxTrabajador = this.getItemPosition(this.form.formFields, 'ids_trabajadores');

            if (this.form?.formFields[idxTrabajador]?.values.selected.length === 1) {
                if (this.form.formFields[idxTrabajador]?.values.selected[0].id_maquina == null
                    || this.form.formFields[idxTrabajador]?.values.selected[0].id_maquina === 0 ) {
                } else {
                    this.model.id_maquinaria = this.form.formFields[idxTrabajador]?.values.selected[0].id_maquina;
                }
            }
        }
        this.countTrabajadores();
        this.countSuperficieParcelas();
    }

    public countTrabajadores() {
        if (this.model.ids_trabajadores) {
            this.seleccionados = this.model.ids_trabajadores.split(';').length;
            if (!isNaN(this.model.ids_trabajadores)) {
                this.seleccionados = 1;
            }
        } else {
            this.seleccionados = 0;
        }
    }

    public countSuperficieParcelas() {
        const ids = (this.model.ids_parcelas || '').split(';');
        this.model.parcelas_superficie = 0;

        (this.form.parcelas.filtered || []).forEach(parcela => {
            if (parcela && parcela.value && ids.includes(parcela.value.id)) {
                this.model.parcelas_superficie += parseFloat((parcela.value.superficie_cultivada || '').replace(',', '.') || '0.00');
            }
        });
        this.model.parcelas_superficie = Utils.decimalFormat(this.model.parcelas_superficie, 2, '.', ',', 3);

        const idxParcelas = this.getItemPosition(this.form.formFields, 'ids_parcelas');
        
        if (this.form.formFields[idxParcelas]) {
            this.form.formFields[idxParcelas]!.label = this.parcelasName + ' (' + this.tipoSuperficieParcelas
            + ':\xa0' + this.model.parcelas_superficie + '\xa0' + this.textoSuperficie + ')';
        }
    }

    public selectAll() {
        this.countSuperficieParcelas();
    }

    public override getItemPosition(formFields: any[], field: string) {
        let position = 0;
        formFields.forEach((item, index) => {
            if (item.field === field) {
                position = index;
            }
        });
        return position;
    }

    public getObservaciones( event: any ) {
        this.model.observaciones = event;
    }
    /**
 *
  hora_fin: "13:02"
  hora_inicio: "14:01"
 */
    public comprobarHoraInicioFin( model: any ){

        if ( model.hora_inicio == null ||  model.hora_fin == null ) {
            this.messageService.add({summary: 'Error en horas', detail: 'Hora inicio y hora fin son necesarias'});
            return true;
        } else {
            let fechaInicio = '';
            let fechaFin = '';
            if (moment(this.model.hora_inicio).isValid()) {
                fechaInicio = moment(this.model.hora_inicio).format('HH:mm');
            } else {
                fechaInicio = this.model.hora_inicio;
            }

            if ( moment(this.model.hora_fin).isValid() ) {
                fechaFin =  moment(this.model.hora_fin).format('HH:mm');
            } else {
                fechaFin =  this.model.hora_fin;
            }

            if ( fechaInicio === fechaFin ) {
                this.messageService.add({summary: 'Error en horas', detail: 'Hora inicio y hora fin no pueden ser iguales'});
                this.requestButton.error = 'Hora inicio y hora fin no pueden ser iguales';
                return true;
            }
            const timePartsInicio: string[] = fechaInicio.split(':');

            let h: number = 0;
            if (timePartsInicio[0]){
                h = +timePartsInicio[0];
            }

            let m: number = 0;
            if (timePartsInicio[1]){
                m = +timePartsInicio[1];
            }
            const resultInicio = this.milliseconds(h, m, 0);


            const timePartsFin = fechaFin.split(':');

            if (timePartsFin[0]){
                h = +timePartsFin[0];
            }

            if (timePartsFin[1]){
                m = +timePartsFin[1];
            }
            const resultFin = this.milliseconds(h, m, 0);
            if ( resultInicio >=  resultFin ){
                this.messageService.add({
                    summary: 'Error en horas',
                    detail: 'Hora inicio y hora fin no pueden ser iguales, ni la hora fin anterior a la hora de inicio'
                });
                this.requestButton.error = 'Hora inicio y hora fin no pueden ser iguales, ni la hora fin anterior a la hora de inicio';
                return true;
            }
            return  false;

        }

    }

    public milliseconds(h: number, m: number, s: number) {
        return ((h * 60 * 60 + m * 60 + s) * 1000);
    }

    // Adjuntos
    public readUrl(input: HTMLInputElement) {
        input.click();
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.adjunto = e.target.result;
            };
            reader.readAsDataURL(input.files[0]);
            const r = this.http.post(environment.serverUrl + 'ws/trabajadores/upload_file.php', this.adjunto).subscribe(res => {
                const file: any = res;
                this.model.adjunto = file.fileName;
                r.unsubscribe();
            });
        }
    }

    public fileChange(event: any) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0] ?? new File([], '');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');

            const name: string[] = file.name.split('.');
            const ext = name[name.length - 1];
            const accepted = ['gif', 'jpeg', 'jpg', 'jiff', 'png', 'svg', 'tiff'];
            let cond = false;

            for (const e of accepted) {
                if (e === ext) {
                    cond = true;
                }
            }

            if (cond) {
                this.http.post(`${environment.serverUrl}ws/tareas/file_upload_otros.php`, formData, {headers: headers}).subscribe(data => {
                    const res: any = data;
                    this.model.adjunto = res.fileName;
                }, error => {
                    console.log(error);
                }
                );
            } else {
                alert('Formato de imágen no válido.');
                this.model.adjunto = null;
            }
        }
    }

    public verAdjunto() {
        const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
        myWindow?.location.assign(environment.serverUrl + 'tareas_otros/' + this.model.adjunto);
        myWindow?.focus();
    }

    private expandFormFields() {
        if (this.hasClients && this.userCanSeeClientes && (this.getType() === FormRequestTypes.DUPLICATE)) {
            this.form.formFields.unshift({
                field: 'id_cliente',
                label: this.clienteName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.form.clientes,
                valuePrimaryKey: 'id',
                required: true
            });
        }
        this.formRequest.beforeSend(resolve => {
            if ( this.appName !== 'fotoagricultura') {
                const horaFin = moment(this.model.hora_fin, 'HH:mm').format('HH:mm');
                const horaInicio = moment(this.model.hora_inicio, 'HH:mm').format('HH:mm');
                const horas = moment(this.model.horas, 'HH:mm').format('HH:mm');
                this.model.hora_inicio = horaInicio;
                this.model.hora_fin = horaFin;
                this.model.horas = horas;
            }

            resolve(true);
        });
    }
    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {
            if (this.horaFechaFinActualPorDefecto && this.getType() !== FormRequestTypes.EDIT) {
                const fechaActual = new Date();

                if (this.appName !== 'sirga') {
                    this.model['fecha'] = fechaActual;
                }

                this.model['hora_fin'] = fechaActual;
            }

            const intervalo = setInterval( () => {
                if ( this.form.maquinarias.filtered.length <= 1 ) {

                } else {
                    this.form.maquinarias.filtered.forEach(row => {

                        if (row.value && row.value.fecha_ultima_inspeccion) {
                            const auxFechaCad = (row.value.fecha_ultima_inspeccion as string).split('/');

                            if ( parseInt(auxFechaCad[0] ?? '', 10) >= 2020 ) {
                                auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + 3).toString();
                            } else {
                                auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + 5).toString();
                            }

                            const fechaCaducidad = auxFechaCad[0] + '/' + auxFechaCad[1] + '/' + auxFechaCad[2];

                            if (moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment())) {
                                row.label = '** ' + row.label;
                            } else if (
                                moment(fechaCaducidad, 'DD/MM/YYYY').isSameOrAfter(moment()) &&
                                moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment().add(3, 'months'))
                            ) {
                                row.label = '* ' + row.label;
                            }
                        }
                    });

                    if (this.getType() === FormRequestTypes.DUPLICATE) {
                        const idxSector = this.getItemPosition(this.form.formFields, 'id_sector');

                        if (this.form.formFields[idxSector]) {
                            this.form.formFields[idxSector]?.values.filtered.forEach((sector: { value: { id: any; }; }) => {
                                if (sector.value?.id === this.model.id_sector && this.form.formFields[idxSector]) {
                                    this.form.formFields[idxSector]!.values.selected = sector.value;
                                }
                            });
                        }
                    }// if();

                    clearInterval(intervalo);
                }
            }, 500);
            resolve(true);
        });

        this.formRequest.beforeLoad(resolve => {

            this.initFormData().then(() => {
                resolve(true);
            });
        });

        this.formRequest.afterFinish(resolve => {
            if (this.getType() === FormRequestTypes.DUPLICATE) {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
            resolve(true);
        });
    }


    private initFormData(): Promise<any> {

        const buildsRequest  = [
            build(this.clientesApi.clientesGET, this.form.clientes, 'nombre'),
            build(this.fincasApi.fincasGET, this.form.fincas, 'nombre', true),
            build(this.fincasApi.sectoresGET, this.form.sectores, 'nombre', true),
            build(this.fincasApi.parcelasGET, this.form.parcelas, 'nombre', true),
            build(this.trabajadoresApi.maquinariaGET, this.form.maquinarias, 'nombre', true),
            build(this.trabajadoresApi.trabajadoresGET, this.form.trabajadores,
                (it: { nombre: string; apellidos: any; maquinaria: any; }) => it.nombre + ' ' + (it.apellidos || '') + (it.maquinaria
                    ? ' - (' + (it.maquinaria || '...') + ')'
                    : ''),
                true, true),
            build(this.tareasApi.listaTareas.GET, this.form.tareas, 'nombre'),
            build(this.productosApi.productos.GET, this.form.productos, 'nombre')
        ];

        if (environment.features.showAssignUserTasksFitosanitario || environment.features.showAssignUserInAllTasks) {
            buildsRequest.push(build(this.usersApi.usuariosGET, this.form.users, 'nombre'));
        }

        return pool(buildsRequest, () => {
            setTimeout(() => {
                this.countTrabajadores();
                this.countSuperficieParcelas();
                this.formRequest.update();
                if ( this.form.fincas.filtered.length === 2  && this.getType() === 0 ){
                    this.model['id_finca'] = this.form.fincas.filtered[1].value.id;
                }
            }, 100);

            this.formRequest.update();

        });
    }

}
