import { StockApiService } from './../../../../service/api/stock-api.service';
import { BaseListView } from './../../../base-listview';
import { DetalleProducto } from '../../../../models/productos/detalle-producto.model';
import { ProductosModel } from '../../../../models/productos/productos.model';
import { Filtering } from './../../../../service/filtering/filtering';
import { FincasModel } from './../../../../models/form-common/fincas.module';
import { FormCommonApiService } from './../../../../service/api/formcommon-api.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';

import type { OnInit } from '@angular/core';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ProductosList } from 'src/app/models/stock/productos-list.model';
import { Message } from 'primeng/api';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-stock-formulario-productos',
    templateUrl: './stock-formulario-productos.component.html',
    styleUrls: ['./stock-formulario-productos.component.scss']
})
export class StockFormularioProductosComponent extends BaseListView<object> implements OnInit {
  
    public cols: ColInterface[] = [
        {field: 'n_registro', header: 'N Registro', filter: ''},
        {field: 'nombre', header: 'Nombre', filter: ''},
        {field: 'formulado', header: 'Formulado', filter: ''},
        {field: 'titular', header: 'Titular', filter: ''},
        {field: 'ficha', header: 'Ficha Técnica', filter: ''}
    ];

    public date: Date = new Date();
  
    public fincas: TypeAheadInterface<FincasModel> = {
        selected: null,
        filtered: [],
        values: []
    };

    public productos: TypeAheadInterface<ProductosList> = {
        selected: null,
        filtered: [{label: 'Cargando...', value: null}],
        values: []
    };

    public usos: TypeAheadInterface<ProductosList> = {
        selected: null,
        filtered: [{ label: 'Cargando...', value: null}],
        values: []
    };
    public usosFilter: Filtering<TypeAheadInterface<ProductosList>> = new Filtering<TypeAheadInterface<ProductosList>>();

    public agentes: TypeAheadInterface<ProductosList> = {
        selected: null,
        filtered: [{ label: 'Cargando...', value: null}],
        values: []
    };
    public agentesFilter: Filtering<TypeAheadInterface<ProductosList>> = new Filtering<TypeAheadInterface<ProductosList>>();
  
    public estado: TypeAheadInterface<string> = {
        selected: null,
        filtered: [],
        values: [
            { label: 'Estado...', value: '' },
            { label: 'Activo', value: 'SI' },
            { label: 'No Activo', value: 'NO' },
        ]
    };

    public aplicacion = '';

    public data: ProductosList = {
        precio: 0,
        unidades: '',
        n: '',
        p: '',
        k: '',
        observaciones: ''
    };

    public msgs: Message[] = [];

    public producto: ProductosList = this.productos.selected as ProductosList;
    public agente: ProductosList = this.agentes.selected as ProductosList;

    constructor(private dashboardEvents: DashboardService,
        private formApi: FormCommonApiService,
        private fincasApi: FincasApiService,
        public stockApi: StockApiService,
        private router: Router) {
        super(formApi.magramaProducto);
    }

    public static createNavigation(router: Router) {
        router.navigate(['register']);
    }

    ngOnInit() {
        this.dashboardEvents.setHeaderTitle('Nuevo Producto');
        this.initFormData();
        this.initFormFiltering();
    }

    onSubmit() {
        const producto: ProductosModel = {
            num_registro: (this.productos.selected as ProductosList).n_registro,
            materia_activa: (this.agentes.selected as ProductosList).composicion,
            ps: (this.agentes.selected as ProductosList).plazo_seguridad,
            nombre: (this.productos.selected as ProductosList).nombre,
            finca: (this.fincas.selected as FincasModel).id,
            estado: this.estado.selected as string ?? '',
            precio: this.data?.precio ?? 0,
            unidades: this.data.unidades,
            N: this.data.n,
            P: this.data.p,
            K: this.data.k,
            observaciones: this.data.observaciones,
            tipo: '',
            fecha_caducidad: ''
        };

        this.stockApi.productosPost.safePerform(producto);
        this.stockApi.productosPost.response(() => {

            if (this.stockApi.productosPost.hasError){
                this.msgs.push({severity: 'error', summary: 'Error', detail: 'No se ha podido crear el producto'});
            } else {
                this.msgs.push({severity: 'success', summary: 'Guardad', detail: 'El producto se ha guardado satisfactoriamente'});
            }
        });
    }
  
    public goBack(){
        this.router.navigate(['dashboard', 'stock-productos']);
    }
  
    public applyFilters(){
        if (this.usosFilter) { this.usosFilter.filter(); }
        if (this.agentesFilter) { this.agentesFilter.filter(); }
    }

    private initFormFiltering(){
        this.usosFilter.addFilter((self: Filtering<TypeAheadInterface<ProductosList>>) => {
            if (this.productos.selected) {
                const copy = this.usos.values
                    .filter(uso => self.like(
                        (uso.value as ProductosList).num_registro ?? '', 
                        (this.productos.selected as ProductosList).n_registro ?? '')
                    );

                const currentUsos: string[] = [];
        
                this.usos.filtered = copy.filter((uso) => {
                    if (!currentUsos.includes(uso.label)){
                        currentUsos.push(uso.label);
                        return true;
                    } else {
                        return false;
                    }
                });

                try {
                    this.aplicacion = (this.usos.filtered[0]?.value as ProductosList).aplicacion ?? '';
                } catch (e){
                    console.log(e);
                    this.aplicacion = '';
                }

                this.usos.filtered.unshift({label: 'Usos...', value: null});
            } else {
                this.usos.filtered = [{label: 'Debe seleccionar un producto...', value: null}];
            }
        });

        this.agentesFilter.addFilter(() => {
            if (this.usos.selected) {
                const copy = this.agentes.values
                    .filter(agente => (agente.value as ProductosList).id_uso === (this.usos.selected as ProductosList).id_uso
              && (agente.value as ProductosList).num_registro === (this.usos.selected as ProductosList).num_registro);
          
                this.agentes.filtered = copy;
                this.agentes.filtered.unshift({label: 'Agente...', value: null});
            } else {
                this.agentes.filtered = [{label: 'Debe seleccionar un uso...', value: null}];
            }
        });
    }
  
    private initFormData(){
        //FINCAS
        this.fincasApi.fincasGET.safePerform();
        this.fincasApi.fincasGET.response((value: FincasModel[]) => {
            if (value){
                this.fincas.values = value.map(it => (
                    {label: it.nombre, value: it})
                );

                this.fincas.values.unshift({label: 'Finca...', value: null});
            }
        });

        //DETALLE PRODUCTOS
        this.formApi.magramaDetalleProducto.safePerform();
        this.formApi.magramaDetalleProducto.response((value: DetalleProducto[]) => {
            if (value){
                this.usos.values = value.map(it => (
                    {label: it.uso, value: it})
                );
                this.agentes.values = value.map(it => (
                    {label: it.agente, value: it})
                );

                this.usosFilter.setValue([this.usos]);
                this.agentesFilter.setValue([this.agentes]);
                this.applyFilters();
            }
        });
    }
}
