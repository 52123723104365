import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {BaseView} from '../../../base-view';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {TareasApiService} from '../../../../service/api/tareas-api.service';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import moment from 'moment';
import { Filtering } from '../../../../service/filtering/filtering';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import { Utils } from '../../../../common/utils';
import { environment } from '../../../../../environments/environment';

import type { OnInit, OnDestroy } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-control-sanitario',
    templateUrl: './control-sanitario.component.html',
    styleUrls: ['./control-sanitario.component.scss']
})
export class ControlSanitarioComponent extends BaseView implements OnInit, OnDestroy {

    public getRequest: RequestHandler = this.tareasApi.controlSanitario.GET;
    public deleteRequest: RequestHandler = this.tareasApi.controlSanitario.DELETE;

    public override filtering: Filtering<any> = new Filtering<any>(); 
    public showTareasFincas = environment.features.showTareasFincas;  
    public fincaName = environment.features.fincaName;   
  
    public distributionOrder = [3];  

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;  

    public override canShow: { [key: string]: any } = {};
    public override model: any = {};

    public cols = [
        {field: 'id', header: 'Nº\xa0Ficha', filter: ''},
        {field: 'finca', header: 'Finca' , filter: '', visible: this.showTareasFincas},       
        {field: 'fecha_inicio', header: 'F.\xa0Inicio', filter: ''},
        {field: 'fecha_fin', header: 'F.\xa0Fin', filter: ''},
        {field: 'fecha_plazo', header: 'Plazo\xa0seg.', filter: ''},
        {field: 'tipo_tratamiento', header: 'T.Tratam.', filter: ''},
        {field: 'veterinario', header: 'Veterinario', filter: ''},
        {field: 'diagnostico', header: 'Diagnóstico', filter: ''},
        {field: 'producto', header: 'Producto', filter: ''},
        {field: 'animales_tratados', header: 'Animal\xa0tratado', filter: ''},
    ];

    public formFields: ItemInterface<any>[] = 
        [
            {field: 'finca', label: this.fincaName, inputType: {type: InputType.TEXT}, visible: this.showTareasFincas},   
            {field: 'fecha_inicio', label: 'F. Inicio', inputType: {type: InputType.TEXT}},
            {field: 'fecha_fin', label: 'F. Fin', inputType: {type: InputType.TEXT}},
            {field: 'fecha_plazo', label: 'Plazo seg.', inputType: {type: InputType.TEXT}},
            {field: 'tipo_tratamiento', label: 'T.Tratam.', inputType: {type: InputType.TEXT}},
            {field: 'veterinario', label: 'Veterinario', inputType: {type: InputType.TEXT}},
            {field: 'diagnostico', label: 'Diagnóstico', inputType: {type: InputType.TEXT}},
            {field: 'producto', label: 'Producto', inputType: {type: InputType.TEXT}},
            {field: 'animales_tratados', label: 'Animal tratado', inputType: {type: InputType.TEXT}},
            {field: 'observaciones', label: 'Observaciones', inputType: {type: InputType.TEXT}}

        ];  


    constructor(public tareasApi: TareasApiService,
        private dashboardEvents: DashboardService,
        private router: Router) {
        super(
            dashboardEvents,
            tareasApi.controlSanitario.GET,
            tareasApi.controlSanitario.DELETE
        );
    }

    ngOnInit() {
        this.dashboardEvents.setSubmenuItem('control-sanitario');    
    }

    override ngOnDestroy(){
        Utils.unsuscribe([
            this.tareasApi.controlSanitario.GET
        ]);
    }

    public globalVisualTransform() {

    }

    public globalConditionalStyle(_value: any, _col: any, _row: any): any {

    }  

    public add() {
        this.router.navigate(['dashboard', 'control-crear-sanitario']);
    }

    public edit(data: any) {
    
        this.router.navigate(['dashboard', 'control-editar-sanitario', data.id]);
    }

    public duplicate(data: any) {
        this.router.navigate(['dashboard', 'control-duplicar-sanitario', data.id]);
    }   
  
    public override deleteRegister() {
        if (this.model['id']) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }
  
    public override show(modal: string, data: any) {
        let x;
        this.model = data;

        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow.hasOwnProperty(x)) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.model = null;
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }  

    public formatDate(date: string) {
        moment.locale('es');
        return moment(date, 'DD/MM/YYYY').format('LL');
    } 
  
  

}
