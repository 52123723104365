import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';

@Injectable({
    providedIn: 'root'
})
export class ProductosApiService {

    public productos = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public listadoVademecum = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public cultivosPatron = {
        GET: new RequestHandler(),
    };

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineProductos();
        this.defineListadoVademecum();
        this.defineCultivosPatron();
    }

    private defineCultivosPatron(){
        this.cultivosPatron.GET.fetch(HttpRequestType.GET, 'productos/cultivos_patron_get');   
        this.api.registerRequest(this.cultivosPatron.GET);  
        this.syncService.register(this.syncService.baseSyncPolicy(this.cultivosPatron.GET));                   
    }

    private defineProductos() {
        this.productos.GET.fetch(HttpRequestType.GET, 'productos/productos_get');
        this.productos.POST.fetch(HttpRequestType.POST, 'generic/productos');
        this.productos.PUT.fetch(HttpRequestType.PUT, 'generic/productos');
        this.productos.DELETE.fetch(HttpRequestType.DELETE, 'generic/productos');

        this.api.registerRequest(this.productos.GET);
        this.api.registerRequest(this.productos.POST);
        this.api.registerRequest(this.productos.PUT);
        this.api.registerRequest(this.productos.DELETE);


        this.syncService.register(this.syncService.baseSyncPolicy(this.productos.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.productos.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.productos.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.productos.DELETE));
    }

    private defineListadoVademecum() {
        this.listadoVademecum.GET.fetch(HttpRequestType.GET, 'vademecum/vademecum_listado_get');
        this.listadoVademecum.POST.fetch(HttpRequestType.POST, 'generic/vademecum_listado');
        this.listadoVademecum.PUT.fetch(HttpRequestType.PUT, 'generic/vademecum_listado');
        this.listadoVademecum.DELETE.fetch(HttpRequestType.DELETE, 'generic/vademecum_listado');

        this.api.registerRequest(this.listadoVademecum.GET);
        this.api.registerRequest(this.listadoVademecum.POST);
        this.api.registerRequest(this.listadoVademecum.PUT);
        this.api.registerRequest(this.listadoVademecum.DELETE);


        this.syncService.register(this.syncService.baseSyncPolicy(this.listadoVademecum.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.listadoVademecum.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.listadoVademecum.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.listadoVademecum.DELETE));
    }

}
