import {Filtering} from '../../../../../service/filtering/filtering';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {list} from '../../../../../common/classes/request-builder';
import { environment } from '../../../../../../environments/environment';

import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ClienteModel } from 'src/app/models/cliente.model';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { ProductosList } from 'src/app/models/stock/productos-list.model';
import { ProductRowInterface } from './product-row.interface';

export class TratamientosFormDefinition {
    public showAplicadoresActivo = environment.features.showAplicadoresActivo;

    public date: Date = new Date();

    public fincas: TypeAheadInterface<FincasModel> = list();
    public clientes: TypeAheadInterface<ClienteModel> = list();
    public estado: TypeAheadInterface<string> = list(['...', null], 'Activo', 'No Activo');

    public productRows: ProductRowInterface[] = [];

    public productos: TypeAheadInterface<ProductosModel> = list();
    public usos: TypeAheadInterface<ProductosList> = list();

    public usosFilter: Filtering<TypeAheadInterface<ProductosList>> = new Filtering<TypeAheadInterface<ProductosList>>();
    public agentes: TypeAheadInterface<ProductosList> = list();

    public agentesFilter: Filtering<TypeAheadInterface<ProductosList>> = new Filtering<TypeAheadInterface<ProductosList>>();

    public values: {nombre: string, fecha_act: string, cantidadReal: string[], cantidadCuaderno: string[], status: string[]} = {
        nombre: '',
        fecha_act: '',
        cantidadReal: [],
        cantidadCuaderno: [],
        status: []
    };

    public formFields: ItemInterface<object>[] = [
        {
            field: 'nombre', 
            label: 'Nombre', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Activo', 
            field: 'activo', 
            inputType: {type: InputType.SWITCH}, 
            visible: this.showAplicadoresActivo
        },
    ];
}