import { ClienteModel } from '../cliente.model';
import { FincaModel } from '../finca.model';
import { ParcelasModel } from '../form-common/parcelas.model';

export class AnalisisModel {
    id: string = '';
    id_usuario: number = 0;
    tipo_analisis?: string;
    id_cliente?: string;
    cliente_nombre?: string;
    cliente?: ClienteModel;
    id_finca?: string;
    finca_nombre?: string;
    finca?: FincaModel;
    id_parcela?: string;
    parcela_nombre: string = '';
    parcelas_nombres?: string;
    parcela?: ParcelasModel;
    id_sector?: string;
    sector_nombre?: string;
    variedad?: string;
    color?: string;
    fecha?: string;
    semana?: string;
    frecuencia?: string;
    materia_activa?: string;
    volumen_kg?: string;
    estado?: string;
    formato?: string;
    remesa?: string;
    adjunto?: string;
    fechaSiguiente?: string;
    muestra?: string;
    objeto_analisis?: string;
    agua_m?: string;
    mgrl?: string;
    analisis?: string;
    boletin?: string;
    laboratorio?: string;
    sustancia_activa?: string;
    valoracion?: string;
    nitratos?: string;
    sulfatos?: string;
    conductividad?: string;
    pH?: string;
}
