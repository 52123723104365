import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class WeatherService {
    public urlBase = environment.serverUrl;
    public url_provisional = this.urlBase + 'ws/weather/get.php';
    constructor(
        private http: HttpClient
    ) { }
    

    getWeatherInfo( fecha: string, finca: string, parcela: string ) {
        return this.http.get( this.url_provisional + '?fecha=' + fecha + '&finca=' + finca + '&parcela=' + parcela);
    }
}