import { HttpRequestType } from './api-request.service';
import { StorageManager } from '../../common/storage-manager.class';
import { environment } from '../../../environments/environment';

export class RequestHandler {
    public static globalCache: any = {};
    public static inject: any = {
        params: {},
        payload: {}
    };

    public appName = environment.appName;
    public hasClients = environment.features.hasClients;
    public mostrarTodosTratamientos = environment.features.mostrarTodosTratamientos;
    public filterAplicadoresByClient = environment.features.filterAplicadoresByClient;
    public userCanSeeAllRecords = environment.features.userCanSeeAllRecords;
    public adminCanSeeAllRecords = environment.features.adminCanSeeAllRecords
        ? ((StorageManager.getUser() || {} as any).rol === 'admin')
        : false;
    public apisWithNoClientId = ['clientes/get', 'trabajadores/cargos'];
    public apisWithNoUserId = ['visitas_variedad_mes/get', 'trabajadores/cargos', 'user/getusers', 'productos/productos_get', 'citas/get'];

    public isLoading: boolean | undefined;
    public isPerforming = false;
    public hasError: boolean | undefined;
    public error: any;
    public value: any;

    public validationNumber = 0;
    public isCancelable = false;

    public promise: Function = new Function();
    private request: Function = new Function();

    private requestType: HttpRequestType = 0;
    private requestEndpoint: string = '';
    private requestParams: any;

    private fetchListeners: Function[] = [];
    private responseListeners: Function[] = [];

    constructor() {
        RequestHandler.inject.params = {
            'id_cliente': (endpoint: string, params?: any) => {
                if (this.apisWithNoClientId.includes(endpoint)
                    || !this.hasClients) {
                    return null;
                }
                // console.log('params', params);
                // Peticiones que se actualizarán cuando se cambie el Cliente Seleccionado.
                const included = [
                    'fincas/get',
                    'sectores/get',
                    'parcelas/get',
                    'analisis/get',
                    'ventas/ventas',
                    'maapas/mapa_parcelas',
                    'tareas/fitosanitarios_get',
                    'tareas/fertilizantes_get',
                    'tareas/tareas_bio_get',
                    'tareas/otros_get',
                    'tareas/siembra_get',
                    'tareas/tareas_fito_locales_get',
                    'tareas/tareas_mto_poda_get',
                    'tareas/tareas_mto_cesped_get',
                    'tareas/tareas_mto_limpieza_general_get',
                    'tareas/tareas_mto_riego_get',
                    'tareas/tareas_mto_piscinas_get',
                    'tareas/tareas_plantacion_get',
                    'tareas/tareas_alquiler_maquinaria_get',
                    'tareas/tareas_edificios_get',
                    'tareas/control_sanitario_get',
                    'tareas/agroambientales_get',
                    'tareas/ecorregimenes_get',
                    'citas/get',
                    'stock/get',
                ];
                if (this.filterAplicadoresByClient) {
                    if (this.appName === 'armentia') {
                        included.push('stock/balances');
                    }
                    if (this.appName === 'abonosfolques') {
                        included.push('trabajadores/trabajadores');
                        /*  const indice = included.indexOf('tratamientos/tratamientos_get');
                         if ( indice >= 0) {
                             included.splice(indice,1);
                         } */
                    } else {
                        included.push('trabajadores/trabajadores', 'trabajadores/maquinarias');

                    }
                }
                if (!this.mostrarTodosTratamientos) {
                    included.push('tratamientos/tratamientos_get');

                }
                return (
                    (StorageManager).getUser().tipo === 'comunero') ? 
                    ((StorageManager).getUser().id_cliente) : included.some(it => endpoint.includes(it))
                        ? (!params || !params.id_cliente)
                            ? (StorageManager.getClient() || {} as any).id
                            : null
                        : null;
            },
            'id_usuario': (endpoint: string, params?: any) => {
                /*
                let rol = StorageManager.getUser().rol? StorageManager.getUser().rol: '';
               if ( this.appName == 'ctl' ) {
                   return (!params || !params['id_usuario']) ? (StorageManager.getUser() || {} as any).id : null;
               } 
               */

                if (this.apisWithNoUserId.includes(endpoint)
                    || this.userCanSeeAllRecords
                    || this.adminCanSeeAllRecords || StorageManager.getUser().tipo === 'trabajador') {
                    return null;
                }


                return (!params || !params['id_usuario']) ? (StorageManager.getUser() || {} as any).id : null;
            },
            'tipo_usuario': (params?: any) => {
                if (environment.features.applicationType !== 'cropgest') {
                    return null;
                }

                return (!params || !params['rol']) ? (StorageManager.getUser() || {} as any).tipo : null;
            }
        };

        RequestHandler.inject.payload = {
            'id_usuario': (endpoint: string, data: any) => (!endpoint.includes('visitas')
                    && !endpoint.includes('citas')
                    && !endpoint.includes('usuarios')
                    && !data['id_usuario'])
                ? (StorageManager.getUser() || {} as any).id
                : null,
            'id_cliente': (endpoint: any, data: any) => {
                if (!this.hasClients) {
                    return null;
                }
                if (this.appName === 'ingenia' && endpoint.includes['tratamientos']) {
                    return null;
                }
                return (!endpoint.includes('clientes')
                    && !endpoint.includes('visitas')
                    && !endpoint.includes('usuarios')
                    && !data['id_cliente'])
                    ? (StorageManager.getClient() || {}).id
                    : (data.id === (StorageManager.getClient() || {}).id)
                        ? StorageManager.saveClient(data)
                        : null;
            }
        };
    }

    public fetch(type: HttpRequestType, endpoint: string, params?: any): RequestHandler {
        this.requestType = type;
        this.requestEndpoint = endpoint;
        this.requestParams = params;
        return this;
    }

    public onPerform(callback: Function) {
        this.request = callback;
    }

    public getRequestData() {
        return {
            type: this.requestType,
            endpoint: this.requestEndpoint,
            params: this.requestParams,
            validationNumber: this.validationNumber
        };
    }

    public perform(data?: any): RequestHandler {
        this.isPerforming = true;
        this.proxyInjection(this.requestType, data);
        // @ts-ignore
        if (!window['custom_cache']) {
            // @ts-ignore
            window['custom_cache'] = {
                cached: 0,
                no_cached: 0,
                performed: 0
            };
        }
        // @ts-ignore
        window['custom_cache']['performed']++;
        //forzar borrado de url
        delete RequestHandler.globalCache['costes/costes_productos_get'];
        const key = this.requestEndpoint;
        const group = JSON.stringify(this.requestParams || {});

        if (this.requestType === HttpRequestType.GET) {
            // @ts-ignore
            if ((RequestHandler.globalCache[key] || {})[group] !== undefined && window['cache_available']) {
                setTimeout(() => {
                    // @ts-ignore
                    window['custom_cache']['cached']++;
                    this.emitResponse(RequestHandler.globalCache[key][group]);
                }, 1);
            } else if (this.request) {
                // @ts-ignore
                window['custom_cache']['no_cached']++;
                this.request(this);
            }
        } else {
            const keys = this.getGroupEndpoint(key);

            keys.forEach(groupedKey => {
                RequestHandler.globalCache[groupedKey] = {};
            });

            if (this.request) {
                // @ts-ignore
                window['custom_cache']['no_cached']++;
                this.request(this);
            }
        }

        this.cacheStats();

        this.isPerforming = false;
        return this;
    }

    public safePerform(data?: any): RequestHandler {
        if (!this.isLoading) {
            this.perform(data);
        }

        return this;
    }

    public toPromise(data?: any): Promise<any> {
        if (data) { this.requestParams = data; }
        return this.promise();
    }

    public reset() {
        this.hasError = undefined;
    }

    public cancelablePerform(data?: any): RequestHandler {
        this.isCancelable = true;
        this.validationNumber++;
        this.perform(data);
        return this;
    }

    public fetchStatus(callback: (status: 'syncing' | 'finish' | 'error') => any): RequestHandler {
        this.fetchListeners.push(callback);
        return this;
    }

    public response(callback: (value: any) => any): RequestHandler {
        if (this.value) {
            callback(this.value);
        }

        this.responseListeners.push(callback);
        return this;
    }

    public emitFetchStatus(status: 'syncing' | 'finish' | 'error') {
        switch (status) {
            case 'syncing':
                this.hasError = false;
                this.isLoading = true;
                break;
            case 'finish':
                this.hasError = false;
                this.isLoading = false;
                break;
            case 'error':
                this.hasError = true;
                this.isLoading = false;
                break;
        }

        this.fetchListeners.forEach(c => c(status));
    }

    public emitResponse(value: any) {
        if (value) {
            const key = this.requestEndpoint;
            const group = JSON.stringify(this.requestParams || {});

            if (value[0]?.id_cliente && StorageManager.getUser().tipo === 'comunero') {
                value[0].id_cliente = StorageManager.getUser().id_cliente;
            }

            if (RequestHandler.globalCache[key] === undefined) {
                RequestHandler.globalCache[key] = {};
            }

            if (RequestHandler.globalCache[key][group] === undefined) {
                RequestHandler.globalCache[key][group] = JSON.parse(JSON.stringify(value));
            }

            this.value = value;
            this.responseListeners.forEach(c => c(value));
        }
    }

    public unsuscribe() {
        this.hasError = undefined;
        this.isLoading = undefined;
        this.value = undefined;
        this.requestParams = {};
        this.responseListeners = [];
        this.fetchListeners = [];
    }

    public injectParams(data: any, endpoint: string) {
        data = data || {};

        for (const x in RequestHandler.inject.params) {
            if (RequestHandler.inject.params.hasOwnProperty(x)) {
                const value = RequestHandler.inject.params[x](endpoint, data);

                if (value !== undefined && value !== null) {
                    if (['string', 'number', 'boolean'].indexOf(typeof value) !== -1) {
                        data[x] = value;
                    } else {
                        console.warn('BaseApi @ Inyect Params: UNSUPORTED TYPE > '
                            + value.constructor.name
                            + ' FOR ' + x + ' < supported types: [string | number | boolean]'
                            + ' request: ' + endpoint
                        );
                    }
                } else {
                    console.warn('BaseApi @ Inyect Params: UNDEFINED OR NULL VALUE FOR > '
                        + x + ' < request: '
                        + endpoint
                    );
                }
            }
        }

        return data;
    }

    public injectPayload(data: any, endpoint: string) {
        if (data) {
            for (const x in RequestHandler.inject.payload) {
                if (RequestHandler.inject.payload.hasOwnProperty(x)) {

                    let value = RequestHandler.inject.payload[x](endpoint, data);

                    if (x === 'id_cliente' && value && StorageManager.getUser().tipo === 'comunero') {
                        value = StorageManager.getUser().id_cliente;
                    }

                    if (value !== undefined && value !== null) {
                        data[x] = value;
                    } else {
                        console.warn('BaseApi @ Inyect Payload: UNDEFINED OR NULL VALUE FOR > '
                            + x + ' < request: '
                            + endpoint
                        );
                    }
                }
            }
        }

        return data;
    }

    public cacheStats() {
        // @ts-ignore
        window['cache_stats']
            = 'Cached request: '// @ts-ignore
            + (window['custom_cache']['cached'] * 100 / window['custom_cache']['performed'])
                .toFixed(2)// @ts-ignore
            + '% / ' + window['custom_cache']['cached']
            + ' of '// @ts-ignore
            + window['custom_cache']['performed']// @ts-ignore
            + ' (active: ' + window['cache_available'] + ')';
    }
    
    private proxyInjection(type: HttpRequestType, data?: any): any {
        if (type === HttpRequestType.GET) {
            this.requestParams = this.injectParams(data, this.requestEndpoint);
        } else {
            this.requestParams = this.injectPayload(data, this.requestEndpoint);
        }
    }
    /**
     *  Para actualizar el listado cuando se elimina
     *  o se vuelve de un formulario
     */
    private getGroupEndpoint(endpoint: string): string[] {
        switch (endpoint) {
            case 'generic/usuarios':
            case 'user/register':
            case 'user/put':
            case 'user/delete':
                return [
                    'user/getusers',
                    endpoint
                ];

            case 'clientes/post':
                return [
                    'clientes/get', 
                    endpoint
                ];
                
            case 'generic/clientes':
                return [
                    'clientes/get',
                    'citas/get',
                    'fincas/get',
                    'tareas/fitosanitarios_get',
                    'tareas/fertilizantes_get',
                    'tareas/tareas_bio_get',
                    'tareas/tareas_mto_poda_get',
                    'tareas/tareas_mto_cesped_get',
                    'tareas/tareas_mto_limpieza_general_get',
                    'tareas/tareas_mto_riego_get',
                    'tareas/tareas_mto_piscinas_get',
                    'tareas/tareas_fito_locales_get',
                    'tareas/tareas_plantacion_get',
                    'tareas/tareas_alquiler_maquinaria_get',
                    'tareas/tareas_edificios_get',
                    'tareas/control_sanitario_get',
                    'tareas/tabla_abonado_get',
                    endpoint
                ];
            case 'generic/fincas':
                return [
                    'clientes/get',
                    'citas/get',
                    'fincas/get',
                    'sectores/get',
                    'parcelas/get',
                    'analisis/get',
                    'ventas/ventas',
                    'tratamientos/tratamientos_get',
                    'tareas/fitosanitarios_get',
                    'tareas/fertilizantes_get',
                    'tareas/tareas_bio_get',
                    'tareas/tareas_mto_poda_get',
                    'tareas/tareas_mto_cesped_get',
                    'tareas/tareas_mto_limpieza_general_get',
                    'tareas/tareas_mto_riego_get',
                    'tareas/tareas_mto_piscinas_get',
                    'tareas/tareas_plantacion_get',
                    'tareas/tareas_alquiler_maquinaria_get',
                    'tareas/tareas_edificios_get',
                    'tareas/tabla_abonado_get',
                    'tareas/siembra_get',
                    'tareas/tareas_fito_locales_get',
                    'tareas/control_sanitario_get',
                    'tareas/otros_get',
                    endpoint
                ];
            case 'generic/entidades':
                return [
                    'entidades/get',
                    endpoint
                ];
            case 'generic/transacciones':
                return [
                    'transacciones/get',
                    endpoint
                ];
            case 'generic/campos':
                return [
                    'campos/get',
                    endpoint
                ];
            case 'generic/parcelas':
                return [
                    'sectores/get',
                    'parcelas/get',
                    'citas/get',
                    'tareas/fitosanitarios_get',
                    'tareas/fertilizantes_get',
                    'tareas/tareas_bio_get',
                    'tareas/tabla_abonado_get',
                    'mapas/mapa_parcelas',
                    'generic/mapa_parcelas',
                    'tareas/siembra_get',
                    endpoint
                ];
            case 'generic/sectores':
                return [
                    'sectores/get',
                    'parcelas/get',
                    'tareas/tabla_abonado_get',
                    'tareas/siembra_get',
                    endpoint
                ];
            case 'analisis/post':
                return [
                    'analisis/get',
                    endpoint
                ];
            case 'generic/analisis':
                return [
                    'analisis/get',
                    endpoint
                ];
            case 'generic/mapa_parcelas':
                return [
                    'parcelas/get',
                    'mapas/mapa_parcelas',
                    endpoint
                ];
            case 'generic/ventas':
                return [
                    'ventas/ventas',
                    endpoint
                ];
            case 'generic/comercios':
                return [
                    'ventas/compradores',
                    'ventas/ventas',
                    endpoint
                ];
            case 'generic/trabajadores':
                return [
                    'trabajadores/trabajadores',
                    'trabajadores/maquinarias',
                    'trabajadores/fichajes_get',
                    'trabajadores/partes',
                    endpoint
                ];
            case 'generic/maquinarias':
                return [
                    'trabajadores/maquinarias',
                    'tareas/fitosanitarios_get',
                    endpoint
                ];
            case 'generic/tareas_fitosanitarias':
                return [
                    'costes/costes_tareas_otras_get',
                    'tareas/fitosanitarios_get',
                    endpoint
                ];

            case 'generic/tareas_mto_poda':
                return [
                    'tareas/tareas_mto_poda_get',
                    endpoint
                ];
            case 'generic/tareas_mto_cesped':
                return [
                    'tareas/tareas_mto_cesped_get',
                    endpoint
                ];
            case 'generic/tareas_mto_limpieza_general':
                return [
                    'tareas/tareas_mto_limpieza_general_get',
                    endpoint
                ];
            case 'generic/tareas_mto_riego':
                return [
                    'tareas/tareas_mto_riego_get',
                    endpoint
                ];
            case 'generic/tareas_mto_piscinas':
                return [
                    'tareas/tareas_mto_piscinas_get',
                    endpoint
                ];
            case 'generic/tareas_plantacion':
                return [
                    'tareas/tareas_plantacion_get',
                    endpoint
                ];
            case 'generic/tareas_alquiler_maquinaria':
                return [
                    'tareas/tareas_alquiler_maquinaria_get',
                    endpoint
                ];
            case 'generic/tareas_edificios':
                return [
                    'tareas/tareas_edificios_get',
                    endpoint
                ];
            case 'generic/tareas_fertilizantes':
                return [
                    'costes/costes_tareas_otras_get',
                    'tareas/fertilizantes_get',
                    endpoint
                ];
            case 'generic/tareas_otras':
                return [
                    'costes/costes_tareas_otras_get',
                    'tareas/otros_get',
                    endpoint
                ];
            case 'generic/tareas_subcontratos':
                return [
                    'costes/costes_tareas_otras_get',
                    'tareas/subcontratos_get',
                    endpoint
                ];
            case 'generic/control_sanitario':
                return [
                    'tareas/control_sanitario_get',
                    endpoint
                ];
            case 'generic/tareas_fito_locales':
                return [
                    'tareas/tareas_fito_locales_get',
                    endpoint
                ];
            case 'generic/tareas_bio':
                return [
                    'tareas/tareas_bio_get',
                    endpoint
                ];
            case 'generic/tareas_siembra':
                return [
                    'tareas/siembra_get',
                    endpoint
                ];
            case 'generic/tareas_agroambientales':
                return [
                    'tareas/agroambientales_get',
                    endpoint
                ];
            case 'generic/tareas_ecorregimenes':
                return [
                    'tareas/ecorregimenes_get',
                    endpoint
                ];
            case 'generic/tabla_abonado':
                return [
                    'tareas/tabla_abonado_get',
                    endpoint
                ];
            case 'generic/tratamientos':
                return [
                    'tratamientos/tratamientos_get',
                    'tratamientos/tratamientos_control_get',
                    'tareas/fitosanitarios_get',
                    endpoint
                ];
            case 'generic/tratamientos_manez':
                return [
                    'tratamientos/tratamientos_manez_get',
                    endpoint
                ];
            case 'generic/tratamientos_control':
                return [
                    'tratamientos/tratamientos_control_get',
                    'tratamientos/tratamientos_get',
                    'tareas/fitosanitarios_get',
                    'generic/tareas_fitosanitarias',
                    endpoint
                ];
            case 'generic/productos':
                return [
                    'productos/productos_get',
                    'tratamientos/tratamientos_get',
                    'tareas/fitosanitarios_get',
                    'tareas/fertilizantes_get',
                    endpoint
                ];
            case 'generic/seguimiento':
                return [
                    'visitas/visitas_pereira_get',
                    endpoint
                ];
            case 'generic/visitas':
                return [
                    'citas/get',
                    'visitas/get',
                    'visitas/visitas_semana_get',
                    'visitas/visitas_distinct_plagas_get',
                    'visitas/trasiego_get',
                    endpoint
                ];
            case 'generic/visitas_semana':
                return [
                    'visitas/visitas_semana_get',
                    'visitas/visitas_distinct_plagas_get',
                    endpoint
                ];
            case 'generic/visitas_trasiego':
                return [
                    'visitas/trasiego_get',
                    'visitas/visitas_distinct_plagas_trasiego_get',
                    endpoint
                ];
            case 'pedidos/put':
            case 'pedidos/delete':
            case 'pedidos/duplicate':
                return [
                    'pedidos/get',
                    endpoint
                ];
            case 'generic/stock':
                return [
                    'stock/get',
                    'stock/balances',
                    endpoint
                ];
            
            case 'generic/stock_new':
                return [
                    'stock/get',
                    'stock/balances',
                    endpoint
                ];
            
            default:
                return [endpoint];
        }
    }
}

