import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { StorageManager } from '../../common/storage-manager.class';

import type { CanActivate } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ShowUsuariosGuardService implements CanActivate {

    constructor(public router: Router) {}

    canActivate(): boolean {
        if (!environment.features.showUsuarios || (StorageManager.getUser().rol !== 'admin')) {
            this.router.navigate(['login']);
            return false;
        }

        return true;
    }
}
