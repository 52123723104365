import { Injectable } from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';

@Injectable({
    providedIn: 'root'
})
export class SdiApiService {
    public SdiClientesGET: RequestHandler = new RequestHandler();
    public SdiProductosGET: RequestHandler = new RequestHandler();

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineSdiClientesGET();
        this.defineSdiProductosGET();
    }

    private defineSdiClientesGET() {
        this.SdiClientesGET
            .fetch(HttpRequestType.GET, 'sdi/clientes_get');

        this.api
            .registerRequest(this.SdiClientesGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.SdiClientesGET));
    }

    private defineSdiProductosGET() {
        this.SdiProductosGET
            .fetch(HttpRequestType.GET, 'sdi/productos_get');
  
        this.api
            .registerRequest(this.SdiProductosGET);
  
        this.syncService
            .register(this.syncService.baseSyncPolicy(this.SdiProductosGET));
    }

}
