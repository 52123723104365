<br />

<div style="width: auto; overflow: auto">
  <app-common-list-table
    [request]="getRequest"
    [cols]="cols"
    [showStockReto]="showStockReto"
    actionButtonText="Añadir"
    (actionButtonClick)="add()"
    (emitSearchText)="updateFooter(filter, cols)"
    [filtering]="filter"
    [globalVisualTransform]="globalVisualTransform"
    [globalConditionalStyle]="globalConditionalStyle"
  >
  <ng-template #header>
    <div *ngIf="this.appName === 'ingenia'" style="display: flex;">
      <div style="margin-left: 20px;">
        <button class="mx-2 btn btn-info" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem;">
            <i class="fa fa-download"></i>
            Exportar listado
        </button>
      </div>
    </div>
  </ng-template>
    <ng-template #thEnd>
      <th>
        <p class="d-flex justify-content-center" style="margin-bottom: 0">
          Acciones
        </p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td>
        <app-action-buttons
          [showDuplicate]="true"
          (duplicateRowButtonEvent)="duplicate(data)"
          (editRowButtonEvent)="edit(data)"
          (deleteRowButtonEvent)="show('delete', data)"
          (showRowButtonEvent)="show('info', data)"
        >
        </app-action-buttons>
      </td>
      
    </ng-template>

    <ng-template #footer let-col>
      <div
        style="font-size: 0.75em; text-align: left"
        *ngIf="['finca_nombre'].includes(col.field)"
      >
        TOTAL:
      </div>
      <div
        *ngIf="['unidades'].includes(col.field)"
        style="font-size: 0.75em; text-align: center; font-weight: bold"
      >
        {{ col?.footer }}
      </div>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog
  header="Detalle del registro"
  [(visible)]="canShow['info']"
  (onShow)="scrollToTop()"
>
  <div *ngIf="canShow['info']" style="width: 80vw !important">
    <app-form-builder
      [fields]="formFieldsEntrada"
      [distributionColumns]="3"
      [model]="model"
    >
    </app-form-builder>
    <br />
    <!-- <form-builder
        *ngIf="showStockTipoDeDato"
        [fields]="formFieldsSemillas"
        [distribution-columns]="3"
        [model]="model">
      </form-builder>
      <br> -->
    <app-form-builder
      *ngIf="showStockTipoDeDato"
      [class.unvisible]="!(model?.tipo_dato === 'Semilla')"
      [fields]="formFieldsSemillas"
      [distributionColumns]="2"
      [model]="model"
    >
    </app-form-builder>
    <br />
    <app-form-builder
      *ngIf="showStockTipoDeDato"
      [class.unvisible]="
        !['Fitosanitario', 'Fertilizante'].includes(model?.tipo_dato)
      "
      [fields]="formFieldsUnidadesMedida"
      [distributionColumns]="3"
      [model]="model"
    >
    </app-form-builder>
    <br />
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
  <br />
</p-dialog>

<p-dialog
  header="Eliminar registro"
  [(visible)]="canShow['delete']"
  (onShow)="scrollToTop()"
>
  <div *ngIf="canShow['delete']" style="width: 80vw !important">
    <app-form-builder
      [fields]="formFieldsEntrada"
      [distributionColumns]="3"
      [distributionOrder]="[3, 3, 3, 3, 3, 3, 3, 1]"
      [model]="model"
    >
    </app-form-builder>
    <br />
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
        (click)="deleteRegister()"
        [request]="deleteRequest"
        [mode]="deleteRequestButtonType"
      >
      </app-request-button>
    </div>
    <br />
    <p
      *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
      style="color: red; text-align: center"
    >
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
</p-dialog>
