import { Injectable } from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';

@Injectable({
    providedIn: 'root'
})
export class UploadApiService {
    public firmasPOST: RequestHandler = new RequestHandler();

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineFirmasPOST();
    }

    private defineFirmasPOST() {
        this.firmasPOST
            .fetch(HttpRequestType.POST, 'upload/firmas');

        this.api
            .registerRequest(this.firmasPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.firmasPOST));
    }


}
