import {Utils} from '../../common/utils';

export class Filtering<T> {

    // tslint:disable: member-ordering
    public value: T[] = [];
    public filtersToApply: Function[];

    
    private fullValue: T[] = [];

    constructor() {
        this.filtersToApply = [];
    }

    public static replaceAll(str: string, search: string | RegExp, replacement: string): string {
        return str.replace(new RegExp(search, 'g'), replacement);
    }

    public static distinctBy(value: { [x: string]: any; }, index: any, self: any[]) {
        const ctx = this as any;
        return self.map((it: { [x: string]: any; }) => it[ctx.field]).indexOf(value[ctx.field]) === index;
    }

    public static filterObjectCollection(term: string, value: any[], searchOn?: string[], searchPriority?: string[], mode?: 'or' | 'and') {
        const copy: any = [];
        mode = mode || 'and';

        if (searchOn) {
            value.forEach(it => {
                const founds: any = [];
    
                for (const x in it) {
                    if (((searchOn || []).length === 0 || searchOn.indexOf(x) >= 0)
                        && it.hasOwnProperty(x)
                        && it[x]) {
    
                        const preparedA = this.replaceAll(it[x].toString().toLowerCase().trim(), '  ', ' ');
                        const preparedAsplited = preparedA.split(' ');
                        const preparedB = this.replaceAll(term.toString().toLowerCase().trim(), '  ', ' ');
                        const preparedBsplited = preparedB.split(' ');
    
                        for (let i = 0; i < preparedAsplited.length; i++) {
                            const preparedAsub = preparedAsplited[i];
    
                            for (let j = 0; j < preparedBsplited.length; j++) {
                                const preparedBsub = preparedBsplited[j];
    
                                if (preparedAsub && preparedAsub.includes(preparedBsub ?? '')) {
                                    founds[j] = 1;
                                }
                            }
                        }
    
                        let totalFounds = 0;
                        founds.forEach((val: number) => totalFounds += val);
    
                        if (totalFounds === preparedBsplited.length) {
    
                            if ((searchPriority || []).includes(x)) {
                                copy.unshift(it);
                            } else {
                                copy.push(it);
                            }
    
                            break;
                        }
                    }
                }
            });   
        }

        return copy;
    }

    public static sort(field?: string, order?: number){
        return (data1: { [x: string]: any; }, data2: { [x: string]: any; }) => {
            order = order || 1;

            let value1 = field ? data1[field] : data1;
            let value2 = field ? data2[field] : data2;
            let result = 0;

            const auxValue1 = Utils.toDate(value1);
            const auxValue2 = Utils.toDate(value2);

            if (auxValue1 && auxValue2) {
                value1 = auxValue1;
                value2 = auxValue2;
            } else if (Utils.isNumber(value1) && Utils.isNumber(value2)) {
                value1 = parseFloat(value1);
                value2 = parseFloat(value2);
            }

            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }

            return order * result;
        };
    }
    
    public setValue(value: T[]): Filtering<T> {
        this.fullValue = value;
        this.value = value;
        return this;
    }

    public addFilter(callback: Function) {
        this.filtersToApply.push(callback);
    }

    public filter() {
        if (this.fullValue) {
            this.value = this.fullValue;
            this.filtersToApply.forEach(filter => {
                if (filter) {
                    this.value = filter(this.value, this);
                }
            });
        }
    }

    public distinct(value: any, index: any, self: string | any[]) {
        return self.indexOf(value) === index;
    }

    public distinctObjectCollection(values: any[], field: string): any[] {
        if (values) {
            return values.map(it => it[field]).filter(this.distinct);
        } else {
            return [];
        }
    }

    public filterObjectCollection(term: string, value: any[], searchOn?: string[], searchPriority?: string[], mode?: 'or' | 'and') {
        return Filtering.filterObjectCollection(term, value, searchOn, searchPriority, mode);
    }

    public replaceAll(str: string, search: string | RegExp, replacement: string): string {
        return str.replace(new RegExp(search, 'g'), replacement);
    }


    public like(a: string, b: string): boolean {
        a = a || '';
        b = b || '';

        return this.replaceAll(a.toString().toLowerCase().trim(), '  ', ' ')
            .includes(this.replaceAll(b.toString().toLowerCase().trim(), '  ', ' '));
    }

}
