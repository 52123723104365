import { InputType } from '../../../../../../common/components/form-builder/form-builder.component';
import { Filtering } from '../../../../../../service/filtering/filtering';
import { list } from '../../../../../../common/classes/request-builder';
import { FormRequestTypes } from '../../../../../../common/classes/form-request';
import { environment } from 'src/environments/environment';
import { checkIfRolAdmin } from 'src/app/common/check-role';

import type { ItemInterface } from '../../../../../../common/components/form-builder/form-builder.component';

export class TareasMtoRiegoFormDefinition {

    public lastFieldChanged: string = '';

    public fincas: TypeAheadInterface = list();
    public trabajadores: TypeAheadInterface = list();
    public clientes: TypeAheadInterface = list();
    public prioridades = list(['...', null], 'Alta', 'Realizada');

    public requestType: FormRequestTypes = 0;

    public model = {};

    public appName: string = environment.appName;
    public fincaName: string = environment.features.fincaName;
    public fincasName: string = environment.features.fincasName;

    public fechaActual: number = Date.now();

    public diasSemana: TypeAheadInterface = list(
        ['Lunes', '1'],
        ['Martes', '2'],
        ['Miércoles', '3'],
        ['Jueves', '4'],
        ['Viernes', '5'],
        ['Sábado', '6'],
        ['Domingo', '7'],
    );

    public formFields: ItemInterface<any>[] =
        [
            { field: 'fecha', label: 'Fecha', inputType: { type: InputType.CALENDAR }, required: true },
            {
                field: 'id_finca',
                label: this.fincaName,
                inputType: { type: InputType.DROPDOWN_SEARCH },
                values: this.fincas,
                valuePrimaryKey: 'id',
                filter: (it: { value: { id_cliente: any; }; }) => {
                    if (this.clientes.selected) {
                        return it.value.id_cliente === (this.clientes.selected || {}).id;
                    } else {
                        return true;
                    }
                },
                required: true
            },
            { field: 'hora_inicio', label: 'Hora Inicio', inputType: { type: InputType.TIME }, required: checkIfRolAdmin() },
            { field: 'hora_fin', label: 'Hora Fin', inputType: { type: InputType.TIME }, required: checkIfRolAdmin() },
            { field: 'horas_totales', label: 'Horas', inputType: { type: InputType.TEXT }},
            { field: 'mantenimiento', label: 'Mantenimiento', inputType: { type: InputType.SWITCH } },
            { field: 'prioridad', label: 'Prioridad/Realizada', inputType: { type: InputType.DROPDOWN }, values: this.prioridades },
        ];
    public formFieldsHorarioRiego: ItemInterface<any>[] =
        [
            {
                field: 'dias',
                label: 'Días',
                inputType: { type: InputType.MULTISELECT },
                values: this.diasSemana,
                
                multiSelect: true
            },
            /*         {field: 'dias', label: 'Días', inputType: {type: InputType.MULTISELECT}, values:this.dias}, */
        ];

    public formFieldsHoraRiego: ItemInterface<any>[] =
        [
            { field: 'hora_inicio_riego', label: 'Hora Inicio Riego', inputType: { type: InputType.TIME } },
            { field: 'off_on', label: 'OFF/ON', inputType: { type: InputType.SWITCH } },
        ];

    public formFieldsSectores: ItemInterface<any>[] =
        [
            { field: 'minutos_sector1', label: 'Sector 1 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector1', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector2', label: 'Sector 2 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector2', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector3', label: 'Sector 3 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector3', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector4', label: 'Sector 4 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector4', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector5', label: 'Sector 5 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector5', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector6', label: 'Sector 6 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector6', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector7', label: 'Sector 7 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector7', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector8', label: 'Sector 8 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector8', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector9', label: 'Sector 9 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector9', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector10', label: 'Sector 10 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector10', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector11', label: 'Sector 11 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector11', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector12', label: 'Sector 12 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector12', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector13', label: 'Sector 13 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector13', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector14', label: 'Sector 14 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector14', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
            { field: 'minutos_sector15', label: 'Sector 15 (minutos)', inputType: { type: InputType.EDIT_NUMERIC } },
            { 
                field: 'metodo_sector15', 
                label: 'Método', 
                inputType: { type: InputType.SELECT_BUTTON }, 
                values: list('Aspersor', 'Difusor', 'Goteo') 
            },
        ];
    public formFieldsAspersores: ItemInterface<any>[] =
        [
            { field: 'aspersores_medio', label: '1/2', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'aspersores_tres_cuartos', label: '3/4', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsDifusores: ItemInterface<any>[] =
        [
            { field: 'difusores', label: 'Difusores', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'boquillas_difusor', label: 'Boquillas Difusor', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsArqueta: ItemInterface<any>[] =
        [
            { field: 'arquetas', label: 'Arquetas', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsArquetas: ItemInterface<any>[] =
        [
            { field: 'arquetas_rectangular', label: 'Rectangular', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'arquetas_circular', label: 'Circular', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'arquetas_cuadrada', label: 'Cuadrada', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'arquetas_baja', label: 'Baja', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'arquetas_jumbo', label: 'Jumbo', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'arquetas_jumbo_baja', label: 'Jumbo Baja', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsElectrovalvulas9vLatch: ItemInterface<any>[] =
        [
            { field: 'electrovalvulas9v_una_pulgada', label: '1"', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'electrovalvulas9v_media_pulgada', label: '1 1/2"', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'electrovalvulas9v_dos_pulgadas', label: '2"', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsElectrovalvulas24v: ItemInterface<any>[] =
        [
            { field: 'electrovalvulas24v_una_pulgada', label: '1"', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'electrovalvulas24v_media_pulgada', label: '1 1/2"', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'electrovalvulas24v_dos_pulgadas', label: '2"', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsSolenoides: ItemInterface<any>[] =
        [
            { field: 'solenoides_9v_latch', label: '9v Latch', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'solenoides_24v', label: '24v', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsProgramadorPilas: ItemInterface<any>[] =
        [
            { field: 'programador_pilas_1', label: '1', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'programador_pilas_2', label: '2', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'programador_pilas_4', label: '4', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'programador_pilas_6', label: '6', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'programador_pilas_8', label: '8', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'programador_pilas_10', label: '10', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'programador_pilas_12', label: '12', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsProgramador220v: ItemInterface<any>[] =
        [
            { field: 'programador220v_1', label: '1', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'programador220v_2', label: '2', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'programador220v_4', label: '4', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'programador220v_6', label: '6', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'programador220v_8', label: '8', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'programador220v_10', label: '10', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'programador220v_12', label: '12', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsCajaConexion: ItemInterface<any>[] =
        [
            { field: 'caja_conexion_1', label: '1', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'caja_conexion_2', label: '2', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'caja_conexion_4', label: '4', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'caja_conexion_6', label: '6', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsTuberiaGoteo: ItemInterface<any>[] =
        [
            { field: 'tuberia_goteo_33cm', label: '33cm', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_goteo_50cm', label: '50cm', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsTuberiaLisa: ItemInterface<any>[] =
        [
            { field: 'tuberia_lisa_16mm', label: '16mm', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsPilas: ItemInterface<any>[] =
        [
            { field: 'pilas_9v', label: '9v', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'pilas_otras', label: 'Otras', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsTuberiaPE01: ItemInterface<any>[] =
        [
            { field: 'tuberia_pe_20mm', label: '20mm', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pe_32mm', label: '32mm', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pe_40mm', label: '40mm', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pe_50mm', label: '50mm', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pe_63mm', label: '63mm', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pe_75mm', label: '75mm', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsTuberiaPE02: ItemInterface<any>[] =
        [
            { field: 'tuberia_pe_16', label: '16--2,5', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pe_20', label: '20--6', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pe_25', label: '25--6', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pe_32', label: '32--6', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pe_40', label: '40--6', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pe_50', label: '50--6', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pe_63', label: '63--6', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsTuberiaPVC: ItemInterface<any>[] =
        [
            { field: 'tuberia_pvc_20mm', label: '20mm', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pvc_32mm', label: '32mm', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pvc_40mm', label: '40mm', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pvc_50mm', label: '50mm', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pvc_63mm', label: '63mm', inputType: { type: InputType.EDIT_NUMERIC } },
            { field: 'tuberia_pvc_75mm', label: '75mm', inputType: { type: InputType.EDIT_NUMERIC } },
        ];

    public formFieldsRiegoManguera: ItemInterface<any>[] =
        [
            { field: 'riego_manguera', label: '', inputType: { type: InputType.EDIT_TEXT } },
        ];

    public formFieldsTrabajadores: ItemInterface<any>[] = [
        {
            field: 'ids_trabajadores',
            label: 'Profesionales',
            inputType: { type: InputType.MULTISELECT },
            values: this.trabajadores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: { value: { activo: string; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                return true;
            }
        },
    ];

    public formFieldsOrdenTrabajo: ItemInterface<any>[] = [
        {
            field: 'orden_trabajo', 
            label: 'Orden de trabajo', 
            inputType: { type: checkIfRolAdmin() ? InputType.TEXTAREA : InputType.EDIT_TEXTAREA }, 
            audio: true
        },
    ];

    public formFieldsObs: ItemInterface<any>[] = [
        { 
            field: 'observaciones', 
            label: this.appName === 'donjardin' ? 'Observaciones' : 'Parte de Trabajo', 
            inputType: { type: InputType.EDIT_TEXTAREA },
            audio: true 
        },
    ];




    public formChanges(tag: string) {
        this.lastFieldChanged = tag;
    }

}

interface TypeAheadInterface {
    selected: any;
    values: any[];
    filtered: any[];
    filterCallback?: Filtering<any>;
}