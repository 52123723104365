<br>
<!-- FORM FIELDS -->
<p-panel>
    <div style="display: flex;">
        <h4>Datos de Césped/Cavados</h4>
    </div>
    
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="2" 
            [fields]="form.formFields">
        </app-form-builder>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="4" 
            [fields]="form.formFieldsCortes">
        </app-form-builder>
        <h4>Cavados</h4>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsCavados">
        </app-form-builder>
        <h4>Recebado</h4>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsRecebado">
        </app-form-builder>  
        <h4>Tierra</h4>              
        <h5>Vegetal</h5> 
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsTVegetal">
        </app-form-builder>   
        <h5>Preparada</h5>                    
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsTPreparada">
        </app-form-builder>                       
        <h5>Garvillada</h5>                    
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsTGarvillada">
        </app-form-builder>    
        <h4>Siembra</h4>
        <h5>Césped</h5>                    
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsSiembraCesped">
        </app-form-builder>    
        <h5>Grama Penisetum/ Kikukyu</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsSiembraGrana">
        </app-form-builder>    
        <h5>Dichondra</h5>                    
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsSiembraDichondra">
        </app-form-builder>
        <h4>Sustrato</h4>                    
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsSustrato">
        </app-form-builder>    
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsTrabajadores">
        </app-form-builder>
        <br>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <app-form-builder 
                    [model]="model" 
                    (formChanges)="form.formChanges($event);" 
                    [distributionColumns]="1"
                    [fields]="form.formFieldsOrdenTrabajo">
                </app-form-builder>
                <app-dictado
                 mensaje="Grabar orden de trabajo" 
                 [observaciones]="model.orden_trabajo" 
                 (sendObservaciones)="getOrdenTrabajo($event)"></app-dictado>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="tareas-camara">
                    <app-upload-images
                    [model]="model"
                    folder="cesped"
                    tipo="form"
                    (sendImageName)="updateImageModel($event)"
                    ></app-upload-images>
                </div>
            </div>
        </div>                
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <app-form-builder
                  [model]="model"
                  (formChanges)="form.formChanges($event); formChanges($event)"
                  [distributionColumns]="1" 
                  [fields]="form.formFieldsObs">
                </app-form-builder> 
                <app-dictado
                mensaje="Grabar observaciones" 
                [observaciones]="model.observaciones" 
                (sendObservaciones)="getObservaciones($event)"
                ></app-dictado>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="tareas-camara">
                    <app-upload-images 
                      [model]="model" 
                      folder="cesped"
                      nombre_imagen = 'image_after' 
                      tipo="form" 
                      (sendImageName)="updateAfterImageModel($event)"
                    ></app-upload-images>
                </div>
            </div>
        </div>                                    
                                              
    <br>
    <div class="distribution_camera_signature" style="display: flex;
    gap: 50px;
    align-items: center;" *ngIf="this.model['signature_img']">
        <div>
            <div *ngIf="this.model['signature_img']" style="display: flex; flex-direction: column;">
                <label>Firma</label>
                <img *ngIf="!this.model['signature_img'].includes('base64')" [src]="serverUrl + 'images/mto_cesped/'+this.model['signature_img'] " class="info-firma-img">
                <img *ngIf="this.model['signature_img'].includes('base64')" [src]="this.model['signature_img'] " class="info-firma-img">
            </div>
        </div>  
        <app-signature (sendFile)="getSignature($event)" (sendTempImg)="updateFirma($event);"></app-signature>
    </div>
    <div class="distribution_camera_signature"  *ngIf="!this.model['signature_img']">
         
        <app-signature (sendFile)="getSignature($event)" (sendTempImg)="updateFirma($event);"></app-signature>
    </div>

    <!-- ACTION BUTTONS -->
    <div class="d-flex justify-content-center">
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>
<br>