import {FormRequest, FormRequestTypes} from '../../../common/classes/form-request';
import {RequestHandler} from '../../../service/OffService/request-handler';
import {InputType} from '../../../common/components/form-builder/form-builder.component';
import {AppFormRequestCreate} from './states/app-form-request-create';
import {AppFormRequestEdit} from './states/app-form-request-edit';
import {AppFormRequestDuplicate} from './states/app-form-request-duplicate';

import type { ItemInterface } from '../../../common/components/form-builder/form-builder.component';

export class AppFormRequest extends FormRequest {

    public requestPOST: RequestHandler = new RequestHandler();
    public requestPUT: RequestHandler = new RequestHandler();
    public requestGET: RequestHandler = new RequestHandler();
    public model: any;
    public modelOld: any;
    public modelChanges: any;
    public registerId: string = '';
    public formFields: ItemInterface<any>[] = [];
    public generic: boolean = false;
    public fieldsToSend: string[] = [];
    public fieldsToRetain: string[] = [];

    public type: FormRequestTypes = 0;
    public createFormRequest: AppFormRequestCreate | undefined;
    public editFormRequest: AppFormRequestEdit | undefined;
    public duplicateFormRequest: AppFormRequestDuplicate | undefined;

    constructor() {
        super();
        this.init();
    }

    // GETTERS
    public setPostRequest(requestPOST: RequestHandler): AppFormRequest {
        this.requestPOST = requestPOST;
        return this;
    }

    public setGetRequest(requestGET: RequestHandler): AppFormRequest {
        this.requestGET = requestGET;
        return this;
    }

    public setPutRequest(requestPUT: RequestHandler): AppFormRequest {
        this.requestPUT = requestPUT;
        return this;
    }

    public setModel(model: any): AppFormRequest {
        this.model = model;
        return this;
    }

    public setModelOld(modelOld: any): AppFormRequest {
        this.modelOld = modelOld;
        return this;
    }

    public setFieldsToSend(fieldsToSend: string[]): AppFormRequest {
        this.fieldsToSend = fieldsToSend;
        return this;
    }

    public setFieldsToRetain(fieldsToRetain: string[]): AppFormRequest {
        this.fieldsToRetain = fieldsToRetain;
        return this;
    }

    public setModelChanges(modelChanges: any): AppFormRequest {
        this.modelChanges = modelChanges;
        return this;
    }

    public setType(type: FormRequestTypes): AppFormRequest {
        this.type = type;
        return this;
    }

    public setFormFields(formFields: ItemInterface<any>[]): AppFormRequest {
        this.formFields = formFields;
        return this;
    }

    public isGeneric(answer: boolean): AppFormRequest {
        this.generic = answer;
        return this;
    }

    public setRegisterId(registerId: string): AppFormRequest {
        this.registerId = registerId;
        return this;
    }


    public checkIfValid(): boolean {
        let cond = true;
        for (const field of this.formFields) {
            if (field && field.required && (field.visible !== false)) {
                if (!this.model[field.field ?? 0]) {
                    cond = false;
                }
                if (
                    [InputType.DROPDOWN, InputType.DROPDOWN_SEARCH].includes(field.inputType?.type ?? 0) &&
                    field.values.selected === undefined
                ) {
                    cond = false;
                }
                if (
                    (field.inputType?.type === InputType.TWO_FIELDS_CONCAT) &&
                    (
                        !this.model[field.subField1 ?? 0] ||
                        !this.model[field.subField2 ?? 0] || 
                        isNaN(this.model[field.subField1 ?? 0]) ||
                        isNaN(this.model[field.subField2 ?? 0])
                    )
                ) {
                    cond = false;
                }
            }

        }
        return cond;
    }

    private init() {
        this.beforeLoad(resolve => {
            if (this.type === undefined) {
                console.error('AppFormRequest @ Check: Parameter TYPE is required, please use setType(type: FormRequestTypes)');
                resolve(false);
            } else {
                resolve(true);
            }
        });

        this.beforeLoad(resolve => {
            switch (this.type) {
                case FormRequestTypes.EDIT:
                    this.editFormRequest = new AppFormRequestEdit(this);
                    break;
                case FormRequestTypes.CREATE:
                    this.createFormRequest = new AppFormRequestCreate(this);
                    break;
                case FormRequestTypes.DUPLICATE:
                    this.duplicateFormRequest = new AppFormRequestDuplicate(this);
                    break;
            }

            resolve(true);
        });

        this.afterLoad(resolve => {
            switch (this.type) {
                case FormRequestTypes.EDIT:
                    resolve(this.checkEdit());
                    break;
                case FormRequestTypes.CREATE:
                    resolve(this.checkCreate());
                    break;
                case FormRequestTypes.DUPLICATE:
                    resolve(this.checkDuplicate());
                    break;
            }

            resolve(true);
        });

        this.beforeSend(resolve => {
            resolve(this.checkIfValid());
        });

        this.afterSend(resolve => {
            this.finish();
            resolve(true);
        });
    }

    private checkCreate(): boolean {
        if (!this.requestPOST) {
            console.error('AppFormRequest @ Check Create: Param requestPOST is required');
            return false;
        }

        if (!this.model) {
            console.error('AppFormRequest @ Check Create: Param model is required');
            return false;
        }

        return true;
    }

    private checkEdit(): boolean {
        if (!this.requestPUT) {
            console.error('AppFormRequest @ Check Edit: Param requestPUT is required');
            return false;
        }

        if (!this.requestGET) {
            console.error('AppFormRequest @ Check Edit: Param requestGET is required');
            return false;
        }

        if (!this.registerId) {
            console.error('AppFormRequest @ Check Edit: Param registerId is required');
            return false;
        }

        if (!this.model) {
            console.error('AppFormRequest @ Check Edit: Param model is required');
            return false;
        }

        if (this.generic === undefined) {
            console.warn('AppFormRequest @ Check Edit: Param generic not defined, using "id"');
        }

        return true;
    }

    private checkDuplicate(): boolean {

        if (!this.requestPOST) {
            console.error('AppFormRequest @ Check Duplicate: Param requestPOST is required');
            return false;
        }

        if (!this.requestGET) {
            console.error('AppFormRequest @ Check Duplicate: Param requestGET is required');
            return false;
        }

        if (!this.registerId) {
            console.error('AppFormRequest @ Check Duplicate: Param registerId is required');
            return false;
        }

        if (!this.model) {
            console.error('AppFormRequest @ Check Duplicate: Param model is required');
            return false;
        }

        if (this.generic === undefined) {
            console.warn('AppFormRequest @ Check Duplicate: Param generic not defined, using "id"');
        }

        return true;
    }
}
