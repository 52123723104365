import {Filtering} from './../service/filtering/filtering';
import {RequestHandler} from '../service/OffService/request-handler';

export class BaseListView<T> {
    public request: RequestHandler = new RequestHandler();
    public filtering: Filtering<T> = new Filtering();
    public fieldsToGlobalSearch: string[] = [];
    public dropdown: any = {};
    public multiselect: any = {};
    public defaultDate: Date = new Date();
    public searchOn: string[] = [];
    public searchPriority: string[] = [];

    public filters: any = {
        global: '',
        dropdown: {},
        multiselect: {},
        dateRange: {}
    };

    constructor(request?: RequestHandler, searchOn?: string[], searchPriority?: string[]) {
        if (request) {
            this.searchOn = (searchOn || []);
            this.searchPriority = (searchPriority || []);
            this.request = request;
            this.request.safePerform();
            this.initFiltering();
            this.onResponse();
        }
    }

    public initRequest(request: RequestHandler) {
        this.request = request;
        this.request.safePerform();
        this.initFiltering();
        this.onResponse();
    }

    public onResponse() {
        this.request.response((value: any[]) => {
            this.filtering.setValue(value);
            this.filtering.filter();
        });
    }

    public setGlobalContentFilter(content: any) {
        content = typeof(content) === 'string' ? content : content.value;
        if (this.filters && this.filtering) {
            this.filters.global = content;
            this.filtering.filter();
        }
    }

    public setDropdownSelectOn(content: string, tag: string) {
        this.cleanMultiSelect();

        this.filters.dropdown[tag] = {
            tag: tag,
            content: content
        };

        this.filtering.filter();
    }


    public setMultiSelectOn(content: string, tag: string) {
        this.filters.multiselect[tag] = content;
        this.filtering.filter();
    }

    public setDateRangeOn() {
        this.filtering.filter();
    }

    public cleanDateRangeOn(tag: string) {
        this.filters.dateRange[tag] = '';
        this.filtering.filter();
    }

    private initFiltering() {
        this.filtering = (this.filtering || new Filtering<T>());

        //FILL DROPDOWNS
        this.filtering.addFilter((data: any[]) => {
            for (const x in this.filters.dropdown) {
                if (this.filters.dropdown.hasOwnProperty(x)) {
                    this.dropdown[x] = [{
                        label: '...',
                        value: ''
                    }];

                    if (data) {
                        this.filtering
                            .distinctObjectCollection(data, x)
                            .forEach(it => {
                                this.dropdown[x].push({
                                    label: it,
                                    value: it
                                });
                            });
                    }
                }}

            return data;
        });

        //FILTER BY DROWDOWNS
        this.filtering.addFilter((data: never[]) => {
            let copy = data || [];

            for (const x in this.filters.dropdown) {if (this.filters.dropdown.hasOwnProperty(x)) {
                const dropdown = this.filters.dropdown[x];

                if (copy
                    && dropdown
                    && dropdown.tag
                    && dropdown.content) {

                    copy = copy
                        .filter((it: { [x: string]: string; }) => this.filtering.like(
                            it[dropdown.tag] ?? '',
                            dropdown.content));
                }
            }}

            return copy;
        });

        //FILL MULTISELECT
        this.filtering.addFilter((data: any[]) => {
            for (const x in this.filters.multiselect) {if (this.filters.multiselect.hasOwnProperty(x)) {
                this.multiselect[x] = [];

                if (data) {
                    this.filtering
                        .distinctObjectCollection(data, x)
                        .forEach(it => {
                            this.multiselect[x].push({
                                label: it,
                                value: it
                            });
                        });
                }
            }}

            return data;
        });

        //FILTER BY MULTISELECT
        this.filtering.addFilter((data: never[]) => {
            let copy = data || [];

            for (const x in this.filters.multiselect) {if (this.filters.multiselect.hasOwnProperty(x)) {
                const multiselect = this.filters.multiselect[x];

                if (copy && multiselect) {
                    copy = copy
                        .filter((it: { [x: string]: any; }) => {
                            if (multiselect.length > 0) {
                                return multiselect.indexOf(it[x]) >= 0;
                            } else {
                                return true;
                            }
                        });
                }
            }}

            return copy;
        });

        //FILTER BY DATE RANGE
        this.filtering.addFilter((data: never[]) => {
            let copy = data || [];

            for (const x in this.filters.dateRange) {if (this.filters.dateRange.hasOwnProperty(x)) {
                const dateRange = this.filters.dateRange[x];

                if (copy && dateRange) {
                    copy = copy
                        .filter((it: { [x: string]: string; }) => {
                            const dateA = new Date(dateRange[0]),
                                dateB = new Date(dateRange[1]),
                                selected = this.getDate(it[x] ?? '', '/');

                            return selected >= dateA && selected <= dateB;
                        });
                }
            }}

            return copy;
        });

        //SET DEFAULT DATE
        this.filtering.addFilter((data: any) => 
        /*if (data) {
                this.defaultDate = this.getDate(data[0].fecha, '/');
            }*/

            data
        );

        //GLOBAL OBJECT COLLECTION FILTER
        this.filtering.addFilter((data: any, self: { filterObjectCollection: (arg0: any, arg1: any, arg2: string[], arg3: string[]) => any; }) => self.filterObjectCollection(
            this.filters.global, 
            data, 
            this.searchOn, 
            this.searchPriority)
        );

        //SORTING
        this.filtering.addFilter((data: any[]) => data.sort((a: { id: string; }, b: { id: string; }) =>
            parseInt(a.id, 10) > parseInt(b.id, 10) ? -1 : 1
        ));

    }

    private getDate(date: string, separator: string): Date {
        const datePart = date.split(separator);
        const day = datePart[0];
        const month = datePart[1];
        const year = datePart[2];
        return new Date([month, day, year].join('/'));
    }

    private cleanMultiSelect() {
        for (const x in this.filters.multiselect) {
            if (x) {
                this.filters.multiselect[x] = [];
            }
        }
    }
}
