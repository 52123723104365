import {Component, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardComponent} from '../../view/dashboard/dashboard.component';
import {LoginApiService} from '../../service/api/login-api.service';
import {UserModel} from '../../models/user.model';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UsuariosApiService } from '../../service/api/usuarios-api.service';
import { StorageManager } from '../../common/storage-manager.class';
import { firstValueFrom } from 'rxjs';

import type { OnInit } from '@angular/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    @ViewChild('passport', {static: true}) passport: ElementRef = new ElementRef('');
    
    public env = environment.appName;
    public logoUrl = environment.logoUrl;
    public serverUrl = environment.serverUrl;
    public canRegister = environment.features.canRegister;
    public canRecoverPassword = environment.features.canRecoverPassword;
    public urlLogoLocatec = environment.serverUrl + 'assets/logo-locatec-footer.png';
    public showWatermark = environment.features.showWatermark;
    public hasUsuarios = environment.features.hasUsuarios;

    public showRecuperarContrasena: boolean = false;
    public showCode: boolean = false;
  
    public error = false;
    public success = false;
    public password: string = '';
    public isDoLoginLoading: boolean = false;
    public loginWithDropdownUsers = [
        'agromainsa', 
        'agrogest', 
        'oliverfutur', 
        'palomares', 
        'prosanzcu',
        'brio', 
        'ortizlavado', 
        'simat', 
        'lafast', 
        'icod', 
        'ebro', 
        'hernandorena',
        'pascualcabedo', 
        'saviatropical', 
        'previlsa', 
        'buitech',
        'laplana', 
        'esparragosgranada', 
        'vginer', 
        'naturfrut', 
        'innovia',
        'upm', 
        'abonosfolques', 
        'armentia', 
        'ava', 
        'avafitogest', 
        'amoros', 
        'torrepacheco',
        'almassora', 
        'burriana', 
        'lacooperativa', 
        'onda', 
        'pyf', 
        'viverosblanco', 
        'agricar', 
        'covidai', 
        'momparler',
        'ctl', 
        'malvesia', 
        'gardengest', 
        'viverogest', 
        'gestata', 
        'donjardin', 
        'ferticinca', 
        'jobarma', 
        'fotoagricultura',
        'lluchychulbi', 
        'agropecuariaramirogruas', 
        'viverospereira', 
        'viverospereirafitosgest', 
        'pedrosaez', 
        'campanaragricola', 
        'campanaragricolafitosgest', 
        'valencianagestionagraria', 
        'valencianaagrogest', 
        'hopsteiner', 
        'cartagenafresh', 
        'plantanova', 
        'bambunova', 
        'jardinpn', 
        'verdurasbelloch', 
        'yagoaznar', 
        'agufert', 
        'agropedbel', 
        'prodalbar', 
        'gragon', 
        'gfcartera', 
        'brimelfitosgest', 
        'brimelagrogest', 
        'ingenia', 
        'agrosol', 
        'bravosolfitosgest', 
        'intasa', 
        'coliberica', 
        'mantengarden', 
        'agrosalvi', 
        'demogardengest', 
        'mbcpomelos', 
        'seteo', 
        'salf', 
        'servalbert', 
        'sirga', 
        'tousagricola', 
        'SIEX', 
        'valdepalma', 
        'valdepalmafitosgest',
        'democropgest'
    ];

    public userList: any = {
        selected: null,
        values: [{label: 'Cargando...', value: {id: null}}]
    };

    /**
     * Recuperar contraseña (de FitoCoop)
     */
    public isSendingEmail: boolean = false;
    public hasSentEmail: boolean = false;
    public isCheckingCode: boolean = false;
    public codeHasError: boolean = false;
    public codeHasBeenChecked: boolean = false;
    public isSendingPassword: boolean = false;
    public userRecovery: any;
    public email: string = '';
    public username: string = '';
    public passwordRepeat: string = '';


    constructor(private router: Router,
        private usuariosApi: UsuariosApiService,
        private http: HttpClient,
        private loginApi: LoginApiService) {
    }


    public static createNavigation(router: Router) {
        router.navigate(['login']);
    }


    ngOnInit() {
        this.loginApi.getUsers()
            .then((userList: UserModel[]) => {
                this.userList.values = [];
                this.userList.values.push({
                    label: 'Seleccionar usuario...',
                    value: {id: null}
                });

                userList.sort((a, b) => (a.nombre || '').toLowerCase().localeCompare((b.nombre || '').toLowerCase())).forEach(user => {
                    this.userList.values.push({
                        label: (user.nombre || '') + ' ' + (user.apellidos || ''),
                        value: user
                    } as any);
                });
            });

        this.loginApi.checkCurrentSession()
            .then((exists: Boolean) => {
                if (exists) {
                    DashboardComponent.createNavigation(this.router);
                }
            });

        if (!this.hasUsuarios && !StorageManager.getUser()) {
            StorageManager.saveUser({
                id: '0',
                nombre: 'Usuario',
                apellidos: '',
                password: '',
                rol: 'demo',
                username: 'usuario'
            });
            window.location.reload();
        }
    }
    
    public setUser(user: any) {
        this.userList.selected = user;
    }


    public doLogin() {
        if (this.userList.selected) {
            this.isDoLoginLoading = true;
            let values: { user: any; password: string; };

            if (!this.userList.selected.value) {
                values = {
                    user: this.userList.selected,
                    password: this.password
                };
            } else {
                this.userList.selected.value.password = this.password;
            }

            setTimeout(() => {
                this.loginApi.doLogin((this.userList.selected.value || values))
                    .then((isValid: boolean) => {
                        if (isValid) {
                            this.success = true;
                            window.location.reload();
                        } else {
                            this.error = true;
                        }
                        this.isDoLoginLoading = false;
                    })
                    .catch(() => {
                        this.error = true;
                        this.isDoLoginLoading = false;
                    });
            }, 700);
        }
    }

    public sendPasswordRecovery(email: string) {
        const usuario = this.userList.values.find((it: { value: any; }) => it.value && (it.value as any).email === email);
        const idUsuario = usuario ? usuario.value.id : '';

        this.isSendingEmail = true;
        setTimeout(() => {
            this.isSendingEmail = false;
            this.hasSentEmail = true;
        }, 700);
    
        (firstValueFrom(this.http.get(this.serverUrl + 'ws/user/sendPasswordRecovery.php?idUsuario=' + idUsuario + '&email=' + email)) as any)
            .then((res: any) => {
                console.log(res);
            });
    }
    
    public sendCode(code: string) {
        this.isCheckingCode = true;
        (firstValueFrom(this.http.get(this.serverUrl + 'ws/user/checkCode.php?code=' + code)) as any)
            .then((res: any) => {
                if (res && res.result === 1) {
                    this.codeHasError = false;
                    this.codeHasBeenChecked = true;
                    this.isCheckingCode = false;
            
                    this.userRecovery = this.userList.values.find((it: { value: { id: any; }; }) => it && it.value && it.value.id === (res || {} as any).id_usuario);
                } else {
                    this.codeHasError = true;
                    this.isCheckingCode = false;
                }
            }, (error: any) => {
                console.log(error);
                this.codeHasError = true;
                this.isCheckingCode = false;
            });
    }
    
    public crearCuenta() {
        this.router.navigate(['register']);
    }

    public changePassword(pass1: string, pass2: string) {
        if (pass1 === pass2) {
            this.isSendingPassword = true;
            this.usuariosApi.usuariosPUT.toPromise({
                id: this.userRecovery.value.id,
                password: pass1
            }).then(res => {
                console.log(res);
                this.isSendingPassword = false;
        
                if (res) {
                    alert('Se ha cambiado la contraseña.');
                    window.location.reload();
                }
            });
        } else {
            alert('Las contraseñas no coinciden.');
        }
    }
    

    onkeyup(e: { key: string; }) {
        if (e.key === 'Enter') {
            this.doLogin();
        }
    }

}
