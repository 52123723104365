import {
    Component,
    ContentChild,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {BaseListView} from '../../../view/base-listview';
import {RequestHandler} from '../../../service/OffService/request-handler';
import {Filtering} from '../../../service/filtering/filtering';
import {Utils} from '../../utils';
import { Table } from 'primeng/table';
import { DashboardService } from '../../../../app/service/events/dashboard.service';
import { environment } from '../../../../environments/environment';
import {StorageManager} from '../../../common/storage-manager.class';

import type { OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import type {SortEvent} from 'primeng/api';
import { NgForOfContext } from '@angular/common';

@Component({
    selector: 'app-common-list-table',
    templateUrl: './common-list-table.component.html',
    styleUrls: ['./common-list-table.component.scss']
})
export class CommonListTableComponent extends BaseListView<any> implements OnInit, OnChanges, OnDestroy {

    private static oldCurrentTitle = '';

    @Input()
    override request: RequestHandler = new RequestHandler();
    @Input()
    override searchOn: string[] = [];
    @Input()
    override searchPriority: string[] = [];
    @Input() minWidth = 750;
    @Input() maxWidth: number = 0;
    @Input() minHeight: number = 0;
    @Input() cols: any;
    @Input() actionButtonText: string = '';
    @Input() searchValue: any;
    @Input() globalVisualTransform: Function = new Function();
    @Input() globalConditionalStyle: Function = new Function();
    @Input() enableFilter: Function | undefined;
    @Input() override filtering: Filtering<any> = new Filtering;
    @Input() selection: any;
    @Input() selectionMode: any;
    @Input() customSort: any;
    @Input()
    hideSearch: boolean = false;
    @Input()
    hidePaginator: boolean = false;
    @Input()
    showExportCsv: boolean = false;
    @Input()
    customExportCsv: boolean = false;
    @Input() first: number = 0;
    @Input() visible = true;
    @Input() showStockReto = false;

    @Input() editButtonsPosition = 'top';

    @Output() actionButtonClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveButtonClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() undoButtonClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitSearchText: EventEmitter<any> = new EventEmitter<any>();
    @Output() filterPressed: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() fieldEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output() exportCsvClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() exportRetoClick: EventEmitter<any> = new EventEmitter<any>();

    @ContentChild('thEnd', {read: TemplateRef, static: false}) thEnd: TemplateRef<NgForOfContext<any, any[]>> | undefined;
    @ContentChild('thStart', {read: TemplateRef, static: false}) thStart: TemplateRef<NgForOfContext<any, any[]>> | undefined;
    @ContentChild('tdEnd', {read: TemplateRef, static: false}) tdEnd: TemplateRef<NgForOfContext<any, any[]>> | undefined;
    @ContentChild('tdStart', {read: TemplateRef, static: false}) tdStart: TemplateRef<NgForOfContext<any, any[]>> | undefined;
    @ContentChild('header', {read: TemplateRef, static: false}) header: TemplateRef<NgForOfContext<any, any[]>> | undefined;
    @ContentChild('caption', {read: TemplateRef, static: false}) caption: TemplateRef<NgForOfContext<any, any[]>> | undefined;
    @ContentChild('footer', {read: TemplateRef, static: false}) footer: TemplateRef<NgForOfContext<any, any[]>> | undefined;

    @ViewChild('pTable', { read: Table, static: true })
    pTable: Table | undefined;

    public appName = environment.appName;
    public rol = StorageManager.getUser().rol;
    public selected = {};
    public hasEditableFields = false;
    public currentTitle: string = '';

    public currentPage: any;

    constructor(private dashboard: DashboardService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes || {} as any)['searchValue']) {
            this.setGlobalContentFilter(changes['searchValue']?.currentValue.term);
            this.searchValue = (this.searchValue || {term: ''});
        }

        if ((changes || {} as any)['cols']) {
            this.cols = this.cols.filter((it: { visible: boolean; }) => it && it.visible !== false);
        }
    }

    ngOnInit() {
        this.currentTitle = this.appName + '_' + this.dashboard.headerTitle;
      
        this.initRequest(this.request);
        this.selection = (this.selection || []);
        this.searchValue = (this.searchValue || {term: ''});
        this.customSort = this.customSort || this.defaultSort;
        this.configureOnPageChange();
       
        if (this.checkOldTitle(this.currentTitle)) {
            this.removeTempData();
        } else {
            this.getTableState(this.request);
        }
    
        // this.dashboard.menuItemChanges$.subscribe(item => {
        //     if (item !== title) {
        //         this.removeTempData();
        //     }
        // });
        
        if (this.cols) {
            this.cols.forEach((col: { filter: any; field: string; editable: any; }) => {
                switch (col.filter) {
                    case 'dropdown':
                        this.setDropdownSelectOn('', col.field);
                        break;
                    case 'multiselect':
                        this.setMultiSelectOn('', col.field);
                        break;
                    case 'calendar':
                        this.setDateRangeOn();
                        break;
                }
                if (col.editable) {
                    this.hasEditableFields = true;
                }
            });
        }
    }

    checkOldTitle( new_title: string) {
        if (CommonListTableComponent.oldCurrentTitle == null || CommonListTableComponent.oldCurrentTitle.length === 0) {
            CommonListTableComponent.oldCurrentTitle = new_title;
            return false;
        }
        if (CommonListTableComponent.oldCurrentTitle.localeCompare(new_title) === 0) {
            return false;
        }
        if (CommonListTableComponent.oldCurrentTitle.localeCompare(new_title) !== 0) {
            if ( new_title.toLowerCase().includes('edit') ){
                return false;
            }
            CommonListTableComponent.oldCurrentTitle = new_title;
            return true;
        }
        return false;

    }

    ngOnDestroy() {
        //this.removeTempData();
        //this.setTableState();
    }

    exportReto(){
        this.exportRetoClick.emit(true);
    }

    showAddButton(){
        if ( StorageManager.getUser().rol === 'demo') {
            return false;
        }
        return true;
    }
    
    public getTableState(request: RequestHandler) {
       
        if ( this.currentTitle.includes('Editar Cliente') ) {
            this.currentTitle = this.currentTitle.replace('Editar Cliente', 'Clientes');
            //CommonListTableComponent.oldCurrentTitle = this.currentTitle;
        }
        const localSearchValue = localStorage.getItem(this.currentTitle + '_TEMP_SEARCH_TEXT') || '';
        const localCurrentPage = JSON.parse(localStorage.getItem(this.currentTitle + '_TEMP_PAGE_NUMBER') ?? '{}') || {};

        if (request.value) {
            if (localCurrentPage && localCurrentPage.first && this.pTable) {
                this.pTable.first = localCurrentPage.first;
            }
            if (localSearchValue) {
                this.searchValue = {'term': localSearchValue};
                this.filters.global = localSearchValue;
            }

        } else {
            request.response(() => {
                if (localCurrentPage && localCurrentPage.first && this.pTable) {
                    this.pTable.first = localCurrentPage.first;
                }
                if (localSearchValue) {
                    this.searchValue = {'term': localSearchValue};
                    this.filters.global = localSearchValue;
                }

            });
        }

        this.setGlobalContentFilter(localSearchValue);
    }

    public setTableState() {
        if (this.searchValue && this.searchValue.term) {
            localStorage.setItem(this.currentTitle + '_TEMP_SEARCH_TEXT', this.searchValue.term);
        }
        if (this.currentPage) {
            localStorage.setItem(this.currentTitle + '_TEMP_PAGE_NUMBER', JSON.stringify(this.currentPage));
        } else if (this.pTable && this.pTable.first !== 0) {
            localStorage.setItem(this.currentTitle + '_TEMP_PAGE_NUMBER', JSON.stringify({
                first: this.pTable.first,
                rows: this.pTable.rows
            }));
        }
    }

    public removeTempData() {
        localStorage.removeItem(this.currentTitle + '_TEMP_SEARCH_TEXT');
        localStorage.removeItem(this.currentTitle + '_TEMP_PAGE_NUMBER');
    }

    public setSearchText(value: string | null) {
        if ( value == null || (value as string ).length === 0 ){
            this.removeTempData();
        } else {
            this.emitSearchText.emit(value);
            
            if (this.pTable) {
                this.pTable.first = 0;
            }
            
            localStorage.removeItem(this.currentTitle + '_TEMP_PAGE_NUMBER');
            this.setTableState();
        }
        
    }

    public editField(event: any, data: any, from: string) {
        this.fieldEdit.emit({event, data, from});
    }

    public exportCsv() {
        // Si el usuario ha definido una función personalizada para procesar los datos, simplemente los devuelve.
        // Si no, les aplica el proceso genérico para convertir a CSV: descargar la tabla tal cual está.
        if (this.customExportCsv) {
            
            this.exportCsvClick.emit({
                cols: this.cols,
                value: this.filtering.value
            });
            // return;
        }
        
        const charset = 'windows-1252';
        let csv: string = 'data:text/csv;charset=' + charset + ',\ufeff' + (this.cols || []).map((it: { header: any; }) => it.header).join(';') + '\n'; 
        
        (this.filtering.value || []).forEach(row => {
            this.cols.forEach((col: any) => {
                let str = this.processCellVisualTransform(row, col);
                if ([undefined, 'undefined', null, 'null', NaN, 'NaN'].includes(str)) {
                    str = '';
                } else {
                    str = str.replace('#', '');
                }
                csv += '"' + (str || '').replace(/"/g, '\\"') + '";';                
                //csv += str + ';';
            });
            csv = csv.slice(0, -1) + '\n';
        });

        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;
        csvLink.download = this.currentTitle + '_exportado.csv';
        csvLink.href = encodeURI(csv);
        csvLink.click();
    }

    public processCellVisualTransform(data: any, col: any) {
        let finalTransform;
    
        //AQUI RECOGIDA DE FIELDS PARA COLUMNA p-table

        if (col.visualTransform) {
            finalTransform = col.visualTransform;
        } else if (this.globalVisualTransform && col.field === 'nombre') {
            finalTransform = this.globalVisualTransform(data[col.field], col, data);
        } else {
            finalTransform = data[col.field];
        }
    
        return finalTransform;
    }

    public processCellConditionalStyle(data: { [x: string]: any; }, col: { style: any; field: string | number; }) {
        let finalStyle;

        if (col.style) {
            finalStyle = col.style;
        } else if (this.globalConditionalStyle) {
            finalStyle = this.globalConditionalStyle(data[col.field], col, this.cols, data);

            if (finalStyle && finalStyle.style) {
                finalStyle = finalStyle.style;
            }
        }

        return finalStyle ? finalStyle : {};
    }

    public processRowConditionalStyle(data: any) {
        let finalStyle;

        for (let i = 0; i < this.cols.length; i++) {
            const col = this.cols[i];

            if (col.rowStyle) {
                finalStyle = col.rowStyle;
                break;
            } else if (this.globalConditionalStyle) {
                finalStyle = this.globalConditionalStyle(data[col.field], col, this.cols, data);

                if (finalStyle && finalStyle.rowStyle) {
                    finalStyle = finalStyle.rowStyle;
                    break;
                } else {
                    finalStyle = null;
                }
            }
        }

        return finalStyle ? finalStyle : {};
    }

    public defaultSort(event: SortEvent) {
        (event.data || []).sort((data1, data2) => {;
            let value1 = data1[event.field ?? 0];
            let value2 = data2[event.field ?? 0];
            let result = 0;

            const auxValue1 = Utils.toDate(data1[event.field ?? 0]);
            const auxValue2 = Utils.toDate(data2[event.field ?? 0]);

            if (auxValue1 && auxValue2) {
                console.log("Cumple condición");
                value1 = auxValue1;
                value2 = auxValue2;
            } else if (Utils.isNumber(value1) && Utils.isNumber(value2)) {
                console.log("No cumple condición");
                value1 = parseFloat(value1);
                value2 = parseFloat(value2);
            }

            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }

            return ((event.order ?? 0) * result);
        });
    }

    private configureOnPageChange() {
        if (this.pTable) {
            this.pTable.onPageChange = (event => {
                this.currentPage = event;
    
                if (this.pTable) {
                    this.pTable.first = event.first;
                    this.pTable.rows = event.rows;
                    if (this.pTable.lazy) {
                        this.pTable.onLazyLoad.emit(this.pTable.createLazyLoadMetadata());
                    }
                    this.pTable.onPage.emit({
                        first: this.pTable.first,
                        rows: this.pTable.rows
                    });
                    this.pTable.tableService.onValueChange(this.pTable.value);
                }
            });   
        }
    }
}
