export class MenuItemModel {

    id: string = '';
    icon: string = '';
    text: string = '';
    visible?: boolean;
    isSelected: boolean = false;
    expandable?: {
        id: string;
        text: string;
        subtitulo?: string;
        fatherId: string;
        isSelected: boolean;
        visible?: boolean;
    }[];
    
}
