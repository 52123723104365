import {Injectable} from '@angular/core';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';

@Injectable({
    providedIn: 'root'
})
export class EntidadesApiService {

    public EntidadesGET: RequestHandler = new RequestHandler();
    public EntidadesDELETE: RequestHandler = new RequestHandler();
    public EntidadesPUT: RequestHandler = new RequestHandler();
    public EntidadesPOST: RequestHandler = new RequestHandler();

    public provinciasGET: RequestHandler = new RequestHandler();
    public provinciasDELETE: RequestHandler = new RequestHandler();
    public provinciasPUT: RequestHandler = new RequestHandler();
    public provinciasPOST: RequestHandler = new RequestHandler();

    public parcelasGET: RequestHandler = new RequestHandler();
    public parcelasDELETE: RequestHandler = new RequestHandler();
    public parcelasPUT: RequestHandler = new RequestHandler();
    public parcelasPOST: RequestHandler = new RequestHandler();


    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineEntidadesGET();
        this.defineEntidadesPUT();
        this.defineEntidadesPOST();
        this.defineEntidadesDELETE();

        this.defineprovinciasGET();
        this.defineprovinciasPUT();
        this.defineprovinciasPOST();
        this.defineprovinciasDELETE();

        this.defineParcelasGET();
        this.defineParcelasPUT();
        this.defineParcelasPOST();
        this.defineParcelasDELETE();
    }

    private defineEntidadesGET() {
        this.EntidadesGET
            .fetch(HttpRequestType.GET, 'entidades/get');

        this.api
            .registerRequest(this.EntidadesGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.EntidadesGET));
    }

    private defineEntidadesPUT() {
        this.EntidadesPUT
            .fetch(HttpRequestType.PUT, 'generic/entidades');

        this.api
            .registerRequest(this.EntidadesPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.EntidadesPUT));
    }

    private defineEntidadesPOST() {
        this.EntidadesPOST
            .fetch(HttpRequestType.POST, 'generic/entidades');

        this.api
            .registerRequest(this.EntidadesPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.EntidadesPOST));
    }

    private defineEntidadesDELETE() {
        this.EntidadesDELETE
            .fetch(HttpRequestType.DELETE, 'generic/entidades');

        this.api
            .registerRequest(this.EntidadesDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.EntidadesDELETE));
    }

    private defineprovinciasGET() {
        this.provinciasGET
            .fetch(HttpRequestType.GET, 'generic/provincias');

        this.api
            .registerRequest(this.provinciasGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.provinciasGET));
    }

    private defineprovinciasPUT() {
        this.provinciasPUT
            .fetch(HttpRequestType.PUT, 'generic/provincias');

        this.api
            .registerRequest(this.provinciasPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.provinciasPUT));
    }

    private defineprovinciasPOST() {
        this.provinciasPOST
            .fetch(HttpRequestType.POST, 'generic/provincias');

        this.api
            .registerRequest(this.provinciasPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.provinciasPOST));
    }

    private defineprovinciasDELETE() {
        this.provinciasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/provincias');

        this.api
            .registerRequest(this.provinciasDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.provinciasDELETE));
    }

    private defineParcelasGET() {
        this.parcelasGET
            .fetch(HttpRequestType.GET, 'parcelas/get');

        this.api
            .registerRequest(this.parcelasGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.parcelasGET));
    }

    private defineParcelasPUT() {
        this.parcelasPUT
            .fetch(HttpRequestType.PUT, 'generic/parcelas');

        this.api
            .registerRequest(this.parcelasPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.parcelasPUT));
    }

    private defineParcelasPOST() {
        this.parcelasPOST
            .fetch(HttpRequestType.POST, 'generic/parcelas');

        this.api
            .registerRequest(this.parcelasPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.parcelasPOST));
    }

    private defineParcelasDELETE() {
        this.parcelasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/parcelas');

        this.api
            .registerRequest(this.parcelasDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.parcelasDELETE));
    }


}
