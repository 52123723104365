<div class="d-flex justify-content-between">
    <div>
        <table class="table-style">
            <tr>
                <td class="tar up">Cuba</td>
                <td class="tar mw-80">
                    <div class="d-flex justify-content-end align-items-center">
                        <input style="width: 60px !important; text-align: right" pInputText type="text"
                               [(ngModel)]="capacidad_cuba" (keyup)="startCalcating()">
                        <span>L</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="tar up">Inyectora</td>
                <td class="tar">
                    {{decimalFormat(caudal_inyectora)}} L/h
                </td>
            </tr>
            <tr>
                <td class="tar up">Dosis Cuba/Día</td>
                <td class="tar">
                    <input pInputText type="text" [(ngModel)]="dosis_cuba" (keyup)="startCalcating()" style="max-width: 50px; text-align: right;">
                </td>
            </tr>
            <tr>
                <td class="tar up">Bomba trabaja al</td>
                <td class="tar">{{porcentaje_bomba || 0}} %</td>
            </tr>
        </table>
    </div>

    <div *ngIf="!fertilizantesFormShort" class="d-flex justify-content-end">
        <table class="table-style center-content mr-2">
            <tr>
                <td>{{sectorName}}</td>
                <td>Árboles</td>
                <td>Goteros</td>
                <td>Gr/Árbol</td>
            </tr>
            <ng-container *ngFor="let sector of sectores">
                <tr *ngIf="sector">
                    <td>{{sector.nombre}}</td>
                    <td>{{sector.plantas}}</td>
                    <td>{{sector.goteros || '...'}}</td>
                    <td>
                        <input [(ngModel)]="sector.proporcionArboles" 
                                pInputText type="text"
                                style="max-width: 50px; text-align:right;">
                    </td>
                </tr>
            </ng-container>
        </table>
        <table style="width: 450px" class="table-style center-content">
            <tr>
                <td style="width: 120px;">H. TOTALES</td>
                <td>
                    <div>
                        <div class="d-flex justify-content-between mx-0 p-0 align-items-center">
                            <span style="font-size: 0.8em">PRE</span>
                            HORAS FERTIRRIGACIÓN
                            <span style="font-size: 0.8em">POST</span>
                        </div>
                    </div>
                    <div>

                    </div>
                </td>
            </tr>
            <ng-container *ngFor="let sector of sectores">
                <tr>
                    <td>{{sector.horas_dia_text || '00:00'}}</td>
                    <td>
                        <div class="row">
                            <div class="col-sm-4">
                                {{sector.tiempoPreRiego || '00:00'}}
                            </div>
                            <div class="col-sm-4">
                                <input pInputText type="text" [(ngModel)]="sector.horas_dia_menos_45min"
                                    placeholder="HH:mm" (keyup)="startCalcating()" (change)="calculaTiemposRiego()">
                            </div>
                            <div class="col-sm-4">
                                <input pInputText type="text" [(ngModel)]="sector.tiempoPostRiego"
                                    placeholder="HH:mm" (keyup)="startCalcating()">
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>

<div class="col-12" style="text-align:right; margin:auto; margin-top:10px; padding-left:10px; padding-right: 0px;">
    <button class="btn btn-warning mx-2" (click)="generarInforme()">Informe</button>
    <button type="button" class="btn btn-primary" (click)="reCalcular()">Calcular</button>
</div>

<br>
<br>
<p style="text-align: center; color: red" *ngIf="errorMessage">Faltan datos de: {{errorMessage}}</p>