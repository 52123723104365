import {StorageManager} from './../../common/storage-manager.class';
import {Injectable} from '@angular/core';
import {BaseApi} from './base-api.class';
import {UserModel} from '../../models/user.model';
import {Utils} from '../../common/utils';
import { HttpClient } from '@angular/common/http';
import {BaseResponseModel} from '../../models/base-response.model';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class LoginApiService extends BaseApi<UserModel> {
    public trabajadoresAsUsuarios = environment.features.trabajadoresAsUsuarios;
    public doLoginRoute = 'user/dologin';
    public getUsersRoute = 'user/getusers';

    protected fakeValidUser: UserModel = new UserModel('admin', 'admin');

    // public isGuest: boolean = false;


    constructor(_: HttpClient) {
        super(_);
        this.fakeValidUser.id = '0';
    }

    public getCurrentUser(): UserModel {
        return StorageManager.getUser();
    }

    public getUsers(): Promise<any> {
        return new Promise((resolve) => {
            if (this.trabajadoresAsUsuarios) {
                this.getUsersRoute = 'trabajadores/trabajadores';
            }
            this.get(this.getUsersRoute)
                .then(this.getEntityDataList.bind(this))
                .then(resolve);
        });
    }

    public register(registererUser: any): Promise<boolean | BaseResponseModel> {
        return this.post('user/register', {
            user: registererUser
        }).then((baseResponse: BaseResponseModel) => {
            if (baseResponse.statusCode === 200) {
                return baseResponse;
            } else {
                return false;
            }
        });
    }
    
    public doLogin(user: any): Promise<boolean> {
        if (this.trabajadoresAsUsuarios) {
            this.doLoginRoute = 'trabajadores/dologin';
        }
        return this.post(this.doLoginRoute, {
            id: (user.id || user.user),
            password: user.password
        }).then((baseResponse: BaseResponseModel) => {
            if (baseResponse.statusCode === 200) {
                const data = baseResponse.data;
                // FotoAgricultura
                if ( data[0]['sm'] != null ) {
                    if ( data[0]['sm'] === true) {
                        localStorage.setItem('sm', '1');
                    }
                }
                
                this.openSession(this.getEntityData(baseResponse));
                return true;
            } else {
                return false;
            }
        });
    }

    public checkCurrentSession(): Promise<Boolean> {
        return new Promise((resolve) => {
            if (this.isLoggedIn()) {
                resolve(true);
                // TODO: Not secure
                // this.doLogin(this.getCurrentUser())
                //   .then(resolve);
            } else {
                resolve(false);
            }
        });
    }

    public isLoggedIn(): boolean {
        return Utils.isSet(this.getCurrentUser());
    }

    public closeSession() {
        StorageManager.deleteUser();
    }

    public openSession(user: UserModel) {
        StorageManager.saveUser(user);
    }

}
