import { ProductosApiService } from '../../../../../service/api/productos-api';
import {TareasApiService} from '../../../../../service/api/tareas-api.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {TrabajadoresApiService} from '../../../../../service/api/trabajadores-api.service';
import {TratamientosApiService} from '../../../../../service/api/tratamientos-api.service';
import {FitosanitariosFormDefinition} from './form-control/form-definition';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {Utils} from '../../../../../common/utils';
import {build, pool, buildParams} from '../../../../../common/classes/request-builder';
import {FormRequestTypes} from '../../../../../common/classes/form-request';
import { InputType } from '../../../../../common/components/form-builder/form-builder.component';
import {ClientesApiService} from '../../../../../service/api/clientes-api.service';
import {environment} from '../../../../../../environments/environment';
import {StorageManager} from '../../../../../common/storage-manager.class';
import moment from 'moment';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { WeatherService } from '../../../../../service/api/weather/weather';
import { RecuperarReferenciaRecetaService } from '../../../../../service/recuperar.service';
import { HttpClient } from '@angular/common/http';
import { SiexApiService } from '../../../../../service/api/siex-api.service';
import { UsuariosApiService } from '../../../../../service/api/usuarios-api.service';
import { firstValueFrom } from 'rxjs';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-formulario-pulverizacion',
    templateUrl: './fitosanitarios-form.component.html',
    styleUrls: ['./fitosanitarios-form.component.scss']
})
export class FitosanitariosFormComponent extends BaseForm implements OnInit {
    
    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
    requestButton: RequestButtonComponent = new RequestButtonComponent();
    public form: FitosanitariosFormDefinition = new FitosanitariosFormDefinition();
    public formRequest: AppFormRequest = new AppFormRequest();
    public override model: any = {
        fecha_inicio: moment().toDate()
    };

    public distributionOrderFormTratamientos = [2, 3, 2];

    public rol = StorageManager.getUser().rol;

    public appName = environment.appName;
    public showTareasFitoObservaciones = environment.features.showTareasFitoObservaciones;

    public horaFechaFinActualPorDefecto = environment.features.horaFechaFinActualPorDefecto;
    public showClimatologia = environment.features.showClimatologia;
    public tipoSuperficieParcelas = environment.features.hasSuperficieCultivada
        ? 'Sup.\xa0Cultivada'
        : 'S.\xa0Sigpac';
    public serverUrl = window.location.hostname === 'localhost'
        ? ''
        : environment.serverUrl;

    public auxServerUrl = environment.serverUrl;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = environment.features.userCanSeeClientes
        ? true
        : (this.rol === 'admin');
    public filterAplicadoresByClient = environment.features.filterAplicadoresByClient;
    public clienteName = environment.features.clienteName;
    public showTareasFitoHoras = environment.features.showTareasFitoHoras;
    public timeStartStopTareas = environment.features.timeStartStopTareas;
    public showFitoValidado = environment.features.showFitoValidado
        ? (this.rol === 'admin')
        : false;
    public parcelasName = environment.features.parcelasName;
    public showTareasFitoMochila = environment.features.showTareasFitoMochila;
    public showTareasFitoAplicacion = environment.features.showTareasFitoAplicacion;
    public textoSuperficie = environment.features.textoSuperficie;
    public showClientesNotas = environment.features.showClientesNotas;

    public getRequest = (this.getType() === FormRequestTypes.DUPLICATE)
        ? this.tareasApi.fitosanitariosGET_GENERIC
        : this.tareasApi.fitosanitariosGET;

    public updatingTratamientos = false;
    public loadingData = true;

 

    /*** innovia litros aplicados */
    public showLitros = false;

    /*** TRACTOR CONFIG ***/
    public idxMaquinaria: any;

    /*** Firma DonJardin ***/
    public signatureFile: File = new File([], '');

    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public usuariosApi: UsuariosApiService,
        public tareasApi: TareasApiService,
        public fincasApi: FincasApiService,
        public trabajadoresApi: TrabajadoresApiService,
        public tratamientosApi: TratamientosApiService,
        public clientesApi: ClientesApiService,
        public productosApi: ProductosApiService,
        public siexApi: SiexApiService,
        public override router: Router,
        public dashboardEvent: DashboardService,
        private weatherService: WeatherService,
        private recuperarService: RecuperarReferenciaRecetaService,
        private http: HttpClient
    ) {
        super(
            route,
            router,
            dashboard,
            tareasApi.fitosanitariosPUT,
            tareasApi.fitosanitariosPOST,
            'fitosanitarios',
            'Actualizar Tarea Fitosanitaria',
            'Realizar Tarea Fitosanitaria',
            'Duplicar Tarea Fitosanitaria'
        );
    }

    ngOnInit() {
        this.dashboard.clientChanges$.subscribe(() => {
            this.fincasApi.fincasGET.unsuscribe();
            this.fincasApi.sectoresGET.unsuscribe();
            this.fincasApi.parcelasGET.unsuscribe();
            this.tratamientosApi.tratamientosList.unsuscribe();
            if (this.filterAplicadoresByClient) {
                this.trabajadoresApi.trabajadoresGET.unsuscribe();
                this.trabajadoresApi.maquinariaGET.unsuscribe();
            }
        });
        
        if (environment.features.horaFechaFinActualPorDefecto) {
            if (this.getType() === 0 || this.getType() === 2){
                const date = new Date;
                this.model.hora_fin = date;
            }
        }
        const fieldsToSend = (['id', 'id_usuario', 'cultivo', 'variedad', 'sup_cultivada', 'id_cliente', 'horas_totales'] as any).concat(
            this.form.formFieldsTiempo.filter(it => it && (it.visible !== false)).map(it => it.field),
            this.form.formFieldsKM.filter(it => it && (it.visible !== false)).map(it => it.field),
            this.form.formFieldsLugar.filter(it => it && (it.visible !== false)).map(it => it.field),
            this.form.formFieldsTratamiento.filter(it => it && (it.visible !== false)).map(it => it.field),
            this.form.formFieldsTractor.filter(it => it && (it.visible !== false)).map(it => it.field),
            this.form.formFieldsFinal.filter(it => it && (it.visible !== false)).map(it => it.field),
            this.form.formFieldsInnovia.filter(it => it && (it.visible !== false)).map(it => it.field),
        ).filter((it: string) => !['litros_aplicados_ha'].includes(it));

        if (this.hasClients && this.userCanSeeClientes) {
            fieldsToSend.push('id_cliente');
        }

        if (this.appName === 'pyf') {
            fieldsToSend.push('referencia_receta');
        }
        if (environment.features.showTareasMto){
            fieldsToSend.push('signature_img');
        }

        if (this.appName === 'donjardin') {
            fieldsToSend.push('id_ref_usuario');
        }

        if (this.appName === 'vginer') {
            fieldsToSend.push(
                ...this.form.formFieldsClima.map(it => it.field),
                ...this.form.formFieldsMezclasSobrantes.map(it => it.field)
            );
        }

        if (this.showClimatologia ) {
            fieldsToSend.push(
                ...this.form.formFieldsClima.map(it => it.field)
            );
        }

        this.initFormData().then(() => {
            this.fincasApi.fincasGET.toPromise().then( (resp_fincas: any) => {
             
                if (resp_fincas.length > 0 ) {
                    if (resp_fincas.length === 1) {
                        this.model['id_finca'] = resp_fincas[0].id;
                    } 
                }
            });
            if (this.appName === 'simat') {
                this.getTipoMaquina();
            }
       
            this.form.tratamientos.values.map(it => {
                if (it && it.value && it.value.activo !== '1') {
                    it.label = '** ' + it.label;
                }
                if (it && it.value && it.value.fecha_caducidad) {
                    it.label = this.checkIfProductIsValid(it.value.fecha_caducidad) + it.label;
                }
                return it;
            });

            this.formRequest.update();
        });
        if ( this.appName === 'ava' && this.getType() === 0 ) {
            this.fincasApi.fincasGET.toPromise().then( (resp_fincas: any) => {
             
                if (resp_fincas.length > 0 ) {
                    this.model['id_finca'] = resp_fincas[0].id;
                }
            });
        }
        this.expandFormFields();
        this.fincasApi.parcelasGET.safePerform({cliente: this.model.id_cliente}).toPromise().then( () => {
            this.loadingData = false;
        }).catch( () => {
            this.loadingData = false;
        });
        
        this.expandFormRequest();
        
       
        this.formRequest
            .setType(this.getType())
            .isGeneric(this.getType() === FormRequestTypes.DUPLICATE)
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.getRequest)
            .setPostRequest(this.tareasApi.fitosanitariosPOST)
            .setPutRequest(this.tareasApi.fitosanitariosPUT)
            .setFormFields(Array.prototype
                .concat(
                    this.form.formFieldsTiempo.map(it => it.field),
                    this.form.formFieldsKM.map(it => it.field),
                    this.form.formFieldsLugar.map(it => it.field),
                    this.form.formFieldsTratamiento.map(it => it.field),
                    this.form.formFieldsFinal.map(it => it.field).filter(it => !['litros_aplicados_ha'].includes(it ?? ''))
                ).concat(this.form.formFieldsTractor.map(it => it.field))
            )
            .setFieldsToSend(fieldsToSend);

        this.formRequest.load();

        this.softInit(this.getType());
        this.form.requestType = this.getType();
        this.form.model = this.model;
        // console.log('this.getType()', this.getType() );
        // console.log('cliente', StorageManager.getClient() );
        if (this.showClientesNotas && this.getType() === FormRequestTypes.CREATE) {
            this.model['notas_cliente'] = StorageManager.getClient()['notas'];
        }           
        if (localStorage.getItem('tratamiento_temp')) {
            this.model = JSON.parse(localStorage.getItem('tratamiento_temp') ?? '');
            this.countTrabajadores();
            this.countSuperficieParcelas();
            this.countLitrosAplicadosHa();
            localStorage.removeItem('tratamiento_temp');
        }
    }

    updateImageModel( event: string  ){
        this.model['imagen'] = event;
    }
    
    updateAfterImageModel( event: string  ){
        this.model['image_after'] = event;
    }

    getSignature( event: File ) {
        this.signatureFile = event;
    }
    
    updateFirma(event: any) {
        this.model['signature_img'] = event;
        this.formRequest.update();    
    }

    public override getItemPosition(formFields: ItemInterface<any>[], field: string) {
        let position = -1;
        formFields.forEach((item, index) => {
            if (item.field === field) {
                position = index;
            }
        });
        return position;
    }

    public countTrabajadores() {
        const idxTrabajadores = this.getItemPosition(this.form.formFieldsTratamiento, 'ids_trabajadores');
        const trabajadoresCount = this.model.ids_trabajadores ? this.model.ids_trabajadores.split(';').length : 0;

        if (this.form.formFieldsTratamiento[idxTrabajadores]) {
            this.form.formFieldsTratamiento[idxTrabajadores]!.label = `Profesionales\xa0(${trabajadoresCount})`;
        }
    }

    public countSuperficieParcelas() {
        const ids = (this.model.ids_parcelas || '').split(';');
        this.model.parcelas_superficie = 0;

        (this.form.parcelas.filtered || []).forEach(parcela => {
            if (parcela && parcela.value && ids.includes(parcela.value.id)) {
                this.model.parcelas_superficie += parseFloat((parcela.value.superficie_cultivada || '').replace(',', '.') || '0.00');
            }
        });
        this.model.parcelas_superficie = Utils.decimalFormat(this.model.parcelas_superficie, 2, '.', ',', 3);

        const idxParcelas = this.getItemPosition(this.form.formFieldsLugar, 'ids_parcelas');
        
        if (this.form.formFieldsLugar[idxParcelas]) {
            this.form.formFieldsLugar[idxParcelas]!.label = this.parcelasName + ' (' + this.tipoSuperficieParcelas
            + ':\xa0' + this.model.parcelas_superficie + '\xa0' + this.textoSuperficie + ')';
        }
        
    }

    public countLitrosAplicadosHa() {
        if (this.form.showTareasFitoLitrosAplicados) {
            this.model.litros_aplicados_ha = Utils.decimalFormat(
                (this.model.litros_aplicados || 0) / ((this.model.parcelas_superficie || '').replace(',', '.') || 0));
            if ([NaN, undefined, 0.00, false, Infinity].includes(this.model.litros_aplicados_ha)) {
                this.model.litros_aplicados_ha = 0;
            }
        }
    }

    public checkIfProductIsValid(fecha_caducidad: string) {
        const diff = moment(new Date()).diff(Utils.toDate(fecha_caducidad), 'months');

        if (diff > 12) {
            return '* ';
        } else if (diff > 6) {
            return '* ';
        } else if (diff >= 0) {
            return '* ';
        }

        return '';
    }

    public formChanges(tag: string) {
        console.log(this.form.sectores.selected);

        if (environment.features.showTareasMto || environment.features.tareasHorasTotales || this.showTareasFitoHoras){
            // console.log(this.model['hora_inicio'] != null && this.model['hora_fin'] != null);
            if (this.model['hora_inicio'] != null && this.model['hora_fin'] != null ) {        
                let horaFin = moment(this.model.hora_fin).format('HH:mm');
                let horaInicio = moment(this.model.hora_inicio).format('HH:mm');
                const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
                if (!timeFormat.test(horaInicio)) {
                    horaInicio = this.model.hora_inicio;
                }
            
                if (!timeFormat.test(horaFin)) {
                    horaFin = this.model.hora_fin;
                }
            
                const diferencia = this.formatoHorasMinutos(horaInicio, horaFin);
                this.model['horas_totales'] = diferencia;            
            } else {
                this.model['horas_totales'] = '';
            }
        }
        //0: {id: '40', nombre: 'AFOURER O NADORCOTT', id_finca: '3', m3: null, superficie: '0', …}
        // 0: {label: 'AFOURER O NADORCOTT', value: {…}}
        if (this.appName === 'pyf') {
            if (tag === 'fecha_inicio') {
                if (this.model.orden_asesor === true){
                    this.model.orden_asesor = false;
                    this.form.formFieldsTratamiento.forEach( (form) => {
                        if (form.field === 'orden_asesor'){
                            form.label = 'Orden asesor';
                            form.inputType = {type: InputType.SWITCH};
                        }
                    });
                }   
                this.model.referencia_receta = '';
            }
            if (tag === 'orden_asesor') {
                if (this.model.fecha_inicio && this.model.orden_asesor){
                    let fullDateModel;
                    let fecha;
                    
                    if (this.model.fecha_inicio.length === 10){
                        fullDateModel = this.model.fecha_inicio.split('/');
                        fecha = new Date(fullDateModel[2], fullDateModel[1], fullDateModel[0]);
                    }else{
                        fecha = new Date(this.model.fecha_inicio);
                    }
    
                    (firstValueFrom(this.recuperarService.getRecuperarReferencia(fecha.getFullYear())) as any)
                        .then((resp: RespReferencia) => {
                            let numero = '';
                            switch (resp?.data[0]?.numero.length){
                                case 1:
                                    numero = '00' + resp.data[0].numero;
                                    break;
                                case 2:
                                    numero = '0' + resp.data[0].numero;
                                    break;
                                default:
                                    numero = resp?.data[0]?.numero ?? '';
                                    break;
                            }
                            const referenciaReceta = fecha.getFullYear() + '-' + numero;
                            this.form.formFieldsTratamiento.forEach( (form) => {
                                if (form.field === 'orden_asesor'){
                                    form.label = 'Orden asesor - ' + referenciaReceta;
                                    this.model.orden_asesor = true;
                                    form.inputType = {type: InputType.RO_MD_SWITCH};
                                    this.model['referencia_receta'] = referenciaReceta;
                                }
                            });
    
                        });       
                }else{
                    this.form.formFieldsTratamiento.forEach( (form) => {
                        if (form.field === 'orden_asesor'){
                            form.label = 'Orden asesor';
                        }
                    });
                    this.model.referencia_receta = '';
                }// if();
            }
        }
        
        if ( this.showClimatologia ) {
            if ( tag === 'climatologia') {
                this.checkWeatherVariables();
            }// if();
        }// if();
      
        this.requestButton.error = '';
        if (tag === 'id_maquinaria' && (this.getType() === FormRequestTypes.CREATE)) {
            if ((this.appName === 'simat') && this.form.formFieldsTractor[this.idxMaquinaria]) {
                this.getTipoMaquina();
            }

            if (!this.model.ids_trabajadores && this.model.id_maquinaria) {
                this.model.ids_trabajadores = '';
                const idxTrabajador = this.getItemPosition(this.form.formFieldsTratamiento, 'ids_trabajadores');

                for (const trabajador of this.form?.formFieldsTratamiento[idxTrabajador]?.values.values) {
                    if (trabajador.value.id_maquina === this.model.id_maquinaria) {
                        this.model.ids_trabajadores += trabajador.value.id + ';';
                    }
                }
                this.model.ids_trabajadores = this.model.ids_trabajadores.slice(0, -1);
            }

        }
        if (tag === 'ids_trabajadores' && !this.model.id_maquinaria && (this.getType() === FormRequestTypes.CREATE)) {
            const idxTrabajador = this.getItemPosition(this.form.formFieldsTratamiento, 'ids_trabajadores');

            if (this.form.formFieldsTratamiento[idxTrabajador] && (this.form.formFieldsTratamiento[idxTrabajador]?.values.selected.length === 1)) {
                this.model.id_maquinaria = this.form.formFieldsTratamiento[idxTrabajador]?.values.selected[0].id_maquina;
            }
        }
        if (tag === 'id_finca' && this.appName !== 'agricar') {
            this.model.id_sector = null;
            this.model.ids_parcelas = null;
        }
        if (tag === 'id_sector' && this.appName !== 'agricar') {
            this.model.ids_parcelas = null;
        }
        if (tag === 'id_cliente' && this.appName !== 'agricar') {
            StorageManager.saveClient(this.form.clientes.selected);
            this.dashboardEvent.emitClientChange();
            this.form.fincas.selected = null;
            this.form.sectores.selected = null;
            this.form.parcelas.selected = null;
            this.form.tratamientos.selected = null;
            this.model.id_finca = null;
            this.model.id_sector = null;
            this.model.ids_parcelas = null;
            this.model.id_tratamiento = null;

            const builds = [
                buildParams(this.fincasApi.fincasGET, {id_cliente: this.model.id_cliente}, this.form.fincas,
                    'nombre', true),
                buildParams(this.fincasApi.sectoresGET, {id_cliente: this.model.id_cliente}, this.form.sectores,
                    'nombre', true),
                buildParams(this.fincasApi.parcelasGET, {id_cliente: this.model.id_cliente}, this.form.parcelas,
                    'nombre', true),
                buildParams(this.tratamientosApi.tratamientosList, {id_cliente: this.model.id_cliente}, this.form.tratamientos,
                    'nombre', true),
            ];
            if (this.filterAplicadoresByClient) {
                this.model.ids_trabajadores = null;
                this.model.id_maquinaria = null;
                builds.push(
                    buildParams(this.trabajadoresApi.trabajadoresGET, {id_cliente: this.model.id_cliente},
                        this.form.trabajadores, 'nombre', true),
                    buildParams(this.trabajadoresApi.maquinariaGET, {id_cliente: this.model.id_cliente},
                        this.form.maquinarias, 'nombre', true)
                );
            }

            pool(builds, () => {
                
                
                this.form.fincas.filtered = this.form.fincas.values;
                // this.form.sectores.filtered = this.form.sectores.values;
                this.form.parcelas.values = this.form.parcelas.values.filter(it => (it.label !== '...'));
                this.form.tratamientos.filtered = this.form.tratamientos.values;
                if (this.filterAplicadoresByClient) {
                    this.form.trabajadores.filtered = this.form.trabajadores.values.filter(it => (it.label !== '...'));
                    this.form.maquinarias.filtered = this.form.maquinarias.values;
                }
            }).then(() => {
                if (this.appName === 'simat') {
                    this.getTipoMaquina();
                }
                this.formRequest.update();
            });   
        }
        this.countTrabajadores();
        this.countSuperficieParcelas();
        this.countLitrosAplicadosHa();
    }

    public selectAll() {
        this.countSuperficieParcelas();
    }

    public async submit() {

        if (this.appName !== 'agrosalvi'){
            delete this.model['facturado'];
            delete this.model['factura_albaran'];
        }// if();
        
        delete this.model['climatologia']; // Para que no se envie a la base de datos

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }

        if ((typeof this.model.fecha_inicio !== 'string')) {
                
            if ( ( this.model.fecha_inicio !=  null ) ) {
                this.model.fecha_inicio = moment(this.model.fecha_inicio ).format('DD/MM/YYYY');
            }
            // La forma antigua para rellerafecha cuando este vacia
            // this.model.fecha_exp = moment(this.model.fecha_exp || new Date()).format('DD/MM/YYYY');
        }
       
        if ((typeof this.model.fecha_seca_cultivo_agua !== 'string')) {
                
            if ( ( this.model.fecha_seca_cultivo_agua !=  null ) ) {
                this.model.fecha_seca_cultivo_agua = moment(this.model.fecha_seca_cultivo_agua ).format('DD/MM/YYYY');
            }
            // La forma antigua para rellerafecha cuando este vacia
            // this.model.fecha_exp = moment(this.model.fecha_exp || new Date()).format('DD/MM/YYYY');
        }
       
        if (environment.features.showTareasMto){
            const horaFin = moment(this.model.hora_fin, 'HH:mm');
            const horaInicio = moment(this.model.hora_inicio, 'HH:mm');
            const diferenciaEntreHoras = horaFin.diff(horaInicio);
        
            if (diferenciaEntreHoras < 0){
                this.requestButton.error = 'La hora de inicio es mayor que la de fin.';
                return;
            }// if();

            if ( this.signatureFile != null ) {
                const formData = new FormData();
                formData.append('fileToUpload', this.signatureFile);
                formData.append('database', 'tareas_fitosanitarias');
                formData.append('folder', 'fitosanitarios');
                formData.append('field', 'signature_img');
                const signature: any = 
                    await firstValueFrom(this.http.post( this.auxServerUrl + 'ws/tareas/fileUploadGeneric.php', formData));
                this.model['signature_img'] = (signature.target_file as string).replace('../../images/fitosanitarios/', '');
            }// if();
          
        }// if();

        if ( environment.appName.toLowerCase().localeCompare('armentia') === 0) {
            this.model['id_cliente'] = StorageManager.getUser().id;
            this.model['cliente'] = StorageManager.getUser().id;
        }

        if ( this.appName !== 'fotoagricultura' ) {
            delete this.model['fin_fecha'];
        }
        
        if ( this.appName !== 'onda' && this.appName !== 'lacooperativa'){
            delete this.model['estado_tarea'];
            delete this.model['empresa_pagadora'];
        }// if();

        if (this.appName !== 'pyf'){
            delete this.model['orden_asesor'];
            delete this.model['sup_tratada'];
        }// if();

        if (this.appName === 'donjardin') {
            if (this.getType() === FormRequestTypes.CREATE)  {
                delete this.model['id_ref_usuario'];
            }else {
                this.model['id_ref_usuario'] = StorageManager.getUser().id;
            }
        }

        if (!environment.features.timeStartStopTareas && !environment.features.showTareasFitoHoras){
            delete this.model['hora_inicio'];
            delete this.model['hora_fin'];
        }
        this.formRequest.send();

        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    checkWeatherVariables() {

        let fecha = this.model['fecha_inicio'];
        let finca = '';
        let parcela = '0';
        let alertFechas = false;
        let alertFincas = false;
        let alertMessage = '';

        if ( this.model['fecha_inicio'] == null || this.model['fecha_inicio'] === '' ) {
            if ( this.model['fecha_fin'] == null || this.model['fecha_fin'] === '' ) { 
                fecha = null;
                alertFechas = true;
            } else {
                fecha = this.model['fecha_fin'];
                alertFechas = false;
            }
        } else {
          
            fecha = this.model['fecha_inicio'];
            alertFechas = false;
        }

        if ( alertFechas ) {
            alertMessage = 'No hay seleccionadas ni Fecha de inicio ni fecha de fin \n';
        }

        if (this.model['id_finca'] == null ){
            alertFincas = true;
            alertMessage += 'No hay finca seleccionada';
        } else {
            alertFincas = false;
            finca = this.model['id_finca'] as string;
        }

        if (  alertFechas || alertFincas ) {
            alert( alertMessage );
        } else {
            if ( this.model['ids_parcelas'] != null ){
              
                if ( (this.model['ids_parcelas'] as string).includes(';')) {
                    parcela = (this.model['ids_parcelas'] as string).split(';')[0] ?? '';
                } else {
                    parcela = this.model['ids_parcelas']; 
                } 
            }

            if (  typeof(fecha) == 'object') {
                fecha = moment(fecha).format('DD/MM/YYYY');
            } 
            
            let indice = 0;
            for (let index = 0; index < this.form.formFieldsClima.length; index++) {
                const form = this.form.formFieldsClima[index];
                if (form?.field === 'climatologia' ) {
                    indice = index;
                    const clima = this.form.formFieldsClima[index];
                    if (clima) {
                        clima.disabled = true;
                    }
                }
                
            }
            const indexedClima = this.form.formFieldsClima[indice];

            (firstValueFrom(this.weatherService.getWeatherInfo(fecha, finca, parcela)) as any)
                .then( (resp: { status: number; mensaje: string; data?: any }) => {
                    if ( indexedClima && resp.status === 400 ) {
                        alert( resp.mensaje ); 
                        indexedClima.disabled = false;
                        return;
                    }
            
                    if ( resp.data.prevision != null ) {
                        this.model['temperatura'] = resp.data.prevision.hourly[0].temperature;
                        this.model['viento'] = resp.data.prevision.hourly[0].wind_speed;
                        this.model['hr'] = resp.data.prevision.hourly[0].humidity;
                        this.model['precip'] = resp.data.prevision.hourly[0].precip;
                    } else {
                        alert('No se han encontrado datos');
                    }

                    if (indexedClima) {
                        indexedClima.disabled = false;
                    }

                })
                .catch( (error: any) => {
                    alert('No hay datos en esta fecha');
                    console.log('error', error);
                    
                    if (indexedClima) {
                        indexedClima.disabled = false;
                    }
                })
            ;

        }
    }

    public async fillProducts(){

        const relacionProductos: any[] = await this.productosApi.productos.GET.toPromise();

        const idsProductos = (this.model.ids_productos || '').split(';');

        for (let index = 0; index < this.form.formFieldsInnovia.length ; index++) {
            const element = this.form.formFieldsInnovia[index];
            // debugger;
            if (  element?.field === 'litros1') { 
                if (idsProductos[0] === undefined ) {
                    element.visible = false;
                } else {
                    const producto = relacionProductos.find(product => product.id === idsProductos[0])['nombre'] || 'no localizado';
                    element.label += producto;
                }
            } 
            if (  element?.field === 'litros2') { 
                if (idsProductos[1] === undefined ) {
                    element.visible = false;
                } else {
                    const producto = relacionProductos.find(product => product.id === idsProductos[1])['nombre'] || 'no localizado';
                    element.label += producto;
                }
            } 
            if (  element?.field === 'litros3') { 
                if (idsProductos[2] === undefined ) {
                    element.visible = false;
                } else {
                    const producto = relacionProductos.find(product => product.id === idsProductos[2])['nombre'] || 'no localizado';
                    element.label += producto;
                }
            } 
            if ( (index + 1) >= this.form.formFieldsInnovia.length  )   { 
                this.showLitros = true;
            }
        }

    }

    formatoHorasMinutos(horaEntrada: string, horaSalida: string) {    
        let timeStr: any = horaEntrada;
        timeStr = timeStr.split(':');
      
        const h = timeStr[0],
            m = timeStr[1];
      
        const newTime = moment( horaSalida + ' 2021-4-5')
            .subtract({'hours': h, 'minutes': m})
            .format('HH:mm');
      
      
        // console.log({str});
        return newTime;
       
    }

    public getObservaciones( event: any ) {
        this.model.observaciones = event;
    }
    
    public getOrdenTrabajo( event: any ) {
        this.model.orden_trabajo = event;
    }

    public getProfesionalesSequiador() {
        this.form.usuarios.filtered = [{'label': '...', 'value': null}];
        this.form.usuarios.values = [{'label': '...', 'value': null}];
                
        const aplicadores: any[] = [];
        const comuneros: any[] = [];

        this.form.parcelas.selected.forEach((parcela: { id_cliente: any; aplicadores: any[]; }) => {
            
            // Añadimos comuneros al desplegable.
            this.usuariosApi.profesionalesGET.safePerform({id_cliente: parcela.id_cliente}).toPromise().then( resp => {
                let nombreComunero = resp[0].nombre;
                        
                if (resp[0].apellidos != null) {
                    nombreComunero += ' ' + resp[0].apellidos;   
                }
            
                comuneros.push(nombreComunero);
                
                if (comuneros.indexOf(nombreComunero) < 0) {
                    this.form.usuarios.values.push({'label': nombreComunero, 'values': resp[0]});
                }
                
            }).catch( error => {
                console.log(error);
            });
            
            // Añadimos aplicadores asignados a la parcela al desplegable.
            parcela.aplicadores.forEach(aplicador => {
                if (aplicador != null) {
                    const label = aplicador.nombre + ' ' + aplicador.apellidos;
                    
                    if (aplicadores.indexOf(aplicador.id) < 0) {
                        aplicadores.push(aplicador.id);
                        this.form.usuarios.values.push({'label': label, 'values': aplicador});
                    }
                }
            });
        });
        this.form.usuarios.filtered = this.form.usuarios.values;
    }

    private getTipoMaquina() {
        const idxPresion = this.getItemPosition(this.form.formFieldsTractor, 'presion');
        if (idxPresion === -1 || this.form?.formFieldsTractor[idxPresion]?.inputType?.type === InputType.EDIT_NUMERIC) {
            return;
        }
        (this.form.maquinarias.filtered || []).forEach(maquina => {
            if (this.form.formFieldsTractor[idxPresion] && maquina.value && (maquina.value.id === this.model.id_maquinaria)) {
                this.model.tipoMaquina = maquina.value.tipo;
                switch (this.model.tipoMaquina) {
                    case 'Herbicida':
                        this.form.formFieldsTractor[idxPresion]!.values = this.form.presionHerbicida;
                        break;
                    case 'Turboatomizador':
                        this.form.formFieldsTractor[idxPresion]!.values = this.form.presionTurbo;
                        break;
                    case 'Disparador':
                        this.form.formFieldsTractor[idxPresion]!.values = this.form.presionDisparador;
                        break;
                    default:
                        this.form.formFieldsTractor[idxPresion]!.values = this.form.presionAll;
                        break;
                }
            }
        });
    }

    private expandFormFields() {
        // if(this.appName == 'donjardin'){
        //     const sessionData = StorageManager.getSessionDataDonJardin();
        //     const fechaActual = new Date();
        //     if ( this.getType() == 0 ){
        //         if(isNull(sessionData[1])){
                    
        //             if ( checkIfRolAdmin() ) {
        //                 this.model['hora_fin'] = fechaActual;
        //             }// if();
        //         }// if();
        //         else{
        //             this.model['id_finca'] = sessionData[0];
        //             this.model['hora_inicio'] = sessionData[1];
        //             this.model['hora_fin'] = sessionData[2];
        //         }// if();
        //     }// if();
        // }// if();
        if (environment.features.showTareasMto) {
            if (this.getType() === 0 || this.getType() === 2){
                if ( StorageManager.getUser().rol === 'user'){
                    const date = new Date;
                    this.model.hora_fin = date;
                }
             
            }
        }

        this.form.formFieldsTractor.forEach((item, index) => {
            if (item.label === 'Maquinaria'){
                this.idxMaquinaria = index;
            }
        });
        if (this.hasClients && this.userCanSeeClientes && (this.getType() === FormRequestTypes.DUPLICATE)) {
            this.form.formFieldsLugar.unshift({
                field: 'id_cliente',
                label: this.clienteName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.form.clientes,
                valuePrimaryKey: 'id',
                required: true
            });
        }
    }

    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {
            if (this.appName === 'agrosalvi') {
                this.model.facturado = (+this.model.facturado === 1);                
            }
            if (this.horaFechaFinActualPorDefecto && this.getType() !== FormRequestTypes.EDIT) {
                const fechaActual = new Date();

                if (this.appName === 'sirga' || this.appName === 'yagoaznar') {
                    this.model['fecha_fin'] = '';
                }else {
                    this.model['fecha_fin'] = fechaActual;
                }

                this.model['hora_fin'] = fechaActual;
            }

            if (this.appName === 'innovia'){
                this.fillProducts();  
            }    
            this.model.aplicado = [1, '1', true, 'true'].includes(this.model.aplicado);
            if (this.showFitoValidado) {
                this.model.validado = [1, '1', true, 'true'].includes(this.model.validado);
            }
            if (this.showTareasFitoMochila) {
                this.model.mochila = [1, '1', true, 'true'].includes(this.model.mochila);
            }
            if (this.appName === 'vginer') {
                this.model.cubeto_evaporacion = [1, '1', true, 'true'].includes(this.model.cubeto_evaporacion);
                this.model.punto_carga = [1, '1', true, 'true'].includes(this.model.punto_carga);
                this.model.limpieza_epis = [1, '1', true, 'true'].includes(this.model.limpieza_epis);
            }

            if (this.getType() === FormRequestTypes.DUPLICATE) {
                this.model.aplicado = false;
                if (this.showFitoValidado) {
                    this.model.validado = [1, '1', true, 'true'].includes(this.model.validado);        
                }
                this.model.eficacia = null;
                this.model.fecha_fin = null;
                if (this.appName === 'pyf'){
                    this.model.orden_asesor = false;
                }
            }

            if ( environment.features.showTareasMto ) {
                if ( StorageManager.getUser().rol === 'user') {
                    this.model.aplicado = true;
                }
            }   

            if (this.getType() === FormRequestTypes.EDIT && this.appName === 'pyf'){
                if (this.model.referencia_receta != null || this.model.referencia_receta === ''){
                    this.form.formFieldsTratamiento.forEach(form => {
                        if (form.field === 'orden_asesor' && this.model.referencia_receta !== ''){
                            form.label = 'Orden asesor - ' + this.model.referencia_receta;
                            this.model.orden_asesor = true;
                            form.inputType = {type: InputType.RO_MD_SWITCH};

                        }
                    });
                }
            }
            // TO DO: Optimizar
            setTimeout(() => {
                this.countTrabajadores();
                this.countSuperficieParcelas();
                this.countLitrosAplicadosHa();
            }, 100);

           
            const intervaloTrabajadores = setInterval( () => {
                if ( this.form.trabajadores.filtered.length <= 0 ) {

                } else {
                    this.form.trabajadores.filtered.forEach(row => {
                        if (row.value && row.value.fecha_cad) {
                            if (moment(row.value.fecha_cad, 'DD/MM/YYYY').isBefore(moment())) {
                                row.label = '** ' + row.label;
                            } else if (
                                moment(row.value.fecha_cad, 'DD/MM/YYYY').isSameOrAfter(moment()) &&
                                    moment(row.value.fecha_cad, 'DD/MM/YYYY').isBefore(moment().add(3, 'months'))
                            ) {
                                row.label = '* ' + row.label;
                            }
                        }
                    });
                    clearInterval(intervaloTrabajadores);
                }
                  
            }, 100);
              
               
             
               
        
            const intervalo = setInterval( () => {
                if ( this.form.maquinarias.filtered.length <= 1 ) {

                } else {
                    this.form.maquinarias.filtered.forEach(row => {
                        if (row.value && row.value.fecha_ultima_inspeccion) {
                            const auxFechaCad = (row.value.fecha_ultima_inspeccion as string).split('/');

                            const edadMaquinaria = moment().year() - moment(row.value.fecha_adquisicion, 'DD/MM/YYYY').year();
                            
                            if (edadMaquinaria > 10 || edadMaquinaria.toString() === 'NaN') {
                                if ( parseInt(auxFechaCad[2] ?? '', 10) >= 2020 ) {
                                    auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + 3).toString();
                                } else {
                                    auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + 5).toString();
                                }
                            } else {
                                auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + 10).toString();
                            }
    
                            const fechaCaducidad = auxFechaCad[0] + '/' + auxFechaCad[1] + '/' + auxFechaCad[2]; 

                            let tiempoAntes = false;
                            if (this.appName === 'prodalbar') {
                                tiempoAntes = moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment().add(1, 'years'));
                            } else {
                                tiempoAntes = moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment().add(3, 'months'));
                            }

                            if (moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment())) {
                                row.label = '** ' + row.label;
                            } else if (
                                moment(fechaCaducidad, 'DD/MM/YYYY').isSameOrAfter(moment()) && tiempoAntes
                            ) {
                                row.label = '* ' + row.label;
                            }
                        }
                    });
                    clearInterval(intervalo);
                }  
            }, 500);
            
            if (environment.features.showTareasMto){
                const userInfo = (StorageManager.getUser() || {} as any);
                if (userInfo.rol === 'user' && this.getType() === FormRequestTypes.CREATE){
                    this.model['ids_trabajadores'] = userInfo.id.toString();
                }

                if (this.getType() === FormRequestTypes.DUPLICATE) {
                    delete this.model['hora_fin'];
                    delete this.model['hora_inicio'];
                    delete this.model['horas_totales'];
                }
            }
            resolve(true);
            
        });

        this.formRequest.beforeSend(resolve => {
            if (this.showClientesNotas) {
                delete this.model['notas_cliente'];
            }
            const clientId = (StorageManager.getClient() || {} as any).id;
            const clientIdFromFinca = (this.form.fincas.selected || {} as any).id_cliente;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
                clientIdFromFinca ? clientIdFromFinca :
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() || {} as any).id;
            const userIdFromFinca = (this.form.fincas.selected || {}).id_usuario;
            const userIdFromForm = this.model['id_usuario'];

            
            if (!environment.features.showTareasMto){
                this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';
            }
            else{
                if ( this.getType() === FormRequestTypes.CREATE || this.getType() === FormRequestTypes.DUPLICATE){
                    this.model.id_usuario = userId ? userId : '';
                }// if();
            }// if();

            this.model.fecha_inicio = this.model.fecha_inicio instanceof Date
                ? Utils.formatDate(this.model.fecha_inicio)
                : this.model.fecha_inicio;

            this.model.fecha_fin = this.model.fecha_fin instanceof Date
                ? Utils.formatDate(this.model.fecha_fin)
                : this.model.fecha_fin;

            if ( this.appName === 'fotoagricultura') {
                this.model.fin_fecha = this.model.fin_fecha instanceof Date
                    ? Utils.formatDate(this.model.fin_fecha)
                    : this.model.fin_fecha;
            }
            
            if (this.showTareasFitoHoras || this.timeStartStopTareas) {
                if (this.model.hora_inicio && moment(this.model.hora_inicio).isValid()) {
                    this.model.hora_inicio = moment(this.model.hora_inicio).format('HH:mm');
                }
                if (this.model.hora_fin && moment(this.model.hora_fin).isValid()) {
                    this.model.hora_fin = moment(this.model.hora_fin).format('HH:mm');
                }
            }

            if (this.appName === 'vginer') {
                this.model.dia_limpieza = this.model.dia_limpieza instanceof Date
                    ? Utils.formatDate(this.model.dia_limpieza)
                    : this.model.dia_limpieza;

                this.model.fecha_recoleccion = this.model.fecha_recoleccion instanceof Date
                    ? Utils.formatDate(this.model.fecha_recoleccion)
                    : this.model.fecha_recoleccion;

                this.model.dia_tratamiento = this.model.dia_tratamiento instanceof Date
                    ? Utils.formatDate(this.model.dia_tratamiento)
                    : this.model.dia_tratamiento;
            }

            if ( this.model.hora_inicio != null ){
                const horaInicio = moment(this.model.hora_inicio, 'HH:mm').format('HH:mm');
                this.model.hora_inicio = horaInicio;
                if (!this.model.hora_inicio.includes(':')) {
                    this.model.hora_inicio = null;
                }
            }
      
            if ( this.model.hora_fin != null ) {
                const horaFin = moment(this.model.hora_fin, 'HH:mm').format('HH:mm');
                this.model.hora_fin = horaFin;
                if (!this.model.hora_fin.includes(':')) {
                    this.model.hora_fin = null;
                }
            }

            this.model.cultivo = (this.form.parcelas.selected || []).map((it: { cultivo: any; }) => it.cultivo).join(';');
            this.model.variedad = (this.form.parcelas.selected || []).map((it: { variedad: any; }) => it.variedad).join(';');
            this.model.sup_cultivada = (this.form.parcelas.selected || []).map((it: { superficie_cultivada: any; }) => it.superficie_cultivada).join(';');
            if ( this.appName === 'amoros' ){
                this.model.litros = this.model.litros.replace(',', '.');
            }
            resolve(true);
        });
        
        this.formRequest.afterSend(resolve => {
            StorageManager.saveSessionDataDonJardin(this.model.id_finca, this.model.hora_inicio, this.model.hora_fin);
            resolve(true);
        });

        this.formRequest.afterSend(resolve => {
            if (this.appName === 'hernandorena') {
                this.tratamientosApi.tratamientosControl.GET.toPromise({
                    id_tarea: this.model.id
                }).then(control => {
                    if (control && control[0]) {
                        this.tratamientosApi.tratamientosControl.PUT.toPromise({
                            id: control[0].id,
                            estado: this.model.aplicado ? 'Aplicado' : 'Planificado'
                        }).then(() => {
                            resolve(true);
                        });
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });

        this.formRequest.afterFinish(resolve => {
            if (this.getType() === FormRequestTypes.DUPLICATE) {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
            resolve(true);
        });

    }

    private initFormData(): Promise<any> {
        this.loadingData = true;

        const buildsRequest = [
            build(this.clientesApi.clientesGET, this.form.clientes, { or: ['razon_social', 'nombre'] }),
            build(this.tratamientosApi.tratamientosList, this.form.tratamientos, 'nombre', true),
            build(this.fincasApi.fincasGET, this.form.fincas, 'nombre', true),
            build(this.fincasApi.sectoresGET, this.form.sectores, 'nombre', true),
            build(this.fincasApi.parcelasGET, this.form.parcelas, 'nombre', true),
            build(this.siexApi.justificacionesActuacionGET, this.form.justificaciones, 'justificacion'),
            build(this.trabajadoresApi.maquinariaGET, this.form.maquinarias, 'nombre', true),
            build(this.trabajadoresApi.trabajadoresGET, this.form.trabajadores,
                (it: { nombre: string; apellidos: any; maquinaria: any; }) => it.nombre + ' ' + (it.apellidos || '') + (it.maquinaria
                    ? ' - (' + (it.maquinaria || '...') + ')'
                    : ''),
                true, true),

        ];

        if (environment.features.showAssignUserTasksFitosanitario || environment.features.showAssignUserInAllTasks) {
            buildsRequest.push(build(this.usuariosApi.usuariosGET, this.form.usuarios, 'nombre'));
        }

        return pool( buildsRequest, () => {
           
            setTimeout(() => {
                this.formRequest.update();
                if ( this.form.fincas.filtered.length === 2  && this.getType() === 0 ){
                    this.model['id_finca'] = this.form.fincas.filtered[1].value.id;
                }
            }, 100);
        });
    }
}





export interface Datum {
    year: string;
    numero: string;
}

export interface RespReferencia {
    statusCode: number;
    statusMessage: string;
    data: Datum[];
}