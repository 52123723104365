import {Injectable} from '@angular/core';
import {RequestHandler} from '../../../app/service/OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FormCommonApiService {

    public data: RequestHandler = new RequestHandler();

    public cubas: RequestHandler = new RequestHandler();
    public tratamientos: RequestHandler = new RequestHandler();
    public productos: RequestHandler = new RequestHandler();
    public materiaActiva: RequestHandler = new RequestHandler();


    public magramaDetalleProducto: RequestHandler = new RequestHandler();
    public magramaProducto: RequestHandler = new RequestHandler();
    public magramaAll: RequestHandler = new RequestHandler();
    public mgUsos: RequestHandler = new RequestHandler();
    public mgAgentes: RequestHandler = new RequestHandler();
    public productosCodigo: RequestHandler = new RequestHandler();

    public variedadesGET: RequestHandler = new RequestHandler();
    public cultivosGET: RequestHandler = new RequestHandler();
    public cultivosPatronGET: RequestHandler = new RequestHandler(); 

    public historicoCheckGET: RequestHandler = new RequestHandler();
    public productosMasUsosGET: RequestHandler = new RequestHandler();

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {


        this.defineData();

        this.defineCubas();
        this.defineTratamientos();
        this.defineProductos();
        this.defineMateriaActiva();
        this.defineMagramaDetalleProducto();
        this.defineMagramaProducto();
        this.defineMagramaProductosCodigo();

        this.defineVariedadesGET();
        this.defineCultivosGET();
        this.defineCultivosPatronGET();        
        this.defineMagramaAll();
        this.defineMagramaUsos();
        this.defineMagramaAgentes();

        this.defineHistoricoCheckGET();
        this.defineProductosMasUsosGET();
    }

    private defineData() {
        this.data.fetch(HttpRequestType.GET, 'mg/_data');
        this.api.registerRequest(this.data);
        this.syncService.register(this.syncService.baseSyncPolicy(this.data));
    }

    private defineCubas() {
        this.cubas
            .fetch(HttpRequestType.GET, 'generic/cubas');

        this.api
            .registerRequest(this.cubas);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.cubas));
    }

    private defineTratamientos() {
        this.tratamientos
            .fetch(HttpRequestType.GET, 'generic/tratamientos');

        this.api
            .registerRequest(this.tratamientos);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.tratamientos));
    }

    private defineVariedadesGET() {
        this.variedadesGET
            .fetch(
                HttpRequestType.GET, 
                environment.appName !== 'fitogest' ? 'generic/visitas_variedad_mes' : 'visitas_variedad_mes/get_variedades'
            );

        this.api
            .registerRequest(this.variedadesGET);
    }

    private defineCultivosPatronGET(){
        this.cultivosPatronGET
            .fetch(HttpRequestType.GET, 'generic/cultivos_patrones');

        this.api
            .registerRequest(this.cultivosPatronGET);        
    }

    private defineCultivosGET() {
        this.cultivosGET
            .fetch(HttpRequestType.GET, 'generic/visitas_variedad_mes');

        this.api
            .registerRequest(this.cultivosGET);
    }

    private defineProductos() {
        this.productos
            .fetch(HttpRequestType.GET, 'generic/productos');

        this.api
            .registerRequest(this.productos);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.productos));
    }

    private defineMateriaActiva() {
        this.materiaActiva
            .fetch(HttpRequestType.GET, 'generic/materiaActiva');

        this.api
            .registerRequest(this.materiaActiva);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.materiaActiva));
    }

    private defineMagramaDetalleProducto() {
        this.magramaDetalleProducto
            .fetch(HttpRequestType.GET, 'mg/usos');

        this.api
            .registerRequest(this.magramaDetalleProducto);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.magramaDetalleProducto));
    }

    private defineMagramaProducto() {
        this.magramaProducto
            .fetch(HttpRequestType.GET, 'mg/productos');

        this.api
            .registerRequest(this.magramaProducto);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.magramaProducto));
    }

    private defineMagramaProductosCodigo() {
        this.productosCodigo
            .fetch(HttpRequestType.GET, 'mg/productos_codigo');

        this.api
            .registerRequest(this.productosCodigo);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.productosCodigo));
    }

    private defineMagramaAll() {
        this.magramaAll
            .fetch(HttpRequestType.POST, 'mg/all');

        this.api
            .registerRequest(this.magramaAll);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.magramaAll));
    }

    private defineMagramaUsos() {
        this.mgUsos
            .fetch(HttpRequestType.GET, 'mg/distinct_usos');

        this.api
            .registerRequest(this.mgUsos);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.mgUsos));
    }

    private defineMagramaAgentes() {
        this.mgAgentes
            .fetch(HttpRequestType.GET, 'mg/distinct_agente');

        this.api
            .registerRequest(this.mgAgentes);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.mgAgentes));
    }

    private defineHistoricoCheckGET() {
        this.historicoCheckGET.fetch(HttpRequestType.GET, 'productos/historico_check');
        this.api.registerRequest(this.historicoCheckGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.historicoCheckGET));
    }

    private defineProductosMasUsosGET() {
        this.productosMasUsosGET.fetch(HttpRequestType.GET, 'mg/listado_vademecum');
        this.api.registerRequest(this.productosMasUsosGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.productosMasUsosGET));
    }
}
