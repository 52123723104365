import { Component, ViewChild } from '@angular/core';
import { FincasApiService } from '../../../../../service/api/fincas-api.service';
import { AppFormRequest } from '../../../../app-common/form-request/app-form-request';
import { DashboardService } from '../../../../../service/events/dashboard.service';
import { FormCommonApiService } from '../../../../../service/api/formcommon-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseForm } from '../../../../base-form';
import {
    list,
    stack,
    build,
} from '../../../../../common/classes/request-builder';
import { environment } from '../../../../../../environments/environment';
import { ClientesApiService } from 'src/app/service/api/clientes-api.service';
import { StorageManager } from '../../../../../common/storage-manager.class';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { Utils } from 'src/app/common/utils';
import { FormRequestTypes } from 'src/app/common/classes/form-request';

import { features } from 'src/environments/features';
import { CRMApiService } from 'src/app/service/api/crm.service';
import { buildFieldsForms } from './fields/formfields';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-crm-form',
    templateUrl: './crm-form.component.html',
    styleUrls: ['./crm-form.component.scss'],
})
export class CrmFormComponent extends BaseForm implements OnInit {
    @ViewChild(RequestButtonComponent, { read: false, static: false })
        requestButton: RequestButtonComponent = new RequestButtonComponent();

    public appName = environment.appName;
    public clienteName = environment.features.clienteName;

    public enableFormChanges = true;

    public override model: any = {};
    public formRequest: AppFormRequest = new AppFormRequest();
    public type: FormRequestTypes = this.getType();
    public id_finca = '';

    // public analisisProximo: any;
    public programarSiguiente = false;

    public analisisImg: any;
    public serverUrl = environment.serverUrl;
    public showAnalisisSectorParcelas = features.showAnalisisSectorParcelas;

    public clienteActual = StorageManager.getClient();


    public formFields: ItemInterface<any>[];
    public formFieldsObs: ItemInterface<any>[] = [];

    public archivo: File = new File([], '');
    public clientes: TypeAheadInterface | TypeAheadInterface[] = list();
  

    constructor(
        public override route: ActivatedRoute,
        public override router: Router,
        public fincasApi: FincasApiService,
        public clientesApi: ClientesApiService,
        public formApi: FormCommonApiService,
        public override dashboard: DashboardService,
        public crmApiService: CRMApiService,
        public http: HttpClient
    ) {
        super(
            route,
            router,
            dashboard,
            crmApiService.CRM.PUT,
            crmApiService.CRM.POST,
            'crm',
            'Editar Tarea comercial',
            'Crear Tarea comercial'
        );
        this.formFields = buildFieldsForms(this.clientes);


    }

    ngOnInit() {
    /* this.fincasApi.sectoresGET.toPromise().then( (sectores:any) => {
      for (let index = 0; index < sectores.length; index++) {
        const element =  { value:sectores[index].id ,label: sectores[index].nombre };
        this.sectores.filtered.push(element);
        if (index + 1 >= sectores.length) {
          console.log(this.sectores);
        }
      }
    });
     */
        const fieldsToSend = (['archivo', 'id'] as any).concat(
            this.formFields.map((it) => it.field)
        );
  
        this.formRequest
            .setType(this.getType())
            .isGeneric(false)
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.crmApiService.CRM.GET)
            .setPostRequest(this.crmApiService.CRM.POST)
            .setPutRequest(this.crmApiService.CRM.PUT)
            .setFormFields(this.formFields)
            .setFieldsToSend(fieldsToSend);
        this.formRequest.load();
        this.softInit(this.getType());

        this.expandFormRequest();
        this.initFormData();
    }


    public async submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
            return;
        }

        if ( this.archivo != null ) {
            const formData: FormData = new FormData();
            formData.append('uploadFile', this.archivo);
            formData.append('folder', 'crm');
            const headers = new HttpHeaders();
            /** In Angular 5, including the header Content-Type can invalidate your request */
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');
            const adjunto: any = await this.http.post(environment.serverUrl + 'ws/upload/upload_adjunto_generic.php',
                formData).toPromise();

            this.model['archivo'] = adjunto.fileName; 
        }

        if (  this.formRequest.type === FormRequestTypes.EDIT ) {
            this.formRequest.send();
        } else {
            this.formRequest.send();
        }

    //this.model['fecha'] = moment(this.model['fecha']).format('YYYY-MM-DD');
    }

    public formChanges() {
   
  
    }

    public readUrl(input: { click: () => void; }) {
        input.click();
   
    }

    public async fileChange(event: any) {
        const fileList: FileList = event.target.files;
    
        if (fileList.length > 0) {
            this.archivo = fileList[0] || new File([], '');
            if ( this.archivo != null ) {
                const formData: FormData = new FormData();
                formData.append('uploadFile', this.archivo);
                formData.append('folder', 'crm');
                const headers = new HttpHeaders();
                /** In Angular 5, including the header Content-Type can invalidate your request */
                headers.append('Content-Type', 'multipart/form-data');
                headers.append('Accept', 'application/json');
                const adjunto: any = await this.http.post(environment.serverUrl + 'ws/upload/upload_adjunto_generic.php',
                    formData).toPromise();

                this.model['archivo'] = adjunto.fileName; 
            }
        }
    }

    public verArchivo() {
        const myWindow = window.open(
            'about:blank',
            '_blank',
            'width=600, height=600'
        );
        myWindow?.location.assign(
            environment.serverUrl + 'adjunto/crm/' + this.model.archivo
        );
        myWindow?.focus();
    }

    private expandFormRequest() {
    // NO CHANGE STACK BY POOL IN THAT CASE!!
    

        this.formRequest.beforeLoad(() => {
            // stack(builds).then(() => {
            //   this.formRequest.update();
            //   resolve(true);
            // });
        });

        this.formRequest.afterLoad((resolve) => {
   
            resolve(true);
        });

        this.formRequest.beforeSend((resolve) => {

            this.model.fecha =
        this.model.fecha instanceof Date
            ? Utils.formatDate(this.model.fecha)
            : this.model.fecha;

            this.model.fecha_proxima_accion_comercial =
            this.model.fecha_proxima_accion_comercial instanceof Date
                ? Utils.formatDate(this.model.fecha_proxima_accion_comercial)
                : this.model.fecha_proxima_accion_comercial;

            resolve(true);
        });
    }

    private initFormData() {
        const builds = [
            build(this.clientesApi.clientesGET, this.clientes,
                (it: { nombre: string; telefono: string}) => it.nombre + ' (Teléfono: ' + (it.telefono || '') + ')',
                false, false),
        ];

        stack(builds).then(() => {
      
            this.formRequest.update();
        });
    }
}

interface TypeAheadInterface {
    selected: any;
    values: any[];
    filtered: any[];
}
