import {Component} from '@angular/core';
import {BaseView} from '../../../base-view';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {Filtering} from '../../../../service/filtering/filtering';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {TareasApiService} from '../../../../service/api/tareas-api.service';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {Utils} from '../../../../common/utils';
import { environment } from '../../../../../environments/environment';


import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { CosteModel } from 'src/app/models/coste.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TratamientosModel } from 'src/app/models/tratamientos/tratamientos.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';

@Component({
    selector: 'app-costes-fertilizantes',
    templateUrl: './costes-fertilizantes.component.html',
    styleUrls: ['./costes-fertilizantes.component.scss']
})
export class CostesFertilizantesComponent extends BaseView implements OnInit, OnDestroy {
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public appName = environment.appName;
    public showCostesTareasFertiCsv = environment.features.showCostesTareasFertiCsv;


    public getRequest: RequestHandler = this.tareasApi.fertilizantes.GET;
    public deleteRequest: RequestHandler = this.tareasApi.fertilizantes.DELETE;
    public menuItemId = 'costes-fertilizantes';

    public override canShow: Record<string, boolean> = {};
    public override model: CosteModel = {};
    public ps = 0;

    public mostrarTratamientosAplicados = true;
    public override filtering: Filtering<CosteModel> = new Filtering<CosteModel>();

    public distributionOrder = [4];

    public formFields: ItemInterface<object>[] = [
        {
            field: 'finca', 
            label: this.fincaName, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'sectores_nombres', 
            label: this.sectoresName, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'parcelas_nombres', 
            label: this.parcelasName, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha_fin', 
            label: 'Fecha', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'tratamiento_nombre', 
            label: 'Tratamientos', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.appName !== 'ava'
        },
        {
            field: 'maquinaria', 
            label: 'Maquinaria', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.appName !== 'ava'
        },
        {
            field: 'litros', 
            label: 'Litros', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.appName !== 'ava'
        },
        {
            field: 'eficacia', 
            label: 'Eficacia', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.appName !== 'ava'
        },
        {
            field: 'aplicado', 
            label: 'Aplicado', 
            inputType: {type: InputType.RO_SWITCH}
        },
    ];

    public cols: ColInterface[] = [
        {field: 'finca', header: this.fincaName, filter: ''},
        {field: 'sectores_nombres', header: this.sectoresName, filter: ''},
        {field: 'parcelas_nombres', header: this.parcelasName, filter: ''},
        {field: 'tratamiento_nombre', header: 'Tratamientos', filter: '', width: '180px'},
        {field: 'maquinaria', header: 'Maquinaria', filter: '', visible: environment.appName !== 'ava'},
        {field: 'fecha_fin', header: 'Fecha', filter: ''},
        {field: 'litros', header: 'Litros', filter: '', visible: environment.appName !== 'ava'},
        {field: 'eficacia', header: 'Eficacia', filter: '', visible: environment.appName !== 'ava'},
        {field: 'coste', header: 'Costes', filter: '', footer: '0'}
    ];

    constructor(public tareasApi: TareasApiService,
        private dashboard: DashboardService) {
        super(dashboard, tareasApi.fertilizantes.GET, tareasApi.fertilizantes.DELETE);
    }

    ngOnInit() {
        this.getRequest.response(res => {
            console.log(res);
        });
        this.dashboard.setSubmenuItem(this.menuItemId);

        this.filtering.addFilter((data: CosteModel[]) => {
            this.updateFooter();
            return data.sort((a: CosteModel, b: CosteModel) =>
                parseInt(a.id ?? '', 10) > parseInt(b.id ?? '', 10) ? -1 : 1
            );
        });
    }

    public filtrarTratamientos() {
        this.filtering.filter();
    }

    public moneyFormat(input: number) {
        return Utils.decimalFormat(input, 2);
    }

    public cuantityFormat(input: string | MantenimientoModel[] | Record<string, TratamientosModel[]> | number | boolean | null) {
        return Utils.decimalFormat(input, 1);
    }

    public override sum(field: string) {
        let sum = 0;

        (this.filtering.value || []).forEach(it => {
            sum += it[field] as number;
        });

        this.cols.forEach(it => {
            if (it.field === field) {
                it['footer'] = sum + '';
            }
        });
    }

    public override updateFooter() {
        ['coste'].forEach(it => this.sum(it));
    }

    public globalConditionalStyle(value: string): ColStylesInterface {
        if (Utils.isNumber(value)) {
            return {
                textAlign: 'right',
            };
        }
        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface) {
        if (Utils.isNumber(value) && !['litros', 'sector'].includes(col.field)) {
            return Utils.decimalFormat(value, 2);
        } else if (Utils.isNumber(value) && col.field === 'litros') {
            return Utils.decimalFormat(value, 0, '.', ',');
        } else {
            return value;
        }
    }

    public calcularTratamiento( tratamiento: TratamientosModel[] ) {
        let suma = 0;
        if ( tratamiento == null || tratamiento.length === 0 ){
            return 0;
        } else {
            for (let index = 0; index < tratamiento.length; index++) {
                const precio = (tratamiento[index]?.precio ? tratamiento[index]?.precio : 0) as number;
                suma += ( precio * (this.model['dosis' + ( 1 + index ) ] as number) );
                if ( (index + 1) >= tratamiento.length ) {
                    return suma;
                }
            }
        }
        return 0;
    }
}