<br>
<p-panel>
  <div style="display: flex; max-width: 20%; position: absolute; right: 2rem;" *ngIf="showFotoTrajador">
    <input type="file" #adjuntarFoto (change)="fileChange($event)" style="display: none;" accept="image/*"/>
    <button *ngIf="model?.adjunto" class="btn btn-link" (click)="verAdjunto()">Ver</button>
    <input type="button" class="btn"
      style='border-radius: 5px;
        font-size: 1em;
        padding: 6px 12px;
        font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif; background-color: #27A745;color:white;'
      value="Añadir foto" (click)="readUrl(adjuntarFoto)" />
  </div>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
      <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
    </div>
  </div>
  <app-form-builder
          [model]="model"
          [undoEnabled]="undoEnabled"
          [distributionColumns]="3"
          [distributionOrder]="distributionOrder"
          [fields]="formFields"
          (formChanges)="formChanges($event)">
  </app-form-builder>
  <br>
  <div *ngIf="showPassErrors" class="p-2 small alert alert-danger" style="width: fit-content;">
    {{passErrors}}
  </div>
  <br>
  <div *ngIf="showTrabajadoresFirma" class="w-100 mb-4 d-flex justify-content-center">
    <div>
      <h6>Firma</h6>
      <div class="canvas-wrap">
          <button data-html2canvas-ignore="true" class="p-0 m-0 btn btn-link" (click)="clearSigPad(sp)">Borrar</button>
          <app-signature #sp (onEndEvent)="drawComplete(sp)"></app-signature>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            [lockOnSuccess]="true"
            (click)="submit()"
            [mode]="requestButtonType"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>
