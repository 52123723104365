import {AppFormRequest} from '../app-form-request';

export class AppFormRequestCreate {

    private form: AppFormRequest;

    constructor(form: AppFormRequest) {
        this.form = form;
        this.init();
    }

    private init() {
        
        this.form.onUpdate(resolve => {
            this.form.model['@_update_@'] = 0;

            setTimeout(() => {
                delete this.form.model['@_update_@'];
            }, 10);

            resolve(true);
        });

        this.form.beforeSend(resolve => {
            delete this.form.model.id;
            resolve(true);
        });

        this.form.beforeSend(resolve => {
            if (this.form.fieldsToSend) {
                for (const x in this.form.model) {
                    if (this.form.model.hasOwnProperty(x)) {
                        let founded = false;

                        this.form.fieldsToSend.forEach(field => {
                            if (field === x) {
                                founded = true;
                            }
                        });

                        if (!founded) {
                            delete this.form.model[x];
                        }
                    }
                }
            }

            resolve(true);
        });

        this.form.onSend(resolve => {
            this.form.requestPOST.safePerform(this.form.model);
            this.form.requestPOST.response(() => {
                resolve(true);
            });
        });

        this.form.afterSend(resolve => {
            if (!this.form.requestPOST.hasError) {
                for (const x in this.form.model) {
                    if (this.form.model.hasOwnProperty(x) && !this.form.fieldsToRetain.includes(x)) {
                        delete this.form.model[x];
                    }
                }

                (this.form.formFields || []).forEach(field => {
                    if (!this.form.fieldsToRetain.includes(field.field ?? '')) {
                        (field.values || {}).selected = null;
                    }
                });
            }

            resolve(true);
        });

        this.form.onFinish(resolve => {
            setTimeout(() => {
                this.form.requestPOST.unsuscribe();
            }, 1000);
            resolve(true);
        });

    }
}
