import { InputType } from '../../../../../../common/components/form-builder/form-builder.component';
import { environment } from '../../../../../../../environments/environment';
import { list } from '../../../../../../common/classes/request-builder';
import { StorageManager } from '../../../../../../common/storage-manager.class';

import type { ItemInterface } from '../../../../../../common/components/form-builder/form-builder.component';

export class OtrasFormDefinition {

    public hasClients = environment.features.hasClients;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public showTareasGenericasObservaciones = environment.features.showTareasGenericasObservaciones;
    public showTareasOtrosDuracion = environment.features.showTareasOtrosDuracion;
    public showTareasOtrasProducto = environment.features.showTareasOtrasProducto;
    public showTareasOtrasCantidad = environment.features.showTareasOtrasCantidad;
    public showTareasOtrosMultiple = environment.features.showTareasOtrosMultiple;
    public showTareasProcura = environment.features.showTareasProcura;
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public serveUrl = environment.serverUrl;

    public timeStartStopTareas = environment.features.timeStartStopTareas;

    public requestType: any;

    public clientes = list();
    public fincas = list();
    public sectores = list();
    public parcelas = list();
    public users  = list();
    public maquinarias = list();
    public trabajadores = list();
    public tareas = list();
    public productos = list(['...', null]);
    public estadoTarea = list(['...', null], 'Orden de Trabajo', 'Parte de Trabajo');

    // Para los usuarios de user debes especificar la horas de inicio y fin para el cliente AVA
    public activateRequireHourForRolUser = this.appName === 'ava' ? StorageManager.getUser().rol !== 'admin' ? true : false : false;

    public actividad_agraria = list(['...', null],
        'Producción en Tierras de Cultivo y Cultivos Permanentes',
        'Mantenimiento',
        'Pastoreo',
        'Siega Producción',
        'Sin actividad agraria por razones medioambientales');

    public tareas_maquinaria = list(['...', null],
        'Abonar',
        'Fangueo',
        'Hacer Márgenes',
        'Trailla',
        'Láser',
        'Cultivador',
        'Rotovator',
        'Multiapero',
        'Paquete de trabajo 1',
        'Paquete de trabajo 2');

    public tareas_manuales = list(['...', null],
        'Abrir Boqueras',
        'Bordoma',
        'Transplantar',
        'Escarda',
        'Desbroce Cursos de Agua',
        'Siega Orillas',
        'Quemar Paja',
    );

    public formFields: ItemInterface<any>[] = [
        {
            field: 'id_finca',
            label: this.fincaName,
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id',
            filter: (it: { value: { id_cliente: any; }; }) => {
                if (this.clientes.selected) {
                    return it.value.id_cliente === (this.clientes.selected || {}).id;
                } else {
                    return true;
                }
            },
            required: true
        },
        {
            field: 'id_sector',
            label: this.sectorName,
            inputType: { type: this.showTareasOtrosMultiple ? InputType.DROPDOWN_MULTISELECT_SEARCH : InputType.DROPDOWN_SEARCH },
            multiSelect: this.showTareasOtrosMultiple,
            canSelectAll: this.showTareasOtrosMultiple,
            values: this.sectores,
            valuePrimaryKey: 'id',
            filter: (it: { value: { id_finca: any; }; }) => it.value.id_finca === (this.fincas.selected || {}).id
        },
        {
            field: 'ids_parcelas',
            label: this.parcelaName,
            inputType: { type: InputType.DROPDOWN_MULTISELECT_SEARCH },
            values: this.parcelas,
            valuePrimaryKey: 'id',
            multiSelect: true,
            canSelectAll: true,
            filter: (it: { value: { activo: string; id_sector: any; id_finca: any; }; }) => {
                if (it.value.activo !== '1' && this.appName !== 'ava') {
                    return false;
                }
                if (this.sectores.selected !== undefined && this.sectores.selected.length > 0) {
                    if (this.showTareasOtrosMultiple) {
                        return this.sectores.selected.map((sector: { id: any; }) => sector.id).includes(it.value.id_sector);
                    }
                    return it.value.id_sector === (this.sectores.selected || {}).id;
                } else {
                    return it.value.id_finca === (this.fincas.selected || {}).id;
                }
            },
            required: this.appName !== 'pyf'
        },
        { field: 'fecha', label: 'Fecha', inputType: { type: InputType.CALENDAR }, required: this.appName !== 'ava' },
        { field: 'fecha_fin', label: 'Fecha Fin', inputType: { type: InputType.CALENDAR }, visible: this.appName === 'fotoagricultura' },
        {
            field: 'hora_inicio',
            label: 'Hora Inicio',
            inputType: { type: InputType.TIME },
            visible: this.timeStartStopTareas,
            required: this.activateRequireHourForRolUser
        },
        {
            field: 'hora_fin',
            label: 'Hora Fin',
            inputType: { type: InputType.TIME },
            visible: this.timeStartStopTareas,
            required: this.activateRequireHourForRolUser
        },
        {
            field: 'horas_totales', 
            label: 'Horas', 
            inputType: {type: InputType.ONLY_TEXT}, visible: environment.features.showTareasMto || environment.features.tareasHorasTotales 
        },
        {
            field: 'id_tarea',
            label: this.esViveroOGardenGest() ? 'Genéricas' : 'Tareas',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: this.tareas,
            valuePrimaryKey: 'id',
            visible: this.appName !== 'sequiador' && this.appName !== 'SIEX'
        },
        {
            field: 'id_producto',
            label: 'Producto',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: this.productos,
            valuePrimaryKey: 'id',
            visible: this.showTareasOtrasProducto
        },
        {
            field: 'cantidad',
            label: 'Cantidad',
            inputType: { type: InputType.EDIT_NUMERIC },
            visible: this.showTareasOtrasCantidad
        },
        {
            field: 'num_albaran',
            label: 'Nº albarán',
            inputType: { type: InputType.EDIT_TEXT },
            visible: environment.appName === 'pyf'
        },
        {
            field: 'apero',
            label: 'Apero utilizado',
            inputType: { type: InputType.EDIT_TEXT },
            visible: environment.appName === 'pyf'
        },
        {
            field: 'pastoreo',
            label: 'Pastoreo',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: list(['...', null], 'Si', 'No'),
            visible: environment.appName === 'pyf'
        },
        {
            field: 'procura',
            label: 'Procura',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: list(['...', null], 'Procura', 'Media procura', 'Libre'),
            visible: this.showTareasProcura
        },
        {
            field: 'obs',
            label: 'Observaciones',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: list(['...', null], 'Total', 'Parcial', 'Por bandas'),
            visible: environment.appName === 'pyf'
        },
        {
            field: 'ids_trabajadores',
            label: 'Trabajadores',
            inputType: { type: InputType.DROPDOWN_MULTISELECT_SEARCH },
            multiSelect: true,
            values: this.trabajadores,
            valuePrimaryKey: 'id',
            filter: (it: { value: { activo: string; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                return true;
            }
        },
        {
            field: 'ids_asignados',
            label: 'Asignador',
            inputType: {type: InputType.MULTISELECT},
            multiSelect: true,
            canSelectAll: true,
            valuePrimaryKey: 'id',
            values: this.users,
            visible: (environment.features.showAssignUserTasksFertilizante || environment.features.showAssignUserInAllTasks) && 
                StorageManager.getUser().rol === 'admin',
            required: true
        },
        {
            field: 'id_maquinaria',
            label: 'Maquinaria',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: this.maquinarias,
            valuePrimaryKey: 'id',
            filter: (it: { value: { activo: string; id_cliente: any; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                if (this.appName === 'pyf') {
                    return it.value.id_cliente === StorageManager.getClient().id;
                }
                return true;
            }
        },
        { 
            field: 'horas', label: 'Horas', 
            inputType: { 
                type: (this.appName === 'fotoagricultura' || this.appName === 'agropedbel') ? InputType.EDIT_TEXT : InputType.TIME
            }, 
            visible: !this.timeStartStopTareas 
        },
        {
            field: 'estado_tarea', 
            label: 'Estado de la Tarea', 
            inputType: {type: InputType.DROPDOWN},
            values: this.estadoTarea,
            visible: this.appName === 'onda' || this.appName === 'lacooperativa'
        },
        {
            field: 'empresa_pagadora', 
            label: 'Empresa Pagadora', 
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName === 'onda' || this.appName === 'lacooperativa'
        },
        {
            field: 'actividad_agraria',
            label: 'Actividad Agraria',
            inputType: { type: InputType.DROPDOWN },
            values: this.actividad_agraria,
            visible: this.appName === 'sequiador' || this.appName === 'SIEX'
        },
        {
            field: 'tareas_maquinaria',
            label: 'Tareas Maquinaria',
            inputType: { type: InputType.DROPDOWN },
            values: this.tareas_maquinaria,
            visible: this.appName === 'sequiador' || this.appName === 'SIEX'
        },
        {
            field: 'tareas_manuales',
            label: 'Tareas Manuales',
            inputType: { type: InputType.DROPDOWN },
            values: this.tareas_manuales,
            visible: this.appName === 'sequiador' || this.appName === 'SIEX'
        },
        {
            field: 'prioridad',
            label: 'Prioridad',
            inputType: { type: InputType.DROPDOWN},
            values: list(['...', null], 'Alta', 'Media', 'Baja'),
            visible: this.appName === 'ava'
        },
        {
            field: 'aplicado',
            label: 'Aplicado',
            inputType: { type: InputType.DROPDOWN},
            values: list(['...', null], 'Si', 'No'),
            visible: this.appName === 'ava'
        }
    ];

    public FormFieldsObs: ItemInterface <any>[] = [
        {
            field: 'observaciones',
            label: 'Observaciones',
            inputType: { type: InputType.EDIT_TEXTAREA },
            visible: this.showTareasGenericasObservaciones
        },
    ];

    public esViveroOGardenGest() {
        if (this.serveUrl.indexOf('gardengest') !== -1 || this.serveUrl.indexOf('viverogest') !== -1 ) {
            return true;
        }else {
            return false;
        }
    }
}


