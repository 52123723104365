import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';

@Injectable({
    providedIn: 'root'
})
export class CitasApiService {

    public citas = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };


    public visitasSemanaGET = new RequestHandler();


    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineCitas();
        this.defineVisitasSemanaGET();

    }

    private defineCitas() {
        this.citas.GET.fetch(HttpRequestType.GET, 'citas/get');
        this.citas.POST.fetch(HttpRequestType.POST, 'generic/citas');
        this.citas.PUT.fetch(HttpRequestType.PUT, 'generic/citas');
        this.citas.DELETE.fetch(HttpRequestType.DELETE, 'generic/citas');

        this.api.registerRequest(this.citas.GET);
        this.api.registerRequest(this.citas.POST);
        this.api.registerRequest(this.citas.PUT);
        this.api.registerRequest(this.citas.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.citas.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.citas.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.citas.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.citas.DELETE));
    }

    private defineVisitasSemanaGET() {
        this.visitasSemanaGET.fetch(HttpRequestType.GET, 'visitas/visitas_semana_get');
        this.api.registerRequest(this.visitasSemanaGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasSemanaGET));
    }
}
