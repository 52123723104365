import {TareasApiService} from '../../../../../service/api/tareas-api.service';
import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { environment } from 'src/environments/environment';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { build, list, pool } from 'src/app/common/classes/request-builder';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { StorageManager } from 'src/app/common/storage-manager.class';
import { Utils } from 'src/app/common/utils';
import moment from 'moment';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-agroambientales-form',
    templateUrl: './agroambientales-form.component.html',
    styleUrls: ['./agroambientales-form.component.css']
})
export class AgroambientalesFormComponent extends BaseForm implements OnInit {
    @ViewChild(RequestButtonComponent)
    requestButton: RequestButtonComponent = new RequestButtonComponent();

    public formRequest: AppFormRequest = new AppFormRequest();

    public rol = StorageManager.getUser().rol;
    
    public appName = environment.appName;
    public hasClients = environment.features.hasClients;
    public showTareasFitoHoras = environment.features.showTareasFitoHoras;
    public tareasHorasTotales = environment.features.tareasHorasTotales;

    public userCanSeeClientes = environment.features.userCanSeeClientes
        ? true
        : (this.rol === 'admin');
    public hasFincas = environment.features.hasFincas;

    public override model: any = {};
    public tratamientosList = list(['...', null]);
    public unidadList = list(['...', null]);

    public listFincas = list(['...', null]);
    public listSectores = list(['...', null]);
    public listParcelas = list(['...', null]);

    public formFields: ItemInterface<any>[] = [
        { 
            field: 'id_finca', 
            label: 'Finca', 
            inputType: {type: InputType.DROPDOWN_SEARCH }, 
            values: this.listFincas,
            valuePrimaryKey: 'id',
            required: true,
     
        },
        { 
            field: 'id_sector', 
            label: 'Sector', 
            inputType: {type: InputType.MULTISELECT }, 
            values: this.listSectores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: { value: { id_finca: any; }; }) => {
                if (this.listFincas.selected) {
                    return it.value.id_finca === (this.listFincas.selected || {} as any).id;
                } else {
                    return false;
                }
            },
        },
        { 
            field: 'id_parcelas', 
            label: 'Parcela', 
            inputType: {type: InputType.MULTISELECT }, 
            multiSelect: true,
            canSelectAll: true,
            values: this.listParcelas,
            valuePrimaryKey: 'id',
            filter: (it: { value: { activo: string; id_sector: any; id_finca: any; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                if (this.listSectores.selected) {
                    return this.listSectores.selected.map((sector: { id: any; }) => sector.id).includes(it.value.id_sector);        
                } else if (this.listFincas.selected) {
                    return it.value.id_finca === (this.listFincas.selected || {} as any).id;
                }
            },
            required: true
        },
        { field: 'fecha_entrada', label: 'Fecha Entrada Agua', inputType: {type: InputType.CALENDAR}},
        { field: 'fecha_salida', label: 'Fecha Salida Agua', inputType: {type: InputType.CALENDAR}},
        {
            field: 'fecha_seca_cultivo_agua',
            label: 'Fecha Inicio Inundación Invernal',
            inputType: {type: InputType.CALENDAR}
        },
        {
            field: 'fecha_fin_inundacion_invernal',
            label: 'Fecha Fin Inundación Invernal',
            inputType: {type: InputType.CALENDAR},
      
        },
        {
            field: 'fecha_seca_arroz', 
            label: 'Fecha Seca Arroz', 
            inputType: {type: InputType.CALENDAR }
        },
    ];

    public formFieldsRastrojo: ItemInterface<any>[] = [
        { field: 'fecha_recoleccion_rastrojo', label: 'Fecha Recolección', inputType: {type: InputType.CALENDAR}},
        { 
            field: 'operacion_paja', 
            label: 'Operación Paja', 
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], 'Secado', 'Quema', 'Empaca', 'Enterrado', 'Retirada', 'Otros')
        },
        { field: 'fecha_operacion', label: 'Fecha Operación Paja', inputType: {type: InputType.CALENDAR}},
        { field: 'hora_inicio', label: 'Hora Inicio Quema Paja', inputType: {type: InputType.TIME}, visible: this.showTareasFitoHoras},
        { field: 'hora_fin', label: 'Hora Fin Quema Paja', inputType: {type: InputType.TIME}, visible: this.showTareasFitoHoras},
        {
            field: 'horas_totales', 
            label: 'Horas', 
            inputType: {type: InputType.ONLY_TEXT}, 
            visible:  this.appName === 'agroleza'
        },




    ];
    requestType: FormRequestTypes = 0;


    constructor(public override dashboard: DashboardService,                 
        public tareasApi: TareasApiService,
        public override route: ActivatedRoute,
        public override router: Router,
        public fincasApi: FincasApiService,
    ) { 
        super(
            route,
            router,
            dashboard,
            tareasApi.agroambientales.PUT,
            tareasApi.agroambientales.POST,
            'tareas-agroambientales',
            'Editar Tarea Agroambientales',
            'Realizar Tarea Agroambiental',
            'Duplicar tareas Agroambientales'
        ); 
    }

    ngOnInit() {
        this.initFormData();
        this.expandFormRequest();

        this.formRequest
            .setType(this.getType())
            .setModel(this.model)
            .setRegisterId(this.getRegisterId())
            .setGetRequest(this.tareasApi.agroambientales.GET)
            .setPostRequest(this.tareasApi.agroambientales.POST)
            .setPutRequest(this.tareasApi.agroambientales.PUT)
            .setFormFields(Array.prototype.concat(this.formFields))
            .setFieldsToSend(
                (['id'] as any)
                    .concat(
                        this.formFields.map(it => it.field)
                    ).concat(
                        this.formFieldsRastrojo.map(it => it.field)
                    )
            );

        this.formRequest.load();
        this.softInit(this.getType());
    }

  
    ngAfterView(){
    
    }

    formChanges(tag: string){
        if (tag === 'aplica_fertirrigacion'){
            if (!this.model.aplica_fertirrigacion) {
                this.model.dosis_n_nitrico = '';
                this.model.dosis_p = '';
                this.model.fecha = null;
            }
        }
        if ( environment.features.tareasHorasTotales){
            if (this.model['hora_inicio'] != null && this.model['hora_fin'] != null ) {        
                let horaFin = moment(this.model.hora_fin).format('HH:mm');
                let horaInicio = moment(this.model.hora_inicio).format('HH:mm');
                const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
                if (!timeFormat.test(horaInicio)) {
                    horaInicio = this.model.hora_inicio;
                }
  
                if (!timeFormat.test(horaFin)) {
                    horaFin = this.model.hora_fin;
                }
          
                const diferencia = this.formatoHorasMinutos(horaInicio, horaFin);
          
                this.model['horas_totales'] = diferencia;    
                console.log( this.model['horas_totales']);
                
            } else {
                this.model['horas_totales'] = '';
            }
        }
    
    
    }
    formatoHorasMinutos(horaEntrada: string, horaSalida: string) {    
        let timeStr: any = horaEntrada;
        timeStr = timeStr.split(':');
  
        const h = timeStr[0],
            m = timeStr[1];
  
        const newTime = moment( horaSalida + ' 2021-4-5')
            .subtract({'hours': h, 'minutes': m})
            .format('HH:mm');
  

        return newTime;
   
    }

    initFormData(){
        return pool([
            build(this.fincasApi.fincasGET, this.listFincas, 'nombre'),
            build(this.fincasApi.sectoresGET, this.listSectores, 'nombre', true),
            build(this.fincasApi.parcelasGET, this.listParcelas, 'nombre', true),
        ], () => {
            this.formRequest.update();
        });
    }

    submit(){
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';   
            console.log('Error');
            return;
        }
    
                
        if ((this.model.fecha_inicio != null)) {
            this.model.fecha_inicio = moment(this.model.fecha_inicio).format('DD/MM/YYYY');
        }
        if ((this.model.fecha_fin_inundacion_invernal != null)) {
            this.model.fecha_fin_inundacion_invernal = moment(this.model.fecha_fin_inundacion_invernal).format('DD/MM/YYYY');
        }
        if ((this.model.fecha_seca_cultivo_agua != null)) {
            this.model.fecha_seca_cultivo_agua = moment(this.model.fecha_seca_cultivo_agua).format('DD/MM/YYYY');
        }
        // La forma antigua para rellerafecha cuando este vacia
        // this.model.fecha_exp = moment(this.model.fecha_exp || new Date()).format('DD/MM/YYYY'); 
        this.formRequest.send();
    }  

    public getObservaciones( event: any ) {
        this.model.observaciones = event; 
    }

    private expandFormRequest() {

        this.formRequest.beforeLoad(resolve => {
            this.initFormData().then(() => {
                resolve(true);
            });
        });

        this.formRequest.beforeSend(resolve => {
            this.model['fecha_entrada'] = this.model['fecha_entrada'] instanceof Date
                ? Utils.formatDate(this.model['fecha_entrada'])
                : this.model['fecha_entrada'];

            this.model['fecha_salida'] = this.model['fecha_salida'] instanceof Date
                ? Utils.formatDate(this.model['fecha_salida'])
                : this.model['fecha_salida'];

            this.model['fecha_seca_cultivo_agua'] = this.model['fecha_seca_cultivo_agua'] instanceof Date
                ? Utils.formatDate(this.model['fecha_seca_cultivo_agua'])
                : this.model['fecha_seca_cultivo_agua'];

            this.model['fecha_seca_arroz'] = this.model['fecha_seca_arroz'] instanceof Date
                ? Utils.formatDate(this.model['fecha_seca_arroz'])
                : this.model['fecha_seca_arroz'];

            this.model['fecha_recoleccion_rastrojo'] = this.model['fecha_recoleccion_rastrojo'] instanceof Date
                ? Utils.formatDate(this.model['fecha_recoleccion_rastrojo'])
                : this.model['fecha_recoleccion_rastrojo'];
        
            this.model['fecha_operacion'] = this.model['fecha_operacion'] instanceof Date
                ? Utils.formatDate(this.model['fecha_operacion'])
                : this.model['fecha_operacion'];

            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() || {} as any).id;
            const userIdFromForm = this.model['id_usuario'];

            this.model.id_usuario =
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';  
            
        
            /**
     * Actualiza las horas de inicio y fin en el modelo si la entrada es válida.
     * Este fragmento de código verifica y formatea las horas de inicio y fin del objeto 'model' en formato 'HH:mm'.
     * 
     * @param {string} this.model.hora_inicio - Hora de inicio a verificar y formatear.
     * @param {string} this.model.hora_fin - Hora de fin a verificar y formatear.
     */

            if (this.showTareasFitoHoras) {
                if (this.model.hora_inicio && moment(this.model.hora_inicio).isValid()) {
                    this.model.hora_inicio = moment(this.model.hora_inicio).format('HH:mm');
                }
                if (this.model.hora_fin && moment(this.model.hora_fin).isValid()) {
                    this.model.hora_fin = moment(this.model.hora_fin).format('HH:mm');
                }
            }
            resolve(true);
        });

        this.formRequest.afterFinish(resolve => {
            if (this.getType() === FormRequestTypes.DUPLICATE) {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
            resolve(true);
        });

    }  
}
