import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ReferenciaCatastralervice {
    public url = 'https://locatec.es/proyectos/fitogest/ws/';
    constructor(private http: HttpClient) {

    }

    public getReferenciaCatastral( longitude: string, latitude: string ) {
        return this.http.get( this.url + 'catastro/findReferenciaCatastral.php?latitud=' + latitude + '&longitud=' + longitude);
    }
}