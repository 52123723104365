import {Injectable} from '@angular/core';
import {SyncPolicy} from './sync-policy';
import {RequestHandler} from './request-handler';

@Injectable({
    providedIn: 'root'
})
export class SyncService {
    private policies: SyncPolicy[] = [];

    constructor() {
        window.addEventListener('online', () => {
            this.policies.forEach(c => c.emitOnlineBack());
        });
    }

    public register(policy: SyncPolicy) {
        policy.emitCustomPolicy();
        policy.emitLoad();
        this.policies.push(policy);
    }

    public baseSyncPolicy(req: RequestHandler) {
        return new SyncPolicy(req)
            .onLoad(() => {
                //if (request.requestType === HttpRequestType.GET) {
                //    request.safePerform();
                //}
            })
            .onOnlineBack(() => {
                //request.safePerform();
            })
            .setCustomPolicy(() => {
            });
    }
}


