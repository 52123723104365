import { HttpClient } from '@angular/common/http';
import { TrabajadoresApiService } from './../../../../../service/api/trabajadores-api.service';
import { TareasApiService } from '../../../../../service/api/tareas-api.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { pool, buildParams, build } from 'src/app/common/classes/request-builder';
import { ClientesApiService } from 'src/app/service/api/clientes-api.service';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { Utils } from 'src/app/common/utils';
import { environment } from 'src/environments/environment';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import moment from 'moment';
import { TareasMtoRiegoFormDefinition } from './form-control/form-definition';
import { firstValueFrom } from 'rxjs';

import type { OnDestroy, OnInit } from '@angular/core';

const TRUTHY_VALUES = [1, '1', true, 'true'];

@Component({
    selector: 'app-tareas-mto-riego-form',
    templateUrl: './tareas-mto-riego-form.component.html',
    styleUrls: ['./tareas-mto-riego-form.component.scss']
})
export class TareasMtoRiegoFormComponent extends BaseForm implements OnInit, OnDestroy {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
    requestButton: RequestButtonComponent = new RequestButtonComponent();

    public hasClients = environment.features.hasClients;
    public serverUrl = environment.serverUrl;
    public clienteName = environment.features.clienteName;
    public appName = environment.appName;

    public form: TareasMtoRiegoFormDefinition = new TareasMtoRiegoFormDefinition();
    public formRequest: AppFormRequest = new AppFormRequest();
    public override model: any = {};  
    public creacion = false;
    public signatureFile: File = new File([], '');

    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public fincasApi: FincasApiService,
        public clientesApi: ClientesApiService,
        public tareasApi: TareasApiService,
        public trabajadoresApi: TrabajadoresApiService,
        public override router: Router,
        private http: HttpClient
    ) {
        super(
            route,
            router,
            dashboard,
            tareasApi.mtoRiego.PUT,
            tareasApi.mtoRiego.POST,
            'riego',
            'Actualizar Tarea Riego',
            'Realizar Tarea Riego',
            'Duplicar Tarea Riego'
        );
    }

    ngOnInit() {
        this.initFormData();
        this.expandFormRequest();



        const fieldsToSend = (['id', 'id_usuario', 'imagen', 'image_after', 'horas_totales', 'signature_img'] as any).concat(
            this.form.formFields.map(it => it.field),
            this.form.formFieldsHorarioRiego.map(it => it.field),
            this.form.formFieldsHoraRiego.map(it => it.field),
            this.form.formFieldsSectores.map(it => it.field),
            this.form.formFieldsAspersores.map(it => it.field),
            this.form.formFieldsDifusores.map(it => it.field),
            this.form.formFieldsArqueta.map(it => it.field),
            this.form.formFieldsArquetas.map(it => it.field),
            this.form.formFieldsElectrovalvulas9vLatch.map(it => it.field),
            this.form.formFieldsElectrovalvulas24v.map(it => it.field),
            this.form.formFieldsSolenoides.map(it => it.field),
            this.form.formFieldsProgramadorPilas.map(it => it.field),
            this.form.formFieldsProgramador220v.map(it => it.field),
            this.form.formFieldsCajaConexion.map(it => it.field),
            this.form.formFieldsTuberiaGoteo.map(it => it.field),
            this.form.formFieldsTuberiaPE01.map(it => it.field),
            this.form.formFieldsTuberiaPE02.map(it => it.field),
            this.form.formFieldsTuberiaPVC.map(it => it.field),
            this.form.formFieldsRiegoManguera.map(it => it.field),
            this.form.formFieldsTrabajadores.map(it => it.field),
            this.form.formFieldsOrdenTrabajo.map(it => it.field),
            this.form.formFieldsObs.map(it => it.field)
        );

        if (this.hasClients) {
            fieldsToSend.push('id_cliente');
        }

        if (this.appName === 'donjardin') {
            fieldsToSend.push('id_ref_usuario');
        }

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.tareasApi.mtoRiego.GET)
            .setPostRequest(this.tareasApi.mtoRiego.POST)
            .setPutRequest(this.tareasApi.mtoRiego.PUT)    
            .setFormFields(this.form.formFields.concat(this.form.formFieldsHorarioRiego)
                .concat(this.form.formFieldsHoraRiego)
                .concat(this.form.formFieldsSectores)
                .concat(this.form.formFieldsAspersores)
                .concat(this.form.formFieldsDifusores)
                .concat(this.form.formFieldsArqueta)
                .concat(this.form.formFieldsArquetas)
                .concat(this.form.formFieldsElectrovalvulas9vLatch)
                .concat(this.form.formFieldsElectrovalvulas24v)
                .concat(this.form.formFieldsSolenoides)
                .concat(this.form.formFieldsProgramadorPilas)
                .concat(this.form.formFieldsProgramador220v)
                .concat(this.form.formFieldsCajaConexion)
                .concat(this.form.formFieldsTuberiaGoteo)
                .concat(this.form.formFieldsTuberiaLisa)
                .concat(this.form.formFieldsPilas)
                .concat(this.form.formFieldsTuberiaPE01)
                .concat(this.form.formFieldsTuberiaPE02)
                .concat(this.form.formFieldsTuberiaPVC)
                .concat(this.form.formFieldsRiegoManguera)
                .concat(this.form.formFieldsTrabajadores)
                .concat(this.form.formFieldsOrdenTrabajo)
                .concat(this.form.formFieldsObs)
            ).setFieldsToSend(fieldsToSend);

        this.formRequest.load();
        
        this.softInit(this.getType());       
        // Añadir fecha actual solo cuando se crea el registro 
        if ( this.getType() === FormRequestTypes.CREATE ){
            this.model['fecha'] = new Date();
        }
        this.tareasApi.mtoRiego.GET.response( resp => {
      
          
            if ( TRUTHY_VALUES.includes(resp[0]['mantenimiento'] )  ){
                this.form.formFields.forEach( it => {
                    if ( it.field === 'mantenimiento' ) {
                        it.label = 'Creación';
             
                    }
                });
            } else {
                this.form.formFields.forEach( it => {
                    if ( it.field === 'mantenimiento' ) {
                        it.label = 'Mantenimiento';
              
                    }
                });
            }
        });
    }

    updateFirma(event: any) {
        this.model['signature_img'] = event;  
        this.formRequest.update();
    }

    getSignature( event: File ) {
        this.signatureFile = event;
    }

    public formChanges(tag: string) {
        if (environment.features.showTareasMto){
            if (this.model['hora_inicio'] != null && this.model['hora_fin'] != null ) {
 
                let horaFin = moment(this.model.hora_fin).format('HH:mm');
                let horaInicio = moment(this.model.hora_inicio).format('HH:mm');
                const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
                if (!timeFormat.test(horaInicio)) {
                    horaInicio = this.model.hora_inicio;
                }

                if (!timeFormat.test(horaFin)) {
                    horaFin = this.model.hora_fin;
                }

                const diferencia = this.formatoHorasMinutos(horaInicio, horaFin);
                this.model['horas_totales'] = diferencia;
   
            } else {
                this.model['horas_totales'] = '';
            }
      
        }
        if (tag === 'mantenimiento')
        {

            if ( TRUTHY_VALUES.includes(this.model[tag] )  ){
                this.form.formFields.forEach( it => {
                    if ( it.field === 'mantenimiento' ) {
                        it.label = 'Creación';
                    }
                });
            } else {
                this.form.formFields.forEach( it => {
                    if ( it.field === 'mantenimiento' ) {
                        it.label = 'Mantenimiento';
                    }
                });
            }
            this.creacion = !this.creacion;
        }
        if (tag === 'id_cliente') {
            this.model.id_finca = null;
            pool([
                buildParams(this.fincasApi.fincasGET, {id_cliente: this.model.id_cliente}, this.form.fincas,
                    'nombre', true),
            ], () => {
                this.form.fincas.filtered = this.form.fincas.values;
           
                this.formRequest.update();
            }).then(() => {
                this.formRequest.update();
            });
        
        }
    }
  
    public async submit() {
    // this.requestButton.error = '';
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
            return;
        }
 
        const horaFin = moment(this.model.hora_fin, 'HH:mm');
        const horaInicio = moment(this.model.hora_inicio, 'H:mm');
        const diferenciaEntreHoras = horaFin.diff(horaInicio);
        if (diferenciaEntreHoras < 0){
            this.requestButton.error = 'La hora de inicio es mayor que la de fin.';
            return;
        }

        if ( this.signatureFile != null ) {
            const formData = new FormData();
            formData.append('fileToUpload', this.signatureFile);
            formData.append('database', 'tareas_mto_riego');
            formData.append('folder', 'mto_riego');
            formData.append('field', 'signature_img');
            const signature: any = await firstValueFrom(this.http.post( this.serverUrl + 'ws/tareas/fileUploadGeneric.php', formData));
            this.model['signature_img'] = (signature.target_file as string).replace('../../images/mto_riego/', '');
        }


        this.formRequest.send();

        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    getObservaciones( event: any ) {
        this.model.observaciones = event;
    }

    updateImageModel( event: string  ){
        this.model['imagen'] = event;
    }

    updateAfterImageModel( event: string  ){
        this.model['imagen_after'] = event;
    }

    formatoHorasMinutos(horaEntrada: string, horaSalida: string) {    
        let timeStr: any = horaEntrada;
        timeStr = timeStr.split(':');

        const h = timeStr[0],
            m = timeStr[1];

        const newTime = moment( horaSalida + ' 2021-4-5')
            .subtract({'hours': h, 'minutes': m})
            .format('HH:mm');


        // console.log({str});
        return newTime;
   
    }
 
    public getOrdenTrabajo( event: any ) {
        this.model.orden_trabajo = event;
    }


    private initFormData(): Promise<any> {
        return pool([
            build(this.fincasApi.fincasGET, this.form.fincas, 'nombre', true),
            build(this.clientesApi.clientesGET, this.form.clientes, {or: ['razon_social', 'nombre']}),
            build(this.trabajadoresApi.trabajadoresGET, this.form.trabajadores,
                (it: { nombre: string; apellidos: any; }) => it.nombre + ' ' + (it.apellidos || '') ,
                true, true),
        
        ], () => {

            if (this.getType() === FormRequestTypes.CREATE){
                this.fincasApi.fincasGET.toPromise().then( (resp_fincas: any) => {
             
                    if (resp_fincas.length > 0 ) {
                        this.model['id_finca'] = resp_fincas[0].id;
                    }
                });
            }
      
            this.formRequest.update();
       
        });
    }

    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {
      
            if (this.model.mantenimiento === 1){
                this.creacion = true;
            }
            else{
                this.creacion = false;
            }// if();
      
            const trueFields = [
                'mantenimiento',
                'off_on'
            ];
      
            trueFields.forEach( f => {
                this.model[f] = TRUTHY_VALUES.includes(this.model[f]);
            });

            const userInfo = (StorageManager.getUser() || {} as any);
            if (userInfo.rol === 'user' && this.getType() === FormRequestTypes.CREATE){
                this.model['ids_trabajadores'] = userInfo.id.toString();
            }
      
      
            if (environment.features.showTareasMto && this.getType() === FormRequestTypes.DUPLICATE){
                delete this.model['hora_fin'];
                delete this.model['hora_inicio'];
                delete this.model['horas_totales'];
            }
      
            if (environment.features.showTareasMto) {
                if (this.getType() === FormRequestTypes.EDIT ){
                    this.tareasApi.mtoRiego.GET.response( (resp) => {
                        if ( resp[0].hora_fin == null || (resp[0].hora_fin as string).length === 0 ){
                            if ( StorageManager.getUser().rol === 'user'){
                                const date = new Date;
                                this.model.hora_fin = date;
                            }
                        }
                    });
                }

                if (this.getType() === FormRequestTypes.DUPLICATE || this.getType() === FormRequestTypes.CREATE){
         
                    if ( StorageManager.getUser().rol === 'user'){
                        const date = new Date;
                        this.model.hora_fin = date;
                    }
        
                }
            }
            if ( environment.features.showTareasMto ) {
                if ( StorageManager.getUser().rol === 'user') {
                    this.model.prioridad = 'Realizada';
                }
            }   
      
            resolve(true);
        });
        this.formRequest.beforeSend(resolve => {
            this.model['fecha'] = this.model['fecha'] instanceof Date
                ? Utils.formatDate(this.model['fecha'])
                : this.model['fecha'];

            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromFinca = (this.form.fincas.selected || {}).id_cliente;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
            clientIdFromFinca ? clientIdFromFinca :
                clientIdFromForm ? clientIdFromForm :
                    clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() || {} as any).id;

            if ( this.getType() === FormRequestTypes.CREATE || this.getType() === FormRequestTypes.DUPLICATE){
                this.model.id_usuario = userId ? userId : '';
            }// if();

            this.model.mantenimiento = this.model.mantenimiento === true ? 1 : 0;
        
            if ( this.model.hora_inicio != null ){
                const horaInicio = moment(this.model.hora_inicio, 'HH:mm').format('HH:mm');
                this.model.hora_inicio = horaInicio;
                if (!this.model.hora_inicio.includes(':')) {
                    this.model.hora_inicio = null;
                }
            }

            if ( this.model.hora_fin != null ){
                const horaFin = moment(this.model.hora_fin, 'HH:mm').format('HH:mm');
                this.model.hora_fin = horaFin;
                if (!this.model.hora_fin.includes(':')) {
                    this.model.hora_fin = null;
                }
            }


            if ( this.model.hora_inicio_riego != null ){
                const horaInicioRiego = moment(this.model.hora_inicio_riego, 'HH:mm').format('HH:mm');
                this.model.hora_inicio_riego = horaInicioRiego;
                if (!this.model.hora_inicio_riego.includes(':')) {
                    this.model.hora_inicio_riego = null;
                }
            }

    

            resolve(true);
        });

        this.formRequest.afterSend(resolve => {
            StorageManager.saveSessionDataDonJardin(this.model.id_finca, this.model.hora_inicio, this.model.hora_fin);
            resolve(true);
        });

        this.formRequest.afterFinish(resolve => {
            if (this.getType() === FormRequestTypes.DUPLICATE) {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
            resolve(true);
        });

    }  

}