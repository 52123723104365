import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';

@Injectable({
    providedIn: 'root'
})
export class UserActivityApiService {

    public statisticsGET = new RequestHandler();
    public vademecumActivityGET = new RequestHandler();
    public vademecumActivityPOST = new RequestHandler();

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineStatisticsGET();
        this.defineVademecumActivityGET();
        this.defineVademecumActivityPOST();

    }

    private defineStatisticsGET() {
        this.statisticsGET.fetch(HttpRequestType.GET, 'user/statistics');
        this.api.registerRequest(this.statisticsGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.statisticsGET));
    }

    private defineVademecumActivityGET() {
        this.vademecumActivityGET.fetch(HttpRequestType.GET, 'vademecum/activity_get');
        this.api.registerRequest(this.vademecumActivityGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.vademecumActivityGET));
    }

    private defineVademecumActivityPOST() {
        this.vademecumActivityPOST.fetch(HttpRequestType.POST, 'generic/vademecum_activity');
        this.api.registerRequest(this.vademecumActivityPOST);
        this.syncService.register(this.syncService.baseSyncPolicy(this.vademecumActivityPOST));
    }
}
