<div class="resp-container" style="margin: -19px -21px 0px -35px; overflow: auto;">

    <p-dialog header="Información" [(visible)]="displayNoParcelDialog" modal="modal" [closable]="false" [baseZIndex]="10000">
      <p>No se ha encontrado ninguna parcela seleccionada.</p>
      <p-footer>
        <button type="button" pButton label="Aceptar" icon="pi pi-check" (click)="hideNoParcelDialog()"></button>
      </p-footer>
    </p-dialog> 

    <div #gmap class="resp-iframe" style="width:100%; height:100vh !important; min-height:100vh !important;">
    </div>

    <ng-container *ngIf="showCargando">
        <img style="   
      width: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -75px;
      margin-left: -75px;" src="./assets/icon/load.gif" />
    </ng-container>

    <div #divSelectSectores>
        <select #selectSectores (change)="getSectorSelected($event.target)" style="
        background-color: white;
        cursor: pointer;
        padding: 0px 8px;
        margin: 10px;
        margin-left: 10px;
        border-radius: 2px;
        color:black;
        font-size: 1.6em;
        font-weight: bold;
        height:40px;
        border:1px solid grey;
        min-width: 180px;
        max-width: 180px;
        box-shadow: 6px 6px 3px grey;
      ">
      <option value="" selected>Selecciona {{sectorName.toLowerCase()}}...</option>
      <option *ngFor="let sector of arraySectores" [value]="sector.nombre" [selected]="sector.nombre == this.sectorSelected">
       <span *ngIf="hasClients"> {{sector.nombre}} ({{sector.cliente_nombre}})</span>
       <span *ngIf="!hasClients"> {{sector.nombre}}</span>
      </option>
    </select>
    </div>

    <div #divSelectCultivos>
        <select #selectCultivos (change)="getCultivoSelected($event.target)" style="
        background-color: white;
        cursor: pointer;
        padding: 0px 8px;
        margin: 10px;
        margin-left: 10px;
        border-radius: 2px;
        color:black;
        font-size: 1.6em;
        font-weight: bold;
        height:40px;
        border:1px solid grey;
        min-width: 180px;
        max-width: 180px;
        box-shadow: 6px 6px 3px grey;
      ">
    </select>
    </div>

    <div #divSearchMap>
        <input #searchMap placeholder="Buscar municipio, provincia, etc..." style="
        background-color: white;
        cursor: pointer;
        padding: 0px 8px;
        margin: 10px;
        margin-left: 10px;
        border-radius: 2px;
        color:black;
        font-size: 1em;
        height:40px;
        border:1px solid grey;
        min-width: 180px;
        max-width: 180px;
        box-shadow: 6px 6px 3px grey;
      ">
    </div>
    

    <div #divNuevoTratamiento id="divNuevoTratamiento" style="
      height:40px; 
      margin: 10px; 
      margin-left: 10px; 
      border-radius: 8px; 
      background-color: green;
      min-width: 180px;
      max-width: 180px;
      cursor: pointer;
      display: grid;
      visibility: hidden;
    " 
    (click)="nuevoTratamientoBtn()">
        <label id="labelDivNuevoTratamiento" style="text-align: center !important; font-size: 1.5em; margin: auto; cursor: pointer;">
    NUEVO TRATAMIENTO
  </label>
    </div>

    <div #divGuardarCambios id="divGuardarCambios" style="
    height:40px; 
    margin: 10px; 
    margin-left: 10px; 
    border-radius: 8px; 
    background-color: green;
    min-width: 180px;
    max-width: 180px;
    cursor: pointer;
    display: grid;
    visibility: hidden;
   " (click)="actualizarParcela()">
        <label style="text-align: center !important; font-size: 1.8em; margin: auto; cursor: pointer;">
  GUARDAR
</label>
    </div>


    <div #divCancelaParcela id="divCancelaParcela" style="
      height:40px; 
      margin: 10px; 
      margin-left: 10px; 
      border-radius: 8px; 
      background-color: orange;
      min-width: 180px;
      max-width: 180px;
      cursor: pointer;
      display: grid;
      visibility: hidden;">
        <label style="text-align: center !important; font-size: 1.8em; margin: auto; cursor: pointer;">
    CANCEL
  </label>
    </div>


    <div id="modal" #modal class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
                </div>
                <div class="modal-body">
                    <p>Modal body text goes here.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary">Save changes</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

</div>