import { environment } from './../../../environments/environment';
import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';

@Injectable({
    providedIn: 'root'
})
export class TareasApiService {

    public diasSemanaGET: RequestHandler = new RequestHandler();

    public fitosanitariosGET: RequestHandler = new RequestHandler();
    public fitosanitariosGET_GENERIC: RequestHandler = new RequestHandler();
    public fitosanitariosDELETE: RequestHandler = new RequestHandler();
    public fitosanitariosPUT: RequestHandler = new RequestHandler();
    public fitosanitariosPOST: RequestHandler = new RequestHandler();

    public enviarReceta: RequestHandler = new RequestHandler();
    public enviarRecetaCaptura: RequestHandler = new RequestHandler();
    public enviarVisita: RequestHandler = new RequestHandler();    
    public enviarVisitaCaptura: RequestHandler = new RequestHandler();

    
    public calendarioGET: RequestHandler = new RequestHandler();
    
    public fertilizantes = {
        GET: new RequestHandler(),
        GET_GENERIC: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler(),
    };

    public bio = {
        GET: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler(),
    };
    public mtoPoda = {
        GET: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler(),
    };

    public mtoCesped = {
        GET: new RequestHandler(),
        GET_GENERIC: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler(),
    };

    public mtoLimpiezaGeneral = {
        GET: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler(),
    };

    public mtoRiego = {
        GET: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler(),
    };
    
    public mtoPiscinas = {
        GET: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler(),
    };

    public plantacion = {
        GET: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler(),
    };

    public alquilerMaquinaria = {
        GET: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler(),
    };

    public edificios = {
        GET: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler(),
    };

    public otras = {
        GET: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler()
    };
    public costes = {
        GET: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler()
    };

    public listaTareas = {
        GET: new RequestHandler(),
        DELETE: new RequestHandler(),
        PUT: new RequestHandler(),
        POST: new RequestHandler()
    };

    public tablaAbonado = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public controlRiego = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public controlSanitario = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public fitoLocales = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };


    public controlRiegoLinea = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };
    public controlRiegoTable = {
        POST: new RequestHandler()
    };
    public resumenConsumosAguaAbonos = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public siembra = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public justificacion_actuacion = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public post_cosecha = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public edificaciones = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public medidasFito = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public medidasPreventivas = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public actividadesAgraria = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
    };

    public detalleActividadesAgraria = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
    };

    public buenasPracticas = {
        GET: new RequestHandler(),
        POST: new RequestHandler()
    };

    public riego = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()    
    };

    public subcontratos = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()    
    };

    public agroambientales = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()        
    };
    
    public ecorregimenes = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()        
    };

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineDiasSemanaGET();
        this.defineFitosanitariosGET();
        this.defineFitosanitariosPOST();
        this.defineFitosanitariosDELETE();
        this.defineFitosanitariosPUT();
        this.defineFertilizantes();
        this.defineBio();
        this.defineMtoPoda();
        this.defineMtoCesped();
        this.defineMtoLimpiezaGeneral();
        this.defineMtoRiego();
        this.defineMtoPiscinas();
        this.definePlantacion();
        this.defineAlquilerMaquinaria();
        this.defineEdificios();
        this.defineEnviarReceta();
        this.defineEnviarRecetaCaptura();
        this.defineEnviarVisita();        
        this.defineEnviarVisitaCaptura();
        this.defineListaTareas();
        this.defineOtros();
        this.defineTablaAbonado();
        this.defineControlRiego();
        this.defineControlSanitario();
        this.defineFitoLocales();
        this.defineControlRiegoLinea();
        this.defineControlRiegoTable();
        this.defineResumenConsumosAguaAbono();
        this.defineSiembra();
        this.defineCostes();
        this.defineJustificacionActuacion();
        this.definePostCosecha();
        this.defineMedidasFito();
        this.defineEdificaciones();
        this.defineOtrasConsultas();
        this.defineRiego();
        this.defineAgroambiental();
        this.defineCalendario();
        this.defineSubcontratos();
        this.defineEcorregimes();
    }   

    public defineDiasSemanaGET() {
        this.diasSemanaGET.fetch(HttpRequestType.GET, 'tareas/dias_semana_get');
        this.api.registerRequest(this.diasSemanaGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.diasSemanaGET));
    }

    public defineEnviarReceta() {
        this.enviarReceta.fetch(HttpRequestType.POST, 'tareas/enviar_receta');
        this.api.registerRequest(this.enviarReceta);
        this.syncService.register(this.syncService.baseSyncPolicy(this.enviarReceta));
    }
    public defineEnviarRecetaCaptura() {
        this.enviarRecetaCaptura.fetch(HttpRequestType.POST, 'tareas/enviar_receta_captura');
        this.api.registerRequest(this.enviarRecetaCaptura);
        this.syncService.register(this.syncService.baseSyncPolicy(this.enviarRecetaCaptura));
    }
    public defineEnviarVisita() {
        this.enviarVisita.fetch(HttpRequestType.POST, 'tareas/enviar_visita');
        this.api.registerRequest(this.enviarVisita);
        this.syncService.register(this.syncService.baseSyncPolicy(this.enviarVisita));
    }    
    public defineEnviarVisitaCaptura() {
        this.enviarVisitaCaptura.fetch(HttpRequestType.POST, 'tareas/enviar_visita_captura');
        this.api.registerRequest(this.enviarVisitaCaptura);
        this.syncService.register(this.syncService.baseSyncPolicy(this.enviarVisitaCaptura));
    }

    public defineFertilizantes(){
        this.fertilizantes.GET.fetch(HttpRequestType.GET, 'tareas/fertilizantes_get');
        this.fertilizantes.GET_GENERIC.fetch(HttpRequestType.GET, 'generic/tareas_fertilizantes');
        this.fertilizantes.POST.fetch(HttpRequestType.POST, 'generic/tareas_fertilizantes');
        this.fertilizantes.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_fertilizantes');
        this.fertilizantes.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_fertilizantes');

        this.api.registerRequest(this.fertilizantes.GET);
        this.api.registerRequest(this.fertilizantes.GET_GENERIC);
        this.api.registerRequest(this.fertilizantes.POST);
        this.api.registerRequest(this.fertilizantes.PUT);
        this.api.registerRequest(this.fertilizantes.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.fertilizantes.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.fertilizantes.GET_GENERIC));
        this.syncService.register(this.syncService.baseSyncPolicy(this.fertilizantes.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.fertilizantes.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.fertilizantes.DELETE));
    }

    public defineBio() {
        this.bio.GET.fetch(HttpRequestType.GET, 'tareas/tareas_bio_get');
        this.bio.POST.fetch(HttpRequestType.POST, 'generic/tareas_bio');
        this.bio.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_bio');
        this.bio.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_bio');

        this.api.registerRequest(this.bio.GET);
        this.api.registerRequest(this.bio.POST);
        this.api.registerRequest(this.bio.PUT);
        this.api.registerRequest(this.bio.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.bio.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.bio.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.bio.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.bio.DELETE));
    }
    public defineMtoPoda() {
        this.mtoPoda.GET.fetch(HttpRequestType.GET, 'tareas/tareas_mto_poda_get');
        this.mtoPoda.POST.fetch(HttpRequestType.POST, 'generic/tareas_mto_poda');
        this.mtoPoda.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_mto_poda');
        this.mtoPoda.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_mto_poda');

        this.api.registerRequest(this.mtoPoda.GET);
        this.api.registerRequest(this.mtoPoda.POST);
        this.api.registerRequest(this.mtoPoda.PUT);
        this.api.registerRequest(this.mtoPoda.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoPoda.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoPoda.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoPoda.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoPoda.DELETE));
    }

    public defineMtoCesped() {
        this.mtoCesped.GET.fetch(HttpRequestType.GET, 'tareas/tareas_mto_cesped_get');
        this.mtoCesped.GET_GENERIC.fetch(HttpRequestType.GET, 'tareas/tareas_mto_cesped_get');
        this.mtoCesped.POST.fetch(HttpRequestType.POST, 'generic/tareas_mto_cesped');
        this.mtoCesped.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_mto_cesped');
        this.mtoCesped.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_mto_cesped');

        this.api.registerRequest(this.mtoCesped.GET);
        this.api.registerRequest(this.mtoCesped.GET_GENERIC);
        this.api.registerRequest(this.mtoCesped.POST);
        this.api.registerRequest(this.mtoCesped.PUT);
        this.api.registerRequest(this.mtoCesped.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoCesped.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoCesped.GET_GENERIC));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoCesped.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoCesped.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoCesped.DELETE));
    }

    public defineMtoLimpiezaGeneral() {
        this.mtoLimpiezaGeneral.GET.fetch(HttpRequestType.GET, 'tareas/tareas_mto_limpieza_general_get');
        this.mtoLimpiezaGeneral.POST.fetch(HttpRequestType.POST, 'generic/tareas_mto_limpieza_general');
        this.mtoLimpiezaGeneral.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_mto_limpieza_general');
        this.mtoLimpiezaGeneral.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_mto_limpieza_general');

        this.api.registerRequest(this.mtoLimpiezaGeneral.GET);
        this.api.registerRequest(this.mtoLimpiezaGeneral.POST);
        this.api.registerRequest(this.mtoLimpiezaGeneral.PUT);
        this.api.registerRequest(this.mtoLimpiezaGeneral.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoLimpiezaGeneral.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoLimpiezaGeneral.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoLimpiezaGeneral.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoLimpiezaGeneral.DELETE));
    }
    
    public defineMtoRiego() {
        this.mtoRiego.GET.fetch(HttpRequestType.GET, 'tareas/tareas_mto_riego_get');
        this.mtoRiego.POST.fetch(HttpRequestType.POST, 'generic/tareas_mto_riego');
        this.mtoRiego.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_mto_riego');
        this.mtoRiego.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_mto_riego');

        this.api.registerRequest(this.mtoRiego.GET);
        this.api.registerRequest(this.mtoRiego.POST);
        this.api.registerRequest(this.mtoRiego.PUT);
        this.api.registerRequest(this.mtoRiego.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoRiego.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoRiego.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoRiego.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoRiego.DELETE));
    }

    public defineMtoPiscinas() {
        this.mtoPiscinas.GET.fetch(HttpRequestType.GET, 'tareas/tareas_mto_piscinas_get');
        this.mtoPiscinas.POST.fetch(HttpRequestType.POST, 'generic/tareas_mto_piscinas');
        this.mtoPiscinas.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_mto_piscinas');
        this.mtoPiscinas.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_mto_piscinas');

        this.api.registerRequest(this.mtoPiscinas.GET);
        this.api.registerRequest(this.mtoPiscinas.POST);
        this.api.registerRequest(this.mtoPiscinas.PUT);
        this.api.registerRequest(this.mtoPiscinas.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoPiscinas.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoPiscinas.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoPiscinas.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.mtoPiscinas.DELETE));
    }

    public definePlantacion() {
        this.plantacion.GET.fetch(HttpRequestType.GET, 'tareas/tareas_plantacion_get');
        this.plantacion.POST.fetch(HttpRequestType.POST, 'generic/tareas_plantacion');
        this.plantacion.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_plantacion');
        this.plantacion.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_plantacion');

        this.api.registerRequest(this.plantacion.GET);
        this.api.registerRequest(this.plantacion.POST);
        this.api.registerRequest(this.plantacion.PUT);
        this.api.registerRequest(this.plantacion.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.plantacion.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.plantacion.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.plantacion.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.plantacion.DELETE));
    }

    public defineAlquilerMaquinaria() {
        this.alquilerMaquinaria.GET.fetch(HttpRequestType.GET, 'tareas/tareas_alquiler_maquinaria_get');
        this.alquilerMaquinaria.POST.fetch(HttpRequestType.POST, 'generic/tareas_alquiler_maquinaria');
        this.alquilerMaquinaria.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_alquiler_maquinaria');
        this.alquilerMaquinaria.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_alquiler_maquinaria');

        this.api.registerRequest(this.alquilerMaquinaria.GET);
        this.api.registerRequest(this.alquilerMaquinaria.POST);
        this.api.registerRequest(this.alquilerMaquinaria.PUT);
        this.api.registerRequest(this.alquilerMaquinaria.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.alquilerMaquinaria.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.alquilerMaquinaria.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.alquilerMaquinaria.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.alquilerMaquinaria.DELETE));
    }

    public defineEdificios() {
        this.edificios.GET.fetch(HttpRequestType.GET, 'tareas/tareas_edificios_get');
        this.edificios.POST.fetch(HttpRequestType.POST, 'generic/tareas_edificios');
        this.edificios.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_edificios');
        this.edificios.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_edificios');

        this.api.registerRequest(this.edificios.GET);
        this.api.registerRequest(this.edificios.POST);
        this.api.registerRequest(this.edificios.PUT);
        this.api.registerRequest(this.edificios.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.edificios.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.edificios.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.edificios.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.edificios.DELETE));
    }

    public defineFitosanitariosGET() {
        this.fitosanitariosGET.fetch(HttpRequestType.GET, 'tareas/fitosanitarios_get');
        this.fitosanitariosGET_GENERIC.fetch(HttpRequestType.GET, 'generic/tareas_fitosanitarias');

        this.api.registerRequest(this.fitosanitariosGET);
        this.api.registerRequest(this.fitosanitariosGET_GENERIC);

        this.syncService.register(this.syncService.baseSyncPolicy(this.fitosanitariosGET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.fitosanitariosGET_GENERIC));
    }

    public defineFitosanitariosPOST() {
        this.fitosanitariosPOST
            .fetch(HttpRequestType.POST, 'generic/tareas_fitosanitarias');

        this.api
            .registerRequest(this.fitosanitariosPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.fitosanitariosPOST));
    }

    public defineFitosanitariosDELETE() {
        this.fitosanitariosDELETE
            .fetch(HttpRequestType.DELETE, 'generic/tareas_fitosanitarias');

        this.api
            .registerRequest(this.fitosanitariosDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.fitosanitariosDELETE));
    }

    public defineFitosanitariosPUT() {
        this.fitosanitariosPUT
            .fetch(HttpRequestType.PUT, 'generic/tareas_fitosanitarias');

        this.api
            .registerRequest(this.fitosanitariosPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.fitosanitariosPUT));
    }

    private defineCostes() {
        if ( environment.features.showCostesAll) {
            this.costes.GET.fetch(HttpRequestType.GET, 'costes/costes_tareas_otras_get');
        } else {
            this.costes.GET.fetch(HttpRequestType.GET, 'tareas/costes_tareas_get');
        }

        this.api.registerRequest(this.costes.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.costes.GET));
        
    }

    private defineOtros() {
        this.otras.GET.fetch(HttpRequestType.GET, 'tareas/otros_get');
        this.otras.POST.fetch(HttpRequestType.POST, 'generic/tareas_otras');
        this.otras.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_otras');
        this.otras.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_otras');

        this.api.registerRequest(this.otras.GET);
        this.api.registerRequest(this.otras.POST);
        this.api.registerRequest(this.otras.PUT);
        this.api.registerRequest(this.otras.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.otras.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.otras.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.otras.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.otras.DELETE));
    }

    private defineListaTareas() {
        this.listaTareas.GET.fetch(HttpRequestType.GET, 'generic/tareas');
        this.listaTareas.POST.fetch(HttpRequestType.POST, 'generic/tareas');
        this.listaTareas.PUT.fetch(HttpRequestType.PUT, 'generic/tareas');
        this.listaTareas.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas');

        this.api.registerRequest(this.listaTareas.GET);
        this.api.registerRequest(this.listaTareas.POST);
        this.api.registerRequest(this.listaTareas.PUT);
        this.api.registerRequest(this.listaTareas.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.listaTareas.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.listaTareas.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.listaTareas.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.listaTareas.DELETE));
    }

    private defineTablaAbonado() {
        this.tablaAbonado.GET.fetch(HttpRequestType.GET, 'tareas/tabla_abonado_get');
        this.tablaAbonado.POST.fetch(HttpRequestType.POST, 'generic/tabla_abonado');
        this.tablaAbonado.PUT.fetch(HttpRequestType.PUT, 'generic/tabla_abonado');
        this.tablaAbonado.DELETE.fetch(HttpRequestType.DELETE, 'tareas/tabla_abonado_delete');

        this.api.registerRequest(this.tablaAbonado.GET);
        this.api.registerRequest(this.tablaAbonado.POST);
        this.api.registerRequest(this.tablaAbonado.PUT);
        this.api.registerRequest(this.tablaAbonado.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.tablaAbonado.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.tablaAbonado.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.tablaAbonado.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.tablaAbonado.DELETE));
    }

    private defineControlRiego() {
        this.controlRiego.GET.fetch(HttpRequestType.GET, 'tareas/control_riego_get');
        this.controlRiego.POST.fetch(HttpRequestType.POST, 'generic/control_riego');
        this.controlRiego.PUT.fetch(HttpRequestType.PUT, 'generic/control_riego');
        this.controlRiego.DELETE.fetch(HttpRequestType.DELETE, 'generic/control_riego');

        this.api.registerRequest(this.controlRiego.GET);
        this.api.registerRequest(this.controlRiego.POST);
        this.api.registerRequest(this.controlRiego.PUT);
        this.api.registerRequest(this.controlRiego.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.controlRiego.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.controlRiego.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.controlRiego.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.controlRiego.DELETE));
    }

    private defineControlSanitario() {
        this.controlSanitario.GET.fetch(HttpRequestType.GET, 'tareas/control_sanitario_get');
        this.controlSanitario.POST.fetch(HttpRequestType.POST, 'generic/control_sanitario');
        this.controlSanitario.PUT.fetch(HttpRequestType.PUT, 'generic/control_sanitario');
        this.controlSanitario.DELETE.fetch(HttpRequestType.DELETE, 'generic/control_sanitario');

        this.api.registerRequest(this.controlSanitario.GET);
        this.api.registerRequest(this.controlSanitario.POST);
        this.api.registerRequest(this.controlSanitario.PUT);
        this.api.registerRequest(this.controlSanitario.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.controlSanitario.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.controlSanitario.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.controlSanitario.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.controlSanitario.DELETE));
    }

    private defineFitoLocales() {
        this.fitoLocales.GET.fetch(HttpRequestType.GET, 'tareas/tareas_fito_locales_get');
        this.fitoLocales.POST.fetch(HttpRequestType.POST, 'generic/tareas_fito_locales');
        this.fitoLocales.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_fito_locales');
        this.fitoLocales.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_fito_locales');

        this.api.registerRequest(this.fitoLocales.GET);
        this.api.registerRequest(this.fitoLocales.POST);
        this.api.registerRequest(this.fitoLocales.PUT);
        this.api.registerRequest(this.fitoLocales.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.fitoLocales.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.fitoLocales.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.fitoLocales.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.fitoLocales.DELETE));
    }

    private defineControlRiegoLinea() {
        this.controlRiegoLinea.GET.fetch(HttpRequestType.GET, 'tareas/control_riego_linea_get');
        this.controlRiegoLinea.POST.fetch(HttpRequestType.POST, 'generic/control_riego_linea');
        this.controlRiegoLinea.PUT.fetch(HttpRequestType.PUT, 'generic/control_riego_linea');
        this.controlRiegoLinea.DELETE.fetch(HttpRequestType.DELETE, 'generic/control_riego_linea');

        this.api.registerRequest(this.controlRiegoLinea.GET);
        this.api.registerRequest(this.controlRiegoLinea.POST);
        this.api.registerRequest(this.controlRiegoLinea.PUT);
        this.api.registerRequest(this.controlRiegoLinea.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.controlRiegoLinea.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.controlRiegoLinea.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.controlRiegoLinea.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.controlRiegoLinea.DELETE));
    }

    private defineControlRiegoTable() {
        this.controlRiegoTable.POST.fetch(HttpRequestType.POST, 'tareas/control_riego_table_post');
        this.api.registerRequest(this.controlRiegoTable.POST);
        this.syncService.register(this.syncService.baseSyncPolicy(this.controlRiegoTable.POST));
    }

    private defineResumenConsumosAguaAbono() {
        this.resumenConsumosAguaAbonos.GET.fetch(HttpRequestType.GET, 'tareas/resumen_consumos_get');
        this.api.registerRequest(this.resumenConsumosAguaAbonos.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.resumenConsumosAguaAbonos.GET));
    }

    private defineSiembra() {
        this.siembra.GET.fetch(HttpRequestType.GET, 'tareas/siembra_get');
        this.siembra.POST.fetch(HttpRequestType.POST, 'generic/tareas_siembra');
        this.siembra.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_siembra');
        this.siembra.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_siembra');

        this.api.registerRequest(this.siembra.GET);
        this.api.registerRequest(this.siembra.POST);
        this.api.registerRequest(this.siembra.PUT);
        this.api.registerRequest(this.siembra.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.siembra.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.siembra.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.siembra.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.siembra.DELETE));
    }

    private defineJustificacionActuacion(){
        this.justificacion_actuacion.GET.fetch(HttpRequestType.GET, 'tareas/justificaciones_get');
        this.api.registerRequest(this.justificacion_actuacion.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.justificacion_actuacion.GET));
    }

    private definePostCosecha(){
        this.post_cosecha.GET.fetch(HttpRequestType.GET, 'tareas/postcosecha');
        this.post_cosecha.POST.fetch(HttpRequestType.POST, 'generic/tareas_fitosanitarias_postcosecha');
        this.post_cosecha.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_fitosanitarias_postcosecha');
        this.post_cosecha.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_fitosanitarias_postcosecha');

        this.api.registerRequest(this.post_cosecha.GET);
        this.api.registerRequest(this.post_cosecha.POST);
        this.api.registerRequest(this.post_cosecha.PUT);
        this.api.registerRequest(this.post_cosecha.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.post_cosecha.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.post_cosecha.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.post_cosecha.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.post_cosecha.DELETE));
    }

    private defineEdificaciones(){
        this.edificaciones.GET.fetch(HttpRequestType.GET, 'tareas/edificaciones_get');
        this.edificaciones.POST.fetch(HttpRequestType.POST, 'generic/tareas_fitosanitarias_edificaciones');
        this.edificaciones.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_fitosanitarias_edificaciones');
        this.edificaciones.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_fitosanitarias_edificaciones');
        
        this.api.registerRequest(this.edificaciones.GET);
        this.api.registerRequest(this.edificaciones.POST);
        this.api.registerRequest(this.edificaciones.PUT);
        this.api.registerRequest(this.edificaciones.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.edificaciones.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.edificaciones.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.edificaciones.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.edificaciones.DELETE));
    }

    private defineMedidasFito(){
        this.medidasFito.GET.fetch(HttpRequestType.GET, 'tareas/medidasFito');
        this.api.registerRequest(this.medidasFito.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.medidasFito.GET));
    }
    private defineOtrasConsultas(){
        this.actividadesAgraria.GET.fetch(HttpRequestType.GET, 'tareas/actividad_agraria');
        this.detalleActividadesAgraria.GET.fetch(HttpRequestType.GET, 'tareas/detalleActividadAgraria');
        this.buenasPracticas.GET.fetch(HttpRequestType.GET, 'tareas/buenasPracticas');
        this.medidasPreventivas.GET.fetch(HttpRequestType.GET, 'tareas/medidasPreventivas');

        this.api.registerRequest(this.actividadesAgraria.GET);
        this.api.registerRequest(this.detalleActividadesAgraria.GET);
        this.api.registerRequest(this.buenasPracticas.GET);
        this.api.registerRequest(this.medidasPreventivas.GET);

        this.syncService.register(this.syncService.baseSyncPolicy(this.actividadesAgraria.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.detalleActividadesAgraria.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.buenasPracticas.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.medidasPreventivas.GET));
    }

    private defineRiego() {
        this.riego.GET.fetch(HttpRequestType.GET, 'tareas/riego');
        this.riego.POST.fetch(HttpRequestType.POST, 'generic/riego');
        this.riego.PUT.fetch(HttpRequestType.PUT, 'generic/riego');
        this.riego.DELETE.fetch(HttpRequestType.DELETE, 'generic/riego');

        this.api.registerRequest(this.riego.GET);
        this.api.registerRequest(this.riego.POST);
        this.api.registerRequest(this.riego.PUT);
        this.api.registerRequest(this.riego.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.riego.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.riego.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.riego.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.riego.DELETE));
    }

    private defineAgroambiental() {
        this.agroambientales.GET.fetch(HttpRequestType.GET, 'tareas/agroambientales_get');
        this.agroambientales.POST.fetch(HttpRequestType.POST, 'generic/tareas_agroambientales');
        this.agroambientales.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_agroambientales');
        this.agroambientales.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_agroambientales');

        this.api.registerRequest(this.agroambientales.GET);
        this.api.registerRequest(this.agroambientales.POST);
        this.api.registerRequest(this.agroambientales.PUT);
        this.api.registerRequest(this.agroambientales.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.agroambientales.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.agroambientales.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.agroambientales.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.agroambientales.DELETE));
    }

    private defineCalendario() {
        this.calendarioGET.fetch(HttpRequestType.GET, 'tareas/calendario_get');
        this.api.registerRequest(this.calendarioGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.calendarioGET));
    }
    
    private defineSubcontratos() {
        this.subcontratos.GET.fetch(HttpRequestType.GET, 'tareas/subcontratos_get');
        this.subcontratos.POST.fetch(HttpRequestType.POST, 'generic/tareas_subcontratos');
        this.subcontratos.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_subcontratos');
        this.subcontratos.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_subcontratos');

        this.api.registerRequest(this.subcontratos.GET);
        this.api.registerRequest(this.subcontratos.POST);
        this.api.registerRequest(this.subcontratos.PUT);
        this.api.registerRequest(this.subcontratos.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.subcontratos.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.subcontratos.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.subcontratos.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.subcontratos.DELETE));
    }

    private defineEcorregimes() {
        this.ecorregimenes.GET.fetch(HttpRequestType.GET, 'tareas/ecorregimenes_get');
        this.ecorregimenes.POST.fetch(HttpRequestType.POST, 'generic/tareas_ecorregimenes');
        this.ecorregimenes.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_ecorregimenes');
        this.ecorregimenes.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_ecorregimenes');

        this.api.registerRequest(this.ecorregimenes.GET);
        this.api.registerRequest(this.ecorregimenes.POST);
        this.api.registerRequest(this.ecorregimenes.PUT);
        this.api.registerRequest(this.ecorregimenes.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.ecorregimenes.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.ecorregimenes.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.ecorregimenes.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.ecorregimenes.DELETE));
    }
}
