import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StorageManager } from 'src/app/common/storage-manager.class';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { AppFormRequest } from 'src/app/view/app-common/form-request/app-form-request';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { environment } from 'src/environments/environment';
import { LoginApiService } from 'src/app/service/api/login-api.service';
import { list } from 'src/app/common/classes/request-builder';
import { UserApiService } from 'src/app/service/api/user-api.service';
import { UsuariosApiService } from 'src/app/service/api/usuarios-api.service';
import { Filtering } from 'src/app/service/filtering/filtering';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    @ViewChild(RequestButtonComponent, { static: false })
    requestButton: RequestButtonComponent = new RequestButtonComponent();

    public users: any[] = [];

    public errors: any = {
        usernameIsValid: true,
        emailIsValid: true
    };

    public logoUrl = environment.logoUrl;
    public accountCreated: boolean = false;

    public model: any = {};
    public formRequest: AppFormRequest = new AppFormRequest();
    public tipos: TypeAheadInterface = list(
        ['...', null],
        'Comunero',
        'Aplicador'
    );

    public formFieldsAccount: ItemInterface<any>[] = [
        {label: 'Nombre de usuario', field: 'username', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Contraseña', field: 'password', inputType: {type: InputType.PASSWORD}},
        {label: 'Repita la contraseña', field: 'password_repeat', inputType: {type: InputType.PASSWORD}}
    ];
    public formFieldsLeft: ItemInterface<any>[] = [
        {label: 'Nombre', field: 'nombre', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Apellidos', field: 'apellidos', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Email', field: 'email', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Teléfono', field: 'telefono',  inputType: {type: InputType.EDIT_TEXT_MASK}, mask: '999-999-999'},
        {label: 'NIF (para autónomos)', field: 'nif', inputType: {type: InputType.EDIT_TEXT_MASK}, mask: '99999999-a'},
        {label: 'CIF (para SL, SA, CB o similares)', field: 'cif', inputType: {type: InputType.EDIT_TEXT_MASK}, mask: 'a-99999999'},
        {label: 'Tipo', field: 'tipo', inputType: {type: InputType.DROPDOWN}, values: this.tipos},
    ];

    constructor(
        private router: Router,
        public loginApi: LoginApiService,
        public userApi: UserApiService,
        public usuariosApi: UsuariosApiService
    ) { }

    ngOnInit() {
        this.usuariosApi.usuariosGET.toPromise().then(users => this.users = users);

        if (StorageManager.getUser()) {
            this.router.navigate(['dashboard']);
        }
    }

    public register() {
        if (this.validateForm()) {
            this.loginApi.register(this.model).then((response: any) => {
                if (response && response.data) {
                    this.accountCreated = true;
                    this.userApi.sendEmailConfirmation({
                        usuario: this.model.username,
                        entidad: this.model.entidad,
                        email: this.model.email,
                        estado: '3'
                    });
                }
            });
        } else {
            alert('Por favor, rellene todos los campos obligatorios y/o asegúrese de que las contraseñas coincidan.');
        }
    }

    public validateForm() {
        let cond = true;
        for (const field of ['username', 'password', 'nombre',
            'apellidos', 'email', 'telefono']) {
            if (!this.model[field]) {
                cond = false;
            }
        }
    
        if (this.model['cif']) {
            this.model['nif'] = this.model['cif'];
        }
    
        delete this.model['cif'];

        if (this.model.password !== this.model.password_repeat) {
            cond = false;
        }
        if (!this.errors.emailIsValid || !this.errors.usernameIsValid) {
            cond = false;
        }
        return cond;
    }

    public formChanges(tag: string) {
        if (tag === 'username') {
            if (this.users.map(it => it.username).includes(this.model.username)) {
                this.errors.usernameIsValid = false;
            } else {
                this.errors.usernameIsValid = true;
            }
        }
        if (tag === 'email') {
            if (
                !this.model.email || (
                    this.users.map(it => it.email).includes(this.model.email) ||
          (this.model.email.indexOf('@') === -1) ||
          (this.model.email.indexOf('.') === -1)
                )
            ) {
                this.errors.emailIsValid = false;
            } else {
                this.errors.emailIsValid = true;
            }
        }

        if (tag === 'cif') {
            this.model.nif = null;
        }
        if (tag === 'nif') {
            this.model.cif = null;
        }
    }

    goBack() {
        this.router.navigate(['login']);
    }

}

interface TypeAheadInterface {
    selected: any;
    values: any[];
    filtered: any[];
    filterCallback?: Filtering<any>;
}
