<div class="card chart-container">

    <p-header>
        <h5 class="p-2 mb-0 text-center">{{title}}</h5>
    </p-header>
    
    <div *ngIf="yearsDropdownOptions!.length > 0" class="d-flex p-2 text-center justify-content-center">
        <p-dropdown 
        [options]="yearsDropdownOptions"
        [(ngModel)]="selectedYear"
          (onChange)="filterDataByYear($event.value)"
          placeholder="Filtrar por año"
          class="dropdown-limited-width"
          id="yearSelect"
          ></p-dropdown>
    </div>
        
    <div class="chart-container" [ngClass]="{ 'pie': type === 'pie', 'doughnut': type === 'doughnut' }">
        <p-chart *ngIf="data?.labels && data?.labels!.length > 0" 
            [type]="type"
            [data]="data"
            [options]="chartOptions || {}"> 
        </p-chart>
        
        <p-chart *ngIf="!(data?.labels && data?.labels!.length > 0)" 
            [type]="type"
            [data]="_noData">
        </p-chart>
    </div>
</div>