<br>
<p-panel>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
      <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
    </div>
  </div>
  <app-form-builder
          [model]="model"
          [distributionOrder]="distributionOrder"
          [undoEnabled]="undoEnabled"
          [distributionColumns]="3"
          [fields]="formFields"
          (formChanges)="formChanges($event)"
          (selectAllcheck)="selectAll()">
  </app-form-builder>
  <div class="d-flex justify-content-center">
    <table *ngIf="model.producto_nombre" class="table-style">
        <tr>
            <th>Nombre de Producto</th>
            <th>Fecha de Aplicación</th>
            <th>Plazo de Seguridad</th>
            <th>Prohibido hasta</th>
        </tr>
        <tr>
          <td>{{model.producto_nombre}}</td>
          <td>{{model.fecha_aplicacion}}</td>
          <td>{{model.plazo_seguridad}}</td>
          <td>{{model.prohibido_hasta}}</td>
        </tr>
      </table>
    </div>
    <p id="alerta" *ngIf="alerta" class="d-flex justify-content-center">ALERTA: la Fecha de Recogida está dentro del Plazo de Seguridad.</p>
  <br>
  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            [lockOnSuccess]="true"
            (click)="submit()"
            [mode]="requestButtonType"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>
