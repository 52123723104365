import {InputType} from '../../../../../../common/components/form-builder/form-builder.component';
import {Filtering} from '../../../../../../service/filtering/filtering';
import {list} from '../../../../../../common/classes/request-builder';
import {FormRequestTypes} from '../../../../../../common/classes/form-request';
import { environment } from 'src/environments/environment';
import { checkIfRolAdmin } from 'src/app/common/check-role';

import type { ItemInterface } from '../../../../../../common/components/form-builder/form-builder.component';
export class TareasMtoCespedFormDefinition {

    public lastFieldChanged: string = '';

    public fincas: TypeAheadInterface = list();  
    public clientes: TypeAheadInterface = list();      
    public portes: TypeAheadInterface = list(['...', null], 'Propios', 'Proveedores');      
    public prioridades = list(['...', null], 'Alta', 'Realizada');
    public formFieldsSimple: any;
    public trabajadores: TypeAheadInterface = list(['...', null]);

    public requestType: FormRequestTypes = 0;

    public model = {};
    
    public appName = environment.appName;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;    

    public formFields: ItemInterface<any>[] = 
        [
            {field: 'fecha', label: 'Fecha', inputType: {type: InputType.CALENDAR}, required: true},
            {
                field: 'id_finca',
                label: this.fincaName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.fincas,
                valuePrimaryKey: 'id',
                filter: (it: { value: { id_cliente: any; }; }) => {
                    if (this.clientes.selected) {
                        return it.value.id_cliente === (this.clientes.selected || {}).id;
                    } else {
                        return true;
                    }
                },
                required: true
            },
            {field: 'hora_inicio', label: 'Hora inicio', inputType: {type: InputType.TIME}, required: checkIfRolAdmin() },
            {field: 'hora_fin', label: 'Hora fin', inputType: {type: InputType.TIME}, required: checkIfRolAdmin() },
            {field: 'horas_totales', label: 'Horas', inputType: {type: InputType.ONLY_TEXT} },
            {field: 'mantenimiento', label: 'Mantenimiento', inputType: {type: InputType.SWITCH}},
            {field: 'prioridad', label: 'Prioridad/Realizada', inputType: {type: InputType.DROPDOWN}, values: this.prioridades}
        ];

    public formFieldsCortes: ItemInterface<any>[] =
        [
            {field: 'corte_desbroce', label: 'Corte/Desbroce', inputType: {type: InputType.SWITCH}},        
            {field: 'escarificado', label: 'Escarificado', inputType: {type: InputType.SWITCH}},            
            {field: 'aireado_pinchado', label: 'Aireado/Pinchado', inputType: {type: InputType.SWITCH}},        
            {field: 'soplado', label: 'Soplado', inputType: {type: InputType.SWITCH}},        
            {field: 'escarda_hierba', label: 'Escarda hierba', inputType: {type: InputType.SWITCH}},   
        ];

    public formFieldsCavados: ItemInterface<any>[] = 
        [
            {field: 'parterres', label: 'Parterres', inputType: {type: InputType.SWITCH}},
            {field: 'arriates', label: 'Arriates', inputType: {type: InputType.SWITCH}},
            {field: 'jardineras', label: 'Jardineras', inputType: {type: InputType.SWITCH}},

        ];

    public formFieldsRecebado: ItemInterface<any>[] = 
        [ 
            {field: 'sacas_una_tonelada', label: 'Sacas ( 1 tonelada )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'sacas_media_tonelada', label: 'Sacas ( 1/2 tonelada )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'sacos_venticinco', label: 'Sacos ( 25Kg )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'kilos_granel', label: 'Kilos a granel', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'sacos_ciemhus_litros', label: 'Sacos Ciemhus ( 50l )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'recebado_portes', label: 'Portes', 
                inputType: {type: InputType.DROPDOWN},
                values: {...this.portes}
            },    ]; 
    public formFieldsTVegetal: ItemInterface<any>[] = 
        [            
            {field: 'vegetal_sacas_una_tonelada', label: 'Sacas ( 1 tonelada )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'vegetal_sacas_media_tonelada', label: 'Sacas ( 1/2 tonelada )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'vegetal_sacos_venticinco', label: 'Sacos ( 25Kg )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'vegetal_kilos_granel', label: 'Kilos a granel', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'vegetal_sacos_ciemhus_litros', label: 'Sacos Ciemhus ( 50l )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'vegtal_portes', label: 'Portes', 
                inputType: {type: InputType.DROPDOWN},
                values: {...this.portes}
            },
        ];

    public formFieldsTPreparada: ItemInterface<any>[] = 
        [            
            {field: 'preparada_sacas_una_tonelada', label: 'Sacas ( 1 tonelada )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'preparada_sacas_media_tonelada', label: 'Sacas ( 1/2 tonelada )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'preparada_sacos_venticinco', label: 'Sacos ( 25Kg )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'preparada_kilos_granel', label: 'Kilos a granel', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'preparada_sacos_ciemhus_litros', label: 'Sacos Ciemhus ( 50l )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'preparada_portes', label: 'Portes', 
                inputType: {type: InputType.DROPDOWN},
                values: {...this.portes}
            },
        
        ];

    public formFieldsTGarvillada: ItemInterface<any>[] = 
        [            
            {field: 'garvillada_sacas_una_tonelada', label: 'Sacas ( 1 tonelada )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'garvillada_sacas_media_tonelada', label: 'Sacas ( 1/2 tonelada )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'garvillada_sacos_venticinco', label: 'Sacos ( 25Kg )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'garvillada_kilos_granel', label: 'Kilos a granel', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'garvillada_sacos_ciemhus_litros', label: 'Sacos Ciemhus ( 50l )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'garvillada_portes', label: 'Portes', 
                inputType: {type: InputType.DROPDOWN},
                values: {...this.portes}
            },
        ];

    public formFieldsSiembraCesped: ItemInterface<any>[] = 
        [            
            {field: 'siembra_cesped_kg', label: 'Kg', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'siembra_cesped_gramos', label: 'Gramos', inputType: {type: InputType.EDIT_NUMERIC}}

        ];

    public formFieldsSiembraGrana: ItemInterface<any>[] = 
        [            
            {field: 'siembra_grana_kg', label: 'Kg', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'siembra_grana_gramos', label: 'Gramos', inputType: {type: InputType.EDIT_NUMERIC}}

        ];

    public formFieldsSiembraDichondra: ItemInterface<any>[] = 
        [            
            {field: 'siembra_dichondra_kg', label: 'Kg', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'siembra_dichondra_gramos', label: 'Gramos', inputType: {type: InputType.EDIT_NUMERIC}}

        ];

    public formFieldsSustrato: ItemInterface<any>[] = 
        [            
            {field: 'sustrato_sacos_venticinco', label: 'Sacos ( 25Kg )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'sustrato_femabon', label: 'Sacos Femabon ( 25l )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'sustrato_ciemhus', label: 'Sacos Ciemhus ( 70l )', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'sustrato_acido', label: 'Sacos sustrato ácido ( 20l )', inputType: {type: InputType.EDIT_NUMERIC}},
        
        ];

   
    public formFieldsTrabajadores: ItemInterface<any>[] = [
        {
            field: 'ids_trabajadores',
            label: 'Profesionales',
            inputType: { type: InputType.MULTISELECT },
            values: this.trabajadores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: { value: { activo: string; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                return true;
            }
        },

    ];

    public formFieldsOrdenTrabajo: ItemInterface<any>[] = [
        {
            field: 'orden_trabajo', 
            label: 'Orden de trabajo', 
            inputType: { type: checkIfRolAdmin() ? InputType.TEXTAREA : InputType.EDIT_TEXTAREA },
        },
    ];

    public formFieldsObs: ItemInterface<any>[] = [
        { field: 'observaciones', label: 'Observaciones', inputType: { type: InputType.EDIT_TEXTAREA } },

    ];
    
  


    public formChanges(tag: string) {
        this.lastFieldChanged = tag;
    }

}

interface TypeAheadInterface {
    selected: any;
    values: any[];
    filtered: any[];
    filterCallback?: Filtering<any>;
}