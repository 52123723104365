import { environment } from './../../../../environments/environment';
import {Component, EventEmitter, Input, KeyValueDiffers, Output} from '@angular/core';
import {RequestHandler} from '../../../service/OffService/request-handler';

import type { DoCheck, OnInit, OnChanges, SimpleChanges } from '@angular/core';

export enum FormType {
    CREATION,
    EDITION
}

export enum FilterType {
    DROPDOWN,
    MULTISELECT,
    DATE_RANGE
}

export enum InputType {
    TEXT,
    ONLY_TEXT,
    TEXTAREA,
    EDIT_TEXT,
    CHECK_BOX_TRISTATE,
    EDIT_TEXTAREA,
    EMPTY,
    PASSWORD,
    DROPDOWN,
    RO_DROPDOWN,
    DROPDOWN_SEARCH,
    DROPDOWN_MULTISELECT_SEARCH,
    MULTISELECT,
    CALENDAR,
    EXTERNAL,
    EDIT_NUMERIC,
    TIME,
    SWITCH,
    RO_SWITCH,
    RO_MD_SWITCH,
    SELECT_BUTTON,
    EDIT_TEXT_MASK,
    TWO_FIELDS_CONCAT,
}

export enum DistributionType {
    COLS,
    ROWS
}

export enum LabelPositionTypes {
    TOP,
    RIGHT,
    BOTTOM
}

@Component({
    selector: 'app-form-builder',
    templateUrl: './form-builder.component.html',
    styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit, OnChanges, DoCheck {

    @Input() fields: ItemInterface<any>[] = [];
    @Input() distributionType: DistributionType | undefined;
    @Input() distributionOrder: number[] = [];
    @Input() distributionColumns: number = 0;
    @Input()

    @Input() model: any;
    @Input() modelChanges: any;
    @Input() modelOld: any;
    @Input() undoEnabled: boolean = false;
    showAudio: boolean = false;

    @Output() formChanges: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectAllcheck: EventEmitter<any> = new EventEmitter<any>();

    public appName = environment.appName;
    public filterInputParcelas = environment.features.filterInputParcelas;

    public es = {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre',
            'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Borrar'
    };

    public INPUT = InputType;
    public DISTRIBUTIONS = DistributionType;
    public TYPE = FormType;

    public differ;
    public distributedFields: ItemInterface<any>[][] = [];
    public modelChangesStatus: any = {};
    public defaultTextStyles = 'background-color: white; border-radius: 3px; border: 1px solid lightgray; padding: 3px;';

    selectAll = false;

    constructor(public differs: KeyValueDiffers) {
        this.differ = differs.find({}).create();
    }

    ngOnInit() {
        this.modelOld = JSON.parse(JSON.stringify(this.model));

        if (this.haveAllRequiredData()) {
            switch (this.distributionType) {
                case DistributionType.COLS:
                    this.setupColumns();
                    break;
                case DistributionType.ROWS:
                    this.setupRows();
                    break;
            }

            /*this.fields
                .filter(field => field.values)
                .forEach((field: any) => {
                    field.values.watch('filtered', (id, oldval, newval) => {
                        setTimeout(() => this.applySelected(field), 1);
                        return newval;
                    });
                });*/
        }
    }

    ngDoCheck() {
        if (this.differ.diff(this.model)) {
            (this.fields || []).forEach(this.applySelected.bind(this));
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['model']) {
            (this.fields || []).forEach(this.applySelected.bind(this));
            this.applyTransforms();
        }
    }

    getAudio(field: string | number, event: any ) {
        this.model[field] = event;
    }
    
    public applySelected(field: ItemInterface<any>) {
        if (field.values && this.model[field.field ?? 0] !== field.values.selected) {
            let searchOn = [];
            if (field.values.values && field.values.values.length > 0) {
                searchOn = field.values.values;
            } else if (field.values.filtered && field.values.filtered.length > 0) {
                searchOn = field.values.filtered;
            }

            if (field.multiSelect) {
                field.values.selected = searchOn
                    .map((it: { value: any; }) => it.value)
                    .filter((it: null | undefined) => it !== null && it !== undefined)
                    .filter((it: { [x: string]: any; }) => (typeof this.model[field.field ?? 0] === 'string' ? this.model[field.field ?? 0] : '')
                        .split(';')
                        .includes(field.valuePrimaryKey
                            ? it[field.valuePrimaryKey]
                            : it
                        ));
            } else {
                field.values.selected = searchOn
                    .map((it: { value: any; }) => it.value)
                    .filter((it: null | undefined) => it !== null && it !== undefined)
                    .find((it: { [x: string]: any; }) => (field.valuePrimaryKey ? it[field.valuePrimaryKey] : it)
                        === this.model[field.field ?? 0]
                    );
            }
        }

        setTimeout(() => {
            this.runFieldFilters();
        }, 1);

    }

    public markFieldAsChanged(field: ItemInterface<any>) {
        if (field.values) {
            if (field.multiSelect) {
                this.model[field.field ?? 0] =
                    (field.values.selected instanceof Array ? field.values.selected : [])
                        .filter((it: any) => it)
                        .map((it: { [x: string]: any; }) => field.valuePrimaryKey
                            ? it[field.valuePrimaryKey]
                            : it)
                        .join(';');
            } else {
                this.model[field.field ?? 0] = field.valuePrimaryKey
                    ? ((field.values || {}).selected || {})[field.valuePrimaryKey]
                    : ((field.values || {}).selected);
            }
        }

        setTimeout(() => {
            this.runFieldFilters();
        }, 1);

        this.modelChangesStatus[field.field ?? 0] = this.model[field.field ?? 0] !== this.modelOld[field.field ?? 0];
        this.handelModelChanges();
        this.formChanges.emit(field.field);
    }

    public selectAllRows(field: ItemInterface<any>, checked: boolean) {
        // Determina los valores a utilizar basándose en si se ha aplicado un filtro
        const values: any = field.filter ? field.values.filtered : (field.values.values ? field.values.filtered : []);

        if (checked && field.values.selected.length === values.length) {
        
            console.log('Todos los ítems ya están seleccionados.');
            return; // Salir temprano si no hay necesidad de cambiar el estado de selección
        }
    
        const allSelected = values.filter((it: { value: any; }) => it && it.value)
            .map((it: { value: { [x: string]: any; }; }) => it.value[field.valuePrimaryKey ?? 0])
            .join(';');
    
        if (checked) {
            this.model[field.field ?? 0] = allSelected;

            const valoresSeleccionados: any[] = [];
            values.forEach((value: { value: any; }) => {
                valoresSeleccionados.push(value.value);
            });

            field.values.selected = valoresSeleccionados;

        } else {
            this.model[field.field ?? 0] = '';
            field.values.selected = [];
        }
    
        // Actualiza el estado de selección 
        this.selectAll = checked;
    
        this.selectAllcheck.emit(field.field);
    }

    
    public handelModelChanges() {
        for (const x in this.model) {
            if (this.modelChangesStatus[x]) {
                this.modelChanges[x] = this.model[x];
            } else {
                if (this.modelChanges[x]) {
                    delete this.modelChanges[x];
                }
            }
        }
    }

    public undoField(field: ItemInterface<any>) {
        if (field.values) {
            field.values.selected = this.modelOld[field.field ?? 0];
            this.markFieldAsChanged(field);
        } else {
            this.model[field.field ?? 0] = this.modelOld[field.field ?? 0];
            this.markFieldAsChanged(field);
        }
    }    

    private runFieldFilters() {
        this.fields
            .filter(it => it.filter instanceof Function)
            .forEach(it => {
                it.values.filtered = (it.multiSelect ? [] : [{label: it.placeholder || '...', value: null}])
                    .concat(it.values.values
                        .filter((j: { value: any; }) => j && j.value)
                        .filter(it.filter)
                    );

                if (it.multiSelect) {
                    it.values.selected = (it.values.selected instanceof Array ? it.values.selected : [])
                        .filter((item: any) =>
                            it.values.filtered.map((i: { value: any; }) => i.value).includes(item)
                        );
                }
            });
    }


    private applyTransforms() {
        if (this.fields) {
            this.fields.forEach((field: ItemInterface<any>) => {
                if (field.transform) {
                    field.transform.forEach(transformFunction => {
                        if (transformFunction) {
                            transformFunction(field, this.model);
                        }
                    });
                }
            });
        }
    }

    private haveAllRequiredData(): boolean {
        if (!this.model) {
            this.model = {};
        }

        if (!this.modelChanges) {
            this.modelChanges = {};
        }

        if (!this.modelOld) {
            this.modelOld = {};
        }

        this.distributionType = this.distributionType !== undefined ? this.distributionType
            : DistributionType.ROWS;

        this.distributionOrder = this.distributionOrder ? this.distributionOrder
            : [];

        if (this.distributionType === DistributionType.COLS) {
            this.distributionColumns = this.distributionOrder.length;

            if (this.distributionOrder.length === 0) {
                throw new Error('DISTRIBUTION ORDER ATTRIBUTE NEEDED IN COL MODE');
            }
        } else {
            if (!this.distributionColumns) {
                throw new Error('DISTRIBUTION COLUMNS ATTRIBUTE NEEDED IN ROW MODE');
            }
        }

        return true;
    }

    private setupRows() {
        let count = 0;
        let row = 0;

        this.distributedFields = [];
        this.distributedFields[row] = [];

        this.fields.forEach((field) => {
            if (count >= (this.distributionOrder[row] || this.distributionColumns)) {
                count = 0;
                this.distributedFields[++row] = [];
            } 
            if (field.visible !== false) {
                // @ts-ignore
                this.distributedFields[row][count++] = field;
            }
        });
    }

    private setupColumns() {
        let count = 0;
        let col = 0;

        this.distributedFields = [];
        this.distributedFields[col] = [];

        this.fields.forEach((field) => {
            if (count >= (this.distributionOrder[col] || this.distributionColumns)) {
                count = 0;
                this.distributedFields[++col] = [];
            }
            // @ts-ignore
            this.distributedFields[col][count++] = field;
        });
    }

    /*private loadExternalComponents() {
        this.fields.forEach((field: ItemInterface) => {
            if (field.component) {
                const component = field.component;

                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

                const viewContainerRef = this.dynamicContainer.viewContainerRef;
                viewContainerRef.clear();

                const componentRef = viewContainerRef.createComponent(componentFactory);
                componentRef.instance.field = field;
            }
        });
    }*/
}

export interface ItemInterface<T> {

    calendarView?: any; // Calendar

    canSelectAll?: boolean;

    component?: Component;
    
    disabled?: boolean;

    display?: {

        table: boolean;

        form: boolean;

    };

    field?: string;

    name?: string; // Nombre de columna para CSVs.

    filter?: Function;

    hasLabel?: boolean;
    
    hasSelectedAll?: boolean; // Multiselect

    inputType?: InputInterface;

    label?: string;

    labelPosition?: LabelPositionTypes;

    mask?: string;
 
    multiSelect?: boolean; // Multiselect

    placeholder?: string;

    required?: boolean;

    separator?: string;

    style?: any;
    
    remove?: boolean;

    maxLength?: number;
    
    maxHeight?: boolean;

    rows?: number;
    
    cols?: number;

    subField1?: string; // TWO_FIELDS_CONCAT

    subField2?: string; // TWO_FIELDS_CONCAT

    transform?: ((value: T, model: any) => T)[];

    undoEnabled?: boolean;

    validate?: ((value: T) => boolean)[];

    valuePrimaryKey?: string;

    values?: T | {
        data: any[] | RequestHandler;
        primaryKey?: string;
        labelKey?: string;
        join?: {
            with: string;
            on: {
                a: string;
                b: string;
            };
        };
    };

    visible?: boolean;
    audio?: boolean;
}

export interface InputInterface {
    type: InputType;
    options?: any;
}

export interface FilterInterface {
    type: FilterType;
    options?: any;
}

export interface DistributionInterface {
    type: DistributionType;
    options?: any;
}

export interface TypeAheadInterface<T> {
    selected: T | null;
    values: {value: T | null; label: string}[];
    filtered: {value: T | null; label: string; tipo?: string; unidades?: number}[];
    label?: string;
}

export interface DropdownInterface<T> {
    label: string;
    value: T;
}

export interface ItemValuesInterface<T> {
    data: T[] | T;
    selected: T[] | T;
    filtered: T[] | T;
    primaryKey?: string;
    labelKey?: string;
    join?: {
        with: string;
        on: {
            a: string;
            b: string;
        };
    };
}