import { Injectable } from '@angular/core';
import { ApiRequestService, HttpRequestType } from '../OffService/api-request.service';
import { SyncService } from '../OffService/sync.service';
import { RequestHandler } from '../OffService/request-handler';

@Injectable({
    providedIn: 'root'
})

export class SiexApiService {
  
    public url = 'https://locatec.es/proyectos/SIEX/ws/';

    public cultivosGET: RequestHandler = new RequestHandler();
    public variedadesGET: RequestHandler = new RequestHandler();  
    public vistaCultivoVariedadGET: RequestHandler = new RequestHandler();
    public variedadesClienteGET: RequestHandler = new RequestHandler();  
    public portainjertosGET: RequestHandler = new RequestHandler();  
    public sistemasRiegoGET: RequestHandler = new RequestHandler();
    public justificacionesActuacionGET: RequestHandler = new RequestHandler();
    public actividadesSobreCubiertaGET: RequestHandler = new RequestHandler();

    constructor(private syncService: SyncService, private api: ApiRequestService) {
        this.defineCultivos();
        this.defineVariedades();
        this.defineVistaCultivoVariedad();
        this.defineVariedadesCliente();
        this.definePortainjertos();
        this.defineSistemasRiego();
        this.defineJustificacionesActuacionGET();
        this.defineActividadesSobreCubiertaGET();
    }

    public defineCultivos(){
        this.cultivosGET.fetch(HttpRequestType.GET, 'cultivo_variedad/get_cultivos');
        this.api.registerRequest(this.cultivosGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.cultivosGET));
    }

    public defineVariedades() {
        this.variedadesGET.fetch(HttpRequestType.GET, 'cultivo_variedad/get_variedades');
        this.api.registerRequest(this.variedadesGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.variedadesGET));
    }

    public defineVistaCultivoVariedad() {
        this.vistaCultivoVariedadGET.fetch(HttpRequestType.GET, 'generic/vista_cultivo_variedad');
        this.api.registerRequest(this.vistaCultivoVariedadGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.vistaCultivoVariedadGET));
    }

    public defineVariedadesCliente() {
        this.variedadesClienteGET.fetch(HttpRequestType.GET, 'generic/vista_variedades_cliente');
        this.api.registerRequest(this.variedadesClienteGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.variedadesClienteGET));
    }
  
    public definePortainjertos() {
        this.portainjertosGET.fetch(HttpRequestType.GET, 'parcelas/get_portainjertos');
        this.api.registerRequest(this.portainjertosGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.portainjertosGET));
    }

    public defineSistemasRiego() {
        this.sistemasRiegoGET.fetch(HttpRequestType.GET, 'parcelas/get_sistemas_riego');
        this.api.registerRequest(this.sistemasRiegoGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.sistemasRiegoGET));
    }

    public defineJustificacionesActuacionGET() {
        this.justificacionesActuacionGET.fetch(HttpRequestType.GET, 'tareas/justificaciones_get');
        this.api.registerRequest(this.justificacionesActuacionGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.justificacionesActuacionGET));
    }

    public defineActividadesSobreCubiertaGET() {
        this.actividadesSobreCubiertaGET.fetch(HttpRequestType.GET, 'tareas/actividades_sobre_cubierta_get');
        this.api.registerRequest(this.actividadesSobreCubiertaGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.actividadesSobreCubiertaGET));
    }

    /*constructor(private http: HttpClient) {  }

  public getCultivos(): any {
    return this.http.get(this.url + 'cultivo_variedad/get_cultivos.php');
  }

  public getVariedades(idCultivo: number): any {
    return this.http.get(this.url + `cultivo_variedad/get_variedades.php?cultivo=${idCultivo}`);
  }

  public getPortainjertos(): any {
    return this.http.get(this.url + 'parcelas/get_portainjertos.php');
  }

  public getSistemasRiego(): any {
    return this.http.get(this.url + 'parcelas/get_sistemas_riego.php');
  }

  public getJustificacionActuacion(): any {
    return this.http.get(this.url + 'tareas/justificaciones_get.php');
  }*/
}
