import {Component, ViewChild} from '@angular/core';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '../../../../base-form';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {VentasApiService} from '../../../../../service/api/ventas-api.service';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { list } from 'src/app/common/classes/request-builder';
import { environment } from 'src/environments/environment';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';

export const listTipos: TypeAheadInterface = list(['...', null ], ['Cliente', 'Cliente'], ['Proveedor', 'Proveedor'] );

@Component({
    selector: 'app-compradores-form',
    templateUrl: './compradores-form.component.html',
    styleUrls: ['./compradores-form.component.scss']
})
export class CompradoresFormComponent extends BaseForm implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
    requestButton: RequestButtonComponent = new RequestButtonComponent();
    public tipos: TypeAheadInterface = listTipos;

    public override model: any = {};
    public formRequest: AppFormRequest = new AppFormRequest();
    public showVentasComercioProveedor = environment.features.showVentasComercioProveedor;


    public formFields: ItemInterface<any>[] = [
        {field: 'razon_social', label: 'Razón Social', inputType: {type: InputType.EDIT_TEXT}, required: true},
        {field: 'nif', label: 'NIF', inputType: {type: InputType.EDIT_TEXT}},
        {field: 'codigo_postal', label: 'Código Postal', inputType: {type: InputType.EDIT_TEXT}},
        {field: 'poblacion', label: 'Población', inputType: {type: InputType.EDIT_TEXT}},
        {field: 'provincia', label: 'Provincia', inputType: {type: InputType.EDIT_TEXT}},
        {field: 'pais', label: 'País', inputType: {type: InputType.EDIT_TEXT}},
        {field: 'rgseaa', label: 'RGSEAA', inputType: {type: InputType.EDIT_TEXT}},
        {
            field: 'tipo',
            label: 'Tipo',
            inputType: {type: InputType.DROPDOWN},
            values: this.tipos,
            visible: this.showVentasComercioProveedor,
            required: true
        },
    ];

    constructor(public override dashboard: DashboardService,
        public override router: Router,
        public override route: ActivatedRoute,
        public ventasApi: VentasApiService) {
        super(
            route,
            router,
            dashboard,
            ventasApi.compradoresPUT,
            ventasApi.compradoresPOST,
            'compradores',
            'Actualizar Comercio',
            'Crear Comercio'
        );
    }

    ngOnInit() {
        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .isGeneric(false)
            .setGetRequest(this.ventasApi.compradoresGET)
            .setPostRequest(this.ventasApi.compradoresPOST)
            .setPutRequest(this.ventasApi.compradoresPUT)
            .setFormFields(this.formFields)
            .setFieldsToSend((['id', 'id_cliente'] as any)
                .concat(
                    this.formFields.map(it => it.field)
                )
            );

        this.formRequest.load();

        this.softInit(this.getType());
    }

    public submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send();
    }

    public formChanges() {
        this.requestButton.error = '';
    }

}


interface TypeAheadInterface {
    selected: any;
    values: any[];
    filtered: any[];
}
