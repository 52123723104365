import {TareasApiService} from '../../../../../service/api/tareas-api.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import {TareasBioFormDefinition} from './form-control/form-definition';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { pool, buildParams, build } from 'src/app/common/classes/request-builder';
import { ClientesApiService } from 'src/app/service/api/clientes-api.service';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { Utils } from 'src/app/common/utils';
import { environment } from 'src/environments/environment';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import moment from 'moment';
import { TrabajadoresApiService } from 'src/app/service/api/trabajadores-api.service';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import type { OnDestroy, OnInit } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app-tareas-bio-pulverizacion',
    templateUrl: './tareas_bio-form.component.html',
    styleUrls: ['./tareas_bio-form.component.scss']
})
export class TareasBioFormComponent extends BaseForm implements OnInit, OnDestroy {
    @ViewChild('imagenCapturada') imagenCapturada: ElementRef = new ElementRef('');

    public appName = environment.appName;
    public logoUrl = environment.logoUrl;
    public hasClients = environment.features.hasClients;
    public showTareasParcela = environment.features.showTareasParcela;
    public clienteName = environment.features.clienteName;

    public horaFechaFinActualPorDefecto = environment.features.horaFechaFinActualPorDefecto; 
    public tareasHorasTotales = environment.features.tareasHorasTotales;
    public showGenerateRecetaBio = environment.features.showGenerateRecetaBio;
    
    public form: TareasBioFormDefinition = new TareasBioFormDefinition();
    public formRequest: AppFormRequest = new AppFormRequest();
    public override model: { [key: string]: any } = {};

    public showRecetaTareaBio: boolean = false;
    public isGeneratingCanvas: boolean = false;
    public receta: any = {};
    public parcela: any = {};






    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public fincasApi: FincasApiService,
        public clientesApi: ClientesApiService,
        public tareasApi: TareasApiService,
        public trabajadores: TrabajadoresApiService,
        public override router: Router) {
        super(
            route,
            router,
            dashboard,
            tareasApi.bio.PUT,
            tareasApi.bio.POST,
            'bio',
            'Actualizar Tarea Bio',
            'Realizar Tarea Bio',
            'Duplicar Tarea Bio'
        );
    }

    ngOnInit() {
        this.form.productosOrdenados.forEach( producto => {
            this.form.productos.filtered.push({'label': producto, 'value': producto });
        });
        if (this.route.snapshot.paramMap.get('deCalendario') != null ){
            if (this.route.snapshot.paramMap.get('deCalendario')?.toString() === 'true') { this.pathToGoBack = 'tratamientos-control'; }
        }else{
            if (this.hasClients && (this.getType() === FormRequestTypes.DUPLICATE) && StorageManager.getUser().tipo !== 'comunero') {
                this.form.formFields.unshift({
                    field: 'id_cliente',
                    label: this.clienteName,
                    inputType: {type: InputType.DROPDOWN_SEARCH},
                    values: this.form.clientes,
                    valuePrimaryKey: 'id',
                    visible: this.hasClients,
                    required: this.hasClients
                });
            }
        }
        this.initFormData();
        this.expandFormFields();        
        this.expandFormRequest();

        const fieldsToSend = (['id', 'id_usuario', 'cultivo', 'variedad', 'horas_totales'] as any).concat(
            this.form.formFields.map(it => it.field)
        );
        if (this.hasClients) {
            fieldsToSend.push('id_cliente');
        }

        this.formRequest
            .setType(this.getType())
            .isGeneric(this.getType() === FormRequestTypes.DUPLICATE)
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setPostRequest(this.tareasApi.bio.POST)
            .setPutRequest(this.tareasApi.bio.PUT)
            .setGetRequest(this.tareasApi.bio.GET)
            .setFormFields(this.form.formFields)
            .setFieldsToSend(fieldsToSend);

        this.formRequest.load();

        this.softInit(this.getType());
        this.form.requestType = this.getType();
        this.form.model = this.model;
    }

    formatoHorasMinutos(horaEntrada: any, horaSalida: string) {    
        let timeStr: any = horaEntrada;
        timeStr = timeStr.split(':');
      
        const h = timeStr[0],
            m = timeStr[1];
      
        const newTime = moment( horaSalida + ' 2021-4-5')
            .subtract({'hours': h, 'minutes': m})
            .format('HH:mm');
            
        return newTime;
       
    }

    public formChanges(tag: string) {
        if (environment.features.showTareasMto || environment.features.tareasHorasTotales) {
            if (this.model['hora_inicio'] != null && this.model['hora_fin'] != null) {
                let horaFin = moment(this.model['hora_fin']).format('HH:mm');
                let horaInicio = moment(this.model['hora_inicio']).format('HH:mm');
                const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
                if (!timeFormat.test(horaInicio)) {
                    horaInicio = this.model['hora_inicio'];
                }

                if (!timeFormat.test(horaFin)) {
                    horaFin = this.model['hora_fin'];
                }

                const diferencia = this.formatoHorasMinutos(horaInicio, horaFin);
                this.model['horas_totales'] = diferencia;
            } else {
                this.model['horas_totales'] = '';
            }
        }

        if (tag === 'id_finca') {
            this.model['id_sector'] = null;
            this.model['ids_parcelas'] = null;
        }
        if (tag === 'id_cliente') {
            this.model['id_finca'] = null;
            this.model['id_sector'] = null;
            this.model['ids_parcelas'] = null;

            pool([
                buildParams(this.fincasApi.fincasGET, {id_cliente: this.model['id_cliente']}, this.form.fincas,
                    'nombre', true),
                buildParams(this.fincasApi.sectoresGET, {id_cliente: this.model['id_cliente']}, this.form.sectores,
                    'nombre', true),
                buildParams(this.fincasApi.parcelasGET, {id_cliente: this.model['id_cliente']}, this.form.parcelas,
                    'nombre', true),
            ], () => {
                this.form.fincas.filtered = this.form.fincas.values;
                this.form.sectores.filtered = [];
                this.form.parcelas.values = this.form.parcelas.values.filter(it => (it.label !== '...'));
                
                this.formRequest.update();
            }).then(() => {
                this.formRequest.update();
            });
            
        }
    }

    public submit() {
        if (this.appName !== 'agrosalvi'){
            delete this.model['facturado'];
            delete this.model['factura_albaran'];
        }// if();

        if (this.appName !== 'onda' && this.appName !== 'lacooperativa'){
            delete this.model['estado_tarea'];
            delete this.model['empresa_pagadora'];
        }// if();

        this.formRequest.send();
        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    public generarReceta() {
        
        this.receta = this.model;
        this.receta['finca_nombre'] = this.form.fincas.selected.nombre;

        this.model = Object.create(this.model);
        
        this.model['dosis1'] = this.model['dosis1'] + ' ' + this.model['ud_real_1'];
        this.model['dosis2'] = this.model['dosis2'] + ' ' + this.model['ud_real_2'];
        this.model['dosis3'] = this.model['dosis3'] + ' ' + this.model['ud_real_3'];
        
        this.model['fecha'] = moment(this.model['fecha']).format('LL');
        this.showRecetaTareaBio = true;
        const elements = document.getElementsByClassName('p-dialog-content');
        setTimeout(() => {
            if (elements[0]) {
                elements[0].scrollTop = 0;
            }
        }, 100);
    }

    public imprimirReceta() {
        this.isGeneratingCanvas = true;
        const config = {

            canvasScale: 2,
            width: 220,

        };

        const canvaaa = document.getElementById('html2canvasDiv');
        
        
        html2canvas(canvaaa ?? new HTMLElement(), { scale: config.canvasScale, logging: true }).then(canvas => {
            this.isGeneratingCanvas = false;

            const pdf = new jsPDF('l', 'mm', 'a4');
            const data = canvas.toDataURL('image/png');
            
            pdf.addImage(data, 'png', 10, 10, config.width, (canvas.height / (canvas.width / config.width)), 'alias', 'FAST');
            pdf.save(('Bio.pdf'));
            // const downloadReceta: any = document.getElementById('downloadReceta');
            // downloadReceta.href = canvas.toDataURL('image/png');
            // downloadReceta.download = 'Receta guardada';
            // downloadReceta.click();
        });
    
    }

    private initFormData(): Promise<any> {
        return pool([
            build(this.fincasApi.fincasGET, this.form.fincas, 'nombre', true),
            build(this.fincasApi.sectoresGET, this.form.sectores, 'nombre', true),
            build(this.fincasApi.parcelasGET, this.form.parcelas, 'nombre', true),
            build(this.clientesApi.clientesGET, this.form.clientes, {or: ['razon_social', 'nombre']}),
            build(this.trabajadores.trabajadoresGET, this.form.trabajadores,
                (it: { nombre: string; apellidos: any; }) => it.nombre + ' ' + (it.apellidos || ''),
                false, true),
        ], () => {
           
            if ( this.form.fincas.filtered.length === 2  && this.getType() === 0 ){
                this.model['id_finca'] = this.form.fincas.filtered[1].value.id;
            }
            
            this.formRequest.update();
           
        });
    }

    private expandFormFields() {
        this.formRequest.afterLoad(resolve => {
            if (this.appName === 'agrosalvi') {
                this.model['facturado'] = (+this.model['facturado'] === 1);                
            }

            if (this.horaFechaFinActualPorDefecto && this.getType() !== FormRequestTypes.EDIT) {
                const fechaActual = new Date();

                if (this.appName !== 'sirga') {
                    this.model['fecha_aplicacion'] = fechaActual;
                }

                this.model['hora_fin'] = fechaActual;
            } 
            resolve(true);
        });
        this.formRequest.beforeSend(resolve => {    
            const horaFin = moment(this.model['hora_fin'], 'HH:mm').format('HH:mm');
            const horaInicio = moment(this.model['hora_inicio'], 'HH:mm').format('HH:mm');
            this.model['hora_inicio'] = horaInicio;
            this.model['hora_fin'] = horaFin;
            
            if (environment.features.timeStartStopTareas) {
                if (this.model['hora_inicio'] && moment(this.model['hora_inicio']).isValid()) {
                    this.model['hora_inicio'] = moment(this.model['hora_inicio']).format('HH:mm');
                }
                if (this.model['hora_fin'] && moment(this.model['hora_fin']).isValid()) {
                    this.model['hora_fin'] = moment(this.model['hora_fin']).format('HH:mm');
                }
            }
            resolve(true);
        });           
    }

    private expandFormRequest() {
        this.formRequest.beforeSend(resolve => {
            this.model['fecha_receta'] = this.model['fecha_receta'] instanceof Date
                ? Utils.formatDate(this.model['fecha_receta'])
                : this.model['fecha_receta'];

            this.model['fecha_aplicacion'] = this.model['fecha_aplicacion'] instanceof Date
                ? Utils.formatDate(this.model['fecha_aplicacion'])
                : this.model['fecha_aplicacion'];


            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromFinca = (this.form.fincas.selected || {}).id_cliente;
            const clientIdFromForm = this.model['id_cliente'];

            this.model['id_cliente'] =
                clientIdFromFinca ? clientIdFromFinca :
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() || {} as any).id;
            const userIdFromFinca = (this.form.fincas.selected || {}).id_usuario;
            const userIdFromForm = this.model['id_usuario'];

            this.model['id_usuario'] =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';
            
            
            if (this.showTareasParcela && this.form.parcelas.selected) {
                this.model['cultivo'] = this.form.parcelas.selected.cultivo;
                this.model['variedad'] = this.form.parcelas.selected.variedad;
            }

            if (environment.features.timeStartStopTareas) {
                if (this.model['hora_inicio'] && moment(this.model['hora_inicio']).isValid()) {
                    this.model['hora_inicio'] = moment(this.model['hora_inicio']).format('HH:mm');
                }
                if (this.model['hora_fin'] && moment(this.model['hora_fin']).isValid()) {
                    this.model['hora_fin'] = moment(this.model['hora_fin']).format('HH:mm');
                }
            }

            const horaFin = this.model['hora_fin'].indexOf(':') > -1 ?  moment(this.model['hora_fin'], 'HH:mm').format('HH:mm') : '00:00';
            const horaInicio = this.model['hora_inicio'].indexOf(':') > -1 ? moment(this.model['hora_inicio'], 'HH:mm').format('HH:mm') : '00:00';
            
            this.model['hora_inicio'] = horaInicio;
            this.model['hora_fin'] = horaFin; 

            resolve(true);
        });

        this.formRequest.afterFinish(resolve => {
            if (this.getType() === FormRequestTypes.DUPLICATE) {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
            resolve(true);
        });

    }
}