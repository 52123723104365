import {Component} from '@angular/core';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {BaseView} from '../../../base-view';
import {VisitasVariedadMesApiService} from '../../../../service/api/visitas_variedad_mes-api.service';
import moment from 'moment';
import {Filtering} from '../../../../service/filtering/filtering';
import { environment } from '../../../../../environments/environment';

import type { OnInit } from '@angular/core';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

@Component({
    selector: 'app-visitas-variedad-mes',
    templateUrl: './visitas-variedad-mes.component.html',
    styleUrls: ['./visitas-variedad-mes.component.scss']
})
export class VisitasVariedadMesComponent extends BaseView implements OnInit {

    public hasClients = environment.features.hasClients;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public showVisitasARealizar = environment.features.showVisitasARealizar;
    public clienteName = environment.features.clienteName;

    public getRequest: RequestHandler = this.visitasApi.visitasPorCliente.GET;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;
    public month = '';
    public monthNumber = 0;
    public yearNumber = 0;
    public actualYear = 0;
    public filter: Filtering<VisitasVariedadMesModel> = new Filtering<VisitasVariedadMesModel>();

    public override canShow: Record<string, boolean> = {};
    public override model: VisitasVariedadMesModel = {cultivo: '', variedad: '', id_variedad: '', unidades: ''};

    public cols = [
        {field: 'tecnico', header: 'Técnico', filter: ''},
        {field: 'cliente_nombre', header: this.clienteName, filter: '', visible: this.hasClients},
        {field: 'finca_nombre', header: this.fincaName},
        {field: 'variedad', header: 'Variedad'},
        {field: '', header: 'Realizadas', filter: ''},
        {field: '', header: 'A\xa0realizar', filter: '', visible: this.showVisitasARealizar},
        {field: '', header: 'Concertadas', filter: ''},
        {field: '', header: 'Pendientes', filter: '', visible: this.showVisitasARealizar},
    ];

    constructor(private dashboard: DashboardService,
        private visitasApi: VisitasVariedadMesApiService) {
        super(dashboard, visitasApi.visitasPorCliente.GET);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem('visitas-variedad-mes');
        this.actualMonth();
        this.actualYear = moment().year();
        this.addFiltering();
    }

    public addFiltering() {
        this.filter.addFilter((data: VisitasVariedadMesModel[]) => data.filter(row => (row[this.month] as number) > 0));
        this.filter.addFilter((data: VisitasVariedadMesModel[]) => data.map(it => {
            const realizadas: number = (it['r_' + this.month + '_' + this.yearNumber]) as number || 0;
            it['p_' + this.month + '_' + this.yearNumber] =
                (parseInt(it[this.month] as string, 10) - realizadas);
            return it;
        }));
    }

    // ----------------------------------------
    // TABLE SETTINGS
    // ----------------------------------------

    globalConditionalStyle(_value: string, cell: ColInterface): ColStylesInterface {
        if (['Realizadas', 'A\xa0realizar', 'Pendientes', 'Concertadas'].includes(cell.header)) {
            return {
                textAlign: 'center'
            };
        } else {
            return {};
        }
    }

    public globalVisualTransform(value: number, cell: ColInterface) {
        if (['Realizadas', 'A\xa0realizar', 'Pendientes', 'Concertadas'].includes(cell.header)) {
            if (value < 0) { 
                return '0';
            }
        } 
        return value;
    }


    // ----------------------------------------
    // LOGIC
    // ----------------------------------------

    previousMonth() {
        if (this.monthNumber < 1) {
            this.monthNumber = 11;
            this.yearNumber--;
        } else {
            this.monthNumber--;
        }

        this.month = this.monthFromNumber(this.monthNumber);

        this.loadMonth(this.month, this.yearNumber);
    }

    actualMonth() {
        this.yearNumber = moment().year();

        this.month = this.monthFromNumber(
            this.monthNumber = moment().month()
        );

        this.loadMonth(this.month, this.yearNumber);
    }

    nextMonth() {
        if (this.monthNumber > 10) {
            this.monthNumber = 0;
            this.yearNumber++;
        } else {
            this.monthNumber++;
        }

        this.month = this.monthFromNumber(this.monthNumber);

        this.loadMonth(this.month, this.yearNumber);
    }

    // ----------------------------------------
    // SUPPORT
    // ----------------------------------------

    loadMonth(month: string, year: string | number) {
        //this.cols[0].field = 'tec_' + month + '_' + year;
        if (this.cols[4]) {
            this.cols[4].field = 'r_' + month + '_' + year;
        } 

        if (this.cols[5]) {
            this.cols[5].field = month;
        }

        if (this.cols[6]) {
            this.cols[6].field = 'c_' + month + '_' + year;
        }

        if (this.cols[7]) {
            this.cols[7].field = 'p_' + month + '_' + year;
        }

        this.filter.filter();
    }

    monthFromNumber(number: number): string {
        return ['enero',
            'febrero',
            'marzo',
            'abril',
            'mayo',
            'junio',
            'julio',
            'agosto',
            'septiembre',
            'octubre',
            'noviembre',
            'diciembre'][number] ?? '';
    }
}
