import { Component } from '@angular/core';
import { RopoApiService } from '../../../../service/api/ropo-api.service';
import { BaseView } from '../../../base-view';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { environment } from '../../../../../environments/environment';
import { Utils } from '../../../../common/utils';
import { firstValueFrom } from 'rxjs';
import { RequestHandler } from 'src/app/service/OffService/request-handler';

import type { OnInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'app-consultas',
    templateUrl: './consultas.component.html',
    styleUrls: ['./consultas.component.scss']
})
export class ConsultasComponent extends BaseView implements OnInit, OnDestroy {
    public localizado = true;
    public dni = '';
    public showRopo = false;
    public showRoma = false;
    public showRegepa = false;
    public informe: any[] = [];
    public showInforme = false;
    public logoUrl = environment.logoUrl;
    public hoy = Utils.formatDate(new Date());
    public listadoDnis: any[] = [];
    public imprimirRopo = false;
    public imprimirRopoMultiple = false;
    public contadorDnis = 0;
    public maxDnis = 0;
  
    public charset = 'windows-1252';
    codigo_regepa = '';

  
    constructor(private _ropoService: RopoApiService,
        public dashboard: DashboardService    ) {
        super(dashboard, new RequestHandler());
    }


    ngOnInit() {


    }

    buscaDni() {

        this.localizado = false;
        this.showRopo = false;
        this.showRoma = false;
        this.showRegepa = false;
        this.codigo_regepa = '';

        (firstValueFrom(this._ropoService.getDatosDni(this.dni)) as any)
            .then((resp: any[]) => {
                //console.log('resp[2].data', resp[2].data);
                try {
                    this.codigo_regepa = resp[2].data['código'];
                } catch (error) {
                    this.codigo_regepa = '';
                }

                this.informe = resp;
                if ( this.informe != null ){

                    if (this.informe[0].errores.length === 0) { this.showRopo = true; };
                    if ( this.informe.length >= 2) {
                        if (this.informe[1].errores.length === 0) { this.showRoma = true; };
                    }

                    if ( this.informe.length >= 3) {
                        if (this.informe[2].errores.length === 0) { this.showRegepa = true; };
                    }

                }

                if ( this.informe.length >= 2) {
                    for (let index = 0; index < this.informe[1].data.length; index++) {
                        this.informe[1].data[index].tipo = Utils.htmlEntities(this.informe[1].data[index].tipo as string);
                    }
                }


                this.localizado = true;
                this.showInforme = true;
                // console.log('Informe',this.informe);

            })
            .catch((error: any) => console.log('error', error)
            );

    }  

    public imprimirConsulta() {
        this.imprimirRopo = true;
        firstValueFrom(this._ropoService.imprimirConsulta(this.dni))
            .then((resp: any) => {

                this.createCsv(resp); 
                this.imprimirRopo = false;
            }).catch(() => {
                this.imprimirRopo = false;
            });

    }


    createCsv(resp: any, multiple: boolean = false) {
    //console.log('resp', resp);

        if (resp.csv != null) {
            let csvContent = 'data:text/csv;charset=' + this.charset + ',\ufeff';
            const rows = resp.csv;
            rows.forEach(function (rowArray: any) {
                const row = rowArray;
                csvContent += (row as string);
            });
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            if (multiple === false) {
                link.setAttribute('download', 'Ropo_' + this.dni + '.csv');
            } else {
                link.setAttribute('download', 'Ropo_multiple.csv');
            }

            document.body.appendChild(link); // Required for FF

            link.click(); // This will download the data file named "my_data.csv".
        }
    }
}