<br>

<div style="width: auto; overflow: auto">
    <!-- <common-list-table [request]="getRequest" [cols]="cols" action-button-text="Añadir formulaio 2" (action-button-click)="add()" [global-conditional-style]="globalConditionalStyle"> -->
    <app-common-list-table [request]="getRequest" [cols]="cols" [globalVisualTransform]="globalVisualTransform" [globalConditionalStyle]="globalConditionalStyle">
    
        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center" style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons [hideDelete]="true" [hideEdit]="true"  (editRowButtonEvent)="edit()" (deleteRowButtonEvent)="show('delete', data)" (showRowButtonEvent)="show('info', data)">
                </app-action-buttons>
            </td>
        </ng-template>
    </app-common-list-table>
</div>

<p-dialog *ngIf="canShow['info']" header="Detalle del registro" [(visible)]="canShow['info']" (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <app-form-builder 
            [fields]="formFields" 
            [distributionColumns]="3" 
            [model]="model">
        </app-form-builder>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog>

<p-dialog *ngIf="canShow['delete']"  header="Eliminar registro" [(visible)]="canShow['delete']" (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <app-form-builder [fields]="formFields" [distributionColumns]="4" [model]="model">
        </app-form-builder>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button (click)="deleteRegister()" [request]="deleteRequest" [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading" style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>

</p-dialog>