<p-panel>
    <h4>Datos del producto</h4>
    <app-form-builder
        [fields]="formFields"
        [distributionColumns]="3"
        (formChanges)="formChanges()"
        [model]="model">
    </app-form-builder>
    <br>
    <div class="form-row">
        <div class="col-12 d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
            <app-request-button
                [request]="request"
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true">
            </app-request-button>
        </div>
    </div>
</p-panel>
<br>
