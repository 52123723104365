<!-- FORM FIELDS -->
<p-panel>
    <div style="display: flex; gap:10px; ">
        <h4>Datos de la tarea</h4>
        <div *ngIf="loadingData">
            <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="6" animationDuration="1.5s">
            </p-progressSpinner>
        </div>
    </div>
    
    <div class="row">
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="this.getType() === 2 ? 4 : 3"
            [fields]="form.formFieldsLugar">
        </app-form-builder>
    </div>
    <div class="row">
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="4"
            [fields]="form.formFieldsTratamiento">
        </app-form-builder>
    </div>
    <!-- ACTION BUTTONS -->
    <div class="d-flex justify-content-center">
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>