import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VoiceRecognitionService } from './voice-recognition.service';

import type { OnInit } from '@angular/core';


@Component({
    selector: 'app-dictado',
    templateUrl: './dictado.component.html',
    styleUrls: ['./dictado.component.scss']
})
export class DictadoComponent implements OnInit {
  
    @Input() mensaje = '' ;
    @Input() observaciones = '' ;
    @Output() sendObservaciones: EventEmitter<string> = new EventEmitter<string>();
    
    public grabandoMicrofono = false;


    constructor( public vrService: VoiceRecognitionService ) {
        this.vrService.auxTemps.subscribe( texto => {
            console.log('texto', texto);
        });
    }

    ngOnInit() {
        this.vrService.init();
    }

    public start() {
        this.vrService.text = '';
        this.grabandoMicrofono = true;
        this.vrService.start();
   
    }

    public stop() {
        this.grabandoMicrofono = false;
        this.vrService.stop();
        if (!this.observaciones) {
            this.observaciones = '';
        }

        console.log('service ', this.vrService.text);
    
    
        this.observaciones += this.vrService.text;
        console.log('this.observaciones', this.observaciones );
    
        this.sendObservaciones.emit(this.observaciones );
    }

    public deleteRecorded() {
        this.observaciones = '';
        this.sendObservaciones.emit(this.observaciones);
    }

}
