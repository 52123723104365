import { InputType } from '../../../../../../common/components/form-builder/form-builder.component';
import { StorageManager } from '../../../../../../common/storage-manager.class';
import { environment } from '../../../../../../../environments/environment';

import type { ItemInterface } from '../../../../../../common/components/form-builder/form-builder.component';

const showAnalisisSectorParcelasForm = environment.features.showAnalisisSectorParcelas;
const showAnalisisM3Agua = environment.features.showAnalisisM3Agua;
const showAnalisisMgrl = environment.features.showAnalisisMgrl;
const showCultivosBajoAguaFields = environment.features.showCultivosBajoAguaFields;


export const armentiaFields = function ( clienteName: any, clientes: { selected: any; }, clienteActual: { id: any; }, hasClients: any,
    fincaName: any, 
    fincas: { selected: any; },
    parcelasName: any, 
    parcelas: any,
    showAnalisisParcelas: any,
    tiposAnalisis: any,
    frecuencias: any,
    appName: string,
    lista_analisis: any,
    showAnalisisBoletin: any,
    estados: any) {
       
        
    const formfield =  [
        {
            label: clienteName,
            field: 'id_cliente',
            inputType: {type: InputType.DROPDOWN},
            required: true,
            values: clientes,
            valuePrimaryKey: 'id',
            filter: (it: { value: any; }) => clienteActual ? ((it.value || {}).id === clienteActual.id) : it,
            visible: hasClients && (StorageManager.getUser().rol === 'admin')
        },
        {
            label: fincaName,
            field: 'id_finca',
            inputType: {type: InputType.DROPDOWN},
            required: true,
            values: fincas,
            valuePrimaryKey: 'id',
            filter: (it: { value: { id_cliente: any; }; }) => {
                if (hasClients && (StorageManager.getUser().rol === 'admin')) {
                    return it.value.id_cliente === (clientes.selected || {}).id;
                } else { 
                    return it;
                }
            }
        },
        {
            label: parcelasName,
            field: 'id_parcela',
            inputType: {type: InputType.DROPDOWN},
            required: true,
            values: parcelas,
            valuePrimaryKey: 'id',
            filter: (it: { value: { id_finca: any; id_cliente: any; }; }) => {
                if (fincas.selected) {
                    return it.value.id_finca === (fincas.selected || {}).id;
                } else if (clientes.selected) {
                    return it.value.id_cliente === (clientes.selected || {}).id;
                } else if (StorageManager.getClient()) {
                    return it.value.id_cliente === StorageManager.getClient().id;
                }
                return true;
            },
            visible: showAnalisisParcelas
        },
        {
            label: 'Tipo de análisis',
            field: 'tipo_analisis',
            inputType: {type: InputType.DROPDOWN},
            required: true,
            values: tiposAnalisis
        },
        {
            label: 'Fecha',
            field: 'fecha',
            inputType: {type: InputType.CALENDAR},
            required: true
        },
        {
            label: 'Muestra',
            field: 'muestra',
            inputType: {type: InputType.EDIT_TEXT}
               
        },
        {
            label: 'Frecuencia',
            field: 'frecuencia',
            inputType: {type: InputType.DROPDOWN},
            values: frecuencias,
            disabled: appName === 'lafast'
        },
        {
            label: 'Objeto del análisis',
            field: 'objeto_analisis',
            inputType: {type: InputType.DROPDOWN},
            values: lista_analisis,
            disabled: appName === 'lafast'
        },
           
        {
            label: 'Nº boletín',
            field: 'boletin',
            inputType: {type: InputType.EDIT_TEXT},
            visible:  showAnalisisBoletin
        },
        {
            label: 'Laboratorio',
            field: 'laboratorio',
            inputType: {type: InputType.EDIT_TEXT},
            visible:  showAnalisisBoletin
        },
        {
            label: 'Sustancia activa detectada',
            field: 'sustancia_activa',
            inputType: {type: InputType.EDIT_TEXT},
            visible:  showAnalisisBoletin
        },
        {
            label: 'Estado',
            field: 'estado',
            inputType: {type: InputType.DROPDOWN},
            values:  estados
        },
        {
            label:  appName === 'laplana' ? 'Notas (cultivo)' : 'Notas',
            field: 'materia_activa',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Volumen (kg)',
            field: 'volumen_kg',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: '',
            field: 'adjunto',
            inputType: {type: InputType.EMPTY}
        },
        {
            label: '',
            field: 'fechaSiguiente',
            inputType: {type: InputType.EMPTY},
            visible: false
        },
          
    ];
    return formfield;
};

export const buildFieldsGeneral = function ( clienteName: any, clientes: { selected: any; }, clienteActual: { id: any; }, hasClients: any,
    fincaName: any, 
    fincas: any,
    tiposAnalisis: any,
    frecuencias: any,
    appName: string,
    applicationType: string,
    lista_analisis: any,
    showAnalisisBoletin: any,
    estados: any, 
    sectores: any, 
    lista_parcelas: any,
    showAnalisisNumeroAnalisis: any, ) {
    /* console.log('sectores: ',sectores);
        console.log('fincas: ',fincas); */
            
    const formfield =  [
        {
            label: clienteName,
            field: 'id_cliente',
            inputType: applicationType === 'cropgest' ? {type: InputType.MULTISELECT} : {type: InputType.DROPDOWN},
            required: true,
            multiSelect: applicationType === 'cropgest',
            canSelectAll: applicationType === 'cropgest',
            values: clientes,
            valuePrimaryKey: 'id',
            filter: (it: { value: any; }) => clienteActual ? ((it.value || {}).id === clienteActual.id) : it,
            visible: hasClients && (StorageManager.getUser().rol === 'admin')
        },
        {
            label: fincaName,
            field: 'id_finca',
            inputType: {type: InputType.DROPDOWN},
            required: true,
            values: fincas,
            valuePrimaryKey: 'id',
            filter: (it: { value: { id_cliente: any; }; }) => {
                if (hasClients && (StorageManager.getUser().rol === 'admin')) {
                            
                    if (applicationType === 'cropgest') {
                        const listaIds: any[] = [];

                        /* Sí se intenta devolver la finca dentro del bucle no sucede nada, 
                            por lo que se recoge en un listado los ids de los clientes para 
                            hacer fuera la comprobación.
                        */
                        clientes.selected.forEach((cliente: { id: any; }) => {
                            listaIds.push(cliente.id);
                        });

                        if (listaIds.includes(it.value.id_cliente)) {
                            console.log(it.value);
                            return it;
                        }
                    }
                    else {
                        return it.value.id_cliente === (clientes.selected || {}).id;
                    }
                } else { 
                    return it;
                }
                return false;
            }
        },
        {
            field: 'id_sector',
            label: appName === 'pyf' ? 'Paraje' : 'Sector',
            inputType: {type: InputType.DROPDOWN},
            // multiSelect: this.showTareasFitoSectorMultiple,
            values: sectores,
            valuePrimaryKey: 'id',
            filter: (it: { value: { id_finca: any; }; }) => {
                if (formfield[1]?.values.selected == null ) {

                } else if ( formfield[1].values.selected.id === it.value.id_finca) {
                    return true;
                }
                return false;
            },
            visible: showAnalisisSectorParcelasForm,
        },
        {
            field: 'ids_parcelas',
            label: 'Parcelas',
            inputType: {type: InputType.MULTISELECT},
            values: lista_parcelas,
            valuePrimaryKey: 'id',
            multiSelect: true,
            canSelectAll: true,
            visible: showAnalisisSectorParcelasForm,
            filter: (it: { value: { activo: string; id_sector: any; id_finca: any; }; }) => {
                // console.log('it',it);
                        
                if (it.value.activo !== '1') {
                    return false;
                }
                // id_sector
                if (formfield[2]?.values.selected) {
                    /* if ( formfield[1].values.selected.id == it.value.id_finca ) {

                               
                                    return formfield[3].values.selected.map(sector => sector.id).includes(it.value.id_sector);
                                }
                                 */
                    return it.value.id_sector === (formfield[2].values.selected || {}).id;
                } else {
                    return it.value.id_finca === (formfield[1]?.values.selected || {}).id;
                }
                        
                       
            }
        },
               
        {
            label: 'Fecha',
            field: 'fecha',
            inputType: {type: InputType.CALENDAR},
            required: true
        },
        /*                 {
                    label: 'Muestra',
                    field: 'muestra',
                    inputType: {type: InputType.EDIT_TEXT},
                    visible: environment.appName !== 'fitogest' 
                }, */
        {
            label: 'Semana',
            field: 'semana',
            inputType: {type: InputType.EDIT_TEXT},
            visible: applicationType === 'viverogest'
        },
        {
            label: 'Remesa',
            field: 'remesa',
            inputType: {type: InputType.EDIT_TEXT},
            visible: applicationType === 'viverogest'
        },
        {
            label: 'Tipo de análisis',
            field: 'tipo_analisis',
            inputType: {type: InputType.DROPDOWN},
            required: true,
            values: tiposAnalisis
        },
        {
            label: 'Frecuencia',
            field: 'frecuencia',
            inputType: {type: InputType.DROPDOWN},
            values: frecuencias,
            disabled: appName === 'lafast'
        },
        {
            label: 'Muestra',
            field: 'muestra',
            inputType: {type: InputType.EDIT_TEXT},
            disabled: appName !== 'fitogest'
        },
        {
            label: 'Origen',
            field: 'origen',
            inputType: {type: InputType.EDIT_TEXT},
            disabled: appName !== 'fitogest',
            visible: appName === 'almassora' || appName === 'burriana' || appName === 'lacooperativa'  || appName === 'onda'
        },
        {
            label: 'Objeto del análisis',
            field: 'objeto_analisis',
            inputType: {type: InputType.DROPDOWN},
            values: lista_analisis,
            disabled: appName !== 'fitogest'
        },
        {
            label: 'Referencia Análisis',
            field: 'analisis',
            inputType: {type: InputType.EDIT_TEXT},
            visible:  showAnalisisNumeroAnalisis
        },
        {
            label: 'Laboratorio',
            field: 'laboratorio',
            inputType: {type: InputType.EDIT_TEXT},
            visible:  showAnalisisBoletin
        },
        {
            label: 'Sustancia activa detectada',
            field: 'sustancia_activa',
            inputType: {type: InputType.EDIT_TEXT},
            visible:  showAnalisisBoletin
        },
        {
            label: 'Estado',
            field: 'estado',
            inputType: {type: InputType.DROPDOWN},
            values:  estados
        },
        {
            label:  appName === 'laplana' ? 'Notas (cultivo)' : 'Notas',
            field: 'materia_activa',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Volumen (kg)',
            field: 'volumen_kg',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Agua m³',
            field: 'agua_m',
            inputType: {type: InputType.EDIT_TEXT},
            visible: showAnalisisM3Agua
        },
        {
            label: 'Nitratos (mg/l)',
            field: 'mgrl',
            inputType: {type: InputType.EDIT_TEXT},
            visible: showAnalisisMgrl || showCultivosBajoAguaFields
        },
        {
            label: '',
            field: 'adjunto',
            inputType: {type: InputType.EMPTY}
        },
        {
            label: '',
            field: 'fechaSiguiente',
            inputType: {type: InputType.EMPTY},
            visible: false
        },
              
    ];
    return formfield;
};

export const buildFieldsForms = function (clienteName: any, clientes: { selected: any; }, 
    clienteActual: { id: any; }, hasClients: any,
    fincaName: any, fincas: { selected: any; },
    parcelasName: any, parcelas: any,
    showAnalisisParcelas: any, tiposAnalisis: any,
    frecuencias: any,
    appName: string,
    applicationType: string,
    lista_analisis: any,
    showAnalisisNumeroAnalisis: any,
    showAnalisisBoletin: any,
    estados: any,
    sectores: any,
    lista_parcelas: any
): ItemInterface<any>[] {
    if ( appName.localeCompare('armentia') === 0 ){
        return armentiaFields(clienteName, clientes, clienteActual, hasClients,
            fincaName, fincas,
            parcelasName, parcelas,
            showAnalisisParcelas, tiposAnalisis,
            frecuencias,
            appName,
            lista_analisis,
            showAnalisisBoletin,
            estados);
    } else {
        return buildFieldsGeneral(clienteName, clientes, clienteActual, hasClients,
            fincaName, fincas,
            tiposAnalisis,
            frecuencias,
            appName,
            applicationType,
            lista_analisis,
            showAnalisisNumeroAnalisis,
            showAnalisisBoletin,
            estados, 
            sectores,
            lista_parcelas
        );
    }
  
};
    
