import { InputType, ItemInterface } from '../../../../../../common/components/form-builder/form-builder.component';
import { Filtering } from '../../../../../../service/filtering/filtering';
import { list } from '../../../../../../common/classes/request-builder';
import { environment } from 'src/environments/environment';
export class ProductosFitosanitariosFormDefinition {

    public showProductosNumReg = environment.features.showProductosNumReg;
    public showNPK = environment.features.showNPK;
    public showProductosFitoDosis = environment.features.showProductosFitoDosis;
    public showReto = environment.features.showReto;
    public showProductosMaxAplicacion = environment.features.showProductosMaxAplicacion;
    public showCostes = environment.features.showCostes;
    public appName = environment.appName;

    public fincas = list();
    public productos = list();
    public usos = list();
    public agentes = list();
    public estado = list(['...', null], 'Activo', 'No Activo');
    public unidades = list(['...', null], 'unidades', 'sobres', 'sacos', 'toneladas', 'CC.', 'Gr.', 'Kilos', 'Litros,', 'ml');
    public unidades_costes = list(['...', null], 'Kilos', 'Litros');

    public usosFilter: Filtering<any> = new Filtering<any>();
    public agentesFilter: Filtering<any> = new Filtering<any>();

    public cols = [
        { field: 'referencia', header: 'N˚ Registro', filter: '' },
        { field: 'nombre_comercial', header: 'Nombre', filter: '' },
        { field: 'titular', header: 'Titular', filter: '' },
        { field: 'fecha_inscripcion', header: 'Inscripción', filter: '' },
        { field: 'fecha_caducidad', header: 'Caducidad', filter: '' },
        { field: 'composicion', header: 'Composición', filter: '' }
    ];

    public topFormFields: ItemInterface<any>[] = [
        {
            label: 'Con N˚ Registro',
            field: 'hasntRegisterNumber',
            inputType: { type: InputType.SWITCH },
            visible: this.showProductosNumReg || (environment.appName === 'fotoagricultura' && localStorage.getItem('sm') == null)
        },
        { label: 'Nº Registro', field: 'num_registro', inputType: { type: InputType.EDIT_TEXT } },
        { label: 'Nombre del producto', field: 'nombre', inputType: { type: InputType.EDIT_TEXT } },
        { label: 'Usos', field: 'uso', inputType: { type: InputType.DROPDOWN }, values: this.usos },
        { label: 'Agentes', field: 'agente', inputType: { type: InputType.DROPDOWN }, values: this.agentes },
        { label: 'Otra denominación común', field: 'otra_denominacion_comun', inputType: { type: InputType.SWITCH } },
        {
            label: '',
            field: 'imagen',
            inputType: { type: InputType.EMPTY },
            visible: this.appName === 'agroleza'
        },
    ];

    public topFormFieldsNoReg: ItemInterface<any>[] = [
        { label: 'Sin N˚ Registro', field: 'hasntRegisterNumber', inputType: { type: InputType.SWITCH } },
        { label: 'Nº Registro', field: 'num_registro', inputType: { type: InputType.EDIT_TEXT } },
        { label: 'Nombre del producto', field: 'nombre', inputType: { type: InputType.EDIT_TEXT }, required: true },
        { label: 'Plazo Seguridad', field: 'ps', inputType: { type: InputType.EDIT_TEXT } },
        { label: 'Materia Activa', field: 'materia_activa', inputType: { type: InputType.EDIT_TEXT } }
    ];

    public bottomFormFieldsLeft = [
        { label: 'Precio', field: 'precio', inputType: { type: InputType.EDIT_NUMERIC } },
        {
            label: 'Uds. Medida',
            field: 'unidades',
            inputType: { type: InputType.DROPDOWN }, values: this.showCostes ? this.unidades_costes : this.unidades, required: this.showCostes
        },
        { label: 'Envase', field: 'envase', inputType: { type: InputType.EDIT_TEXT } },
        { label: 'Dosis', field: 'dosis', inputType: { type: InputType.EDIT_TEXT }, visible: this.showProductosFitoDosis },
        {
            label: 'Nº Máximo aplicaciones',
            field: 'num_maximo_aplicaciones',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.showProductosMaxAplicacion
        },
        { label: 'Observaciones', field: 'observaciones', inputType: { type: InputType.EDIT_TEXT }, visible: this.appName === 'amoros' },
        { label: 'Última fecha compra', field: 'ultima_fecha_compra', inputType: { type: InputType.CALENDAR }, visible: this.appName === 'amoros' }

    ];

}