import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { RequestButtonTypes } from 'src/app/common/components/request-button/request-button.component';
import { Utils } from 'src/app/common/utils';
import { ProductosApiService } from 'src/app/service/api/productos-api';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { BaseView } from 'src/app/view/base-view';
import { environment } from 'src/environments/environment';

import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-producto-ganaderia',
    templateUrl: './producto-ganaderia.component.html',
    styleUrls: ['./producto-ganaderia.component.scss']
})
export class ProductoGanaderiaComponent extends BaseView implements OnInit, OnDestroy {

    public appName = environment.appName;
    public canAddProductos = environment.features.canAddProductos;
    public canEditProductos = environment.features.canEditProductos;
    public canDeleteProductos = environment.features.canDeleteProductos;

    public showDescripcion = environment.features.showDescripcion;
    public showPrecio = environment.features.showPrecio;


    public getRequest: RequestHandler = this.productosApi.productos.GET;
    public deleteRequest: RequestHandler = this.productosApi.productos.DELETE;
    public menuItemId = 'productos-ganaderia';
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: { [key: string]: any } = {};
    public override model: { [key: string]: any } = {};

    public tableValues = new RequestHandler();  

    public topFormFields: ItemInterface<any>[] = [
        {label: 'Nombre', field: 'nombre', inputType: {type: InputType.TEXT}},
        {label: 'Unidades', field: 'unidades', inputType: {type: InputType.TEXT}},
        {label: 'Precio', field: 'precio', inputType: {type: InputType.TEXT}, visible: this.showPrecio},
        {label: 'Observaciones', field: 'observaciones', inputType: {type: InputType.TEXT}},
    ];

    public cols = [
        {field: 'nombre', header: 'Nombre', filter: ''},
        {field: 'unidades', header: 'Unidades', filter: '', width: '80px'},
        {field: 'precio', header: 'Precio', filter: '', width: '80px', visible: this.showPrecio},
        {field: 'observaciones', header: 'Observaciones'}
    ];

    constructor(public productosApi: ProductosApiService,
        private dashboardEvents: DashboardService,
        private router: Router) {
        super(dashboardEvents, productosApi.productos.GET);
    }

    ngOnInit() {
        this.dashboardEvents.setSubmenuItem(this.menuItemId);
        this.tableValues.isLoading = true;
        this.productosApi.productos.GET.safePerform();
        this.productosApi.productos.GET.response(data => {
            this.tableValues.emitResponse(data.filter((item: { tipo: string; }) => {
                this.tableValues.isLoading = false;
                return item.tipo === 'ganaderia';
            }));
        });
    }

    public globalConditionalStyle(value: string, col: { field: string; }, _row: any): any {

        if (col.field === 'observaciones') {
            return {
                textAlign: 'left',
                paddingLeft: '35px',
            };
      
        }

        if (Utils.isNumber(value) || col.field === 'precio') {
            return {
                textAlign: 'right',
                paddingRight: '30px',
            };
      
        }
  
        if (Utils.isNumber(value) && col.field !== 'descripcion') {
            return {
                textAlign: 'right',
            };
        }
  
    }

    public globalVisualTransform(value: string, col: { field: string; }) {
        if (Utils.isNumber(value) && col.field !== 'descripcion') {
            return Utils.decimalFormat(value, 2, '.', ',', 3);
        } else {
            return value;
        }
    }

    public addProducto() {
        this.router.navigate(['dashboard', 'crear-producto-ganaderia']);
    }

    public edit(data: any) {
        this.router.navigate(['dashboard', 'editar-producto-ganaderia', data.id]);
    }

    public override deleteRegister() {
        if (this.model['id']) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public override show(modal: string, data: any) {
        let x;

        this.model = data;
        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow.hasOwnProperty(x)) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

}
