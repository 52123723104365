import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import type { CanActivate } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ShowVisitasSeguimientoGuardService implements CanActivate {

    constructor(public router: Router) {}

    canActivate(): boolean {

        if (!environment.features.showVisitasSeguimiento) {
            this.router.navigate(['login']);
            return false;
        }

        return true;
    
    }
}
