/* eslint-disable max-len */
import { CostesProductosComponent } from './view/main/9_costes/costes-productos/costes-productos.component';
import { CrmFormComponent } from './view/main/2_fincas/crm/crm-form/crm-form.component';
import { CrmComponent } from './view/main/2_fincas/crm/cmr.component';
import { CRMConsultasComponent } from './view/main/2_fincas/crm-consultas/crm-consultas.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeEs from '@angular/common/locales/es';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OcticonDirective } from './common/directives/octicon.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { LoginComponent } from './view/login/login.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { VentasComponent } from './view/main/3_ventas/ventas/ventas.component';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChipsModule } from 'primeng/chips';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SidenavCellComponent } from './view/dashboard/sidenav-cell/sidenav-cell.component';
import { InicioComponent } from './view/main/0_inicio/inicio.component';
import { TrabajadoresComponent } from './view/main/4_trabajadores/trabajadores.component';
import { TareasComponent } from './view/main/5_tareas/tareas.component';
import { VentasFormComponent } from './view/main/3_ventas/ventas/ventas-form/ventas-form.component';
import { SidenavBarComponent } from './common/components/sidenav-bar/sidenav-bar.component';
import { MantenimientoComponent } from './view/main/4_trabajadores/mantenimiento/mantenimiento.component';
import { FertilizantesComponent } from './view/main/5_tareas/fertilizantes/fertilizantes.component';
import { FitosanitariosComponent } from './view/main/5_tareas/fitosanitarios/fitosanitarios.component';
import { TareasBioComponent } from './view/main/5_tareas/tareas_bio/tareas_bio.component';
import { TareasBioFormComponent } from './view/main/5_tareas/tareas_bio/tareas_bio-form/tareas_bio-form.component';
import { TratamientosComponent } from './view/main/7_tratamientos/tratamientos.component';
import { ProductosComponent } from './view/main/6_productos/producto-add/productos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FitosanitariosFormComponent } from './view/main/5_tareas/fitosanitarios/fitosanitarios-form/fitosanitarios-form.component';
import { TratamientosFromComponent } from './view/main/7_tratamientos/tratamientos-form/tratamientos-from.component';
import { ProductosFormComponent } from './view/main/6_productos/producto-add/productos-form/productos-form.component';
import { FincasComponent } from './view/main/2_fincas/fincas.component';
import { CommonListHeaderComponent } from './common/components/common-list-header/common-list-header.component';
import { CommonListTableComponent } from './common/components/common-list-table/common-list-table.component';
import { FincaComponent } from './view/main/2_fincas/finca/finca.component';
import { ParcelasComponent } from './view/main/2_fincas/parcelas/parcelas.component';
import { SectoresComponent } from './view/main/2_fincas/sectores/sectores.component';
import { FincaFormComponent } from './view/main/2_fincas/finca/finca-form/finca-form.component';
import { ParcelasFormComponent } from './view/main/2_fincas/parcelas/parcelas-form/parcelas-form.component';
import { MantenimientoFormComponent } from './view/main/4_trabajadores/mantenimiento/mantenimiento-form/mantenimiento-form.component';
import { SectorFormComponent } from './view/main/2_fincas/sectores/sector-form/sector-form.component';
import { FertilizantesFormComponent } from './view/main/5_tareas/fertilizantes/fertilizantes-form/fertilizantes-form.component';
import { ClientesComponent } from './view/main/1_clientes/clientes.component';
import { ClientesFormComponent } from './view/main/1_clientes/clientes-form/clientes-form.component';
import { CompradoresComponent } from './view/main/3_ventas/compradores/compradores.component';
import { CompradoresFormComponent } from './view/main/3_ventas/compradores/compradores-form/compradores-form.component';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MaquinariaComponent } from './view/main/4_trabajadores/maquinaria/maquinaria.component';
import { MaquinariaFormComponent } from './view/main/4_trabajadores/maquinaria/maquinaria-form/maquinaria-form.component';
import { FormBuilderComponent } from './common/components/form-builder/form-builder.component';
import { RequestButtonComponent } from './common/components/request-button/request-button.component';
import { ActionButtonsComponent } from './common/components/action-buttons/action-buttons.component';
import { SpinnerComponent } from './common/components/spinner/spinner.component';

import { BlockUIModule } from 'primeng/blockui';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { AccordionModule } from 'primeng/accordion';
import { ChartModule } from 'primeng/chart';
import { CardModule } from 'primeng/card';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

import { ProductosConsultasComponent } from './view/main/6_productos/productos-consultas/productos-consultas.component';
import { ProductoFertilizanteComponent } from './view/main/6_productos/producto-fertilizante/producto-fertilizante.component';
import { ProductoFertilizanteFormComponent } from './view/main/6_productos/producto-fertilizante/producto-fertilizante-form/producto-fertilizante-form.component';
import { VisitasComponent } from './view/main/8_visitas/visitas/visitas.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { VisitasFormComponent } from './view/main/8_visitas/visitas/visitas-form/visitas-form.component';
import { AbonadoComponent } from './view/main/5_tareas/fertilizantes/fertilizantes-form/abonado/abonado.component';
import { MapaAddComponent } from './view/main/2-1_mapas/mapa-add/mapa-add.component';
import { MapaSateliteComponent } from './view/main/2-1_mapas/mapa-satelite/mapa-satelite.component';
import { VisitasVariedadMesComponent } from './view/main/8_visitas/visitas-variedad-mes/visitas-variedad-mes.component';
import { VisitasConsultasComponent } from './view/main/8_visitas/visitas-consultas/visitas-consultas.component';
import { VisitasSemanaComponent } from './view/main/8_visitas/visitas-semana/visitas-semana.component';
import { AnalisisComponent } from './view/main/2_fincas/analisis/analisis.component';
import { AnalisisFormComponent } from './view/main/2_fincas/analisis/analisis-form/analisis-form.component';

import { ModalsComponent } from './view/app-common/modals/modals.component';
import { DecodeInterceptor } from './service/decode.interceptor';

// AGROGEST
import { OtrosComponent } from './view/main/5_tareas/otros/otros.component';
import { OtrosFormComponent } from './view/main/5_tareas/otros/otros-form/otros-form.component';
import { PartesComponent } from './view/main/4_trabajadores/partes/partes.component';
import { FichajesComponent } from './view/main/4_trabajadores/fichajes/fichajes.component';
import { CostesTareasComponent } from './view/main/9_costes/costes-tareas/costes-tareas.component';
import { StockComponent } from './view/stock/stock.component';
import { EntradasComponent } from './view/stock/entradas/entradas.component';
import { SalidasComponent } from './view/stock/salidas/salidas.component';
import { BalancesComponent } from './view/stock/balances/balances.component';
import { StockFormularioProductosComponent } from './view/stock/productos/stock-formulario-productos/stock-formulario-productos.component';
import { StockProductosComponent } from './view/stock/productos/productos.component';
import { EntradasFormComponent } from './view/stock/entradas/entradas-form/entradas-form.component';
import { SalidasFormComponent } from './view/stock/salidas/salidas-form/salidas-form.component';

import { TratamientosControlComponent } from './view/main/7_tratamientos/control/control.component';
import { TratamientosControlFormComponent } from './view/main/7_tratamientos/control/control-form/control-form.component';
import { CalendarioAbonadoComponent } from './view/main/5_tareas/calendario-abonado/calendario-abonado.component';
import { TrataManezComponent } from './view/main/7_tratamientos/trata-manez/trata-manez.component';
import { TrataManezFormComponent } from './view/main/7_tratamientos/trata-manez/trata-manez-form/trata-manez-form.component';
import { CamposComponent } from './view/main/2_fincas/campos/campos.component';
import { CamposFormComponent } from './view/main/2_fincas/campos/campos-form/campos-form.component';
import { CommonChartComponent } from './common/components/common-chart/common-chart.component';
import { StatisticsComponent } from './view/main/0_inicio/statistics/statistics.component';
import { ProductosSimpleComponent } from './view/main/6_productos/producto-simple/productos-simple.component';
import { ProductosSimpleFormComponent } from './view/main/6_productos/producto-simple/productos-simple-form/productos-simple-form.component';
import { UsuariosComponent } from './view/main/X_usuarios/usuarios.component';
import { UsuariosFormComponent } from './view/main/X_usuarios/usuarios-form/usuarios-form.component';
import { ControlRiegoComponent } from './view/main/5_tareas/control-riego/control-riego.component';
import { CostesFitosanitariosComponent } from './view/main/9_costes/costes-fitosanitarios/costes-fitosanitarios.component';
import { CostesFertilizantesComponent } from './view/main/9_costes/costes-fertilizantes/costes-fertilizantes.component';
import { SiembraComponent } from './view/main/5_tareas/siembra/siembra.component';
import { SiembraFormComponent } from './view/main/5_tareas/siembra/siembra-form/siembra-form.component';
import { VisitasSemanaFormComponent } from './view/main/8_visitas/visitas-semana/visitas-semana-form/visitas-semana-form.component';
import { VisitasTrasiegoComponent } from './view/main/8_visitas/visitas-trasiego/visitas-trasiego.component';
import { VisitasTrasiegoFormComponent } from './view/main/8_visitas/visitas-trasiego/visitas-trasiego-form/visitas-trasiego-form.component';
import { ConsultasComponent } from './view/main/4_trabajadores/consultas/consultas.component';
import { CostesTareasOtrosComponent } from 'src/app/view/main/9_costes/costes-tareas/modal-costes/otros/costes-tareas-otros.component';
import { CostesTareasFertilizantesComponent } from 'src/app/view/main/9_costes/costes-tareas/modal-costes/fertilizantes/costes-tareas-fertilizantes.component';
import { CostesTareasFitoSanitarioComponent } from 'src/app/view/main/9_costes/costes-tareas/modal-costes/fitosanitarios/costes-tareas-fitosanitarios.component';
import { GraficaVelocidadManezLozanoComponent } from 'src/app/view/main/7_tratamientos/trata-manez/graficas/grafica-velocidad/grafica-velocidad.component';
import { GraficaPresionManezLozanoComponent } from 'src/app/view/main/7_tratamientos/trata-manez/graficas/grafica-presion/grafica-presion.component';
import { GraficaCaudalManezLozanoComponent } from './view/main/7_tratamientos/trata-manez/graficas/grafica-caudal/grafica-caudal.component';
import { ControlSanitarioFormComponent } from './view/main/5_tareas/control-sanitario/control-sanitario-form/control-sanitario-form.component';
import { ControlSanitarioComponent } from './view/main/5_tareas/control-sanitario/control-sanitario.component';
import { FitoLocalesComponent } from './view/main/5_tareas/fito-locales/fito-locales.component';
import { FitoLocalesFormComponent } from './view/main/5_tareas/fito-locales/fito-locales-form/fito-locales-form.component';
import { TransaccionesComponent } from './view/main/XI_reto/transacciones/transacciones.component';
import { RetoComponent } from './view/main/XI_reto/reto.component';
import { EntidadesComponent } from './view/main/XI_reto/entidades/entidades.component';
import { EntidadesFormComponent } from './view/main/XI_reto/entidades/entidades-form/entidades-form';
import { TransaccionesFormComponent } from './view/main/XI_reto/transacciones/transacciones-form/transacciones-form.component';
import { MapaVentuskyComponent } from './view/main/2-1_mapas/mapa-ventusky/mapa-ventusky.component';
import { ProductoGanaderiaComponent } from './view/main/6_productos/producto-ganaderia/producto-ganaderia.component';
import { ProductoGanaderiaFormComponent } from './view/main/6_productos/producto-ganaderia/producto-ganaderia-form/producto-ganaderia-form.component';
import { DialogSigPacComponent } from './view/main/2_fincas/parcelas/parcelas-form/dialog/dialog.sigpac.component';
import { GraficaDoselManezLozanoComponent } from './view/main/7_tratamientos/trata-manez/graficas/grafica-dose/grafica-dose.component';
import { MapaRutasComponent } from './view/main/2-1_mapas/mapa-rutas/mapa-rutas.component';
import { MapaTratamientosComponent } from './view/main/2-1_mapas/mapa-tratamientos/mapa-tratamientos.component';
import { dialogTratamientoManezLozanoChartsComponent } from './view/main/7_tratamientos/control/dialog-tratamiento-manez/dialog-tratamiento-manez.component';
import { TareasMtoPodaComponent } from './view/main/5_tareas/tareas-mto-poda/tareas-mto-poda.component';
import { TareasMtoPodaFormComponent } from './view/main/5_tareas/tareas-mto-poda/tareas-mto-poda-form/tareas-mto-poda-form.component';
import { TareasMtoCespedComponent } from './view/main/5_tareas/tareas-mto-cesped-cavado/tareas-mto-cesped.component';
import { TareasMtoCespedFormComponent } from './view/main/5_tareas/tareas-mto-cesped-cavado/tareas-mto-cesped-cavado-form/tareas-mto-cesped-form.component';
import { TareasMtoLimpiezaGeneralComponent } from './view/main/5_tareas/tareas-mto-limpieza-general/tareas-mto-limpieza-general.component';
import { TareasMtoLimpiezaGeneralFormComponent } from './view/main/5_tareas/tareas-mto-limpieza-general/tareas-mto-limpieza-general-form/tareas-mto-limpieza-general-form.component';
import { TareasMtoRiegoFormComponent } from './view/main/5_tareas/tareas-mto-riego/tareas-mto-riego-form/tareas-mto-riego-form.component';
import { TareasMtoRiegoComponent } from './view/main/5_tareas/tareas-mto-riego/tareas-mto-riego.component';
import { TareasMtoPiscinasFormComponent } from './view/main/5_tareas/tareas-mto-piscinas/tareas-mto-piscinas-form/tareas-mto-piscinas-form.component';
import { TareasMtoPiscinasComponent } from './view/main/5_tareas/tareas-mto-piscinas/tareas-mto-piscinas.component';
import { TareasPlantacionFormComponent } from './view/main/5_tareas/tareas-plantacion/tareas-plantacion-form/tareas-plantacion.component';
import { TareasPlantacionComponent } from './view/main/5_tareas/tareas-plantacion/tareas-plantacion.component';
import { TareasAlquilerMaquinariaFormComponent } from './view/main/5_tareas/tareas-alquiler-maquinaria/tareas-alquiler-maquinaria-form/tareas-alquiler-maquinaria-form.component';
import { TareasAlquilerMaquinariaComponent } from './view/main/5_tareas/tareas-alquiler-maquinaria/tareas-alquiler-maquinaria.component';
import { TareasEdificiosFormComponent } from './view/main/5_tareas/tareas-edificios/tareas-edificios-form/tareas-edificios-form.component';
import { TareasEdificiosComponent } from './view/main/5_tareas/tareas-edificios/tareas-edificios.component';
import { CameraComponent } from './common/components/camera/camera.component';
import { DictadoComponent } from './common/components/dictado/dictado.component';
import { SignatureComponent } from './common/components/signature/signature.component';
import { UploadImagesComponent } from './common/components/upload-images/upload-images.component';
import { FichajesHourMinuteComponent } from './view/main/4_trabajadores/fichajes/fichajes_hours_minutes/fichajes_hours_minutes.component';
import { FichajesDecimalComponent } from './view/main/4_trabajadores/fichajes/fichajes-decimal/fichajes-decimal.component';
import { DatepickerComponent } from './common/components/datepicker/datepicker.component';
import { ConsultasLogsComponent } from './view/main/XII_consultas-logs/consultas-logs.component';
import { VisitasViverospereiraComponent } from './view/main/8_visitas/visitas-viverospereira/visitas-viverospereira.component';
import { ViverosPereiraFormComponent } from './view/main/8_visitas/visitas-viverospereira/viveros-pereira-form/viveros-pereira-form.component';
import { AgroambientalesComponent } from './view/main/5_tareas/agroambientales/agroambientales.component';
import { AgroambientalesFormComponent } from './view/main/5_tareas/agroambientales/agroambientales-form/agroambientales-form.component';
import { CalendarioTareasComponent } from './view/main/5_tareas/calendario-tareas/calendario-tareas.component';
import { SubcontratosComponent } from './view/main/5_tareas/subcontratos/subcontratos.component';
import { SubcontratosFormComponent } from './view/main/5_tareas/subcontratos/subcontratos-form/subcontratos-form.component';
import { EcorregimesComponent } from './view/main/5_tareas/ecorregimes/ecorregimes.component';
import { EcorregimesFormComponent } from './view/main/5_tareas/ecorregimes/ecorregimes-form/ecorregimes-form.component';
import { RegisterComponent } from './view/login/register/register.component';
import { ProductosBioComponent } from './view/main/6_productos/producto-bio/producto-bio.component';
import { ProductoBioFormComponent } from './view/main/6_productos/producto-bio/producto-bio-form/producto-bio-form.component';
import { RecogidasComponent } from './view/main/XIII_recogidas/recogidas.component';
import { RecogidasFormComponent } from './view/main/XIII_recogidas/recogidas-form/recogidas-form.component';
import { VisitasPedidosComponent } from './view/main/8_visitas/visitas-pedidos/visitas-pedidos.component';
import { VisitasPedidosFormComponent } from './view/main/8_visitas/visitas-pedidos/visitas-pedidos-form/visitas-pedidos-form.component';
import { LogUpdateService } from './service/ServiceWorker/log-update.service';
import { provideServiceWorker } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AlbaranesComponent } from './view/main/3_ventas/albaranes/albaranes.component';
import { AlbaranesFormComponent } from './view/main/3_ventas/albaranes/albaranes-form/albaranes-form.component';


@NgModule({
    declarations: [
        AppComponent,
        OcticonDirective,
        LoginComponent,
        RegisterComponent,
        DashboardComponent,
        VentasComponent,
        AlbaranesComponent,
        SidenavCellComponent,
        InicioComponent,
        TrabajadoresComponent,
        TareasComponent,
        VentasFormComponent,
        AlbaranesFormComponent,
        SidenavBarComponent,
        MantenimientoComponent,
        FertilizantesComponent,
        FitosanitariosComponent,
        TareasBioComponent,
        TareasBioFormComponent,
        TratamientosComponent,
        ProductosComponent,
        FitosanitariosFormComponent,
        TratamientosFromComponent,
        ProductosFormComponent,
        FincasComponent,
        CommonListHeaderComponent,
        CommonListTableComponent,
        FincaComponent,
        ParcelasComponent,
        SectoresComponent,
        FincaFormComponent,
        ParcelasFormComponent,
        MantenimientoFormComponent,
        SectorFormComponent,
        FertilizantesFormComponent,
        ClientesComponent,
        ClientesFormComponent,
        CompradoresComponent,
        CompradoresFormComponent,
        MaquinariaComponent,
        MaquinariaFormComponent,
        FormBuilderComponent,
        RequestButtonComponent,
        ActionButtonsComponent,
        SpinnerComponent,
        ProductosConsultasComponent,
        ProductoFertilizanteComponent,
        ProductoFertilizanteFormComponent,
        VisitasComponent,
        ProductoFertilizanteFormComponent,
        VisitasFormComponent,
        AbonadoComponent,
        MapaAddComponent,
        MapaSateliteComponent,
        VisitasVariedadMesComponent,
        VisitasConsultasComponent,
        VisitasSemanaComponent,
        VisitasSemanaFormComponent,
        VisitasTrasiegoComponent,
        VisitasTrasiegoFormComponent,
        VisitasPedidosComponent,
        VisitasPedidosFormComponent,
        AnalisisComponent,
        AnalisisFormComponent,
        OtrosComponent,
        OtrosFormComponent,
        SiembraComponent,
        SiembraFormComponent,
        ModalsComponent,
        PartesComponent,
        FichajesComponent,
        CostesFitosanitariosComponent,
        CostesFertilizantesComponent,
        CostesTareasComponent,
        StockComponent,
        StockProductosComponent,
        StockFormularioProductosComponent,
        EntradasComponent,
        EntradasFormComponent,
        SalidasComponent,
        SalidasFormComponent,
        BalancesComponent,
        TratamientosControlComponent,
        TratamientosControlFormComponent,
        CalendarioAbonadoComponent,
        CalendarioTareasComponent,
        TrataManezComponent,
        TrataManezFormComponent,
        CamposComponent,
        CamposFormComponent,
        CommonChartComponent,
        StatisticsComponent,
        ProductosSimpleComponent,
        ProductosSimpleFormComponent,
        UsuariosComponent,
        UsuariosFormComponent,
        ControlRiegoComponent,
        ConsultasComponent,
        CostesTareasOtrosComponent,
        CostesTareasFertilizantesComponent,
        CostesTareasFitoSanitarioComponent,
        GraficaVelocidadManezLozanoComponent,
        GraficaPresionManezLozanoComponent,
        GraficaCaudalManezLozanoComponent,
        ControlSanitarioComponent,
        ControlSanitarioFormComponent,
        FitoLocalesComponent,
        FitoLocalesFormComponent,
        RetoComponent,
        TransaccionesComponent,
        EntidadesComponent,
        EntidadesFormComponent,
        TransaccionesFormComponent,
        MapaVentuskyComponent,
        ProductoGanaderiaComponent,
        ProductoGanaderiaFormComponent,
        DialogSigPacComponent,
        GraficaDoselManezLozanoComponent,
        MapaRutasComponent,
        MapaTratamientosComponent,
        dialogTratamientoManezLozanoChartsComponent,
        TareasMtoPodaComponent,
        TareasMtoPodaFormComponent,
        TareasMtoCespedComponent,
        TareasMtoCespedFormComponent,
        TareasMtoLimpiezaGeneralFormComponent,
        TareasMtoLimpiezaGeneralComponent,
        TareasMtoRiegoFormComponent,
        TareasMtoRiegoComponent,
        TareasMtoPiscinasFormComponent,
        TareasMtoPiscinasComponent,
        TareasPlantacionFormComponent,
        TareasPlantacionComponent,
        TareasAlquilerMaquinariaFormComponent,
        TareasAlquilerMaquinariaComponent,
        TareasEdificiosFormComponent,
        TareasEdificiosComponent,
        SignatureComponent,
        DictadoComponent,
        CameraComponent,
        UploadImagesComponent,
        FichajesHourMinuteComponent,
        FichajesDecimalComponent,
        DatepickerComponent,
        CrmComponent,
        CrmFormComponent,
        CRMConsultasComponent,
        CostesProductosComponent,
        ConsultasLogsComponent,
        VisitasViverospereiraComponent,
        ViverosPereiraFormComponent,
        AgroambientalesComponent,
        AgroambientalesFormComponent,
        SubcontratosComponent,
        SubcontratosFormComponent,
        EcorregimesComponent,
        EcorregimesFormComponent,
        ProductosBioComponent,
        ProductoBioFormComponent,
        RecogidasComponent,
        RecogidasFormComponent
    ],
    bootstrap: [
        AppComponent
    ], 
    imports: [
        BrowserModule,
        TableModule,
        PanelModule,
        TooltipModule,
        FormsModule,
        TooltipModule,
        ReactiveFormsModule,
        MessagesModule,
        TieredMenuModule,
        TriStateCheckboxModule,
        FileUploadModule,
        MessageModule,
        CommonModule,
        DialogModule,
        ToastModule,
        InputSwitchModule,
        SelectButtonModule,
        ProgressSpinnerModule,
        DropdownModule,
        InputTextModule,
        InputMaskModule,
        ListboxModule,
        MultiSelectModule,
        InputTextareaModule,
        BlockUIModule,
        CalendarModule,
        TabViewModule,
        AutoCompleteModule,
        ChipsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule,
        FullCalendarModule,
        FileUploadModule,
        AccordionModule,
        ChartModule,
        CardModule,
        VirtualScrollerModule,
    ], 
        providers: [
            DatePipe,
            {
                provide: LocationStrategy,
                useClass: HashLocationStrategy
            },
            { provide: LOCALE_ID, useValue: 'es-ES' },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: DecodeInterceptor,
                multi: true
            },
            MessageService,
            LogUpdateService,
            provideHttpClient(withInterceptorsFromDi()),
            provideServiceWorker('ngsw-worker.js', { enabled: environment.production })
        ]
})
export class AppModule {

}

registerLocaleData(localeEs, 'es');