import { Injectable } from '@angular/core';
import { RequestHandler } from '../OffService/request-handler';
import { SyncService } from '../OffService/sync.service';
import { ApiRequestService, HttpRequestType } from '../OffService/api-request.service';

@Injectable({
    providedIn: 'root'
})
export class CultivosVariedadApiService {

    public cultivos = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public variedad = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public cultivosSiex = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };
  
    public variedadSiex = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    constructor(
        private syncService: SyncService,
        private api: ApiRequestService    ) {
        this.defineCultivo();
        this.defineVariedad();
        this.defineCultivosSiex();
        this.defineVariedadSiex();  
    }

    public defineCultivo() {
        this.cultivos.GET.fetch(HttpRequestType.GET, 'cultivo_variedad/get_cultivos');
        this.api.registerRequest(this.cultivos.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.cultivos.GET));
    }

    public defineVariedad(){
        this.variedad.GET.fetch(HttpRequestType.GET, 'cultivo_variedad/get_variedad');
        this.api.registerRequest(this.variedad.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.variedad.GET));
    }

    public defineCultivosSiex(){
        this.cultivosSiex.GET.fetch(HttpRequestType.GET, 'cultivo_variedad/get_cultivos');
        this.api.registerRequest(this.cultivosSiex.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.cultivosSiex.GET));
    }

    public defineVariedadSiex(){
        this.variedadSiex.GET.fetch(HttpRequestType.GET, 'cultivo_variedad/get_variedades');
        this.api.registerRequest(this.variedadSiex.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.variedadSiex.GET));
    }
}
