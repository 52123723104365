<br>
<p-panel>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
      <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
    </div>
  </div>
  <div *ngIf="canRegister || applicationType === 'cropgest'">
    <h4>Datos de Cuenta</h4>
    <app-form-builder
      [model]="model"
      [distributionColumns]="3"
      [fields]="formFieldsDatosCuenta"
      (formChanges)="formChanges()">
    </app-form-builder>
    <div class="w-25" style="padding-left: 5px;">
      <div *ngIf="formRequest?.type !== 0" style="margin-top: 20px;">
        <span style="margin-top: -15px;">
          <div class="d-flex flex-nowrap" style="position: absolute;">
            <span class="mr-1">
              Contraseña actual
            </span>
            <button class="m-0 p-0 btn btn-link text-primary"
              style="font-size: .75rem;"
              (click)="togglePassword()">
              {{isShowingPassword ? 'Ocultar' : 'Ver'}}
            </button>
          </div>
          <br>
          <input pInputText type="text"
            [value]="isShowingPassword ? (model.password_old || 'Cargando...') : '************'"/>
        </span>
      </div>
    </div>
    <br>

    <h4>Datos Personales</h4>
    <app-form-builder
      [model]="model"
      [distributionColumns]="3"
      [fields]="formFieldsDatosPersonales"
      (formChanges)="formChanges()">
    </app-form-builder>
  </div>
  <div *ngIf="!canRegister && applicationType !== 'cropgest'">
    <app-form-builder
      [model]="model"
      [distributionColumns]="4"
      [fields]="formFields"
      (formChanges)="formChanges()">
    </app-form-builder>
    <app-form-builder
        [model]="model"
        [distributionColumns]="4"
        [fields]="formFields2"
        (formChanges)="formChanges()">
    </app-form-builder>

    <div class="d-flex justify-content-center align-items-center">
      <div *ngIf="applicationType !== 'cropgest'" class="w-75" style="padding-right: 5px;">
        <app-form-builder
                [model]="model"
                [distributionColumns]="3"
                [fields]="loginFormFields"
                (formChanges)="formChanges()">
        </app-form-builder>
      </div>
  
      <div class="w-25" style="padding-left: 5px;">
        <div *ngIf="formRequest?.type !== 0" style="margin-top: 20px;">
          <span style="margin-top: -15px;">
            <div class="d-flex flex-nowrap" style="position: absolute;">
              <span class="mr-1">
                Contraseña actual
              </span>
              <button class="m-0 p-0 btn btn-link text-primary"
                style="font-size: .75rem;"
                (click)="togglePassword()">
                {{isShowingPassword ? 'Ocultar' : 'Ver'}}
              </button>
            </div>
            <br>
            <input pInputText type="text"
              [value]="isShowingPassword ? (model.password_old || 'Cargando...') : '************'"/>
          </span>
        </div>
      </div>
    </div>
    <div>
      <app-form-builder
        [model]="model"
        [distributionColumns]="4"
        [fields]="roleFormFields"
        (formChanges)="formChanges()">
      </app-form-builder>
    </div>
  </div>




  <div *ngIf="showPassErrors" class="p-2 small alert alert-danger" style="width: fit-content;">
    {{passErrors}}
  </div>



  <br>

  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            [lockOnSuccess]="true"
            (click)="submit()"
            [mode]="requestButtonType"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>
