<p-panel>
  <div style="overflow-x: scroll; height: 70vh;">
    <div id="html2canvasDiv" class="p-3">

      <div class="mb-4 d-flex justify-content-between">
        <span style="font-size: 1.2rem;">Control de riego</span>
        <ng-container *ngIf="!isShowingResumen">
          <div *ngIf="!isPrinting" style="margin-top: -19px;">
            <app-form-builder 
              [model]="model"
              [distributionColumns]="2"
              [fields]="formFieldsFecha"
              (formChanges)="formChanges($event)">
            </app-form-builder>
          </div>
          <div *ngIf="isPrinting">
            Mes y año: 
            {{
              ((mesesList.selected.length === 1) ? ('0' + mesesList.selected) : mesesList.selected) + '/' + anyosList.selected
            }}
        </div>
        </ng-container>

        <div data-html2canvas-ignore="true">
          <button *ngIf="!isShowingResumen" class="mx-1 btn btn-sm btn-success" style="font-size: .7rem; height: fit-content;"
            [disabled]="!model?.id_finca || !model?.mes || !model?.anyo || !formIsDirty || isPrinting" (click)="save()">
            <i class="fa fa-save"></i>
            Guardar
          </button>
          <button class="mx-1 btn btn-sm btn-secondary" style="font-size: .7rem; height: fit-content;"
            [disabled]="!model?.id_finca || !model?.mes || !model?.anyo || isPrinting" (click)="print()">
            <i class="fa fa-print"></i>
            Imprimir
          </button>
          <!-- <button class="mx-1 btn btn-sm btn-info" style="font-size: .7rem; height: fit-content;" (click)="showStockage()"
            [disabled]="!model?.id_finca || isPrinting">
            <i class="fa fa-folder"></i>
            Stockage
          </button> -->
          <button class="mx-1 btn btn-sm btn-info" style="font-size: .7rem; height: fit-content;" (click)="toggleResumen()"
            [disabled]="!model?.id_finca || isPrinting">
            {{isShowingResumen ? 'Mostrar Control de Riego' : 'Mostrar Resumen de Consumos'}}
          </button>
        </div>
      </div>
    
      <div *ngIf="!isShowingResumen" class="bg-white custom-header">
        <div class="w-100 p-2 d-flex justify-content-between">
          <div style="flex: 3">
            <div class="d-flex justify-content-between">
              <span class="font-weight-bold" style="text-decoration: underline;">
                {{fincas.selected.razon_social || fincas.selected.nombre || 'Seleccione una finca'}}
              </span>
              <span class="font-weight-bold" style="text-decoration: underline;">CONTROL RIEGO Y ABONADO POR GOTEO EN CÍTRICOS</span>
            </div>
            <div class="d-flex justify-content-between">
              <div class="d-flex" style="flex: 2">
                <span class="mx-1 font-weight-bold" style="flex: 1">Nombre&nbsp;Finca: </span>
                <div *ngIf="!isPrinting" style="flex: 3; margin-top: -19px;">
                  <app-form-builder
                    [model]="model"
                    [distributionColumns]="1"
                    [fields]="formFieldsFinca"
                    (formChanges)="formChanges($event)">
                  </app-form-builder>
                </div>
                <div *ngIf="isPrinting">{{(fincas.selected.nombre || '')}}</div>
              </div>
              <div class="d-flex" style="flex: 2">
                <span class="mx-1 font-weight-bold" style="flex: 1">Operario: </span>
                <div *ngIf="!isPrinting" style="flex: 3; margin-top: -19px;">
                  <app-form-builder
                    [model]="model"
                    [distributionColumns]="1"
                    [fields]="formFieldsOperario"
                    (formChanges)="formChanges($event)">
                  </app-form-builder>
                </div>
                <div *ngIf="isPrinting">{{(trabajadores.selected.nombre || '') + ' ' + (trabajadores.selected.apellidos || '')}}</div>
              </div>
              <div class="text-right" style="flex: 1">
                <span class="mx-1 font-weight-bold">Firma: </span>
              </div>
            </div>
          </div>
          <div style="flex: 1">
    
          </div>
        </div>
        <table class="table table-bordered">
          <tr class="text-center font-weight-bold" style="vertical-align: middle;">
            <td rowspan="2">
              Fecha<br>(día/mes/año)
            </td>
            <td [attr.colspan]="(sectores.filtered || [0]).length">
              SECTORES DE RIEGO (Horas de riego)
            </td>
            <td class="sticky-header" style="border-bottom: none;">
              Lluvia (lts/m<sup>2</sup>)
            </td>
            <td [attr.colspan]="productosCount">
              ABONO INTRODUCIDO EN CUBAS (Kg o Lts)
            </td>
            <td rowspan="2" colspan="2" style="border-top: 1px solid white; border-right: 1px solid white">
    
            </td>
          </tr>
          <tr class="text-center" style="font-size: .6rem; vertical-align: middle;">
            <td *ngFor="let sector of (sectores.filtered.length > 0 ? sectores?.filtered : [0])" class="sticky-header">
              {{sector?.value ? sector?.label : 'Finca sin sectores'}}
            </td>
            <td style="border-top: 1px solid white">
    
            </td>
            <td *ngFor="let producto of ((productos.filtered.length > 0) ? productos?.filtered : [0])" class="sticky-header">
              <span>{{producto?.value ? producto?.label : 'Finca sin productos'}}</span>
              <br>
              <span *ngIf="producto?.value?.unidades">({{producto?.value?.unidades}})</span>
            </td>
          </tr>
          <tr *ngFor="let i of diasArray">
            <td class="text-center">
              {{i}} - {{model?.mes || 'mm'}} - {{model?.anyo || 'yyyy'}}
            </td>
            <td *ngFor="let j of columnCountArray">
              <ng-container *ngIf="model?.table[i] && model?.id_finca">
                <div *ngIf="!isPrinting">
                  <input *ngIf="!['sector_null', 'producto_null'].includes(j)"
                    type="text"
                    [(ngModel)]="model.table[i][j]"
                    maxlength="4"
                    (change)="formChanges('model_' + i + '_' + j)" />
                </div>
                <div *ngIf="isPrinting">
                  <ng-container *ngIf="!j.includes('sector') && !j.includes('producto')">
                    {{model?.table[i][j] || ''}}
                  </ng-container>
                  <ng-container *ngIf="j.includes('sector') && model?.table[i].show_sectores">
                    {{model?.table[i][j] || ''}}
                  </ng-container>
                  <ng-container *ngIf="j.includes('producto') && model?.table[i].show_productos">
                    {{model?.table[i][j] || ''}}
                  </ng-container>
                </div>
              </ng-container>
            </td>
            <td data-html2canvas-ignore="true">
              <div class="text-center">
                <p-inputSwitch
                  *ngIf="model?.table[i] && model?.id_finca"
                  [(ngModel)]="model.table[i].show_sectores"
                  (onChange)="formChanges('model_' + i + '_visible')">
                </p-inputSwitch>
                
                <p-inputSwitch
                  *ngIf="model?.table[i] && !model?.id_finca" disabled
                  [(ngModel)]="alwaysFalse[i]">
                </p-inputSwitch>
              </div>
            </td>
            <td data-html2canvas-ignore="true">
              <div class="text-center">
                <p-inputSwitch
                  *ngIf="model?.table[i] && model?.id_finca"
                  [(ngModel)]="model.table[i].show_productos"
                  (onChange)="formChanges('model_' + i + '_visible')">
                </p-inputSwitch>
                
                <p-inputSwitch
                  *ngIf="model?.table[i] && !model?.id_finca" disabled
                  [(ngModel)]="alwaysFalse[i]">
                </p-inputSwitch>
              </div>
            </td>
          </tr>
        </table>
      </div>
    
      <div *ngIf="isShowingResumen" class="bg-white custom-header">
        <div class="w-100 p-2">
          <div class="mb-2 d-flex justify-content-between font-weight-bold">
            <span>
              {{fincas.selected.razon_social || fincas.selected.nombre || 'Seleccione una finca'}}
            </span>
            <span style="text-decoration: underline; font-size: .9rem">RESUMEN CONSUMOS AGUA - ABONOS</span>
            <span>&nbsp;</span>
          </div>
          <div class="d-flex justify-content-between font-weight-bold">
            <span style="text-decoration: underline;">
              FINCA: {{fincas.selected.nombre}}
            </span>
            <span>
              Superficie: {{fincas.selected.superficie}} Ha
            </span>
            <span>
              CAMPAÑA: {{model?.anyo}}/{{sumOne(model?.anyo)}}
            </span>
          </div>
        </div>
        <table class="table table-bordered text-center">
          <tr class="font-weight-bold">
            <td>
              MES
            </td>
            <td>
              M<sup>3</sup>
            </td>
            <td>
              Lluvia (lts/m<sup>2</sup>)
            </td>
            <td *ngFor="let producto of productos?.filtered || []">
              {{producto?.label}}
              <span *ngIf="producto?.value?.unidades">({{producto?.value?.unidades}})</span>
            </td>
          </tr>
          <tr *ngFor="let mes of mesesArray || []">
            <td>
              {{(mes?.label || '').toUpperCase()}}
            </td>
            <td>
              <span *ngIf="modelResumen && modelResumen[mes?.index]">
                {{decimalFormat(modelResumen[mes?.index].m3, 0)}}
              </span>
            </td>
            <td>
              <span *ngIf="modelResumen && modelResumen[mes?.index]">
                {{decimalFormat(modelResumen[mes?.index].lluvia, 0)}}
              </span>
            </td>
            <td *ngFor="let idx of columnCountResumenArray">
              <span *ngIf="modelResumen && modelResumen[mes?.index]">
                {{decimalFormat(modelResumen[mes?.index][idx], 0)}}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              TOTAL M<sup>3</sup>
            </td>
            <td>
              {{decimalFormat(modelResumen?.totalM3, 0)}}
            </td>
            <td>
              Total lts/m<sup>2</sup>
            </td>
            <td *ngFor="let producto of productos?.filtered || []">
              Total
            </td>
          </tr>
          <tr>
            <td>
              M<sup>3</sup>/Ha/AÑO
            </td>
            <td>
              {{decimalFormat(modelResumen?.totalM3Anyo, 0)}}
            </td>
            <td>
              {{decimalFormat(modelResumen?.totalLluvia, 0)}}
            </td>
            <td *ngFor="let producto of productos?.filtered || []">
              {{decimalFormat(modelResumen['producto_total_' + producto?.value?.id], 0)}}
            </td>
          </tr>
        </table>
    
        <table class="w-50 mt-1 table table-bordered text-center font-weight-bold">
          <thead>
            <th>UF</th>
            <th>N</th>
            <th>P<sub>2</sub>O<sub>5</sub></th>
            <th>K<sub>2</sub>0</th>
            <th>Ca0</th>
          </thead>
          <tbody>
            <tr>
              <td>Totales</td>
              <td>{{decimalFormat(modelResumen?.totalN)}}</td>
              <td>{{decimalFormat(modelResumen?.totalP)}}</td>
              <td>{{decimalFormat(modelResumen?.totalK)}}</td>
              <td>{{decimalFormat(modelResumen?.totalCa)}}</td>
            </tr>
            <tr>
              <td>UF/Ha/año</td>
              <td>{{decimalFormat(modelResumen?.totalAnyoN)}}</td>
              <td>{{decimalFormat(modelResumen?.totalAnyoP)}}</td>
              <td>{{decimalFormat(modelResumen?.totalAnyoK)}}</td>
              <td>{{decimalFormat(modelResumen?.totalAnyoCa)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
  
    </div>
  </div>
</p-panel>

<p-dialog header="Stockage" [(visible)]="isShowingStockage">
  <div style="width: 80vw !important; height: 60vh; overflow-y: scroll;">
    <div>
      <div style="font-weight: bold; text-decoration: underline;" class="text-center">
        HOJA DE STOCKAGE DE FERTILIZANTES
      </div>
      Stockage a {{stockage?.day || '___'}} de {{stockage?.month || '____________'}} de {{stockage?.year || '_____'}}
    </div>
    <table class="table table-bordered">
        <thead class="text-center">
          <th>FERTILIZANTE</th>
          <th>MARCA COMERCIAL</th>
          <th>STOCK (Kg/Lt)</th>
          <th>STOCK</th>
        </thead>
        <tr *ngFor="let producto of productos?.filtered || []" class="text-center">
          <td>{{producto?.value?.nombre}}</td>
          <td>{{producto?.value?.producto?.fabricante}}</td>
          <td>
            <span *ngIf="stockage?.teorico">{{stockage?.teorico[producto?.value?.id] || 0}}</span>
          </td>
          <td>
            <span *ngIf="stockage">{{stockage[producto?.value?.id] || 0}}</span>
          </td>
        </tr>
    </table>
    <div class="mb-4 text-center" style="font-weight: bold; text-decoration: underline;">
      HOJA DE STOCKAGE DE FERTILIZANTES
    </div>
    <table class="table table-bordered">
      <thead class="text-center">
        <th>Día</th>
        <th>PROVEEDOR</th>
        <th>Nº&nbsp;ALBARÁN</th>
        <th>Kgs/Lts</th>
        <th>MARCA COMERCIAL</th>
        <th>FERTILIZANTE</th>
        <th>Firma Operario</th>
      </thead>
      <tr *ngFor="let entrada of entradas">
        <td>{{entrada?.fecha}}</td>
        <td>{{entrada?.proveedor}}</td>
        <td>{{entrada?.albaran}}</td>
        <td>{{entrada?.unidades}}</td>
        <td>{{entrada?.marca_comercial}}</td>
        <td>{{entrada?.producto_nombre}}</td>
        <td></td>
      </tr>
    </table>
  </div>
</p-dialog>
