import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {ClientesApiService} from '../../../../service/api/clientes-api.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {BaseForm} from '../../../base-form';
import {AppFormRequest} from '../../../app-common/form-request/app-form-request';
import {build, list, stack} from '../../../../common/classes/request-builder';
import {UsuariosApiService} from '../../../../service/api/usuarios-api.service';
import {StorageManager} from '../../../../common/storage-manager.class';
import {FormRequestTypes} from 'src/app/common/classes/form-request';
import { environment } from 'src/environments/environment';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import SignaturePad from 'signature_pad';
import { UploadApiService } from 'src/app/service/api/upload-api.service';
import { RopoApiService } from '../../../../service/api/ropo-api.service';
import { firstValueFrom } from 'rxjs';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { ClienteModel } from 'src/app/models/cliente.model';

const clienteName = environment.features.clienteName;
@Component({
    selector: 'app-clientes-form',
    templateUrl: './clientes-form.component.html',
    styleUrls: ['./clientes-form.component.scss']
})
export class ClientesFormComponent extends BaseForm implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton: RequestButtonComponent = new RequestButtonComponent();
    @ViewChild(SignaturePad, {read: false, static: false}) sp: SignaturePad | undefined;


    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public clienteName = clienteName;
    public showTecnicoAsignado = (this.appName !== 'melia' && this.appName !== 'gardengest' && this.appName !== 'covidai');
    public hasCodigoCliente = environment.features.hasCodigoCliente;
    public showClientesFirma = environment.features.showClientesFirma;
    public showClientesObservaciones = environment.features.showClientesObservaciones;
    public showClientesNotas = environment.features.showClientesNotas;
    public showClientesCooperativa = environment.features.showClientesCooperativa;
    public showClientesRegepa = environment.features.showClientesRegepa;
    public showRopoRomaRegepa = environment.features.showRopoRomaRegepa;
    public hasClientesAliasField = environment.features.hasClientesAliasField;
    public showFieldsClientesEstadoReglas = environment.features.showFieldsClientesEstadoReglas;


    public formRequest: AppFormRequest = new AppFormRequest();
    public override model = {} as ClienteModel;
    public hasClienteError = '';

    public usuarios = list();
    public clientes = list();

    public hasSignature = false;
    public modelSignature: string | null = null;
    public urlSignature = this.pathToFirmas + '_clientes/' + (this.getRegisterId() || 0) + '.png';

    public gruposCompra = list(
        ['...', null],
        'Bigmat',
        'Gamma',
        'Amistad',
        'Bdb',
        'Ibricks',
        'Unico',
        '+Obra',
        'Divendi',
        'DCC3000',
        'Grupalco',
        'Emcat',
        'Otros',
        'Ninguno'
    );

    public tiposCliente = list(
        ['...', null],
        'Cliente habitual',
        'No cliente',
        'Cliente puntual'
    );
    
    public clasesCliente = list(
        ['...', null],
        'Almacén',
        'Constructor',
        'Técnico',
        'Particular',
        'Centro logístico'
    );
    public almacenesCovidai = list(
        ['...', null],
        ['1  General o Central', '1'],
        ['2  Pedro (Montealegre)', '2'],
        ['3', '3'],        
        ['4  Juan Mora (Daimiel)', '4'],
        ['5  Agrohuertas (Pedro Muñoz)', '5'],
        ['6  Manuel Valbuena (Horcajo)', '6'],
        ['7  José Miguel Olmeda (Villamalea)', '7'],
        ['8  Hilario Delgado (Valdepeñas)', '8'],
        ['9  Laboragro (Aguilar)', '9'],
        ['10 Juan Antonio Ucen (Toboso)', '10'],
        ['11 Patricio González (Infantes)', '11'],
        ['12', '12'],
        ['13 Luis Miguel (Pinos)', '13'],
        ['14 Agromanchuela (Quintanar del Rey)', '14'],
        ['15 Vicente Iniesta (Campo de Criptana)', '15'],
        ['16 Cial Agrícola (Jumilla)', '16'],
        ['17', '17'],
        ['18', '18'],
        ['19', '19'],
        ['20', '20'],
        ['21', '21'],
        ['22 Jose Antonio (Valdeganga)', '22'],
        ['23 Demetrio Nieto (Almoradiel)', '23'],
        ['24 Jacinto Perez del Hoyo (Carrión de Calatrava)', '24'],
    );

    public tiposEstado = list(
        ['...', null],
        'Enviado', 
        'Aplazado',
        'Perdidos',
        'Confirmado' 
    );
    public tiposReglaNecogio = list(
        ['...', null],
        'Pequeño', 
        'Mediano',
        'Grandoe'
    );

    public tipo = list(
        ['...', null],
        'Agro',
        'Garden'
    );

    public formFields: ItemInterface<object>[] = [
        {label: 'Nombre o Razón Social', field: 'nombre', inputType: {type: InputType.EDIT_TEXT}, required: true},
        {label: 'NIF / CIF', field: 'nif', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Sector', field: 'sector', inputType: {type: InputType.EDIT_TEXT}, visible: this.appName === 'crm'},
        {label: 'Cooperativa', field: 'cooperativa', inputType: {type: InputType.EDIT_TEXT}, visible: this.showClientesCooperativa,
            required: true},
        {label: 'Código', field: 'codigo', inputType: {type: InputType.EDIT_TEXT}, visible: this.hasCodigoCliente},
        {
            label: this.appName === 'covidai' ? 'Almacén' : 'Alias', 
            field: 'alias', 
            inputType: {type: InputType.DROPDOWN}, 
            values: this.almacenesCovidai, 
            visible: this.hasClientesAliasField
        },
        {label: 'Dirección', field: 'direccion', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Localidad', field: 'localidad', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Codigo Postal', field: 'codigo_postal', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Provincia', field: 'provincia', inputType: {type: InputType.EDIT_TEXT}},
        {
            label: 'Código Municipio INE', 
            field: 'municipio_ine', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: environment.features.showReto
        }, 
        {
            label: 'Nº ROPO SU', 
            field: 'ropo', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: environment.features.showReto
        },                   
        {label: 'Teléfono', field: 'telefono', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Fax', field: 'fax', inputType: {type: InputType.EDIT_TEXT}, visible: environment.features.showReto},          
        {label: 'Email', field: 'email', inputType: {type: InputType.EDIT_TEXT}},
        {
            label: 'Gerente',
            field: 'gerente',
            inputType: {type: InputType.EDIT_TEXT},
            visible: environment.features.showCrm
        },
        {
            label: 'Email del Gerente',
            field: 'email_gerente',
            inputType: {type: InputType.EDIT_TEXT},
            visible: environment.features.showCrm
        },
        {
            label: 'Teléfono del Gerente',
            field: 'telefono_gerente',
            inputType: {type: InputType.EDIT_TEXT},
            visible: environment.features.showCrm
        },
        {
            label: 'Responsable de Calidad',
            field: 'responsable_calidad',
            inputType: {type: InputType.EDIT_TEXT},
            visible: environment.features.showCrm
        },
        {
            label: 'Email del Responsable de Calidad',
            field: 'email_responsable_calidad',
            inputType: {type: InputType.EDIT_TEXT},
            visible: environment.features.showCrm
        },
        {
            label: 'Teléfono del Responsable de Calidad',
            field: 'telefono_responsable_calidad',
            inputType: {type: InputType.EDIT_TEXT},
            visible: environment.features.showCrm
        },
        {
            label: this.appName === 'crm' ? 'Comercial Asignado' : 'Técnico/Aplicador Asignado',
            field: 'id_usuario_asignado',
            inputType: {
                type: this.appName === 'agrosalvi' || 
                    environment.features.showMultiselectAplicadores ? 
                    InputType.MULTISELECT : 
                    InputType.DROPDOWN
            },
            multiSelect: this.appName === 'agrosalvi' ||  environment.features.showMultiselectAplicadores ? true : false,
            values: this.usuarios,
            filter: (usuario: { value: { rol: string; }; }) => {
                if (this.applicationType === 'cropgest') {
                    return usuario.value.rol === 'responsable';
                } else {
                    return true;
                }// if();
            },
            valuePrimaryKey: 'id',
            visible: this.showTecnicoAsignado
        },
        {
            field: 'operador_explotacion', 
            label: 'Nº Operador explotación',
            inputType: {type: InputType.EDIT_TEXT},          
            visible: environment.appName === 'pyf'
        },
        {label: 'REGEPA', field: 'regepa', inputType: {type: InputType.EDIT_TEXT}, visible: this.showClientesRegepa},
        {label: 'Observaciones', field: 'observaciones', inputType: {type: InputType.EDIT_TEXTAREA},
            visible: this.showClientesObservaciones},
        {
            label: 'Tipo', 
            field: 'tipo', 
            inputType: {type: InputType.DROPDOWN}, 
            values: environment.features.showCrm ?  list(['...', null], 'Afianzado', 'Potencial') : this.tipo,
            visible: this.appName === 'agrosalvi' || environment.features.showCrm
        },
        {label: 'Notas', field: 'notas', inputType: {type: InputType.EDIT_TEXTAREA},
            visible: this.showClientesNotas},
        {
            label: 'Acceso a Plataforma', 
            field: 'acceso_plataforma', 
            inputType: {type: InputType.SWITCH}, 
            visible: this.applicationType === 'cropgest'  && StorageManager.getUser().rol === 'admin'
        }
    ];

    public formFieldsPrevilsa1: ItemInterface<object>[] = [
        {label: 'Persona de contacto 1', field: 'persona1', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Persona de contacto 2', field: 'persona2', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Teléfono 1', field: 'telefono1', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Teléfono 2', field: 'telefono2', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Cargo 1', field: 'cargo1', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Cargo 2', field: 'cargo2', inputType: {type: InputType.EDIT_TEXT}},
    ];

    public formFieldsPrevilsa2: ItemInterface<object>[] = [
        {label: 'Grupo de compra', field: 'grupo_compra', inputType: {type: InputType.DROPDOWN}, values: this.gruposCompra},
        {label: 'Tipo de cliente', field: 'tipo_cliente', inputType: {type: InputType.DROPDOWN}, values: this.tiposCliente},
        {label: 'Clase de cliente', field: 'clase_cliente', inputType: {type: InputType.DROPDOWN}, values: this.clasesCliente},
        {label: 'Condiciones de venta', field: 'condiciones_venta', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Notas', field: 'notas', inputType: {type: InputType.EDIT_TEXTAREA}},
        {label: 'Comercial', field: 'comercial', inputType: {type: InputType.EDIT_TEXT}},
    ];

    public formFieldsEstadoReglas: ItemInterface<object>[] = [
        {label: 'Estado', field: 'estado', inputType: {type: InputType.DROPDOWN}, values: this.tiposEstado},
        {label: 'Reglas de negocio', field: 'regla_negocio', inputType: {type: InputType.DROPDOWN}, values: this.tiposReglaNecogio},
    ];

    constructor(public override router: Router,
        public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public usuariosApi: UsuariosApiService,
        public clientesApi: ClientesApiService,
        public _ropoService: RopoApiService,
        public uploadApi: UploadApiService) {
        super(
            route,
            router,
            dashboard,
            clientesApi.clientesPUT,
            clientesApi.clientesPOST,
            'clientes',
            'Editar ' + clienteName,
            'Crear ' + clienteName,
            'Duplicar ' + clienteName
        );

    }

    ngOnInit() {
        // console.log('tecnico asignado',this.showTecnicoAsignado);
        
        let formFieldsToUse = this.formFields;
        let formFieldsToSend = this.formFields.map(it => it.field ?? '');

        if (this.appName === 'previlsa') {
            formFieldsToUse = formFieldsToUse.concat(
                this.formFieldsPrevilsa1, this.formFieldsPrevilsa2
            );
            formFieldsToSend = formFieldsToSend.concat(
                this.formFieldsPrevilsa1.map(it => it.field ?? ''),
                this.formFieldsPrevilsa2.map(it => it.field ?? '')
            );
        }

        if ((this.getType() === FormRequestTypes.EDIT) && this.showClientesFirma) {
            this.fetchSignature();
        }
        if ( this.showFieldsClientesEstadoReglas ) {
            formFieldsToSend.push('estado', 'regla_negocio');
        }

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.clientesApi.clientesGET)
            .setPutRequest(this.clientesApi.clientesPUT)
            .setPostRequest(this.clientesApi.clientesPOST)
            .setFormFields(formFieldsToUse)
            .setFieldsToSend((['id', 'id_usuario', 'id_usuario_asignado', 'id_usuario_asignado2'])
                .concat(formFieldsToSend)
            );



        this.expandFormRequest();

        this.formRequest.load();

        this.softInit(this.getType());
    }

    public validate(tag?: string) {
        if ((tag === 'nif') && this.formRequest.type === FormRequestTypes.CREATE) {
            const nifSearch = ((this.formRequest.model || {}).nif || '').toLowerCase();

            if ((nifSearch || '').length === 0) {
                return true;
            }


            const existeNif = (this.clientes.values || [])
                .filter(it => it && it.value)
                .map(it => it.value)
                .find((nif) => (nif.nif || '').toLowerCase() === nifSearch);

            if (existeNif) {
                this.hasClienteError = 'El cliente ya existe';
            } else {
                this.hasClienteError = '';
            }

            return !existeNif;
        }

        return true;
    }

    public submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }

        if (this.appName !== 'pyf' ) {
            delete this.model.operador_explotacion;
        }

        if (!environment.features.showCrm) {
            delete this.model.gerente;
            delete this.model.email_gerente;
            delete this.model.telefono_gerente;
            delete this.model.responsable_calidad;
            delete this.model.email_responsable_calidad;
            delete this.model.telefono_responsable_calidad;
        }
        
        this.formRequest.send();
    }

    public formChanges(tag?: string) {
        this.requestButton.error = '';
        this.validate(tag);
        
        // Cálculo automático del REGEPA al introducir DNI si tiene activada esa funcionalidad
        if ( tag === 'nif' && this.showRopoRomaRegepa && (this.model.nif as string).length >= 9 ){

            (firstValueFrom(this._ropoService.getREGEPA(this.model.nif ?? '')))
                .then( (resp: any) => {
                    const regepa: any = resp.regepa;
                    if ( regepa.length !== 0 ) {
                        this.model.regepa = regepa['código'];

                    } 
                    return;
                })
                .catch( (error: string) => console.log('error', error)
                );            
        } 

    }

    // Sigpad
    public drawComplete(sigpad: any) {
        this.modelSignature = sigpad.toDataURL('image/png');
    }
    public clearSigPad(sigpad: any) {
        sigpad.clear();
        this.modelSignature = null;
    }
    public fetchSignature() {
        fetch(this.urlSignature).then(res => {
            if (res.status === 200) {
                this.hasSignature = true;
                res.blob().then(blob => {
                    const urlCreator = window.URL; 
                    this.sp?.fromDataURL(urlCreator.createObjectURL(blob));
                });
            }
            return;
        })
            .catch( (error: string) => console.log('error', error)
            );  
    }

    private expandFormRequest() {
        // NO CHANGE STACK BY POOL IN THAT CASE!!
        this.formRequest.beforeLoad(resolve => {
            stack([
                build(this.usuariosApi.usuariosGET, this.usuarios, ((it: { nombre: any; apellidos: any; }) => (it.nombre || '') + ' ' + (it.apellidos || '')), true),  
                build(this.clientesApi.clientesGET, this.clientes, 'nombre', true)
            ]).then(() => {
                this.formRequest.update();
                resolve(true);
            });
        });

        this.formRequest.afterLoad(resolve => {
            if (this.applicationType === 'cropgest' && StorageManager.getUser().rol === 'admin') {
                this.model.acceso_plataforma = this.model.acceso_plataforma === 1;                
            }
            
            if (!this.model['id_usuario_asignado']) {
                this.model['id_usuario_asignado'] = (StorageManager.getUser() || {} as any).id;
            }

            if (!this.model['id_usuario_asignado2']) {
                this.model['id_usuario_asignado2'] = (StorageManager.getUser() || {} as any).id;
            }

            resolve(true);
        });

        this.formRequest.beforeSend(resolve => {

            // Seleccionar el usuario asignado al cliente como id_usuario 
            if (this.model.id_usuario_asignado == null){
                this.model.id_usuario_asignado = null;
                this.model.id_usuario = null;
            }// if   
            this.model.id_usuario = (this.model.id_usuario_asignado || this.model.id_usuario) ?? '';
            

            if (this.formRequest.type === FormRequestTypes.CREATE) {
                resolve(this.validate('nif'));
            } else {
                resolve(true);
            }
        });

        this.formRequest.afterSend(resolve => {
            if (this.modelSignature) {
                this.uploadApi.firmasPOST.toPromise({
                    id: this.model.id,
                    dir: 'firmas_clientes',
                    firma: this.modelSignature
                });
            }
            this.dashboard.emitClientChange();
            resolve(true);
        });
    }
}
