import {DashboardService} from '../service/events/dashboard.service';
import {RequestHandler} from '../service/OffService/request-handler';
import {Subscription} from 'rxjs';
import {Directive} from '@angular/core';
import { environment } from '../../environments/environment';
import { Utils } from '../common/utils';
import { Filtering } from '../service/filtering/filtering';

import type { OnDestroy } from '@angular/core';

@Directive()
export class BaseView implements OnDestroy {

    public canShow: { [key: string]: any } = {}
    public self: BaseView;
    public model: any;

    public filtering: Filtering<any> | undefined;
    public filters: any = {
        global: '',
        dropdown: {},
        multiselect: {},
        dateRange: {}
    };

    // SignaturePad
    public pathToFirmas = environment.serverUrl + 'firmas';
    public signaturePadOptions: object = {
        'minWidth': 1,
        'canvasWidth': 300,
        'canvasHeight': 150,
        'allowTaint': true
    };
    public signaturePadOptionsSm: object = {
        'minWidth': 1,
        'canvasWidth': 140,
        'canvasHeight': 80,
        'allowTaint': true
    };
    public _deleteRequest: RequestHandler;

    private _dashboard: DashboardService;
    private _getRequest: RequestHandler;
    private _sub: Subscription = new Subscription();

    constructor(dashboard: DashboardService, getRequest: RequestHandler, deleteRequest?: RequestHandler) {
        this._dashboard = dashboard;
        this._dashboard.changeClientActive = true;
        this._getRequest = getRequest;
        this._deleteRequest = deleteRequest ?? new RequestHandler();
        this.model = {};
        this.self = this;
        this.init();
    }
    
    public setDropdownSelectOn(content: string, tag: string) {
        this.cleanMultiSelect();

        this.filters.dropdown[tag] = {
            tag: tag,
            content: content
        };

        this.filtering?.filter();
    }

    public ngOnDestroy() {
        for (const x in this) {
            if (this.hasOwnProperty(x)) {
                if (this[x] instanceof RequestHandler) {
                    // @ts-ignore
                    this[(x as any)].unsuscribe();
                }
            }
        }

        this._sub.unsubscribe();
    }


    public deleteRegister() {
        if (this.model['id']) {
            this._deleteRequest.safePerform(this.model);
            this._deleteRequest.response(() => {
                if (!this._deleteRequest.hasError) {
                    this._getRequest.safePerform();
                }
            });
        } else {
            this._deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public show(modal: string, data: any) {
        let x;

        this.model = data;
        this.canShow[modal] = true;
        
        for (x in this.canShow) {
            if (x !== modal && this.canShow.hasOwnProperty(x)) {
                this.canShow[x] = false;
            }
        }
    }

    public hide(modal: string) {
        this.model = null;
        this.canShow[modal] = false;
        if (this._deleteRequest) {
            this._deleteRequest.reset();
        }
    }

    public scrollToTop() {
        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        if (elements && elements[0]) {
            setTimeout(() => {
                if (elements[0]) {
                    elements[0].scrollTop = 0;
                }
            }, 100);
        }
    }

    public decimalFormat(input: any, nDecimals?: number, millar?: string, comma?: string, progressiveTo?: number) {
        if (!input) {
            return '';
        }
        return Utils.decimalFormat(input, nDecimals, millar, comma, progressiveTo);
    }

    public splitFormat(str: string, splitSeparator = ';', join = false, joinSeparator = ', '): string | string[] {
        let result: string | string[] = (str || '').split(splitSeparator);
        if (join) {
            result = result.join(joinSeparator);
        }
        return result;
    }

    public splitFromArrayFormat(array: any[], prop = 'nombre', joinSeparator = ', ', removeDupes = false) {
        let toReturn: any = (array || []).map(it => it[prop]);
        if (removeDupes) {
            toReturn = Array.from(new Set(toReturn));
        }
        toReturn = toReturn.join(joinSeparator);
        return toReturn;
    }

    public sum(field: string, filter: Filtering<any>, cols: any[]) {
        let sum = 0;

        (filter.value || []).forEach(it => {
            if (!isNaN((parseFloat(it[field]) + sum))) {

                sum += parseFloat((it[field] as number).toString().replace(',', '.'));
            } 

        });

        cols.forEach(it => {
            if (it.field === field) {
                it.footer = this.decimalFormat(sum + '', 2);
            }
        });
    }

    public updateFooter(filter: Filtering<any>, cols: any[]) {
        
        (cols || [])
            .filter(it => Object.keys(it).includes('footer'))
            .map(it => it.field)
            .forEach(field => this.sum(field, filter, cols));
    }

    private cleanMultiSelect() {
        for (const x in this.filters.multiselect) {
            if (x) {
                this.filters.multiselect[x] = [];
            }
        }
    }


    private init() {
        this._sub = this._dashboard.onChangeClient().subscribe(
            () => this._getRequest.safePerform()
        );
    }
}