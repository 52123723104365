<br>
<p-panel>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
      <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
    </div>
  </div>
  <h5 *ngIf="this.appName !== 'gardengest'">Datos de {{fincaName}}</h5>
  <app-form-builder
    [model]="model"
    [undoEnabled]="undoEnabled"
    [distributionColumns]="3"
    [fields]="formFields"
    (formChanges)="formChanges()">
  </app-form-builder>
  <br>
  <h5 *ngIf="this.appName !== 'gardengest'">Datos del titular</h5>
  <app-form-builder *ngIf="this.appName !== 'gardengest'"
    [model]="model"
    [undoEnabled]="undoEnabled"
    [distributionColumns]="3"
    [fields]="formFieldsTitular"
    (formChanges)="formChanges()">
  </app-form-builder>
  <br>
  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            [lockOnSuccess]="true"
            (click)="submit()"
            [error]="error"
            [mode]="requestButtonType"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>
<br>
