import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({providedIn: 'root'})
export class HandleUnrecoverableStateService {
    constructor(updates: SwUpdate) {
        updates.unrecoverable.subscribe(event => {
            alert(
                'Ha ocurrido un error que la aplicación no es capaz de manejar:\n' +
        event.reason +
        '\n\nPor favor,recarga la página para corregirlo.'
            );
        });
    }
}