import {Injectable} from '@angular/core';
import {RequestHandler} from 'src/app/service/OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';

@Injectable({
    providedIn: 'root'
})
export class VentasApiService {

    public ventasGET: RequestHandler = new RequestHandler();
    public ventasPUT: RequestHandler = new RequestHandler();
    public ventasPOST: RequestHandler = new RequestHandler();
    public ventasDELETE: RequestHandler = new RequestHandler();

    public compradoresGET: RequestHandler = new RequestHandler();
    public compradoresPUT: RequestHandler = new RequestHandler();
    public compradoresPOST: RequestHandler = new RequestHandler();
    public compradoresDELETE: RequestHandler = new RequestHandler();

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineVentasGET();
        this.defineVentasPUT();
        this.defineVentasPOST();
        this.defineVentasDELETE();

        this.defineCompradoresGET();
        this.defineCompradoresPUT();
        this.defineCompradoresPOST();
        this.defineCompradoresDELETE();
    }

    private defineVentasGET() {
        this.ventasGET
            .fetch(HttpRequestType.GET, 'ventas/ventas');

        this.api
            .registerRequest(this.ventasGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.ventasGET));
    }

    private defineVentasPUT() {
        this.ventasPUT
            .fetch(HttpRequestType.PUT, 'generic/ventas');

        this.api
            .registerRequest(this.ventasPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.ventasPUT));
    }

    private defineVentasPOST() {
        this.ventasPOST
            .fetch(HttpRequestType.POST, 'generic/ventas');

        this.api
            .registerRequest(this.ventasPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.ventasPOST));
    }

    private defineVentasDELETE() {
        this.ventasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/ventas');

        this.api
            .registerRequest(this.ventasDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.ventasDELETE));
    }

    private defineCompradoresGET() {
        this.compradoresGET
            .fetch(HttpRequestType.GET, 'ventas/compradores');

        this.api
            .registerRequest(this.compradoresGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.compradoresGET));
    }

    private defineCompradoresPUT() {
        this.compradoresPUT
            .fetch(HttpRequestType.PUT, 'generic/comercios');

        this.api
            .registerRequest(this.compradoresPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.compradoresPUT));
    }

    private defineCompradoresPOST() {
        this.compradoresPOST
            .fetch(HttpRequestType.POST, 'generic/comercios');

        this.api
            .registerRequest(this.compradoresPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.compradoresPOST));
    }

    private defineCompradoresDELETE() {
        this.compradoresDELETE
            .fetch(HttpRequestType.DELETE, 'generic/comercios');

        this.api
            .registerRequest(this.compradoresDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.compradoresDELETE));
    }
}


