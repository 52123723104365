
import {Injectable} from '@angular/core';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';

@Injectable({
    providedIn: 'root'
})
export class CamposApiService {

    public campos = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };    

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineCampos();
    }

    private defineCampos() {
        this.campos.GET.fetch(HttpRequestType.GET, 'campos/get');
        this.campos.POST.fetch(HttpRequestType.POST, 'generic/campos');
        this.campos.PUT.fetch(HttpRequestType.PUT, 'generic/campos');
        this.campos.DELETE.fetch(HttpRequestType.DELETE, 'generic/campos');
    
        this.api.registerRequest(this.campos.GET);
        this.api.registerRequest(this.campos.POST);
        this.api.registerRequest(this.campos.PUT);
        this.api.registerRequest(this.campos.DELETE);
    
        this.syncService.register(this.syncService.baseSyncPolicy(this.campos.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.campos.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.campos.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.campos.DELETE));
    }
}
