import { CRMApiService } from './../../../../service/api/crm.service';
import { Utils } from './../../../../common/utils';
import { Component } from '@angular/core';
import moment from 'moment';
import { Router } from '@angular/router';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { RequestHandler } from '../../../../service/OffService/request-handler';
import { environment } from 'src/environments/environment';
import { StorageManager } from '../../../../common/storage-manager.class';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseView } from 'src/app/view/base-view';
import { CrmModel } from 'src/app/models/crm/crm.model';
import { Filtering } from 'src/app/service/filtering/filtering';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import type { OnInit, AfterViewInit } from '@angular/core';
@Component({
    selector: 'app-crm',
    templateUrl: './crm.component.html',
    styleUrls: ['./crm.component.scss']
})
export class CrmComponent extends BaseView implements OnInit, AfterViewInit {

    
    public appName = environment.appName;

    
    public isAdmin = (StorageManager.getUser().rol === 'admin');
    public deleteRequest: RequestHandler = this.crmApi.CRM.DELETE;

    public analisisSeleccionado: CrmModel = new CrmModel();
    public analisisArray = [];
    public analisisArrayFiltered: any[] = [];
    public analisis: CrmModel[] = [];
    public analisisImg: any;

    public clientChange: Subscription = new Subscription();
    public isMobile: boolean = false;

    public date: Date = new Date();
    public fechaSel: string = '';
    public analisisDia = [];
    public showEditCRM = false;
    public showEliminaCRM = false;

    public cambiaEstadoLabel: string = '';
    public cambiaEstadoColor: string = '';
    public cambiandoEstado = false;

    public options = {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        defaultDate: new Date(),
        locale: esLocale,
        header: {
            left: 'prev,next',
            center: 'title',
            right: 'month,agendaWeek,agendaDay'
        },
        height: 640,
        width: 400,
        aspectRatio: 0.8,
        editable: true,
        eventLimit: false,
        eventClick: (event: any) =>  { 
            this.irAlEvento(event);
        },
        dateClick: () => {
            this.irAlDia();
        } 
    };
    public options2 = {
        height: 400,
        width: 400,
        aspectRatio: 0.4
    };

    public months = [
        {name: 'Enero', value: 1},
        {name: 'Febrero', value: 2},
        {name: 'Marzo', value: 3},
        {name: 'Abril', value: 4},
        {name: 'Mayo', value: 5},
        {name: 'Junio', value: 6},
        {name: 'Julio', value: 7},
        {name: 'Agosto', value: 8},
        {name: 'Septiembre', value: 9},
        {name: 'Octubre', value: 10},
        {name: 'Noviembre', value: 11},
        {name: 'Diciembre', value: 12},
    ];
    public years: any[] = [];
    public selectedMonth: any;
    public selectedYear: any;

    // Imprenta
    public showCRMImprimir: boolean = false;
    public crmImprimirAll: any[] = [];
    public crmImprimir: any[] = [];
    public override filtering: Filtering<any> = new Filtering<any>();
    public imprimirMostrarTodos: boolean = false;
    public isPrinting: boolean = false;
    public isGenerating: boolean = false;

    constructor(
        private crmApi: CRMApiService,
        private dashboard: DashboardService,
        private router: Router,
        public http: HttpClient
    ) {
        super(dashboard, crmApi.CRM.GET, crmApi.CRM.DELETE);
        for (let i = 2017; i <= 2025; i++) {
            this.years.push( {name: i.toString(), value: i} );
        }
    }

    ngOnInit() {
        this.isMobile = (
            ((navigator.userAgent.match('iPad') || []).length > 0) ||
            ((navigator.userAgent.match('iPhone') || []).length > 0) ||
            ((navigator.userAgent.match('iPod') || []).length > 0) || 
            ((navigator.userAgent.match('Android') || []).length > 0)
        );

        this.dashboard.setSubmenuItem('crm');

        this.dashboard.clientChanges$.subscribe(() => {
            this.filterArray();
        });

        this.dashboard.changeClientActive = true;

        
        this.getCRM();
    }

    ngAfterViewInit() {
        const elements: any = document.getElementsByClassName('fc-view');
        for (const element of elements) {
            element.classList.add('no-zindex');
        }
    }

    getCRM() {
        this.crmApi.CRM.GET.safePerform();
        this.crmApi.CRM.GET.response(data => {
            
            
            this.analisis = data;
            this.analisisArray = data
                .map((it: CrmModel) => {
                    let color = '';

                    const start = moment(
                        Utils.toDate(it.fecha)
                    ).format('YYYY-MM-DD');
         
                    const today = new Date();
                    const yyyy = today.getFullYear();
                    let mm: number | string = today.getMonth() + 1;
                    let dd: number | string = today.getDate();

                    if (dd < 10) dd = '0' + dd;
                    if (mm < 10) mm = '0' + mm;

                    const formattedToday = dd + '/' + mm + '/' + yyyy;

                    if ((it.realizado === '0' || !it.realizado) && Date.parse(it.fecha) < Date.parse(formattedToday)) {
                        color = '#f44242';
                    } else if (it.realizado === '1') {
                        color = '#3be246';
                    } else {
                        color = '#4198f4';
                    }
         
                    return {
                        id: it.id,
                        title: it.cliente.nombre + ' \n' + (it.cliente.apellidos ? it.cliente.apellidos : '')  ,
                        start,
                        color
                    };
                    

                });
            this.filterArray();
            
            this.crmApi.CRM.GET.unsuscribe();
        });
    }
    
    showSelectedDate(calendar: any) {
        const day = new Date().getDate();
        const month = this.selectedMonth ? this.selectedMonth.value : new Date().getMonth() + 1;
        const year = this.selectedYear ? this.selectedYear.value : new Date().getFullYear();
        const date = new Date(`${year}-${month}-${day}`);

        calendar.calendar.gotoDate(date.getTime());
    }

    filterArray() {
        this.analisisArrayFiltered = this.analisisArray;
       
    }

    irAlDia() {

    }

    irAlEvento(e: { event: { _def: { publicId: any; }; }; }) {
        const idAnalisis = e.event._def.publicId;
        for (let i = 0; i < this.analisis.length; i++) {
            if (this.analisis[i]?.id === idAnalisis) {
                this.analisisSeleccionado = this.analisis[i] ?? new CrmModel();
                break;
            }
        }

    }

    crearCRM() {
        this.router.navigate(['dashboard', 'crear-crm']);
    }

    editCRM(data: any) {
        this.router.navigate(['dashboard', 'editar-crm', data.id]);
    }

    eliminaCRM() {
        this.showEliminaCRM = false;

        this.deleteRequest.safePerform(this.analisisSeleccionado);
        this.deleteRequest.response(() => {
            if (!this.deleteRequest.hasError) {
                for (const analisis of this.analisisArrayFiltered) {
                    if (this.analisisSeleccionado && (analisis.id === this.analisisSeleccionado.id)) {
                        this.analisisArrayFiltered.splice(this.analisisArrayFiltered.indexOf(analisis), 1);
                    }
                }
                this.analisisSeleccionado = new CrmModel();
                this.crmApi.CRM.GET.safePerform();
                // location.reload();
                // this.getCRM();
            } else {
                console.log('Error al borrar analisis...');
            }
        });
    }


 

    public setNextDate(date: Date | string | any, period: number, timelapse: string) {
        if (typeof date === 'string') { 
            date = moment(date, 'DD/MM/YYYY').toDate(); 
        }
        let aux: number;
        const auxDate = new Date(date);
        switch (timelapse) {

            case 'day':
                aux = (date as Date).getDate() + period;
                auxDate.setDate(aux);
                break;


            case 'month':
                aux = date.getMonth() + period;
                auxDate.setMonth(aux);
                break;
            case 'year':
                aux = date.getFullYear() + period;
                auxDate.setFullYear(aux);
                break;
        }
        return moment(auxDate).format('DD/MM/YYYY');
    }

    

    public descargarArchivo(adjunto: string) {
        const a: HTMLAnchorElement = (document.getElementById('downloadAdjunto') as HTMLAnchorElement);
        a.download = adjunto;

        fetch(environment.serverUrl + 'analisis/' + adjunto, {
            headers: {
                'Content-Type': 'application/pdf'
            }
        })
            .then(response => response.blob())
            .then(blob => {
                a.href = URL.createObjectURL(blob);
                a.click();
            });
    }

    /*** ADJUNTAR ANÁLISIS ***/
    public readUrl(input: { click: () => void; files: Blob[]; }) {
        input.click();
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.analisisImg = e.target.result;
            };
            reader.readAsDataURL(input.files[0]);
            const r = this.http.post(environment.serverUrl + 'ws/analisis/upload_file.php', this.analisisImg).subscribe(res => {
                const file: any = res;
                this.analisisSeleccionado.archivo = file.fileName;

                r.unsubscribe();
            });
        }
    }
    
    public fileChange(event: { target: { files: FileList; }; }) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0] = new File([], '');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            const headers = new HttpHeaders();

            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');

            this.http.post(`${environment.serverUrl}ws/analisis/upload_file.php`, formData, {headers: headers}).subscribe(data => {
                const postRes: any = data;
                this.analisisSeleccionado.archivo = postRes.fileName;

                this.crmApi.CRM.PUT.safePerform({
                    id: this.analisisSeleccionado.id,
                    archivo: this.analisisSeleccionado.archivo
                });
    
                this.crmApi.CRM.PUT.response(putRes => {
                    console.log(putRes);
                    this.crmApi.CRM.PUT.unsuscribe();
                });

            }, error => {
                console.log(error);
            }
            );
        }
    }


    reloadAnalisis(){
        location.reload();
    }

    public verArchivo( archivo: string) {
        const myWindow = window.open(
            'about:blank',
            '_blank',
            'width=600, height=600'
        );
        myWindow?.location.assign(
            environment.serverUrl + 'adjunto/crm/' + archivo
        );
        myWindow?.focus();
    }

    public showImprimir() {
        this.showCRMImprimir = true;
        this.crmImprimirAll = [...this.filtering.value];
        this.crmImprimir = [...this.filtering.value].slice(0, 100);
        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        if (elements && elements[0]) {
            setTimeout(() => {
                if (elements[0]) {
                    elements[0].scrollTop = 0;
                }
            }, 100);
        }
    }

    public imprimir() {
        this.isPrinting = true;

        html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement())
            .then(canvas => {
                const pdf = new jsPDF('p', 'pt', 'letter');

                for (let i = 0; i <= (document?.getElementById('html2canvasDiv')?.clientHeight ?? new HTMLElement().clientHeight) / 980; i++) {
                // ! This is all just html2canvas stuff
                    const srcImg  = canvas;
                    const sX      = 0;
                    const sY      = 980 * i; // start 980 pixels down for every new page
                    const sWidth  = 900;
                    const sHeight = 980;
                    const dX      = 0;
                    const dY      = 0;
                    const dWidth  = 900;
                    const dHeight = 980;

                    let onePageCanvas = (window as any).onePageCanvas;
                    onePageCanvas = document.createElement('canvas');
                    onePageCanvas.setAttribute('width', 900);
                    onePageCanvas.setAttribute('height', 980);
                    const ctx = onePageCanvas.getContext('2d');
                    // details on this usage of this function:
                    // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
                    ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

                    const canvasDataURL = onePageCanvas.toDataURL('image/png', 1.0);

                    const width         = onePageCanvas.width;
                    const height        = onePageCanvas.clientHeight;

                    // ! If we're on anything other than the first page,
                    // add another page
                    if (i > 0) {
                        pdf.addPage([612, 791]); // 8.5" x 11" in pts (in*72)
                    }
                    // ! now we declare that we're working on that page
                    pdf.setPage(i + 1);
                    // ! now we add content to that page!
                    pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width * .62), (height * .62));

                }
                // ! after the for loop is finished running, we save the pdf.
                this.isPrinting = false;
                pdf.save('Tareas Comerciales.pdf');

            }).catch(res => {
                console.error(res);
            });
    }
    
}
