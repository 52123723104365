import { Component, Input, EventEmitter } from '@angular/core';
import { TrabajadoresApiService } from '../../../../../service/api/trabajadores-api.service';
import { DashboardService } from '../../../../../service/events/dashboard.service';
import { BaseView } from 'src/app/view/base-view';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { Utils } from '../../../../../common/utils';
import { StorageManager } from '../../../../../common/storage-manager.class';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { Filtering } from 'src/app/service/filtering/filtering';
import { Router } from '@angular/router';

import type { OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-fichajes_hours_minutes',
  templateUrl: './fichajes_hours_minutes.component.html',
  styleUrls: ['./fichajes_hours_minutes.component.scss']
})

export class FichajesHourMinuteComponent extends BaseView implements OnInit {

    @Input() saveButtonClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() undoButtonClick: EventEmitter<any> = new EventEmitter<any>();

    public appName = environment.appName;
    public showTrabajadoresCuadrillas = environment.features.showTrabajadoresCuadrillas;
    public showFichajesCargos = environment.features.showFichajesCargos;
    public showFichajesExportarCSV = environment.features.showFichajesExportarCSV;
    public showFormatHourMinute = environment.features.showFormatHourMinute;
    public showFichajesUbicacion = environment.features.showFichajesUbicaciones;

    public override filtering: Filtering<any> = new Filtering<any>();

    public getRequest: RequestHandler = this.trabajadoresApi.fichajesGET;
    public postRequest: RequestHandler = this.trabajadoresApi.fichajesPOST;
    public deleteRequest: RequestHandler = this.trabajadoresApi.fichajesDELETE;
    public isPerforming: boolean = false;

    public usuarioActual = StorageManager.getUser();
    public trabajadores: any[] = [];
    public cuadrillas: any[] = [];
    public cuadrilla: any = {};
    public cargos: any[] = [];
    public cargo: any = {};
    public RANDOM_VALUE_MARGIN = (this.appName === 'naturfrut') ? 400 : 0;

    public editingEnabled: boolean = false;
    public date = moment().toDate();
    public es = Utils.es;
    public override model: any = {};
    public isDownloadingCsv = false;
    public almacenFilter = '';

    public cols: any[] = [];
    public colsNoEdit: any[] = [
        { field: 'nif', header: 'NIF' },
        { field: 'nombre', header: 'Nombre' },
        { field: 'horaEntrada', header: 'Entrada (mañana)' },
        { field: 'horaSalida', header: 'Salida (mañana)' },
        { field: 'horaEntradaTarde', header: 'Entrada (tarde)' },
        { field: 'horaSalidaTarde', header: 'Salida (tarde)' },
        { field: this.showFormatHourMinute ? 'hour_minute' : 'horas', header: 'Horas' },
        { field: 'cargo_nombre', header: 'Cargo', visible: this.showFichajesCargos }
    ];

    public colsEdit: any[] = [
        { field: 'nif', header: 'NIF' },
        { field: 'nombre', header: 'Nombre' },
        { field: 'horaEntrada', header: 'Entrada (mañana)', editable: true, maxLength: 5 },
        { field: 'horaSalida', header: 'Salida (mañana)', editable: true, maxLength: 5 },
        { field: 'horaEntradaTarde', header: 'Entrada (tarde)', editable: true, maxLength: 5 },
        { field: 'horaSalidaTarde', header: 'Salida (tarde)', editable: true, maxLength: 5 },
        { field: this.showFormatHourMinute ? 'hour_minute' : 'horas', header: 'Horas', editable: true, maxLength: 5 },
        { field: 'cargo_nombre', header: 'Cargo', visible: this.showFichajesCargos }
    ];
    public showFichajes  = true;
    public horas = '';
    public hour_minute = '';

    constructor(public trabajadoresApi: TrabajadoresApiService,
        private dashboardEvent: DashboardService,
        private messageService: MessageService,
        private router: Router
    ) {
        super(dashboardEvent, 
            trabajadoresApi.fichajesGET, 
            trabajadoresApi.fichajesDELETE);
    
    }

    ngOnInit() {
    
        this.initFilters();
        if (this.showTrabajadoresCuadrillas) {
            this.getCuadrillas();
        }
        if (this.showFichajesCargos) {
            this.getCargos();
        }

        this.cols = this.colsNoEdit;
        this.dashboardEvent.setSubmenuItem('fichajes-test');

        this.trabajadoresApi.fichajesGET.response(() => {
            this.isPerforming = false;
        });
    }

    redirectTo(uri: string){
        this.router.navigateByUrl('/', {skipLocationChange: false}).then(() =>
            this.router.navigate([uri]));
    }


    formatoHorasMinutos(horaEntrada: string, horaSalida: string) {
        let timeStr: any = horaEntrada;
        timeStr = timeStr.split(':');

        const h = timeStr[0],
            m = timeStr[1];

        const newTime = moment( horaSalida + ' 2021-4-5')
            .subtract({'hours': h, 'minutes': m})
            .format('hh:mm');

        return newTime;
   
    }

    timeToDecimal(t: any) {
        t = t.split(':');
        return parseInt(t[0], 10) * 1 + parseInt(t[1], 10) / 60;
        // var arr:string[] = t.split(':');
        // var dec:any = parseInt( (( parseInt(arr[1])  / 6) * 10, 10).toString());

    // return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
    }

    mostrarUbicacion(coordenadas: string) {
        if (coordenadas) {
            window.open('https://www.google.com/maps/search/?api=1&query=' + coordenadas, '_blank');
        } else {
            this.messageService.add(
                { 
                    severity: 'error', 
                    summary: 'Sin Ubicación Conocida', 
                    detail: 'No hay ubicación registrada para este fichaje.' 
                }
            );   
        }
    }

    public changeDate() {
        this.getRequest.safePerform({ fecha: moment(this.date).format('DD/MM/YYYY') });
    }

    public autofillHoras(horas: any, horaEntrada: any, horaSalida: any, horaEntradaTarde: any, horaSalidaTarde: any) {
        const h1 = moment((horaEntrada.value || '00:00'), 'hh:mm').hours();
        const m1 = moment((horaEntrada.value || '00:00'), 'hh:mm').minutes();
        const h2 = moment((horaEntradaTarde.value || '00:00'), 'hh:mm').hours();
        const m2 = moment((horaEntradaTarde.value || '00:00'), 'hh:mm').minutes();

        let horasMañana = 0;
        let minutosMañana = 0;
        let horasTarde = 0;
        let minutosTarde = 0;
        if ( this.showFormatHourMinute ) {
      
            const horaEntradaFormateada = moment(horaEntrada.value || '00:00', 'HH:mm').format('HH:mm');
            const horaSalidaFormateada = moment(horaSalida.value || '00:00', 'HH:mm').format('HH:mm');
            const horaEntradaTardeFormateada = moment(horaEntradaTarde.value || '00:00', 'HH:mm').format('HH:mm');
            const horaSalidaTardeFormateada = moment(horaSalidaTarde.value || '00:00', 'HH:mm').format('HH:mm');
   
      
            let diffManana = '';
            let diffTarde = '';
      
            let checkMorning = false;
            let checkAfter = false;
            if (horaEntrada.value && horaSalida.value) {
                diffManana = this.formatoHorasMinutos( horaEntradaFormateada, horaSalidaFormateada );
                checkMorning = true;
            }
            if (horaEntradaTarde.value && horaSalidaTarde.value) {
                diffTarde = this.formatoHorasMinutos( horaEntradaTardeFormateada, horaSalidaTardeFormateada);
                checkAfter = true;
            }

            if (checkMorning) { 
                this.horas =  diffManana;       
            }

            if (checkAfter){ 
                this.horas = diffTarde;
            }

            if ( checkMorning && checkAfter) {
                this.horas =  
                moment(diffTarde, 'HH:mm').add(diffManana.split(':')[0], 'h').add(diffManana.split(':')[1], 'm').format('HH:mm');
            }

            this.hour_minute = this.horas;
      
        } else {
            const diffManana = moment((horaSalida.value || horaEntrada || '00:00'), 'hh:mm')
                .subtract(h1, 'hours')
                .subtract(m1, 'minutes');
            const diffTarde = moment((horaSalidaTarde.value || horaEntradaTarde.value || '00:00'), 'hh:mm')
                .subtract(h2, 'hours')
                .subtract(m2, 'minutes');

            if (horaEntrada.value && horaSalida.value) {
                horasMañana = diffManana.hours();
                minutosMañana = diffManana.minutes();
            }
            if (horaEntradaTarde.value && horaSalidaTarde.value) {
                horasTarde = diffTarde.hours();
                minutosTarde = diffTarde.minutes();
            }

            horas.value = (horasMañana + horasTarde + ((minutosMañana + minutosTarde) / 60)).toFixed(2);
        }

   

        if (horas.value > 23 || horas.value < 0 || ['0', 0].includes(horas.value)) {
            horas.value = null;
        }
    }

    public save(horas?: any, horaEntrada?: string, horaSalida?: string, horaEntradaTarde?: string, horaSalidaTarde?: string) {
    
        this.isPerforming = true;
        const fichajesToSend: { id: any; horas: any; horaEntrada: any; horaSalida: any; horaEntradaTarde: any; horaSalidaTarde: any; fecha: string; id_trabajador: any; id_cliente: number; id_usuario: string; }[] = [];
    
        const toSend = {
            horaEntrada: '',
            horaSalida: '',
            horaEntradaTarde: '',
            horaSalidaTarde: '',
            horas: null,
        };
    
        (this.filtering.value || []).forEach(fichaje => {
      
            if ( horas == null ){
                this.horas = '';
            }

            if (horaEntrada) {
                toSend.horaEntrada = moment(horaEntrada, 'HH:mm')
                    .subtract(Math.floor(Math.random() * this.RANDOM_VALUE_MARGIN), 'seconds').format('HH:mm');
            }

            if (horaSalida) {
                toSend.horaSalida = moment(horaSalida, 'HH:mm')
                    .subtract(Math.floor(Math.random() * this.RANDOM_VALUE_MARGIN), 'seconds').format('HH:mm');
            }
            if (horaEntradaTarde) {
                toSend.horaEntradaTarde = moment(horaEntradaTarde, 'HH:mm')
                    .subtract(Math.floor(Math.random() * this.RANDOM_VALUE_MARGIN), 'seconds').format('HH:mm');
            }
            if (horaSalidaTarde) {
                toSend.horaSalidaTarde = moment(horaSalidaTarde, 'HH:mm')
                    .subtract(Math.floor(Math.random() * this.RANDOM_VALUE_MARGIN), 'seconds').format('HH:mm');
            }
      
            if ( horas == null ){
                let diffManana = '';
                let diffTarde = '';
                let checkMorning = false;
                let checkAfter = false;
                if (fichaje.horaEntrada && fichaje.horaSalida) {
                    diffManana = this.formatoHorasMinutos( fichaje.horaEntrada , fichaje.horaSalida );
                    checkMorning = true;
                }
  
                if (fichaje.horaEntradaTarde && fichaje.horaSalidaTarde) {
                    diffTarde = this.formatoHorasMinutos( fichaje.horaEntradaTarde, fichaje.horaSalidaTarde);
                    checkAfter = true;
                }
  
                if (checkMorning) { 
                    this.horas =  diffManana;       
                }
  
                if (checkAfter){ 
                    this.horas = diffTarde;
                }
        
                if ( checkMorning && checkAfter) {
                    this.horas =  moment(diffTarde, 'HH:mm')
                        .add(diffManana.split(':')[0], 'h')
                        .add(diffManana.split(':')[1], 'm')
                        .format('HH:mm');
                }

                if ( !checkMorning && !checkAfter) {
                    this.horas =  ' ';
                }
        
            }
      
            this.hour_minute = this.horas;  
      
            if (horas || this.horas) {
                const sendHours: any = { 
                    id: fichaje.id || fichaje.id_fichaje,
                    horas: toSend.horas || (fichaje.horas || '').toString().replace(',', '.'),
                    horaEntrada: toSend.horaEntrada || fichaje.horaEntrada,
                    horaSalida: toSend.horaSalida || fichaje.horaSalida,
                    horaEntradaTarde: toSend.horaEntradaTarde || fichaje.horaEntradaTarde,
                    horaSalidaTarde: toSend.horaSalidaTarde || fichaje.horaSalidaTarde,
                    fecha: moment(this.date).format('DD/MM/YYYY'),
                    id_trabajador: fichaje.id_trabajador,
                    id_cliente: 0,
                    id_usuario: (this.usuarioActual || {} as any).id,
                };
        
                if ( this.showFormatHourMinute ) {
                    sendHours['hour_minute'] = this.horas;
                    sendHours['horas'] = isNaN(this.timeToDecimal(this.horas)) ? '' : this.timeToDecimal(this.horas);
                }

                fichajesToSend.push(sendHours);
        
            }
      
        });
        let contador = 0;
        (fichajesToSend || []).forEach(async (fichaje) => {

            await this.postRequest.toPromise(fichaje).then( resp => {
                console.log({resp});
            }).catch( error => {
                console.log({error});
            });
            contador++;
      
            if (contador === (fichajesToSend.length - 1)) {
       
                this.getRequest.safePerform({ fecha: moment(this.date).format('DD/MM/YYYY') });

                await this.trabajadoresApi.fichajesGET.toPromise();
                this.getRequest = this.trabajadoresApi.fichajesGET;
                this.filtering.filter();
            }

        });

    }

    public edit() {
        this.editingEnabled = !this.editingEnabled;
        if (this.editingEnabled) {
            this.cols = this.colsEdit;
        } else {
            this.cols = this.colsNoEdit;
        }
    }

    public globalConditionalStyle(_value: any, col: { field: string; }, _row: any): any {
        if (['horas', 'horaEntrada', 'horaSalida', 'horaEntradaTarde', 'horaSalidaTarde', 'hour_minute'].includes(col.field)) {
            return {
                textAlign: 'center'
            };
        }
    }

    public globalVisualTransform(value: any, col: { field: string; }) {
        if ( !this.showFormatHourMinute ) {
            if (['horas'].includes(col.field) && (value || 0).toString().includes('.')) {
                return Utils.decimalFormat(value, 2);
            }
        }
        return value;
   
    }

    public exportarCSV() {
        this.isDownloadingCsv = true;

        let csv = 'data:text/csv;charset=utf-8,';
        (this.filtering.value || []).forEach((row, i) => {
            if (i === 0) {
                csv += '"' + Object.keys(row).join('";"') + '"\n';
            }
            csv += '"' + Object.values(row).join('";"') + '"\n';
        });

        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;
        csvLink.download = 'fichajes.csv';
        csvLink.href = encodeURI(csv);
        csvLink.click();

        this.isDownloadingCsv = false;
    }

    public formChanges(tag: string) {
        if (tag === 'es-almacen') {
            if (this.almacenFilter !== 'es-almacen') {
                this.almacenFilter = 'es-almacen';
            } else {
                this.almacenFilter = '';
            }
        }

        if (tag === 'no-es-almacen') {
            if (this.almacenFilter !== 'no-es-almacen') {
                this.almacenFilter = 'no-es-almacen';
            } else {
                this.almacenFilter = '';
            }
        }

        this.filtering.filter();
        this.sortAlphabetically(this.filtering.value, 'nombre');
    }

    public editField(obj: { event: KeyboardEvent; data: any; from: string }) {
    // Arreglo decimal de las horas
        if (obj.from === 'horas' && (obj.data.horas || 0).toString().includes(',')) {
            obj.data.horas = parseFloat(obj.data.horas.replace(',', '.'));
            return;
        }

        const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
        if (!timeFormat.test(obj.data[obj.from])) {
            obj.data[obj.from] = '';
        }

        let horas: any = 0;
        // Sólo calculamos la duración de un periodo si existe una hora de inicio y otra de fin de ese mismo periodo.
        if (timeFormat.test(obj.data.horaEntrada) && timeFormat.test(obj.data.horaSalida)) {
            if ( this.showFormatHourMinute ) {
                horas = this.formatoHorasMinutos(obj.data.horaEntrada, obj.data.horaSalida );
            } else {
                horas += moment(obj.data.horaSalida, 'hh:mm').hours() + (moment(obj.data.horaSalida, 'hh:mm').minutes() / 60);
                horas -= moment(obj.data.horaEntrada, 'hh:mm').hours() + (moment(obj.data.horaEntrada, 'hh:mm').minutes() / 60);
            }
     
        }

        if (timeFormat.test(obj.data.horaEntradaTarde) && timeFormat.test(obj.data.horaSalidaTarde)) {
            if ( this.showFormatHourMinute ) {
                const auxHours = this.formatoHorasMinutos(obj.data.horaEntradaTarde, obj.data.horaSalidaTarde );
                horas = moment(horas, 'HH:mm').add(auxHours.split(':')[0], 'h').add(auxHours.split(':')[1], 'm').format('HH:mm');
            } else {
                horas += moment(obj.data.horaSalidaTarde, 'hh:mm').hours() + (moment(obj.data.horaSalidaTarde, 'hh:mm').minutes() / 60);
                horas -= moment(obj.data.horaEntradaTarde, 'hh:mm').hours() + (moment(obj.data.horaEntradaTarde, 'hh:mm').minutes() / 60);
            }
     
        }
    
   
        if (horas !== 0) {
            if (horas < 0 || horas > 24) {
                horas = 0;
            }

            if (horas.toString().includes('.')) {
                horas = (horas as number).toFixed(2);
            }
            if ( this.showFormatHourMinute ) {
                obj.data.hour_minute = horas;
            }
            this.horas = horas;
            obj.data.horas = horas;
        }

    }

    private initFilters() {
        this.filtering.addFilter((copy: any[]) => copy.filter(row => {
            if (this.usuarioActual.rol === 'basico') {

                return row.nombre === this.usuarioActual.nombre + ' ' + this.usuarioActual.apellidos;
            } else {
                return [1, '1', true].includes(row.activo);
            }
        }));

        if (this.showTrabajadoresCuadrillas) {
            this.filtering.addFilter((copy: any[]) => copy.filter(row => {
                if (this.cuadrilla && this.cuadrilla.id) {
                    return (row.id === this.cuadrilla.id) || (row.cuadrilla === this.cuadrilla.id);
                }
                return row;
            }));
        }

        if (this.showFichajesCargos) {
            this.filtering.addFilter((copy: any[]) => copy.filter(row => {
                if (this.cargo && this.cargo.id) {
                    return row.id_cargo === this.cargo.id;
                }
                return row;
            }));
        }

        if (this.appName === 'naturfrut') {
            this.filtering.addFilter((copy: any[]) => copy.filter(row => {
                switch (this.almacenFilter) {
                    case 'es-almacen':
                        return [true, '1', 1].includes(row.es_almacen);
                    case 'no-es-almacen':
                        return [null, undefined, '0', 0].includes(row.es_almacen);
                    default:
                        return true;
                }
            }));
        }
    }

    private sortAlphabetically(collection: any[], field: string) {
        collection.sort(function (a, b) {
            let nameA, nameB;
            if (a && b && a.value && b.value) {
                nameA = (a.value[field] || '').toUpperCase(); // ignore upper and lowercase
                nameB = (b.value[field] || '').toUpperCase(); // ignore upper and lowercase
            } else {
                return 0;
            }

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
    }

    private getCuadrillas() {
        this.trabajadoresApi.cuadrillas.GET.toPromise().then(cuadrillas => {
            this.cuadrillas = cuadrillas.map((it: { nombre: any; apellidos: any; }) => ({
                label: (it.nombre || '') + ' ' + (it.apellidos || ''),
                value: it
            }));
            this.cuadrillas.unshift({ label: '...', value: null });
        });
    }

    private getCargos() {
        this.trabajadoresApi.cargosGET.toPromise().then(cargos => {
            this.cargos = cargos.map((it: { nombre: any; }) => ({
                label: it.nombre,
                value: it
            }));
            this.cargos.unshift({ label: '...', value: null });
        });
    }
}
