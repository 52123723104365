import {Component} from '@angular/core';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {Router} from '@angular/router';
import {ProductosApiService} from '../../../../service/api/productos-api';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {Utils} from '../../../../common/utils';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {BaseView} from '../../../base-view';
import {environment} from '../../../../../environments/environment';

import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-producto-fertilizante',
    templateUrl: './producto-fertilizante.component.html',
    styleUrls: ['./producto-fertilizante.component.scss']
})
export class ProductoFertilizanteComponent extends BaseView implements OnInit, OnDestroy {

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public canAddProductos = environment.features.canAddProductos;
    public canEditProductos = environment.features.canEditProductos;
    public canDeleteProductos = environment.features.canDeleteProductos;
    public showFertisComposicion = environment.features.showFertisComposicion;
    public showFertilizantesNPK = environment.features.showFertilizantesNPK;
    public showFertilizantesCa = environment.features.showFertilizantesCa;
    public showFertilizantesS = environment.features.showFertilizantesS;
    public showFertilizantesMg = environment.features.showFertilizantesMg;
    public showDescripcion = environment.features.showDescripcion;
    public showPrecio = environment.features.showPrecio;
    public showFertisComposicionCol = environment.features.showFertisComposicionCol;
    public showFertisOtrosCompuestosCol = environment.features.showFertisOtrosCompuestosCol;
    public showWidthFertilizanteNombre = environment.features.showWidthFertilizanteNombre;
    public showRoturaStock = environment.features.showFertiRoturaStock;
    public showFertilizantesSubtipo = environment.features.showFertilizantesSubtipo;

    public getRequest: RequestHandler = this.productosApi.productos.GET;
    public deleteRequest: RequestHandler = this.productosApi.productos.DELETE;
    public menuItemId = 'productos-fertilizantes';
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: { [key: string]: any } = {};
    public override model:  { [key: string]: any } = {};;
    public buttonText = 'Añadir Fertilizante';

    public tableValues = new RequestHandler();

    public topFormFields: ItemInterface<any>[] = [
        {label: this.appName === 'vginer' ? 'Fertilizante' : 'Nombre o Marca Comercial', field: 'nombre',
            inputType: {type: InputType.TEXT}},
        {
            label: this.appName === 'agropecuariaramirogruas' ? 
                'Descripción (Tipo Fertilizante)' : 
                'Descripción', 
            field: 'descripcion', 
            inputType: {type: InputType.TEXTAREA}, 
            visible: this.showDescripcion
        },
    ];

    public bottomFormFieldsLeft: ItemInterface<any>[] = [
        {label: 'Precio', field: 'precio', inputType: {type: InputType.TEXT}, visible: this.showPrecio},
        {label: 'Unidades medida', field: 'unidades', inputType: {type: InputType.TEXT}},
        {label: 'Envase', field: 'envase', inputType: {type: InputType.TEXT}},
        {
            label: 'Rotura stock', 
            field: 'rotura_stock', 
            inputType: {type: InputType.TEXT},
            visible: this.showRoturaStock },
        {label: 'Subtipo', field: 'subtipo', inputType: {type: InputType.TEXT},
            visible: this.showFertilizantesSubtipo}
    ];

    public bottomFormFieldsRight: ItemInterface<any>[] = [
        {label: 'N%', field: 'N', inputType: {type: InputType.TEXT}, visible: this.showFertilizantesNPK},
        {label: 'P%', field: 'P', inputType: {type: InputType.TEXT}, visible: this.showFertilizantesNPK},
        {label: 'K%', field: 'K', inputType: {type: InputType.TEXT}, visible: this.showFertilizantesNPK},
        {label: 'Ca%', field: 'Ca', inputType: {type: InputType.TEXT}, visible: this.showFertilizantesCa},
        {label: 'S%', field: 'S', inputType: {type: InputType.TEXT}, visible: this.showFertilizantesS},
        {label: 'Mg%', field: 'Mg', inputType: {type: InputType.TEXT}, visible: this.showFertilizantesMg}
    ];

    public formFieldsMaterialFerti: ItemInterface<any>[] = [
        {field: 'material_ferti', label: 'Material Fertilizante', inputType: {type: InputType.TEXT}},
        {field: 'n_total', placeholder: 'macronutriente_1' , label: 'N Total', inputType: {type: InputType.TEXT}},
        {field: 'n_organico', placeholder: 'macronutriente_3' , label: 'N Orgánico', inputType: {type: InputType.TEXT}},
        {field: 'n_nitrico', placeholder: 'macronutriente_2' , label: 'N Nítrico', inputType: {type: InputType.TEXT}},
        {field: 'n_amoniacal', placeholder: 'macronutriente_4' , label: 'N Amoniacal', inputType: {type: InputType.TEXT}},
        {field: 'n_ureico', placeholder: 'macronutriente_5' , label: 'N Ureico', inputType: {type: InputType.TEXT}},
        {field: 'p2o5_total', placeholder: 'macronutriente_6' , label: 'P2O5 Total', inputType: {type: InputType.TEXT}},
        {field: 'p2o5_agua', placeholder: 'macronutriente_7' , label: 'P205 Soluble en Agua', inputType: {type: InputType.TEXT}},
        {
            field: 'p2o5_amonico', 
            placeholder: 'macronutriente_8' , 
            label: 'P205 Soluble en Citrato Amónico Neutro y Agua', 
            inputType: {type: InputType.TEXT}, 
            visible: this.applicationType !== 'cropgest'
        },
        {field: 'k20_total', placeholder: 'macronutriente_9' , label: 'K20 Total', inputType: {type: InputType.TEXT}, hasLabel: false},
        {field: 'materia_organica', placeholder: 'macronutriente_10' , label: 'Materia orgánica', inputType: {type: InputType.TEXT}},
        {
            field: 'calcio', 
            placeholder: 'macronutriente_11', 
            label: 'Calcio (CaO)', 
            inputType: {type: InputType.TEXT}, 
            visible: this.applicationType !== 'cropgest'
        },
        {
            field: 'nombre_tipo', 
            placeholder: 'macronutriente_12', 
            label: 'Nombre del Tipo', 
            inputType: {type: InputType.TEXT}, 
            visible: this.applicationType !== 'cropgest'
        },
        {
            field: 'dosis', 
            placeholder: 'macronutriente_13', 
            label: 'Dosis', 
            inputType: {type: InputType.TEXT}, 
            visible: this.applicationType !== 'cropgest'
        },
        {
            field: 'tratamiento_recibido', 
            placeholder: 'macronutriente_15', 
            label: 'Tratamiento Recibido', 
            inputType: {type: InputType.TEXT}, 
            visible: this.applicationType !== 'cropgest'
        },
    ];

    public formFieldsEmpresaResiduos: ItemInterface<any>[] = [
        {field: 'empresa', label: 'Empresa suministradora (para estiércoles y residuos)', inputType: {type: InputType.TEXT}},
        {field: 'nif_empresa', label: 'NIF de Empresa suministradora (para estiércoles y residuos)', inputType: {type: InputType.TEXT}},    
    ];

    public baselineFormFields: ItemInterface<any>[] = [
        {label: 'Composición', field: 'composicion', inputType: {type: InputType.EDIT_TEXT}, visible: this.showFertisComposicion},
        /*         {label: 'Observaciones', field: 'observaciones', inputType: {type: InputType.EDIT_TEXTAREA}} */
    ];

    public formFieldsMetalesPesados: ItemInterface<any>[] = [
        { label: 'Cadmio (Cd)', field: 'm_cadmio', inputType: { type: InputType.EDIT_NUMERIC}},
        { label: 'Cobre (Cu)', field: 'm_cobre', inputType: { type: InputType.EDIT_NUMERIC}},
        { label: 'Plomo (Pb)', field: 'm_plomo', inputType: { type: InputType.EDIT_NUMERIC}},
        { label: 'Niquel (Ni)', field: 'm_niquel', inputType: { type: InputType.EDIT_NUMERIC}},
        { label: 'Zinc (Zn)', field: 'm_zinc', inputType: { type: InputType.EDIT_NUMERIC}},
        { label: 'Mercurio (Hg)', field: 'm_mercurio', inputType: { type: InputType.EDIT_NUMERIC}},
        { label: 'Cromo total (Cr)', field: 'm_cromo_total', inputType: { type: InputType.EDIT_NUMERIC}},
    ];

    /*     public baselineFormFields: ItemInterface<any>[] = [
        {label: 'Otros compuestos', field: 'observaciones', inputType: {type: InputType.TEXTAREA}}
    ]; */

    public cols = [
        {field: 'nombre', header: this.appName === 'vginer' ? 'Fertilizante' : 'Nombre o Marca Comercial', filter: '', 
            width: this.showWidthFertilizanteNombre ? '300px' : '100px'},
        {field: 'descripcion', header: 'Descripción', visible: this.showDescripcion},
        {field: 'composicion', header: 'Composición', visible: this.showFertisComposicion && this.showFertisComposicionCol},
        {field: 'precio', header: 'Precio', filter: '', width: '80px', visible: this.showPrecio},
        {field: 'unidades', header: 'Unidades', filter: '', width: '80px'},
        {field: 'envase', header: 'Envase', filter: '', width: '60px'},
        {field: 'N', header: 'N%', filter: '', width: '70px', visible: this.showFertilizantesNPK},
        {field: 'P', header: 'P%', filter: '', width: '70px', visible: this.showFertilizantesNPK},
        {field: 'K', header: 'K%', filter: '', width: '70px', visible: this.showFertilizantesNPK},
        {field: 'Ca', header: 'Ca%', filter: '', width: '70px', visible: this.showFertilizantesCa},
        {field: 'S', header: 'S%', filter: '', width: '70px', visible: this.showFertilizantesS},
        {field: 'Mg', header: 'Mg%', filter: '', width: '70px', visible: this.showFertilizantesMg},
        /* {field: 'observaciones', header: 'Observaciones', visible: this.showFertisOtrosCompuestosCol} */ // header: Otros\xa0compuestos
    ];

    constructor(public productosApi: ProductosApiService,
        private dashboardEvents: DashboardService,
        private router: Router) {
        super(dashboardEvents, productosApi.productos.GET);
    }

    ngOnInit() {
        this.dashboardEvents.setSubmenuItem(this.menuItemId);
        this.tableValues.isLoading = true;
        this.productosApi.productos.GET.safePerform();
        this.productosApi.productos.GET.response(data => {
            this.tableValues.emitResponse(data.filter((item: { tipo: string; }) => {
                this.tableValues.isLoading = false;
                return item.tipo === 'fertilizante';
            }));
        });
    }

    public globalConditionalStyle(value: string, col: { field: string; }, _row: any): any {

        if (col.field === 'observaciones') {
            return {
                textAlign: 'left',
                paddingLeft: '35px',
            };
            
        }

        if (Utils.isNumber(value) || col.field === 'precio') {
            return {
                textAlign: 'right',
                paddingRight: '30px',
            };
            
        }
        
        if (Utils.isNumber(value) && col.field !== 'descripcion') {
            return {
                textAlign: 'right',
            };
        }
        
    }

    public globalVisualTransform(value: string, col: { field: string; }) {
        if (Utils.isNumber(value) && col.field !== 'descripcion') {
            return Utils.decimalFormat(value, 2, '.', ',', 3);
        }

        if (col.field === 'descripcion') {
            return (value?.length > 25) ? value.slice(0, 25 - 1) + '…' : value;
        }
        return value
    }

    public addProducto() {
        this.router.navigate(['dashboard', 'crear-producto-fertilizante']);
    }

    public edit(data: any) {
        this.router.navigate(['dashboard', 'editar-producto-fertilizante', data.id]);
    }

    public override deleteRegister() {
        if (this.model['id']) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public override show(modal: string, data: any) {
        let x;

        this.model = data;
        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow.hasOwnProperty(x)) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

}
