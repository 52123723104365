import {Injectable} from '@angular/core';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';

@Injectable({
    providedIn: 'root'
})
export class TransaccionesApiService {

    public TransaccionesGET: RequestHandler = new RequestHandler();
    public TransaccionesGET_GENERIC: RequestHandler = new RequestHandler();
    public TransaccionesDELETE: RequestHandler = new RequestHandler();
    public TransaccionesPUT: RequestHandler = new RequestHandler();
    public TransaccionesPOST: RequestHandler = new RequestHandler();

    public sectoresGET: RequestHandler = new RequestHandler();
    public sectoresDELETE: RequestHandler = new RequestHandler();
    public sectoresPUT: RequestHandler = new RequestHandler();
    public sectoresPOST: RequestHandler = new RequestHandler();

    public parcelasGET: RequestHandler = new RequestHandler();
    public parcelasDELETE: RequestHandler = new RequestHandler();
    public parcelasPUT: RequestHandler = new RequestHandler();
    public parcelasPOST: RequestHandler = new RequestHandler();


    constructor(private syncService: SyncService,
        private api: ApiRequestService) {
        
        this.defineTransaccionesGET();
        this.defineTransaccionesPUT();
        this.defineTransaccionesPOST();
        this.defineTransaccionesDELETE();

        this.defineSectoresGET();
        this.defineSectoresPUT();
        this.defineSectoresPOST();
        this.defineSectoresDELETE();

        this.defineParcelasGET();
        this.defineParcelasPUT();
        this.defineParcelasPOST();
        this.defineParcelasDELETE();
    }

    private defineTransaccionesGET() {
        this.TransaccionesGET.fetch(HttpRequestType.GET, 'transacciones/get');
        this.TransaccionesGET_GENERIC.fetch(HttpRequestType.GET, 'generic/transacciones');
        
        this.api.registerRequest(this.TransaccionesGET);
        this.api.registerRequest( this.TransaccionesGET_GENERIC );

        this.syncService.register(this.syncService.baseSyncPolicy(this.TransaccionesGET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.TransaccionesGET_GENERIC));     
    }

    private defineTransaccionesPUT() {
        this.TransaccionesPUT
            .fetch(HttpRequestType.PUT, 'generic/transacciones');

        this.api
            .registerRequest(this.TransaccionesPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.TransaccionesPUT));
    }

    private defineTransaccionesPOST() {
        this.TransaccionesPOST
            .fetch(HttpRequestType.POST, 'generic/transacciones');
        
        this.api
            .registerRequest(this.TransaccionesPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.TransaccionesPOST));
    }

    private defineTransaccionesDELETE() {
        this.TransaccionesDELETE
            .fetch(HttpRequestType.DELETE, 'generic/transacciones');

        this.api
            .registerRequest(this.TransaccionesDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.TransaccionesDELETE));
    }

    private defineSectoresGET() {
        this.sectoresGET
            .fetch(HttpRequestType.GET, 'sectores/get');

        this.api
            .registerRequest(this.sectoresGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.sectoresGET));
    }

    private defineSectoresPUT() {
        this.sectoresPUT
            .fetch(HttpRequestType.PUT, 'generic/sectores');

        this.api
            .registerRequest(this.sectoresPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.sectoresPUT));
    }

    private defineSectoresPOST() {
        this.sectoresPOST
            .fetch(HttpRequestType.POST, 'generic/sectores');

        this.api
            .registerRequest(this.sectoresPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.sectoresPOST));
    }

    private defineSectoresDELETE() {
        this.sectoresDELETE
            .fetch(HttpRequestType.DELETE, 'generic/sectores');

        this.api
            .registerRequest(this.sectoresDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.sectoresDELETE));
    }

    private defineParcelasGET() {
        this.parcelasGET
            .fetch(HttpRequestType.GET, 'parcelas/get');

        this.api
            .registerRequest(this.parcelasGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.parcelasGET));
    }

    private defineParcelasPUT() {
        this.parcelasPUT
            .fetch(HttpRequestType.PUT, 'generic/parcelas');

        this.api
            .registerRequest(this.parcelasPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.parcelasPUT));
    }

    private defineParcelasPOST() {
        this.parcelasPOST
            .fetch(HttpRequestType.POST, 'generic/parcelas');

        this.api
            .registerRequest(this.parcelasPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.parcelasPOST));
    }

    private defineParcelasDELETE() {
        this.parcelasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/parcelas');

        this.api
            .registerRequest(this.parcelasDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.parcelasDELETE));
    }


}
