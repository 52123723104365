import { Component, ViewChild } from '@angular/core';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { ProductosFitosanitariosFormDefinition } from '../../producto-add/productos-form/form-control/form-definition';
import { AppFormRequest } from 'src/app/view/app-common/form-request/app-form-request';
import { BaseForm } from 'src/app/view/base-form';
import { Utils } from 'src/app/common/utils';
import moment from 'moment';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { FormCommonApiService } from 'src/app/service/api/formcommon-api.service';
import { ProductosApiService } from 'src/app/service/api/productos-api';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { list } from 'src/app/common/classes/request-builder';
import { Filtering } from 'src/app/service/filtering/filtering';

import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-producto-bio-form',
    templateUrl: './producto-bio-form.component.html',
    styleUrls: ['./producto-bio-form.component.css']
})
export class ProductoBioFormComponent extends BaseForm implements OnInit, OnDestroy {
    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
    requestButton: RequestButtonComponent = new RequestButtonComponent();

    public override model: any = {};
    public form: ProductosFitosanitariosFormDefinition = new ProductosFitosanitariosFormDefinition();

    public formRequest: AppFormRequest = new AppFormRequest();
    public isProductSelected: boolean = false;
    public hasntRegisterNumber: boolean = false;
    public appName = environment.appName;


    public productosOrdenados: any[] = [
        'Suelta de Enemigos Naturales',
        'Feromonas Picudo',
        'P. Persimilis (Spidex)',
        'A. Californicus (Spical)',
        'A. Swirkii (Swirski-mite)',
        'Cryptolaemus montrouzieri (Cryptoline)',
        'Crysoperla sp (Crysoline)',
        'Bioknock',
        'Equisetum arvense',
        'Tigre',
        'Micorriza (Aegis irriga)',
        'Btu-8 (Bactofus)',
        'Vigor Plus (Btu-8)',
        'Ácid plus (Btu-8)',
        'Nematodo Steirnernema carpocapsae (Biorend leñosos)',
        'Nematodo Heterorhabditis bacteriophora (Biorend melolontha)',
        'Nematodo Steirnernema feltiae ( Biorend invernaderos)',
        'Nematodo Steirnernema feltiae (Entonem)',
        'Kira',
        'Cynoil',
        'Extracto canela (Amicosec)',
        'Isr altech',
        'Jabón potásico (Neptasila)',
        'Bacillus thurigiensis israelensis (Vectobac)',
        'Bacillus thurigiensis israelensis granulado(Vectomax)',
        'Piretinas naturales 3% (Pirecris)',
        'Nematodo Heterorhabditis bacteriophora (Biorend trips)',
        'Nematodo Steirnernema carpocapsae (Capsanem)',
        'Trichoderma (Condor)',
        'Anagyrus pseudococci (Anagline)',
        'Isonet z',
        'Veni biosulfur (Azufre 70%)',
        'Cryptobug',
        'Citripar',
        'Aphytis',
        'Orius Laevigatus',
        'Orius Majusculus',
        'Beauveria Bassiana',
        'Rhizobug 250 adulto',
        'Amblyseius Andersoni',
        'Larvas Chrysoperla',
        'Phytoseiulus Persimilis',
        'Trichogramma Achaeae',
        'Pheromona Duponchelia Fovealis',
        'Euzophera Pinguis',
        'Opogona Sacchari',
        'Californicus System',
        'Feromona + Kairomona Rhynchonex (Picudo)',
        'Margaronia Unionalis', 
        'Scyphophorus Acupunctatus',
        'Zeuzera Pyrina',
        'Hypoaspis System', 
        'Phyllocnistis Citrella',
        'Pupas Anagyrus Vladimirii',
        'Anthocoris Nemoralis',
        'Amblyseius Swirskii',
        'Nesidiocoris Tenuis',
        'Stratiolaelaps Scimitus',
        'Econex Cromática Amarilla 20 X25 CM',
        'Econex Triangular Blanca Plegable (Trampa sin Lámina)',
        'Econex Lámina para Trampa Triangular',
        'Feromonas Prays Citri',
        'Feromonas Planococus Ficus',
        'Placas 20 X 25 CM Negras CAJA contiene 500 Uds.'
    ].sort();

    public productos: TypeAheadInterface = list(['...', null]);

    public unidades = {
        selected: null,
        filtered: [
            { label: '...', value: null },
            { label: 'ml', value: 'ml' },
        ],
        values: []
    };

    public topFormFields: ItemInterface<any>[] = [
        {
            field: 'nombre', label: 'Nombre o Marca Comercial', inputType: {type: InputType.DROPDOWN_SEARCH}, values: this.productos,
        },
        { field: 'composicion', label: 'Composición', inputType: {type: InputType.EDIT_TEXT} },
    
        { label: 'Precio', field: 'precio', inputType: { type: InputType.EDIT_NUMERIC } },
        {
            label: 'Ud Medida', field: 'unidades', inputType: { type: InputType.DROPDOWN }, values: this.unidades,
        },
    ];


    constructor(public dashboardEvents: DashboardService,
        public formApi: FormCommonApiService,
        public productosApi: ProductosApiService,
        public override router: Router,
        public override route: ActivatedRoute) {
        super(
            route,
            router,
            dashboardEvents,
            productosApi.productos.PUT,
            productosApi.productos.POST,
            'productos',
            'Actualizar Bio',
            'Alta de Producto Bio',
        );
    }

    ngOnInit() {
        this.productosOrdenados.forEach( producto => {
            this.productos.filtered.push({'label': producto, 'value': producto });
        });

        console.log(this.productos);

        // this.initFormFiltering();
        // this.expandFormRequest();

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setPostRequest(this.productosApi.productos.POST)
            .setPutRequest(this.productosApi.productos.PUT)
            .setGetRequest(this.productosApi.productos.GET)
            .setFormFields(Array.prototype
                .concat(
                    this.topFormFields,
          
                )
            )
            .setFieldsToSend((['id', 'num_registro', 'tipo'] as any)
                .concat(
                    this.topFormFields.map(it => it.field),
                ).filter((it: string) => it !== 'hasntRegisterNumber')
            );

        this.formRequest.load();

        this.softInit(this.getType());
    }

    public submit() {  

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.beforeSend(resolve => {
            this.model['tipo'] = 'bio';
            resolve(true);
        });

        this.formRequest.send();
    }

    public globalConditionalStyle(_cellValue: any, colDefinition: { field: string; }, _colGroup: any, rowValue: { fecha_inscripcion: string; fecha_caducidad: string; }): any {

        let estiloVademecum: any;
        if (colDefinition.field === 'composicion') {
            estiloVademecum = {
                rowStyle: {
                    color: 'black',
                    backgroundColor: '#ffffff',
                    borderBottom: '1px solid #dddddd'
                }
            };

            const diffInsc = moment(new Date()).diff(Utils.toDate(rowValue.fecha_inscripcion), 'months');
            if (diffInsc < 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#cbdecb',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            } else {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ffffff',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            }


            const diff = moment(new Date()).diff(Utils.toDate(rowValue.fecha_caducidad), 'months');

            if (diff > 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'white',
                        backgroundColor: '#222222',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            } else if (diff > 6) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ff7f7f',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            } else if (diff >= 0) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ffcf78',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            }
        }


        return estiloVademecum;
    }

    public goToVademecum(num_reg: number) {
        this.router.navigate(['dashboard', 'consultas', num_reg.toString()]);
    }

    public override goBack() {
        this.router.navigate(['dashboard', 'productos-bio']);
    }
    public applyFilters() {
        if (this.form.agentesFilter) {
            this.form.agentesFilter.filter();
        }
    }
    public formChanges(tag: string) {
        this.requestButton.error = '';

        switch (tag) {
            case 'nombre':
                if (!this.hasntRegisterNumber) {
                    this.formApi.magramaProducto.cancelablePerform({p3: this.model.nombre});
                }
                break;
            case 'uso':
                this.form.agentes.selected = null;
                this.applyFilters();
                break;
            case 'productos':
                this.form.usos.selected = null;
                this.isProductSelected = false;
                this.form.productos.selected = null;
                break;
        }

        this.hasntRegisterNumber = this.model.hasntRegisterNumber;
    }

    public override ngOnDestroy() {
        Utils.unsuscribe([
            this.formApi.magramaDetalleProducto,
            this.formApi.magramaProducto
        ]);
    }
  
}

interface TypeAheadInterface {
    selected: any;
    values: any[];
    filtered: any[];
    filterCallback?: Filtering<any>;
}

