
<p-panel>
  <button class="float-right btn btn-success"
    style="font-size: .8rem; padding: 5px;"
    (click)="showAddCultivos = true">
    Añadir un cultivo / variedad
  </button>

  <h4>Datos del Campo</h4>
  <app-form-builder
          [model]="model"
          [undoEnabled]="undoEnabled"
          [distributionColumns]="4"
          [fields]="formFieldsDatosParcela"          
          (formChanges)="formChanges($event)">
  </app-form-builder>
  <br>
  <br>

  <button *ngIf="showMapas"
    class="float-right btn btn-warning btn-sm"
    (click)="abreMapa();">
    <i class="fa fa-search mr-1" aria-hidden="true"></i>
    <span>Mapa</span>
  </button>

  <h4>Datos SigPAC</h4>
  <app-form-builder
          [model]="model"
          [undoEnabled]="undoEnabled"
          [distributionColumns]="4"
          [fields]="formFieldsDatosSigpac"      
          (formChanges)="formChanges($event)">
  </app-form-builder>
  <br>
  <br>
  <div class="d-flex justify-content-center">
      <div>
          <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
      </div>
      <app-request-button
              (click)="submit()"
              [mode]="requestButtonType"
              [lockOnSuccess]="true"
              [request]="request">
      </app-request-button>
  </div>
</p-panel>
<br>

<p-dialog [header]="'Mapa ' + parcelaName" [(visible)]="mapaAbierto" #mapaDialog (onShow)="scrollToTop()">
                       
<div *ngIf="mapaAbierto" style="height:86%; margin-left:20px; margin-right:20px; width: 80vw !important; top: 0">
  <app-mapa-add
      [origin]="origin"
      [finca]="finca"
      [localidad]="localidad"
      [cultivo]="cultivo"
      [variedad]="variedad"
      [parcelaNombre]="parcelaNombre"
      (fromMapa)="getFromMapa($event)">
  </app-mapa-add>
</div>
<br>

</p-dialog>

<p-dialog header="Añadir cultivos / variedades" [(visible)]="showAddCultivos" (onShow)="scrollToTop()">
  <div style="width: 40vw; height: 40vh;">
    <div class="w-100 mb-5">
      <app-form-builder
        [model]="modelCultivo"
        [undoEnabled]="undoEnabled"
        [distributionColumns]="2"
        [fields]="formFieldsCultivos"    
        (formChanges)="formChangesCultivo($event)">
      </app-form-builder>
      <div class="mt-1 small"
        style="height: 15vh; overflow: auto;"
        [style.visibility]="(cultivosExistentes.length > 0) ? 'visible' : 'hidden'">
        <div *ngFor="let existente of cultivosExistentes">
          <button class="p-0 m-0 btn btn-sm btn-link"
            (click)="selectCultivoExistente(existente)">
            {{existente?.label}}
          </button>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="showAddCultivos = false">Cancelar</button>
      <button class="btn btn-primary mx-2" (click)="submitCultivo()" [disabled]="submittingCultivo">
        {{submittingCultivo ? 'Guardando...' : 'Guardar'}}
      </button>
    </div>

  </div>
</p-dialog>
