import { Component } from '@angular/core';

@Component({
    selector: 'app-reto',
    templateUrl: './reto.component.html',
    styleUrls: ['./reto.component.scss']
})
export class RetoComponent {
    constructor() { }
}
