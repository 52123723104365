import {Component, EventEmitter, Input, Output} from '@angular/core';

import type { OnChanges, OnInit } from '@angular/core';
@Component({
    selector: 'app-sidenav-cell',
    templateUrl: './sidenav-cell.component.html',
    styleUrls: ['./sidenav-cell.component.scss']
})
export class SidenavCellComponent implements OnInit, OnChanges {

    public static readonly CELL_BEHAVE_SELECTED = 'selected';
    public static readonly CELL_BEHAVE_UNSELECTED = 'un-selected';

    @Input() public id: string = '';
    @Input() public text: string = '';
    @Input() public icon: string = ' ';
    @Input() public isSelected: boolean = false;
    @Input() public expand: any;
    @Output() public cellClickHandler: EventEmitter<string> = new EventEmitter();
    @Output() public subcellClickHandler: EventEmitter<string> = new EventEmitter();

    public status: string = '';
    public submenuHeight: string = '';
    public submenuOpen: string = '';
    public isOpen = true;

    constructor() {
    }

    ngOnInit() {
        
        const height = [];
        if (this.expand) {
            for (const e of this.expand) {
                if (!(e.visible === false)) {
                    height.push(e);
                }
            }
        }
        this.submenuHeight = (height.length > 0) ? (height.length * 36).toString() + 'px' : '0';

        if (this.isSelected) {
            this.status = SidenavCellComponent.CELL_BEHAVE_SELECTED;
        } else {
            this.status = SidenavCellComponent.CELL_BEHAVE_UNSELECTED;
        }
    }

    ngOnChanges() {
        if (this.isSelected) {
            this.status = SidenavCellComponent.CELL_BEHAVE_SELECTED;
            this.submenuOpen = this.submenuHeight;
        } else {
            this.status = SidenavCellComponent.CELL_BEHAVE_UNSELECTED;
            this.submenuOpen = '0px';
        }
    }

    public sidenavClicked() {
        const height = [];
        if (this.expand) {
            for (const e of this.expand) {
                if (!(e.visible === false)) {
                    height.push(e);
                }
            }
        }
        this.submenuHeight = (height.length > 0) ? (height.length * 36).toString() + 'px' : '0';
        
        this.cellClickHandler.emit(this.id);
        if (this.isOpen) {
            this.isOpen = false;
            this.submenuOpen = '0px';
            this.isSelected = false;
        } else {
            this.isOpen = true;
            this.submenuOpen = this.submenuHeight;
            this.isSelected = true;
        }
    }

    public subSidenavClicked(id: string) {
        this.subcellClickHandler.emit(id);
    }

}
