import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';

@Injectable({
    providedIn: 'root'
})
export class CRMApiService {

    public CRM = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };


    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineCrm();

    }

    private defineCrm() {
        this.CRM.GET.fetch(HttpRequestType.GET, 'crm/get');
        this.CRM.POST.fetch(HttpRequestType.POST, 'crm/post');
        this.CRM.PUT.fetch(HttpRequestType.PUT, 'crm/put');
        this.CRM.DELETE.fetch(HttpRequestType.DELETE, 'generic/crm');

        this.api.registerRequest(this.CRM.GET);
        this.api.registerRequest(this.CRM.POST);
        this.api.registerRequest(this.CRM.PUT);
        this.api.registerRequest(this.CRM.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.CRM.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.CRM.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.CRM.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.CRM.DELETE));
    }
}
