import {InputType} from '../../../../../../common/components/form-builder/form-builder.component';
import {Filtering} from '../../../../../../service/filtering/filtering';
import {list} from '../../../../../../common/classes/request-builder';
import {FormRequestTypes} from '../../../../../../common/classes/form-request';
import { environment } from 'src/environments/environment';
import { checkIfRolAdmin } from 'src/app/common/check-role';

import type { ItemInterface } from '../../../../../../common/components/form-builder/form-builder.component';
export class TareasMtoPiscinasFormDefinition {

    public lastFieldChanged: string = '';

    public fincas: TypeAheadInterface = list();  
    public trabajadores: TypeAheadInterface = list();  
    public clientes: TypeAheadInterface = list();      
    public prioridades = list(['...'], 'Alta', 'Realizada');

    public requestType: FormRequestTypes = 0;

    public model = {};
    
    public appName = environment.appName;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;    

    public fechaActual = Date.now();

    public formFields: ItemInterface<any>[] = 
        [
            {field: 'fecha', label: 'Fecha', inputType: {type: InputType.CALENDAR}, required: true},
            {
                field: 'id_finca',
                label: this.fincaName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.fincas,
                valuePrimaryKey: 'id',
                filter: (it: { value: { id_cliente: any; }; }) => {
                    if (this.clientes.selected) {
                        return it.value.id_cliente === (this.clientes.selected || {}).id;
                    } else {
                        return true;
                    }
                },
                required: true
            },
            {field: 'hora_inicio', label: 'Hora Inicio', inputType: {type: InputType.TIME}, required: checkIfRolAdmin()},
            {field: 'hora_fin', label: 'Hora Fin', inputType: {type: InputType.TIME}, required: checkIfRolAdmin()},
            {field: 'horas_totales', label: 'Horas', inputType: {type: InputType.ONLY_TEXT} },
            {field: 'mantenimiento', label: 'Mantenimiento', inputType: {type: InputType.SWITCH}},     
            {field: 'prioridad', label: 'Prioridad/Realizada', inputType: {type: InputType.DROPDOWN}, values: this.prioridades}

        ];        
    public formFieldsTareas01: ItemInterface<any>[] = 
        [
            {field: 'barredera', label: 'Barredera', inputType: {type: InputType.SWITCH}},
            {field: 'cepillado', label: 'Cepillado Paredes/Fondo', inputType: {type: InputType.SWITCH}},
            {field: 'limpieza_red', label: 'Limpieza con Red', inputType: {type: InputType.SWITCH}},
            {field: 'rascado_linea_flotacion', label: 'Rascado Línea Flotación', inputType: {type: InputType.SWITCH}},
        ];

    public formFieldsTareas02: ItemInterface<any>[] =
        [
            {field: 'comprobar_parametros_ph', label: 'Ph', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'comprobar_parametros_cloro', label: 'Cloro', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'comprobar_parametros_acido_isocianurico', label: 'Acido Isocianurico', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'comprobar_parametros_alcalinidad', label: 'Alcalinidad', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'comprobar_parametros_fosfatos', label: 'Fosfatos', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'comprobar_parametros_observaciones', label: 'Observaciones', inputType: {type: InputType.EDIT_NUMERIC}},
        ];

    public formFieldsTareas03: ItemInterface<any>[] =
        [
            {field: 'lavado_enjuague_cesti', label: 'Lavado/Enjuague/Cesto Bomba', inputType: {type: InputType.SWITCH}},
            {field: 'limpieza_duchas', label: 'Limpieza Duchas', inputType: {type: InputType.SWITCH}},
            {field: 'limpieza_skimmer', label: 'Limpieza Skimmer', inputType: {type: InputType.SWITCH}},
            {field: 'nivel_vaso', label: 'Nivel del Vaso', inputType: {type: InputType.SWITCH}},
            {field: 'limpieza_cuarto_depuradora', label: 'Limpieza Cuarto Depuradora', inputType: {type: InputType.SWITCH}},
        ];

    public formFieldsProductos01: ItemInterface<any>[] = 
        [ 
            {field: 'cloro_liquido', label: 'Líquido (litros)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'cloro_granulado', label: 'Granulado (kilos)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'cloro_pastillas', label: 'Pastillas', inputType: {type: InputType.EDIT_NUMERIC}},
        ]; 

    public formFieldsProductos02: ItemInterface<any>[] = 
        [ 
            {field: 'floculante_liquido', label: 'Líquido (litros)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'floculante_pastillas', label: 'Pastillas', inputType: {type: InputType.EDIT_NUMERIC}},
        ]; 

    public formFieldsProductos03: ItemInterface<any>[] = 
        [ 
            {field: 'coagulante', label: 'Coagulante (litros)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'algicida', label: 'Algicida (litros)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'alcalinidad', label: 'Alcalinidad (kg)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'ph_minus', label: 'PH- (litros)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'ph_plus', label: 'PH+ (litros)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'activador', label: 'Activador (litros)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'enzimatic', label: 'Enzimatic (litros)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'desincrustante', label: 'Desincrustante (litros)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'eliminador_fosfatos', label: 'Eliminador Fosfatos (kg)', inputType: {type: InputType.EDIT_NUMERIC}},
        ]; 
    public formFieldsRecambios: ItemInterface<any>[] = 
        [      
            {field: 'arena_silice', label: 'Arena Sílice (25kg)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'limpiador_silex', label: 'Limpiador Silex (litros)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'vidrio', label: 'Vidrio (20kg)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'sacos_sal', label: 'Sacos Sal (25kg)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'limpiador_fotocelulas', label: 'Limpiador Fotocelulas (litros)', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'cesto_skimmer', label: 'Cesto Skimmer', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'tapas_skimmer', label: 'Tapas Skimmer', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'rejillas_sumidero', label: 'Rejillas Sumidero', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'foco_luz', label: 'Foco Luz', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'alcachofa_ducha', label: 'Alcachofa Ducha', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'pomo_sencilla', label: 'Pomo Sencilla', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'pomo_completo', label: 'Pomo Completo', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'red_piscina', label: 'Red Piscina', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'pertiga', label: 'Pertiga', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'bomba', label: 'Bomba', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'cesto_bomba', label: 'Cesto Bomba', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'escalera', label: 'Escalera', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'rejillas_transversales', label: 'Rejillas Transversales', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'impulsores_boquilla', label: 'Impulsores/Boquilla', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'neat_clean', label: 'Neat Clean', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'dosificadores_orp', label: 'Dosificadores ORP', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'dosificadores_ph', label: 'Dosificadores PH', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'sonda_orp', label: 'Sonda ORP', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'sonda_ph', label: 'Sonda PH', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'dosificador_pastillas', label: 'Dosificador Pastillas', inputType: {type: InputType.EDIT_NUMERIC}},
            {field: 'manometro', label: 'Manómetro', inputType: {type: InputType.EDIT_NUMERIC}},
        ];

    public formFieldsTrabajadores: ItemInterface<any>[] = [
        {
            field: 'ids_trabajadores',
            label: 'Profesionales',
            inputType: { type: InputType.MULTISELECT },
            values: this.trabajadores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: { value: { activo: string; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                return true;
            }
        },
    ];
    
    public formFieldsOrdenTrabajo: ItemInterface<any>[] = [
        {
            field: 'orden_trabajo', 
            label: 'Orden de trabajo', 
            inputType: { type: checkIfRolAdmin() ? InputType.TEXTAREA : InputType.EDIT_TEXTAREA },
        },
    ];

    public formFieldsObs: ItemInterface<any>[] = [
        { field: 'observaciones', label: 'Observaciones', inputType: { type: InputType.EDIT_TEXTAREA } },

    ];




    public formChanges(tag: string) {
        this.lastFieldChanged = tag;
    }

}

interface TypeAheadInterface {
    selected: any;
    values: any[];
    filtered: any[];
    filterCallback?: Filtering<any>;
}