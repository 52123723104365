import {InputType} from '../../../../../../common/components/form-builder/form-builder.component';
import {Filtering} from '../../../../../../service/filtering/filtering';
import {list} from '../../../../../../common/classes/request-builder';
import {FormRequestTypes} from '../../../../../../common/classes/form-request';
import { environment } from '../../../../../../../environments/environment';

import type { ItemInterface } from '../../../../../../common/components/form-builder/form-builder.component';

export class TareasBioFormDefinition {
    public sectoresMultiple = environment.features.showTareasBioSectorMultiple;
    public parcelasMultiple = environment.features.showTareasBioParcelaMultiple;

    public lastFieldChanged: string = '';
    public clientes: TypeAheadInterface = list();
    public fincas: TypeAheadInterface = list();
    public sectores: TypeAheadInterface = list();
    public parcelas: TypeAheadInterface = list();
    public trabajadores: TypeAheadInterface = list();

    
    public productosOrdenados: any[] = [
        'Suelta de Enemigos Naturales',
        'Feromonas Picudo',
        'P. Persimilis (Spidex)',
        'A. Californicus (Spical)',
        'A. Swirkii (Swirski-mite)',
        'Cryptolaemus montrouzieri (Cryptoline)',
        'Crysoperla sp (Crysoline)',
        'Bioknock',
        'Equisetum arvense',
        'Tigre',
        'Micorriza (Aegis irriga)',
        'Btu-8 (Bactofus)',
        'Vigor Plus (Btu-8)',
        'Ácid plus (Btu-8)',
        'Nematodo Steirnernema carpocapsae (Biorend leñosos)',
        'Nematodo Heterorhabditis bacteriophora (Biorend melolontha)',
        'Nematodo Steirnernema feltiae ( Biorend invernaderos)',
        'Nematodo Steirnernema feltiae (Entonem)',
        'Kira',
        'Cynoil',
        'Extracto canela (Amicosec)',
        'Isr altech',
        'Jabón potásico (Neptasila)',
        'Bacillus thurigiensis israelensis (Vectobac)',
        'Bacillus thurigiensis israelensis granulado(Vectomax)',
        'Piretinas naturales 3% (Pirecris)',
        'Nematodo Heterorhabditis bacteriophora (Biorend trips)',
        'Nematodo Steirnernema carpocapsae (Capsanem)',
        'Trichoderma (Condor)',
        'Anagyrus pseudococci (Anagline)',
        'Isonet z',
        'Veni biosulfur (Azufre 70%)',
        'Cryptobug',
        'Citripar',
        'Aphytis',
        'Orius Laevigatus',
        'Orius Majusculus',
        'Beauveria Bassiana',
        'Rhizobug 250 adulto',
        'Amblyseius Andersoni',
        'Larvas Chrysoperla',
        'Phytoseiulus Persimilis',
        'Trichogramma Achaeae',
        'Pheromona Duponchelia Fovealis',
        'Euzophera Pinguis',
        'Opogona Sacchari',
        'Californicus System',
        'Feromona + Kairomona Rhynchonex (Picudo)',
        'Margaronia Unionalis', 
        'Scyphophorus Acupunctatus',
        'Zeuzera Pyrina',
        'Hypoaspis System', 
        'Phyllocnistis Citrella',
        'Pupas Anagyrus Vladimirii',
        'Anthocoris Nemoralis',
        'Amblyseius Swirskii',
        'Nesidiocoris Tenuis',
        'Stratiolaelaps Scimitus',
        'Econex Cromática Amarilla 20 X25 CM',
        'Econex Triangular Blanca Plegable (Trampa sin Lámina)',
        'Econex Lámina para Trampa Triangular',
        'Feromonas Prays Citri',
        'Feromonas Planococus Ficus',
        'Placas 20 X 25 CM Negras CAJA contiene 500 Uds.'
    ].sort();
    
    public productos: TypeAheadInterface = list(['...', null]);
    //public ordenes: TypeAheadInterface = list(['...', null] ,['Dada','Dada'],['Aplicada','Aplicada']);
    //public ordenes: TypeAheadInterface = list(['...', null] ,'Dada','Aplicada');

    public tipo_plaga: TypeAheadInterface = list(['...', null], 
        'Ácaros', 
        'Aguado', 
        'Alternaria', 
        'Amarre fruto', 
        'Antracnosis',
        'Araña roja',
        'Ácaro blanco', 
        'Caracoles', 
        'Carencias', 
        'Ceratitis',
        'Citripar', 
        'Conservación', 
        'Cotonet',
        'Cuajado', 
        'Diaspinos', 
        'Enfermedades tronco',        
        'Engorde', 
        'Estímulo',
        'Gusano blanco',
        'Gusano de alambre',
        'Gusano gris',
        'Heladas',
        'Hormigas', 
        'Hongos',
        'Larvas Chrysoperla Carnea',
        'Malas hierbas', 
        'Mejorante', 
        'Melaza', 
        'Minador', 
        'Mosca blanca', 
        'Mosca scatella', 
        'Nutrición', 
        'Oidio',
        'Orius Laevigatus',
        'Otiorrinco',
        'Piojo rojo de california',
        'Piojo blanco',
        'Phytophtora', 
        'Phytoseiulus Persimilis',
        'Pulgones', 
        'Sciara',
        'Trichoderma (Condor)',
        'Tipula',
        'Trips'
    );

    public estados = list('Planificado', 'Orden ficha', 'Aplicado');        
    public estadoTarea = list(['...', null], 'Orden de Trabajo', 'Parte de Trabajo');        

    public requestType: FormRequestTypes = 0;

    public model = {};
    
    public appName = environment.appName;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public parcelasName = environment.features.parcelasName;
    public showTareasParcela = environment.features.showTareasParcela;
    public showBioMotivo = environment.features.showBioMotivo;
    public showBioOrden = environment.features.showBioOrden;
    
    public formFields: ItemInterface<any>[] = 
        [
            {
                field: 'id_finca',
                label: this.fincaName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.fincas,
                valuePrimaryKey: 'id',
                filter: (it: { value: { id_cliente: any; }; }) => {
                    if (this.clientes.selected) {
                        return it.value.id_cliente === (this.clientes.selected || {}).id;
                    } else {
                        return true;
                    }
                },
                required: true
            },
            {
                field: 'id_sector',
                label: this.sectorName,
                inputType: this.sectoresMultiple ? {type: InputType.MULTISELECT} : {type: InputType.DROPDOWN},
                multiSelect: this.sectoresMultiple,
                values: this.sectores,
                valuePrimaryKey: 'id',
                filter: (it: { value: { id_finca: any; }; }) => {
                    if (this.fincas.selected) {
                        return it.value.id_finca === (this.fincas.selected || {}).id;
                    } else {
                        return false;
                    }
                },
                visible: this.appName !== 'hernandorena' 
            },
            {
                field: 'ids_parcelas',
                label: this.parcelasName,
                inputType: this.parcelasMultiple ? {type: InputType.DROPDOWN_MULTISELECT_SEARCH} : {type: InputType.DROPDOWN},
                multiSelect: this.parcelasMultiple,
                values: this.parcelas,
                valuePrimaryKey: 'id',
                style: 'min-height: 29px !important;',
                visible: this.showTareasParcela,
                filter: (it: { value: { activo: string; id_sector: any; id_finca: any; id_cliente: any; }; }) => {
                    if (it.value.activo !== '1') {
                        return false;
                    }

                    if (this.sectores.selected.length !== 0) {
                        return this.sectores.selected.map((sector: { id: any; }) => sector.id).includes(it.value.id_sector);
                    } else if (this.fincas.selected) {
                        return it.value.id_finca === (this.fincas.selected || {}).id;
                    } else if (this.clientes.selected) {
                        return it.value.id_cliente === (this.clientes.selected || {}).id;
                    } else {
                        return false;
                    }
                },
                required: true
            },
            {
                field: 'fecha_receta', 
                label: this.appName === 'hernandorena' ? 'Fecha' : 'Fecha Orden', 
                inputType: {type: InputType.CALENDAR}
            },
            {
                field: 'fecha_aplicacion', 
                label: 'Fecha de aplicación', 
                visible: environment.appName !== 'hernandorena', 
                inputType: {type: InputType.CALENDAR}
            },
            {
                field: 'hora_inicio', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TIME}, 
                visible: environment.features.timeStartStopTareas && this.appName !== 'hernandorena'
            },
            {
                field: 'hora_fin', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TIME}, 
                visible: environment.features.timeStartStopTareas && this.appName !== 'hernandorena'
            },         
            {
                field: 'horas_totales', 
                label: 'Horas', 
                inputType: {type: InputType.ONLY_TEXT}, 
                visible: environment.features.tareasHorasTotales && this.appName !== 'hernandorena' 
            },
            {
                field: 'id_trabajadores',
                label: 'Trabajadores',
                inputType: { type: InputType.DROPDOWN_MULTISELECT_SEARCH },
                multiSelect: true,
                values: this.trabajadores,
                valuePrimaryKey: 'id',
                visible: this.appName !== 'hernandorena'
            },
            {field: 'facturado', label: 'Facturado', inputType: {type: InputType.SWITCH}, visible: this.appName === 'agrosalvi'},
            {
                field: 'factura_albaran', 
                label: 'Factura/Albarán', 
                inputType: {type: InputType.EDIT_TEXT}, 
                visible: this.appName === 'agrosalvi'
            },
            {field: 'producto', label: 'Producto', inputType: {type: InputType.DROPDOWN_SEARCH}, values: this.productos},
            {field: 'tipo_plaga', label: 'Tipo de plaga', inputType: {type: InputType.DROPDOWN_SEARCH}, values: this.tipo_plaga},
            {field: 'dosis', label: 'Dosis', inputType: {type: InputType.EDIT_TEXT}},
        
            {
                field: 'modo_accion', 
                label: this.appName === 'hernandorena' ? 'Tipo medida' : 'Modo de acción', 
                inputType: {type: InputType.EDIT_TEXT}
            },
            {field: 'forma_aplicacion', label: 'Forma de aplicación (intensidad)', inputType: {type: InputType.EDIT_TEXT}},
            { 
                field: 'suelta_m2', 
                label: 'Suelta m²', 
                inputType: {type: InputType.EDIT_NUMERIC },
                visible: this.appName === 'hernandorena' ? false : true
            },
            { 
                field: 'motivo', 
                label: 'Motivo', 
                inputType: {type: InputType.EDIT_TEXTAREA },
                visible: this.showBioMotivo
            },
            /*         { 
    field: 'gasto', 
            label: 'Gasto', 
            inputType: {type: InputType.EDIT_NUMERIC },
            visible: this.appName == 'hernandorena',
        }, */
            {
                field: 'ph_caldo',
                label: 'pH Caldo',
                inputType: {type: InputType.EDIT_NUMERIC},
                visible: this.appName === 'hernandorena'
            },
            {
                field: 'ph_agua',
                label: 'pH Agua',
                inputType: {type: InputType.EDIT_NUMERIC},
                visible: this.appName === 'hernandorena'            
            },
            {
                field: 'litros_caldo',
                label: 'litros de caldo',
                inputType: {type: InputType.EDIT_NUMERIC},
                visible: this.appName === 'hernandorena'            
            },        
            { 
                field: 'm2', 
                label: 'm²', 
                inputType: {type: InputType.EDIT_NUMERIC },
                visible: this.appName === 'hernandorena'
            },
            { 
                field: 'cantidad_total_producto', 
                label: 'Cantidad total producto', 
                inputType: {type: InputType.EDIT_NUMERIC },
                visible: this.appName === 'hernandorena'
            },
            {
                field: 'orden',
                label: this.appName === 'hernandorena' ? 'Estado' : 'Orden',
                inputType: {type: InputType.DROPDOWN},
                values: this.estados,
                visible: this.showBioOrden
            },
            {
                field: 'estado_tarea', 
                label: 'Estado de la Tarea', 
                inputType: {type: InputType.DROPDOWN},
                values: this.estadoTarea,
                visible: this.appName === 'onda' || this.appName === 'lacooperativa'
            },
            {
                field: 'empresa_pagadora', 
                label: 'Empresa Pagadora', 
                inputType: {type: InputType.EDIT_TEXT},
                visible: this.appName === 'onda' || this.appName === 'lacooperativa'
            }
        
        ];
    
    public formChanges(tag: string) {
        this.lastFieldChanged = tag;
    }
}

interface TypeAheadInterface {
    selected: any;
    values: any[];
    filtered: any[];
    filterCallback?: Filtering<any>;
}
