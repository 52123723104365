
<p-panel>
  <div style="max-width: 60%; position: absolute; right: 2rem;">
    <div *ngIf="model?.estado === 'Realizada'" style="display: flex;">
      <input type="file" #adjuntarAnalisis (change)="fileChange($event)" style="display: none;"/>
      <button *ngIf="model?.adjunto" class="btn btn-link" (click)="verAdjunto()">Ver</button>
      <input type="button" class="btn btn-success" value="Adjuntar análisis" (click)="readUrl(adjuntarAnalisis)" />
    </div>
  </div>
    
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">Actualizar Recordatorio de Análisis</h4>
      <h4 *ngIf="flag === 'new'">Crear Recordatorio de Análisis</h4>
    </div>
  </div>
  
  <app-form-builder #mainForm
          [model]="model"
          [undoEnabled]="undoEnabled"
          [distributionColumns]="3"
          [fields]="formFields"
          (selectAll)="selectAll()"
          (formChanges)="formChanges($event)">
  </app-form-builder>
  <br>
  <h4 *ngIf="this.applicationType === 'viverogest'">Resultados del Análisis</h4>
  <app-form-builder *ngIf="this.applicationType === 'viverogest'" #mainForm
          [model]="model"
          [undoEnabled]="undoEnabled"
          [distributionColumns]="3"
          [fields]="formFieldsResultadosAnalisis"
          (selectAll)="selectAll()"
          (formChanges)="formChanges($event)">
  </app-form-builder>
  <br>
  
  <div *ngIf="(type === 0 || type === 2) && model?.fechaSiguiente" class="d-flex align-content-center small">
    <p-inputSwitch
      [(ngModel)]="shouldCreateNextAnalisis">
    </p-inputSwitch>
    <span class="ml-2">
      Crear también el análisis siguiente (programado para el día {{model?.fechaSiguiente}})
    </span>
  </div>

  <br>

  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            (click)="submit()"
            [mode]="requestButtonType"
            [lockOnSuccess]="true"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>

