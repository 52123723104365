<br>
<p-panel>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">Actualizar Comercio</h4>
      <h4 *ngIf="flag === 'new'">Crear Comercio</h4>
    </div>
  </div>
  <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          [fields]="formFields"
          (formChanges)="formChanges()">
  </app-form-builder>
  <br>
  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            (click)="submit()"
            [lockOnSuccess]="true"
            [mode]="requestButtonType"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>


