import {Injectable} from '@angular/core';
import {RequestHandler} from 'src/app/service/OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';


@Injectable({
    providedIn: 'root'
})
export class MapaParcelasApiService {

    public mapaParcelasGET: RequestHandler = new RequestHandler();
    public mapaParcelasPUT: RequestHandler = new RequestHandler();
    public mapaParcelasPOST: RequestHandler = new RequestHandler();
    public mapaParcelasDELETE: RequestHandler = new RequestHandler();

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineMapaParcelasGET();
        this.defineMapaParcelasPUT();
        this.defineMapaParcelasPOST();
        this.defineMapaParcelasDELETE();
    }

    private defineMapaParcelasGET() {
        this.mapaParcelasGET
            .fetch(HttpRequestType.GET, 'mapas/mapa_parcelas');

        this.api
            .registerRequest(this.mapaParcelasGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.mapaParcelasGET));
    }

    private defineMapaParcelasPUT() {
        this.mapaParcelasPUT
            .fetch(HttpRequestType.PUT, 'generic/mapa_parcelas');

        this.api
            .registerRequest(this.mapaParcelasPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.mapaParcelasPUT));
    }

    private defineMapaParcelasPOST() {
        this.mapaParcelasPOST
            .fetch(HttpRequestType.POST, 'generic/mapa_parcelas');

        this.api
            .registerRequest(this.mapaParcelasPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.mapaParcelasPOST));
    }

    private defineMapaParcelasDELETE() {
        this.mapaParcelasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/mapa_parcelas');

        this.api
            .registerRequest(this.mapaParcelasDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.mapaParcelasDELETE));
    }

}


