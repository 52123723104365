<div class="resp-container" style="margin: -19px -21px 0px -35px; overflow: auto;min-height:100vh !important;">

    <div #gmap class="resp-iframe" style="width:100%; height:100vh !important; min-height:100vh !important;">
    </div>

    <ng-container *ngIf="showCargando">
        <img style="   
      width: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -75px;
      margin-left: -75px;" src="./assets/icon/load.gif" />
    </ng-container>

    <div #divSelectFincas>
        <select #selectFincas (change)="getFincaSelected($event.target)" style="
        background-color: white;
        cursor: pointer;
        padding: 0px 8px;
        margin: 10px;
        margin-left: 10px;
        border-radius: 2px;
        color:black;
        font-size: 1.6em;
        font-weight: bold;
        height:40px;
        border:1px solid grey;
        min-width: 180px;
        max-width: 180px;
        box-shadow: 6px 6px 3px grey;
      ">
      <option value="" disabled selected hidden>Selecciona {{fincaName.toLowerCase()}}...</option>
      <option *ngFor="let finca of arrayFincas" [value]="finca.nombre" [selected]="finca.nombre == finca">
       <span *ngIf="hasClients"> {{finca.nombre}} ({{finca.cliente_nombre}})</span>
       <span *ngIf="!hasClients"> {{finca.nombre}}</span>
      </option>
    </select>
    </div>

    <div #divSelectSectores>
      <select *ngIf="this.appName === 'cartagenafresh'" (change)="getSectorSelected($event.target)" style="
      background-color: white;
      cursor: pointer;
      padding: 0px 8px;
      margin: 10px;
      margin-left: 10px;
      border-radius: 2px;
      color:black;
      font-size: 1.6em;
      font-weight: bold;
      height:40px;
      border:1px solid grey;
      min-width: 180px;
      max-width: 180px;
      box-shadow: 6px 6px 3px grey;
    ">
      <option value="" selected>Selecciona {{sectorName.toLowerCase()}}...</option>
      <option *ngFor="let sector of arraySectores" [value]="sector.nombre" [selected]="sector.nombre == this.sectorSelected">
      <span *ngIf="hasClients"> {{sector.nombre}} ({{sector.cliente_nombre}})</span>
      <span *ngIf="!hasClients"> {{sector.nombre}}</span>
      </option>
    </select>
  </div>

    <div #divSearchMap>
        <input #searchMap placeholder="Buscar municipio, provincia, etc..." style="
        background-color: white;
        cursor: pointer;
        padding: 0px 8px;
        margin: 10px;
        margin-left: 10px;
        border-radius: 2px;
        color:black;
        font-size: 1em;
        height:40px;
        border:1px solid grey;
        min-width: 180px;
        max-width: 180px;
        box-shadow: 6px 6px 3px grey;
      ">
    </div>


    <div #divNuevaParcela id="divNuevaParcela" style="
      height:40px; 
      margin: 10px; 
      margin-left: 10px; 
      border-radius: 8px; 
      background-color: green;
      min-width: 180px;
      max-width: 180px;
      cursor: pointer;
      display: grid;
      visibility: hidden;
    " (click)="nuevaParcela()">
        <label style="text-align: center !important; font-size: 1.8em; margin: auto; cursor: pointer;">
    NUEVA {{parcelaName.toUpperCase()}}
  </label>
    </div>

    <div #divGuardarCambios id="divGuardarCambios" style="
    height:40px; 
    margin: 10px; 
    margin-left: 10px; 
    border-radius: 8px; 
    background-color: green;
    min-width: 180px;
    max-width: 180px;
    cursor: pointer;
    display: grid;
    visibility: hidden;
   " (click)="actualizarParcela()">
        <label style="text-align: center !important; font-size: 1.8em; margin: auto; cursor: pointer;">
  GUARDAR
</label>
    </div>

    <div #divCancelarEdicion id="divCancelarEdicion" style="
    height:40px; 
    margin: 10px; 
    margin-left: 10px; 
    border-radius: 8px; 
    background-color: orange;
    min-width: 180px;
    max-width: 180px;
    cursor: pointer;
    display: grid;
    visibility: hidden;
   " (click)="cancelarEdicion()">
        <label style="text-align: center !important; font-size: 1.8em; margin: auto; cursor: pointer;">
  CANCELAR
</label>
    </div>

    <div #divCancelaParcela id="divCancelaParcela" style="
      height:40px; 
      margin: 10px; 
      margin-left: 10px; 
      border-radius: 8px; 
      background-color: orange;
      min-width: 180px;
      max-width: 180px;
      cursor: pointer;
      display: grid;
      visibility: hidden;">
        <label style="text-align: center !important; font-size: 1.8em; margin: auto; cursor: pointer;">
    CANCEL
  </label>
    </div>

    <div #divMeteoBtn id="divMeteoBtn" [style.visibility]="showMeteoBtn ? 'visible' : 'hidden'" (click)="showWeatherFunction()" style="
      height:80px; 
      margin: 10px; 
      margin-left: -66px;
      margin-bottom: 24px;
      border-radius: 8px; 
      background-color: orange;
      min-width: 80px;
      max-width: 80px;
      cursor: pointer;
      display: grid;">
        <label style="text-align: center !important; font-size: 1.8em; margin: auto; cursor: pointer;">
    METEO
  </label>
    </div>


    <div #divMeteo id="divMeteo" [style.visibility]="showWeather ? 'visible' : 'hidden'" style="margin-left:6px; width:50%; margin-bottom:30px; height:80px;">
        <a class="weatherwidget-io" href="https://forecast7.com/es/39d47n0d38/valencia/" lang="spanish" data-icons="Climacons Animated" data-theme="pure" style="border-radius:10px !important;"></a>
        <script>
        const loadMeteoButton = !function(d, s, id) {
            let js = d.getElementsByTagName(s)[0];
            const fjs = d.getElementsByTagName(s)[0];

            if (!d.getElementById(id)) {
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://weatherwidget.io/js/widget.min.js';
                fjs.parentNode.insertBefore(js, fjs);
            }

        }(document, 'script', 'weatherwidget-io-js');

        loadMeteoButton();
        </script>
    </div>




    <div id="modal" #modal class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
                </div>
                <div class="modal-body">
                    <p>Modal body text goes here.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary">Save changes</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

</div>