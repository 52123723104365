
import { BaseListView } from './../../base-listview';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../../../service/events/dashboard.service';
import { PulverizacionModel } from '../../../models/tareas/pulverizacion.model';
import { StockApiService } from '../../../service/api/stock-api.service';

import type { OnInit } from '@angular/core';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';

@Component({
    selector: 'app-productos',
    templateUrl: './productos.component.html',
    styleUrls: ['./productos.component.scss']
})
export class StockProductosComponent extends BaseListView<PulverizacionModel> implements OnInit {

    public cols: ColInterface[] = [
        {field: 'num_registro', header: 'N Registro', filter: '', width: '120px'},
        {field: 'nombre', header: 'Nombre', filter: ''},
        {field: 'materia_activa', header: 'Materia Activa', filter: 'dropdown', width: '200px'},
        {field: 'plazo_seguridad', header: 'Plazo Seg.', filter: '', width: '120px'},
        {field: 'precio', header: 'Precio', filter: '', width: '80px'},
        {field: 'unidades', header: 'Unidades', filter: '', width: '80px'},
        {field: 'n', header: 'N', filter: '', width: '40px'},
        {field: 'p', header: 'P', filter: '', width: '40px'},
        {field: 'k', header: 'K', filter: '', width: '40px'}
    ];

    constructor(public stockApi: StockApiService,
        private dashboardEvents: DashboardService,
        private router: Router) {
                
        super(stockApi.productos);
    }

    ngOnInit() {
        this.dashboardEvents.setMenuItem('stock-productos');
        this.setDropdowns();
    }

    public addProducto(){
        this.router.navigate(['dashboard', 'stock-productos-form']);
    }
    
    private setDropdowns(){
        this.setDropdownSelectOn('', 'materia_activa');
    }

}
