import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {TrabajadoresApiService} from '../../../../../service/api/trabajadores-api.service';
import {BaseForm} from '../../../../base-form';
import {Utils} from '../../../../../common/utils';
import { build, list, stack } from 'src/app/common/classes/request-builder';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { BoquillaModel } from 'src/app/models/trabajadores/boquillas.model';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { ClientesApiService } from 'src/app/service/api/clientes-api.service';
import {StorageManager} from '../../../../../common/storage-manager.class';
import moment from 'moment';
import { VentasApiService } from 'src/app/service/api/ventas-api.service';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-maquinaria-form',
    templateUrl: './maquinaria-form.component.html',
    styleUrls: ['./maquinaria-form.component.scss']
})
export class MaquinariaFormComponent extends BaseForm implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
    requestButton: RequestButtonComponent = new RequestButtonComponent();

    public appName = environment.appName;
    public clienteName = environment.features.clienteName;
    public showMaquinariaGps = environment.features.showMaquinariaGps;
    public showAplicadoresActivo = environment.features.showAplicadoresActivo;
    public showMaquinariaClienteAsignado = environment.features.showMaquinariaClienteAsignado;
    public showMaquinariaPropietario = environment.features.showMaquinariaPropietario;
    public titleActivo = environment.features.titleActivoMaqTra;    
    public showMaquinariaAdjuntoPdf = environment.features.showMaquinariaAdjuntoPdf;

    public precioHora = environment.features.precioHora;

    public isShowingBoquilla: boolean = false;
    public isPerformingBoquilla: boolean = false;

    public adjunto: any;

    public override model: any = {
        activo: '1'
    };
    public boquillaModel = {hci: {}, amt: {}} as BoquillaModel;
    public boquillaModelOld = {hci: {}, amt: {}} as BoquillaModel;
    public boquillasList: BoquillaModel[] = [];

    public totalHCI = 0;
    public totalAMT = 0;
    public totalCaudales: any[] = [];
    public formRequest: AppFormRequest = new AppFormRequest();

    public comercios: TypeAheadInterface = list(['...', null]);
    public clientes = list();
    public tipos = list(
        ['...', null],
        'Abonadora', 
        'Apero de preparación de suelo',
        ['Atomizador/Turboatomizador', 'Turboatomizador'], // Mochila
        'Carretilla de fumigar',
        'Cosechadora',
        'Desbrozadora Manual',
        ['Cuba/Herbicida', 'Herbicida'], 
        ['Cuba con pistolete/Disparador normal', 'Disparador'],
        'Mochila',
        'Mochila para fumigar',
        'Parcheo',
        'Pulverizadora',
        'Remolque esparcidor de basura',
        'Sembradora',
        'Tractor',
    );

    public tablaCaudales: any = {
        hci: {
            index: ['h005', 'h0075', 'h01', 'h015', 'h02', 'h025', 'h03', 'h035', 'h04', 'h05'],
            3: [0.19, 0.3, 0.4, 0.6, 0.8, 1, 1, 2, 1.4, 1.6, 2],
            4: [0.22, 0.35, 0.46, 0.69, 0.92, 1.15, 1.39, 1.62, 1.85, 2.31],
            5: [0.25, 0.39, 0.52, 0.77, 1.03, 1.29, 1.55, 1.81, 2.07, 2.58],
            6: [0.27, 0.42, 0.57, 0.85, 1.13, 1.41, 1.7, 1.98, 2.26, 2.83],
            7: [0.29, 0.46, 0.61, 0.92, 1.22, 1.53, 1.83, 2.14, 2.44, 3.06],
            8: [0.31, 0.49, 0.65, 0.98, 1.31, 1.63, 1.96, 2.29, 2.61, 3.27],
            9: [0.33, 0.52, 0.69, 1.04, 1.39, 1.73, 2.08, 2.42, 2.77, 3.46],
            10: [0.35, 0.55, 0.73, 1.1, 1.46, 1.83, 2.19, 2.56, 2.92, 3.65],
            11: [0.36, 0.57, 0.77, 1.15, 1.53, 1.91, 2.3, 2.68, 3.06, 3.83],
            12: [0.38, 0.6, 0.8, 1.2, 1.6, 2, 2.4, 2.8, 3.2, 4],
            13: [0.4, 0.62, 0.83, 1.25, 1.67, 2.08, 2.5, 2.91, 3.33, 4.16],
            14: [0.41, 0.65, 0.86, 1.31, 1.73, 2.16, 2.59, 3.02, 3.46, 4.32],
            15: [0.42, 0.67, 0.89, 1.34, 1.79, 2.24, 2.68, 3.13, 3.58, 4.47],
            16: [0.44, 0.69, 0.92, 1.39, 1.85, 2.31, 2.77, 3.23, 3.7, 4.62],
            17: [0.45, 0.71, 0.95, 1.43, 1.9, 2.38, 2.86, 3.33, 3.81, 4.76],
            18: [0.47, 0.73, 0.98, 1.47, 1.96, 2.45, 2.94, 3.43, 3.92, 4.9],
            19: [0.48, 0.75, 1.01, 1.51, 2.01, 2.52, 3.02, 3.52, 4.03, 5.03],
            20: [0.49, 0.77, 1.03, 1.55, 2.07, 2.58, 3.1, 3.61, 4.13, 5.16]
        },
        // Valores referenciales. Se calculan en la función calcularTablaCaudal().
        amt: {
            index: ['a08', 'a1', 'a11', 'a12', 'a1212', 'a15', 'a1515', 'a18', 'a1818', 'a2', 'a22'],
            3: [0.45, 0.50, 0.88, 0.56, 1.16, 0.77, 1.93, 1.24, 2.33, 1.05, 2.30],
            4: [0.52, 0.58, 0.95, 0.67, 1.28, 0.89, 2.15, 1.40, 2.66, 1.22, 2.71],
            5: [0.60, 0.65, 1.02, 0.78, 1.40, 1.02, 2.37, 1.56, 3, 1.40, 3.12],
            6: [0.68, 0.72, 1.09, 0.89, 1.52, 1.15, 2.59, 1.72, 3.34, 1.58, 3.53],
            7: [0.75, 0.80, 1.16, 1, 1.64, 1.27, 2.81, 1.88, 3.67, 1.75, 3.94],
            8: [0.83, 0.87, 1.24, 1.10, 1.76, 1.40, 3.04, 2.04, 4.01, 1.93, 4.34],
            9: [0.90, 0.95, 1.31, 1.21, 1.88, 1.52, 3.26, 2.20, 4.34, 2.10, 4.75],
            10: [0.98, 1.02, 1.38, 1.32, 2, 1.65, 3.48, 2.36, 4.68, 2.28, 5.16],
            11: [1, 1.05, 1.45, 1.36, 2.10, 1.70, 3.65, 2.50, 4.92, 2.41, 5.36],
            12: [1.02, 1.08, 1.52, 1.40, 2.19, 1.76, 3.82, 2.63, 5.16, 2.54, 5.57],
            13: [1.04, 1.12, 1.58, 1.45, 2.29, 1.81, 3.98, 2.77, 5.40, 2.68, 5.77],
            14: [1.06, 1.15, 1.65, 1.49, 2.38, 1.87, 4.15, 2.90, 5.64, 2.81, 5.98],
            15: [1.08, 1.18, 1.72, 1.53, 2.48, 1.92, 4.32, 3.04, 5.88, 2.94, 6.18],
            16: [1.12, 1.22, 1.77, 1.60, 2.56, 2.02, 4.49, 3.10, 6.10, 3.04, 6.42],
            17: [1.15, 1.27, 1.82, 1.66, 2.64, 2.11, 4.66, 3.17, 6.31, 3.13, 6.66],
            18: [1.19, 1.31, 1.86, 1.73, 2.72, 2.21, 4.82, 3.23, 6.53, 3.23, 6.90],
            19: [1.22, 1.36, 1.91, 1.79, 2.80, 2.30, 4.99, 3.30, 6.74, 3.32, 7.14],
            20: [1.26, 1.40, 1.96, 1.86, 2.88, 2.40, 5.16, 3.36, 6.96, 3.42, 7.38]
        }
    };

    public formFields: ItemInterface<any>[] = [
        {label: 'Nombre, Marca y Modelo', field: 'nombre', inputType: {type: InputType.EDIT_TEXT}, required: true},
        {
            label: 'Tipo',
            field: 'tipo',
            inputType: {type: InputType.DROPDOWN},
            values: this.tipos
        },
        {label: 'Capacidad (Litros)', field: 'capacidad', inputType: {type: InputType.EDIT_NUMERIC}},
        {label: 'ROMA', field: 'roma', inputType: {type: InputType.EDIT_TEXT}},
        {
            field: 'id_cliente',
            label: this.appName === 'innovia' ? this.clienteName + ' asignado' : 'Propietario',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.clientes,
            valuePrimaryKey: 'id',
            visible: this.showMaquinariaClienteAsignado,
            required: true
        },
        {
            label: 'Fecha Adquisición',
            field: 'fecha_adquisicion',
            inputType: {type: InputType.CALENDAR}
        },        
        {
            label: 'Fecha Última Inspección ITEAF',
            field: 'fecha_ultima_inspeccion',
            inputType: {type: InputType.CALENDAR}
        },
        {
            label: 'Fecha Caducidad ITEAF',
            field: 'fecha_caducidad',
            inputType: {type: InputType.ONLY_TEXT},
            style: 'color: black'
        },
        {
            label: 'Coste/hora (€)',
            field: 'precio_hora',
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.precioHora
        },
        {
            label: 'Proveedor',
            field: 'id_comercio',
            inputType: {type: InputType.DROPDOWN},
            visible: this.appName === 'sirga',
            valuePrimaryKey: 'id',
            values: this.comercios,
            filter: (it: { value: { tipo: string; }; }) => {
                if (it.value.tipo === 'Proveedor') {
                    return true;
                }
                return false;
            }
        },
        {
            label: 'Identificador GPS',
            field: 'gps',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showMaquinariaGps
        },
        {
            label: 'IMEI',
            field: 'imei',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showMaquinariaGps
        },
        {label: this.titleActivo, field: 'activo', inputType: {type: InputType.SWITCH}, visible: this.showAplicadoresActivo},
        {
            label: 'Recibir mail tratamientos', 
            field: 'mail_trat', 
            inputType: {type: InputType.SWITCH}, 
            visible: this.appName === 'manezylozano' },
        {
            label: '',
            field: 'adjunto',
            inputType: {type: InputType.EMPTY}
        },
        {
            label: '',
            field: 'adjunto_pdf',
            inputType: {type: InputType.EMPTY},
            visible: this.showMaquinariaAdjuntoPdf
        }
    ];

    public boquillasCols = [
        {header: '#', field: 'linea', sort: false},
        {header: 'Nombre', field: 'nombre', sort: false},
        {header: 'HCI 60', field: 'hci', sort: false},
        {header: 'Disco cerámico', field: 'amt', sort: false},
    ];


    constructor(
        public override dashboard: DashboardService,
        public override router: Router,
        public override route: ActivatedRoute,
        public ventasApi: VentasApiService,
        public trabajadoresApi: TrabajadoresApiService,
        public clientesApi: ClientesApiService,
        public http: HttpClient) {
        super(
            route,
            router,
            dashboard,
            trabajadoresApi.maquinariaPUT,
            trabajadoresApi.maquinariaPOST,
            'maquinaria',
            'Actualizar Máquina',
            'Crear Máquina'
        );
    }

    ngOnInit() {
        this.initFormData();
        this.expandFormRequest();

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .isGeneric(false)
            .setGetRequest(this.trabajadoresApi.maquinariaGET)
            .setPostRequest(this.trabajadoresApi.maquinariaPOST)
            .setPutRequest(this.trabajadoresApi.maquinariaPUT)
            .setFormFields(this.formFields)
            .setFieldsToSend((['id', 'id_cliente'] as any)
                .concat(
                    this.formFields.map(it => it.field)
                )
            );

        this.formRequest.load();

        this.softInit(this.getType());
    }

    public submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }

        this.formRequest.send();
    }

    public formChanges(tag: string) {
        if (tag === 'activo' && this.titleActivo === 'Propio') {
            this.formFields.forEach( field => {
                if (field.field === 'activo') {
                    field.label = field.label === 'Propio' ? 'Externo' : 'Propio';
                }
            });
        }
        if (tag === 'fecha_adquisicion' || tag === 'fecha_ultima_inspeccion') {
            const edadMaquinaria = moment().year() - moment(this.model.fecha_adquisicion, 'DD/MM/YYYY').year();

            let periodoAnyos = 0;
                
            if (this.model.fecha_ultima_inspeccion || edadMaquinaria > 10 || edadMaquinaria.toString() === 'NaN') {
                periodoAnyos = (moment(this.model.fecha_ultima_inspeccion, 'DD/MM/YYYY').year() < 2020) ? 5 : 3;
            } else {
                periodoAnyos = 10;
            }

            this.model.fecha_caducidad = moment(this.model.fecha_ultima_inspeccion ? this.model.fecha_ultima_inspeccion : 
                this.model.fecha_adquisicion, 'DD/MM/YYYY').add(periodoAnyos, 'years').format('DD/MM/YYYY');
        }

        this.formRequest.update();
    }


    // Adjuntos
    public readUrl(input: HTMLInputElement) {
        input.click();
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.adjunto = e.target.result;
            };
            reader.readAsDataURL(input.files[0]);
            const r = this.http.post(environment.serverUrl + 'ws/trabajadores/upload_file.php', this.adjunto).subscribe(res => {
                const file: any = res;
                this.model.adjunto = file.fileName;
                r.unsubscribe();
            });
        }
    }

    public fileChangePdf(event: any) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0] = new File([], '');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');

            const name: string[] = file.name.split('.');
            const ext = name[name.length - 1];
            const accepted = ['docx', 'doc', 'pdf'];
            let cond = false;

            for (const e of accepted) {
                if (e === ext) {
                    cond = true;
                }
            }
            
            if (cond) {
                this.http.post(`${environment.serverUrl}ws/trabajadores/upload_file.php`, formData, {headers: headers}).subscribe(data => {
                    const res: any = data;
                    this.model.adjunto_pdf = res.fileName;
                }, error => {
                    console.log(error);
                }
                );
            } else {
                alert('Formato no válido.');
                this.model.adjunto_pdf = null;
            }
        }
    }
    public fileChange(event: any) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0] ?? new File([], '');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');

            const name: string[] = file.name.split('.');
            const ext = name[name.length - 1];
            const accepted = ['gif', 'jpeg', 'jpg', 'jiff', 'png', 'svg', 'tiff'];
            let cond = false;

            for (const e of accepted) {
                if (e === ext) {
                    cond = true;
                }
            }
            
            if (cond) {
                this.http.post(`${environment.serverUrl}ws/trabajadores/upload_file.php`, formData, {headers: headers}).subscribe(data => {
                    const res: any = data;
                    this.model.adjunto = res.fileName;
                }, error => {
                    console.log(error);
                }
                );
            } else {
                alert('Formato de imágen no válido.');
                this.model.adjunto = null;
            }
        }
    }

    public showAddBoquilla(data?: any) {
        if (data) {
            this.boquillaModel = data;
            this.boquillaModel.is_in_list = true;
            this.boquillaModelOld = Object.create(this.boquillaModel);
        } else {
            this.boquillaModel = {hci: {}, amt: {}} as BoquillaModel;
            this.boquillaModelOld = {hci: {}, amt: {}} as BoquillaModel;
        }
        this.isShowingBoquilla = !this.isShowingBoquilla;
        
        this.getTotales();
    }

    public addBoquilla() {
        if (!this.boquillaModel.nombre) {
            alert('Debe especificar un nombre o identificador de la boquilla.');
            return;
        }
        const toSend = Object.create(this.boquillaModel);
        
        Object.keys(this.boquillaModel.hci).forEach(it => {
            if (!this.boquillaModel.hci[it]) { delete this.boquillaModel.hci[it]; }
        });
        Object.keys(this.boquillaModel.amt).forEach(it => {
            if (!this.boquillaModel.amt[it]) { delete this.boquillaModel.amt[it]; }
        });

        toSend.hci = JSON.stringify(this.boquillaModel.hci);
        toSend.amt = JSON.stringify(this.boquillaModel.amt);
        toSend.id_maquinaria = this.model.id;
        toSend.nombre = this.boquillaModel.nombre;
        toSend.linea = this.boquillaModel.linea;

        if (this.boquillaModel.id || this.boquillaModel.is_in_list) {
            if (this.formRequest.type === FormRequestTypes.EDIT) {
                this.isPerformingBoquilla = true;
                this.trabajadoresApi.boquillas.PUT.safePerform(toSend);
                this.trabajadoresApi.boquillas.PUT.response(() => {
                    this.boquillasList[this.boquillasList.indexOf(this.boquillaModelOld)] = this.boquillaModel;
                    this.formatBoquillas();
                    this.boquillaModelOld = {hci: {}, amt: {}} as BoquillaModel;
                    this.isPerformingBoquilla = false;
                    this.isShowingBoquilla = false;
                    if (!this.trabajadoresApi.boquillas.PUT.hasError) {
                        this.trabajadoresApi.maquinariaGET.safePerform({id: this.getRegisterId()});
                    }
                });
            } else {
                this.boquillasList[this.boquillasList.indexOf(this.boquillaModelOld)] = this.boquillaModel;
                this.formatBoquillas();
                this.boquillaModelOld = {hci: {}, amt: {}} as BoquillaModel;
                this.isShowingBoquilla = false;
            }
        } else {
            if (this.formRequest.type === FormRequestTypes.EDIT) {
                this.isPerformingBoquilla = true;
                this.trabajadoresApi.boquillas.POST.safePerform(toSend);
                this.trabajadoresApi.boquillas.POST.response(() => {
                    this.boquillasList.push(this.boquillaModel);
                    this.formatBoquillas();
                    this.boquillaModel = {hci: {}, amt: {}} as BoquillaModel;
                    this.isPerformingBoquilla = false;
                    this.isShowingBoquilla = false;

                });
            } else {
                this.boquillasList.push(this.boquillaModel);
                this.formatBoquillas();
                this.boquillaModel = {hci: {}, amt: {}} as BoquillaModel;
                this.isShowingBoquilla = false;
            }
        }
    }

    public deleteBoquilla(boquilla: BoquillaModel) {
        if (this.formRequest.type === FormRequestTypes.EDIT) {
            if (confirm('¿Borrar esta boquilla?\n\n'
                + boquilla.hci_format + ' '
                + boquilla.amt_format + ' '
                + (boquilla.nombre || 'Boquilla sin nombre'))
            ) {
                this.trabajadoresApi.boquillas.DELETE.toPromise({id: boquilla.id}).then(() => {
                    this.boquillasList.splice(this.boquillasList.indexOf(boquilla), 1);
                    this.boquillaModel = {hci: {}, amt: {}} as BoquillaModel;
                    this.isShowingBoquilla = false;
                    if (this.getRegisterId()) {
                        this.trabajadoresApi.maquinariaGET.safePerform({id: this.getRegisterId()});
                    }
                });
            }
        } else {
            this.boquillasList.splice(this.boquillasList.indexOf(boquilla), 1);
            this.boquillaModel = {hci: {}, amt: {}} as BoquillaModel;
        }
    }

    public cancelar() {
        if (this.boquillaModelOld.amt && this.boquillaModelOld.hci) {
            this.boquillaModel = this.boquillaModelOld;
            this.boquillaModelOld = {hci: {}, amt: {}} as BoquillaModel;
        }
        this.isShowingBoquilla = false;
    }

    public getTotales() {
        this.totalHCI = Object.values(this.boquillaModel.hci).reduce((a, b) => (+a) + (+b), 0);
        this.totalAMT = Object.values(this.boquillaModel.amt).reduce((a, b) => (+a) + (+b), 0);
        for (const i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]) {
            this.getCaudal(i);
        }
    }

    public getCaudal(tableIndex: number) {
        let total = 0;
    
        for (const prop in this.boquillaModel.hci) {
            if (this.boquillaModel.hci[prop]) {
                const idx = this.tablaCaudales.hci.index.indexOf(prop);
                total += (this.tablaCaudales.hci[tableIndex]?.[idx] as number) * (this.boquillaModel.hci[prop]?.[idx] as number);
            }
        }

        for (const prop in this.boquillaModel.amt) {
            if (this.boquillaModel.amt[prop]) {
                const idx = this.tablaCaudales.amt.index.indexOf(prop);
                total += (this.tablaCaudales.amt[tableIndex]?.[idx] as number) * (this.boquillaModel.amt[prop]?.[idx] as number);
            }
        }

        this.totalCaudales[tableIndex] = total.toFixed(2);
    }

    public formatBoquillas() {
        this.boquillasList.map(boquilla => {
            boquilla.hci_format = '';
            boquilla.amt_format = '';
            if (typeof boquilla.hci === 'string') {
                boquilla.hci = JSON.parse(boquilla.hci || '{}');
            }
            if (typeof boquilla.amt === 'string') {
                boquilla.amt = JSON.parse(boquilla.amt || '{}');
            }
    
            Object.keys(boquilla.hci).forEach(key => {
                boquilla.hci_format += key + ' (x' + boquilla.hci[key] + ') - ';
            });
            Object.keys(boquilla.amt).forEach(key => {
                boquilla.amt_format += key + ' (x' + boquilla.amt[key] + ') - ';
            });
    
            boquilla.hci_format = (boquilla.hci_format as string).substring(0, boquilla.hci_format.length - 2);
            boquilla.amt_format = (boquilla.amt_format as string).substring(0, boquilla.amt_format.length - 2);

            return boquilla;
        });
    }

    public verAdjunto() {
        const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
        myWindow?.location.assign(environment.serverUrl + 'fotos_maquinaria/' + this.model.adjunto);
        myWindow?.focus();
    }
    public verAdjuntoPdf() {
        const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
        myWindow?.location.assign(environment.serverUrl + 'fotos_maquinaria/' + this.model.adjunto_pdf);
        myWindow?.focus();
    }

    private initFormData(): Promise<any> {
        const builds = [];
        if (this.showMaquinariaClienteAsignado) {
            builds.push(build(this.clientesApi.clientesGET, this.clientes, (it: { nombre: string; apellidos: any; }) => it.nombre + ' ' + (it.apellidos || '')));
        }
        if (this.appName === 'sirga') {
            builds.push(build(this.ventasApi.compradoresGET, this.comercios, 'razon_social', true));
        }
        return stack(builds, () => {
            this.formRequest.update();
        });
    }

    private expandFormRequest(){
        if (StorageManager.getUser().tipo === 'comunero') {
            this.model.id_cliente = StorageManager.getUser().id_cliente;
        }
        if (this.appName === 'manezylozano') {
            this.calcularTablaCaudal();
            this.trabajadoresApi.maquinariaPOST.response(res => {
                const maquina = res;
                console.log(maquina.id);
                const toSendList = this.boquillasList.map(it => {
                    console.log(maquina.id);
                    const o = {
                        nombre: it.nombre,
                        hci: it.hci,
                        amt: it.amt,
                        id_maquinaria: maquina.id
                    };

                    return o;
                });

                toSendList.forEach(toSend => {
                    this.trabajadoresApi.boquillas.POST.perform(toSend);
                });
            });
        }

        this.formRequest.afterLoad(resolve => {
            this.model.activo = [1, '1', true, 'true'].includes(this.model.activo);
            if ( this.appName === 'manezylozano' ) {
                this.model.mail_trat = [1, '1', true, 'true'].includes(this.model.mail_trat);
            }

            if (this.model.boquillas) {
                this.boquillasList = Object.create(this.model.boquillas);
            }
            this.formatBoquillas();
            resolve(true);
        });

        this.formRequest.beforeSend(resolve => {
            if (this.model['fecha_adquisicion']) {
                this.model['fecha_adquisicion'] = Utils.formatDate(this.model['fecha_adquisicion']);
            }

            if (this.model['fecha_ultima_inspeccion']) {
                this.model['fecha_ultima_inspeccion'] = Utils.formatDate(this.model['fecha_ultima_inspeccion']);
            }
            resolve(true);
        });
    }

    private calcularTablaCaudal() {
        let D10: number, D15: number, D20: number, D25: number;

        for (const i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]) {
            for (const j of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
                D10 = this.tablaCaudales.amt[5][j];
                D15 = this.tablaCaudales.amt[10][j];
                D20 = this.tablaCaudales.amt[15][j];
                D25 = this.tablaCaudales.amt[20][j];
                if (i < 5) {
                    this.tablaCaudales.amt[i][j] = D10 - (((D15 - D10) / 5) * (5 - i));
                } else if (i < 10) {
                    this.tablaCaudales.amt[i][j] = D10 + (((D15 - D10) / 5) * (i - 5));
                } else if (i < 15) {
                    this.tablaCaudales.amt[i][j] = D15 + (((D20 - D15) / 5) * (i - 10));
                } else if (i < 20) {
                    this.tablaCaudales.amt[i][j] = D20 + (((D25 - D20) / 5) * (i - 15));
                }
            }

        }
    }
}

interface TypeAheadInterface {
    selected: any;
    values: any[];
    filtered: any[];
}