<br>
<p-panel>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
      <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
    </div>
  </div>
  <app-form-builder
    [model]="model"
    [undoEnabled]="undoEnabled"
    [distributionColumns]="3"
    [fields]="formFields"
    (formChanges)="formChanges($event)">
  </app-form-builder>

  <div *ngIf="showAlmacen">
    <app-form-builder
      [model]="model"
      [undoEnabled]="undoEnabled"
      [distributionColumns]="3"
      [fields]="formAlmacenFields"
      (formChanges)="formChanges($event)">
    </app-form-builder>
    
  </div>


  <div style="margin-top: 0px; position: absolute;" >
    <div style="position: relative;top: 20px; left: 80px;">
      {{ this.model.unidades? '('+ this.model.unidades +')': '' }}
    </div>
  </div>
    <app-form-builder
        [model]="model"
        [undoEnabled]="undoEnabled"
        [distributionColumns]="3"
        [fields]="formProductoFields"
        (formChanges)="formChanges($event)">
        
      </app-form-builder>

 
  <div *ngIf="model.tipo_transaccion === '8' ">
    <app-form-builder
      [model]="model"
      [undoEnabled]="undoEnabled"
      [distributionColumns]="3"
      [fields]="formCultivoFields"
      (formChanges)="formChanges($event)">
    </app-form-builder>

  </div>

  <div *ngIf="showNIMA">
    <app-form-builder
      [model]="model"
      [undoEnabled]="undoEnabled"
      [distributionColumns]="3"
      [fields]="formNIMAFields"
      (formChanges)="formChanges($event)">
    </app-form-builder>
    
  </div>
  <br>

 
  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            [lockOnSuccess]="true"
            (click)="submit()"
            [error]="error"
            [mode]="requestButtonType"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>
<br>
