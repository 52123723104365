<br>

<div style="width: auto; overflow: auto">
  <app-common-list-table
    [request]="getRequest"
    [cols]="cols"
    actionButtonText="Añadir Salida"
    (actionButtonClick)="add()">

    <ng-template #thEnd>
      <th>
        <p class="d-flex justify-content-center"
           style="margin-bottom: 0">Acciones</p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td>
        <app-action-buttons
          (editRowButtonEvent)="edit(data)"
          (deleteRowButtonEvent)="show('delete', data)"
          (showRowButtonEvent)="show('info', data)">
        </app-action-buttons>
      </td>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog header="Detalle del registro" [(visible)]="canShow['info']" (onShow)="scrollToTop()">
  <div *ngIf="canShow['info']" style="width: 80vw !important">
      <app-form-builder
        [fields]="formFields"
        [distributionColumns]="3"
        [distributionOrder]="[3,3,3,3,3,3,3,1]"
        [model]="model">
      </app-form-builder>
      <br>
      <div class="d-flex justify-content-center">
          <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
      </div>
  </div>
  <br>
</p-dialog>

<p-dialog
        header="Eliminar registro"
        [(visible)]="canShow['delete']"
        (onShow)="scrollToTop()">

  <div *ngIf="canShow['delete']" style="width: 80vw !important">
    <app-form-builder
      [fields]="formFields"
      [distributionColumns]="3"
      [distributionOrder]="[3,3,3,3,3,3,3,1]"
      [model]="model">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
              (click)="deleteRegister()"
              [request]="deleteRequest"
              [mode]="deleteRequestButtonType">
      </app-request-button>
    </div>
    <br>
    <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
       style="color: red; text-align: center">
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
</p-dialog>
