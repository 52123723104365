import {Injectable} from '@angular/core';
import {RequestHandler} from 'src/app/service/OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';

@Injectable({
    providedIn: 'root'
})
export class PedidosApiService {

    public pedidosGET: RequestHandler = new RequestHandler();
    public pedidosPUT: RequestHandler = new RequestHandler();
    public pedidosPOST: RequestHandler = new RequestHandler();
    public pedidosDELETE: RequestHandler = new RequestHandler();

    public enviarEmail: RequestHandler = new RequestHandler();

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.definePedidosGET();
        this.definePedidosPUT();
        this.definePedidosPOST();
        this.definePedidosDELETE();
        this.defineEnviarEmail();
    }

    private definePedidosGET() {
        this.pedidosGET
            .fetch(HttpRequestType.GET, 'pedidos/get');

        this.api
            .registerRequest(this.pedidosGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.pedidosGET));
    }

    private definePedidosPUT() {
        this.pedidosPUT
            .fetch(HttpRequestType.PUT, 'pedidos/put');

        this.api
            .registerRequest(this.pedidosPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.pedidosPUT));
    }

    private definePedidosPOST() {
        this.pedidosPOST
            .fetch(HttpRequestType.POST, 'pedidos/duplicate');

        this.api
            .registerRequest(this.pedidosPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.pedidosPOST));
    }

    private definePedidosDELETE() {
        this.pedidosDELETE
            .fetch(HttpRequestType.DELETE, 'pedidos/delete');

        this.api
            .registerRequest(this.pedidosDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.pedidosDELETE));
    }

    private defineEnviarEmail() {
        this.enviarEmail
            .fetch(HttpRequestType.POST, 'pedidos/enviar_email');

        this.api
            .registerRequest(this.enviarEmail);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.enviarEmail));
    }    
}


