<br>

<div style="width: auto; overflow: auto">
  <app-common-list-table
          [request]="getRequest"
          [cols]="cols"
          [globalConditionalStyle]="globalConditionalStyle"
          [globalVisualTransform]="globalVisualTransform"
          [filtering]="filter"

          [actionButtonText]="canAddSectores ? btnAnadir : ''"
          (actionButtonClick)="add()"
          (emitSearchText)="updateFooter()">

    <ng-template #thEnd>
      <th>
        <p class="d-flex justify-content-center"
           style="margin-bottom: 0">Acciones</p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td>
        <app-action-buttons
          [hideEdit]="!canEditSectores"
          [hideDelete]="!canDeleteSectores"   
          (editRowButtonEvent)="edit(data)"
          (deleteRowButtonEvent)="show('delete', data)"
          (showRowButtonEvent)="show('info', data)">
        </app-action-buttons>
      </td>
    </ng-template>

    <ng-template #footer let-col>
      <div style="font-size: 0.75em; text-align: left" *ngIf="(['nombre'].includes(col.field))">
        TOTAL:
      </div>
      <div *ngIf="(['sup_cultivada', 'total_plantas'].includes(col.field))"
        style="font-size: 0.75em; text-align: right; font-weight: bold; padding-right: 34px">
        {{col?.footer || 0}}
      </div>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog
        header="Detalle del registro"
        [(visible)]="canShow['info']"
        (onShow)="scrollToTop()">

  <div *ngIf="canShow['info']" style="width: 80vw !important">
    <app-form-builder
            [fields]="formFields"
            [distributionColumns]="this.appName === 'gardengest' ? 2 : 4"
            [model]="model">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
  <br>

</p-dialog>

<p-dialog
        header="Eliminar registro"
        [(visible)]="canShow['delete']"
        (onShow)="scrollToTop()">

  <div *ngIf="canShow['delete']" style="width: 80vw !important">
    <app-form-builder
            [fields]="formFields"
            [distributionColumns]="4"
            [model]="model">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
              (click)="deleteRegister()"
              [request]="deleteRequest"
              [mode]="deleteRequestButtonType">
      </app-request-button>
    </div>
    <br>
    <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
       style="color: red; text-align: center">
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br>

</p-dialog>