import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

import type { OnInit } from '@angular/core';

@Component({
    selector: 'app-upload-images',
    templateUrl: './upload-images.component.html',
    styleUrls: ['./upload-images.component.scss']
})
export class UploadImagesComponent implements OnInit {

    @Input() model: any = {
        impagen: ''
    };

    @Input() folder = '';
    @Input() nombre_imagen: string = '';
    @Input() tipo = '';

    @Output() sendImageName: EventEmitter<string> = new EventEmitter<string>();

    public adjunto: any = [];
    public imagen = '';

    constructor(
        private http: HttpClient
    ) { }

    ngOnInit() {
    
    }

    // Adjuntos
    public readUrl(input: { click: () => void; files: Blob[]; }) {
        input.click();
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.adjunto = e.target.result;
            };
            reader.readAsDataURL(input.files[0]);
            const r = this.http.post(environment.serverUrl + 'ws/upload/upload_file_generic.php', this.adjunto).subscribe(res => {
                const file: any = res;
                if ( this.nombre_imagen == null ) {
                    this.model.imagen = file.fileName;
                } else {
                    this.model[this.nombre_imagen] = file.fileName;
                }
        
                this.imagen = file.fileName;
                r.unsubscribe();
            });
        }
    }

    public fileChange(event: File) {
    //console.log('event',event);
        const fileList: File = event;
        if (fileList) {
            const file: File = fileList;
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            formData.append('folder', this.folder );
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');

            const name: string[] = file.name.split('.');
            const ext = name[name.length - 1];
            const accepted = ['gif', 'jpeg', 'jpg', 'jiff', 'png', 'svg', 'tiff'];
            let cond = false;

            for (const e of accepted) {
                if (e === ext) {
                    cond = true;
                }
            }

            if (cond) {
                this.http.post(
                    `${environment.serverUrl}ws/upload/upload_file_generic.php`, 
                    formData, 
                    { headers: headers }
                ).subscribe(data => {
                    const res: any = data;
   
                    if ( this.nombre_imagen == null ) {
                        this.model.imagen = res.fileName;
                    } else {
                        this.model[this.nombre_imagen] = res.fileName;
                    }
          
                    this.imagen = res.fileName;
                    this.sendImageName.emit(res.fileName);
         
          
                }, error => {
                    console.log(error);
                }
                );
            } else {
                alert('Formato de imágen no válido.');
                this.model.adjunto = null;
            }
        }
    }

    public verImagen() {
        const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
        if ( this.nombre_imagen == null ) {
            myWindow?.location.assign(environment.serverUrl + 'mto/'  + this.folder + '/' + this.model.imagen);
        } else {
            myWindow?.location.assign(environment.serverUrl + 'mto/'  + this.folder + '/' + this.model[this.nombre_imagen]);
        }
    
        myWindow?.focus();
    }
}
