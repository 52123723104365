import {TareasApiService} from '../../../../../service/api/tareas-api.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { Utils } from 'src/app/common/utils';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { ControlSanitarioFormDefinition } from './form-control/form-definition';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { FincasApiService } from '../../../../../service/api/fincas-api.service';
import { build, stack } from 'src/app/common/classes/request-builder';

import type { OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-control-sanitario-form',
    templateUrl: './control-sanitario-form.component.html',
    styleUrls: ['./control-sanitario-form.component.scss']
})
export class ControlSanitarioFormComponent extends BaseForm implements OnInit, OnDestroy {

    public form: ControlSanitarioFormDefinition = new ControlSanitarioFormDefinition();
    public formRequest: AppFormRequest = new AppFormRequest();
    public override model: any = {};

    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public tareasApi: TareasApiService,
        public fincasApi: FincasApiService,              
        public override router: Router) {
        super(
            route,
            router,
            dashboard,
            tareasApi.controlSanitario.PUT,
            tareasApi.controlSanitario.POST,
            'control-sanitario',
            'Actualizar Control Sanitario',
            'Realizar Control Sanitario',
            'Duplicar Control Sanitario'
        );
    }



    ngOnInit() {
  
        this.formRequest.update();
        this.expandFormRequest();
        const fieldsToSend = (['id', 'id_cliente', 'id_usuario'] as any).concat( this.form.formFields.map(it => it.field));    

        this.formRequest
            .setType(this.getType())
            .isGeneric(true)
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setPostRequest(this.tareasApi.controlSanitario.POST)
            .setPutRequest(this.tareasApi.controlSanitario.PUT)
            .setGetRequest(this.tareasApi.controlSanitario.GET)
            .setFormFields(this.form.formFields)
            .setFieldsToSend(fieldsToSend);

        this.formRequest.load();

        this.softInit(this.getType());
        this.form.requestType = this.getType();
        this.form.model = this.model;
    }  

    public formChanges() {
        this.formRequest.update();
    }

    public submit() {
        this.formRequest.send();
        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    public override goBack() {
        this.router.navigate(['dashboard', this.pathToGoBack]);
    }

    private initFormData(): Promise<any> {
        if (  this.getType() === 0 ) {
            this.fincasApi.fincasGET.toPromise().then( (resp_fincas: any) => {
     
                if (resp_fincas.length === 1 ) {
                    this.model['id_finca'] = resp_fincas[0].id;
                }
            });
        }
        return stack([
            build(this.fincasApi.fincasGET, this.form.fincas, 'nombre', true)
        ]).then(() => {
            setTimeout(() => {

                this.formRequest.update();
            }, 100);
            this.formRequest.update();
        }); 
    }


    private expandFormRequest() {

        this.formRequest.beforeLoad(resolve => {
            this.initFormData().then(() => {
                resolve(true);
            });
        });

        this.formRequest.beforeSend(resolve => {
            this.model['fecha_inicio'] = this.model['fecha_inicio'] instanceof Date
                ? Utils.formatDate(this.model['fecha_inicio'])
                : this.model['fecha_inicio'];

            this.model['fecha_fin'] = this.model['fecha_fin'] instanceof Date
                ? Utils.formatDate(this.model['fecha_fin'])
                : this.model['fecha_fin'];

            this.model['fecha_plazo'] = this.model['fecha_plazo'] instanceof Date
                ? Utils.formatDate(this.model['fecha_plazo'])
                : this.model['fecha_plazo'];

            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() || {} as any).id;
            const userIdFromForm = this.model['id_usuario'];

            this.model.id_usuario =
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';            

            resolve(true);
        });

        this.formRequest.afterFinish(resolve => {
            if (this.getType() === FormRequestTypes.DUPLICATE) {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
            resolve(true);
        });

    }  
}
