import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import type { CanActivate } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ShowVademecumGuardService implements CanActivate {

    constructor(public router: Router) {}

    canActivate(): boolean {
        if (environment.features.showVademecumMenu || environment.features.hasProductos) {
            return true;
        }

        this.router.navigate(['login']);
        return false;
    }
}
