<br>

<div style="width: auto; overflow: auto">
  <app-common-list-table
          [request]="getRequest"
          [filtering]="filter"
          [cols]="cols"

          [globalConditionalStyle]="globalConditionalStyle"
          [globalVisualTransform]="globalVisualTransform"

          actionButtonText="Añadir Maquinaria"
          (actionButtonClick)="add()">
    <ng-template #header>
      <div *ngIf="showMaquinariaMostrarTodos" class="d-flex justify-content-start align-content-center">
          <p-inputSwitch
            [style]="{marginLeft: '15px', marginRight: '10px'}"
            [(ngModel)]="showAll"
            (onChange)="changeShowAll();filter ? filter.filter() : null">
          </p-inputSwitch>
          <p style="color: white; font-size: 1.2em; margin-bottom: 0">Mostrar todos</p>
      </div>
    </ng-template>
    <ng-template #thEnd>
      <th>
        <p class="d-flex justify-content-center"
           style="margin-bottom: 0">Acciones</p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td>
        <app-action-buttons
                (editRowButtonEvent)="edit(data)"
                (deleteRowButtonEvent)="show('delete', data)"
                (showRowButtonEvent)="show('info', data)"
                [hideEdit]="applicationType === 'cropgest' && this.currentUser.tipo === 'comunero' && data.tipo_usuario === 'aplicador'"
                [hideDelete]="applicationType === 'cropgest' && this.currentUser.tipo === 'comunero' && data.tipo_usuario === 'aplicador'">
        </app-action-buttons>
      </td>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog
        header="Detalle del registro"
        [(visible)]="canShow['info']"
        (onShow)="scrollToTop()">

  <div *ngIf="canShow['info']" style="width: 80vw !important; max-height: 400px;">
    <app-form-builder
            [fields]="formFields"
            [distributionColumns]="3"
            [model]="model">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
    <div *ngIf="imagenAdjunto" class="adjunto-container">
      <p>Foto</p>
      <div class="img-container">
          <img [src]="imagenAdjunto" alt="" (click)="verAdjunto()">
      </div>
    </div>
  </div>
<br>

</p-dialog>

<p-dialog
        header="Eliminar registro"
        [(visible)]="canShow['delete']"
        (onShow)="scrollToTop()">

  <div *ngIf="canShow['delete']" style="width: 80vw !important">
    <app-form-builder
            [fields]="formFields"
            [distributionColumns]="3"
            [model]="model">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
              (click)="deleteRegister()"
              [request]="deleteRequest"
              [mode]="deleteRequestButtonType">
      </app-request-button>
    </div>
    <br>
    <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
       style="color: red; text-align: center">
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br>

</p-dialog>