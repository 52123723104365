import { Component } from '@angular/core';
import {DashboardService} from '../../../service/events/dashboard.service';
import {StockApiService} from '../../../service/api/stock-api.service';
import {Router} from '@angular/router';
import {RequestHandler} from '../../../service/OffService/request-handler';
import { RequestButtonTypes } from '../../../common/components/request-button/request-button.component';
import {BaseView} from '../../base-view';
import { environment } from 'src/environments/environment';

import type { OnInit } from '@angular/core';
import { InputType, type ItemInterface } from '../../../common/components/form-builder/form-builder.component';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { StockageModel } from 'src/app/models/stock/stokage.model';

@Component({
    selector: 'app-salidas',
    templateUrl: './salidas.component.html',
    styleUrls: ['./salidas.component.scss']
})
export class SalidasComponent extends BaseView implements OnInit {

    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;

    public getRequest: RequestHandler = this.stockApi.salidas.GET;
    public deleteRequest: RequestHandler = this.stockApi.salidas.DELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public formFields: ItemInterface<object>[] = [
        {
            field: 'finca_nombre',
            label: this.fincaName, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'unidades', 
            label: 'Total de unidades', 
            inputType: {type: InputType.TEXT}
        },
        {
            inputType: {type: InputType.EMPTY}
        },

        {
            field: 'producto1_nombre', 
            label: 'Producto', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'unidades1', 
            label: 'Unidades', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'lote1', 
            label: 'Lote', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'producto2_nombre', 
            hasLabel: false, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'unidades2', 
            hasLabel: false, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'lote2', 
            hasLabel: false, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'producto3_nombre', 
            hasLabel: false, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'unidades3', 
            hasLabel: false, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'lote3', 
            hasLabel: false, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'albaran', 
            label: 'Albarán', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha', 
            label: 'Fecha', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'tiposalida', 
            label: 'Tipo', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'observaciones', 
            label: 'Observaciones', 
            inputType: {type: InputType.TEXT}
        }
    ];

    public cols: ColInterface[] = [
        {field: 'finca_nombre', header: this.fincaName, filter: 'dropdown'},
        {field: 'producto1_nombre', header: 'Producto', filter: 'dropdown'},
        {field: 'fecha', header: 'Fecha', filter: 'calendar'},
        {field: 'unidades', header: 'Unidades', filter: ''},
        {field: 'albaran', header: 'Albarán', filter: ''}
    ];

    constructor(public stockApi: StockApiService, private dashboardEvents: DashboardService, private router: Router) {
        super(dashboardEvents, stockApi.salidas.GET, stockApi.salidas.DELETE);
    }

    ngOnInit() {
        this.dashboardEvents.setSubmenuItem('stock-salidas');
    }

    public add() {
        this.router.navigate(['dashboard', 'stock-crear-salida']);
    }

    public edit(data: StockageModel) {
        this.router.navigate(['dashboard', 'stock-editar-salida', data.id]);
    }

}
