<br>

<div style="width: auto; overflow: auto">
    <app-common-list-table
            [request]="getRequest"
            [cols]="cols"
            [filtering]="filtering"
            [globalConditionalStyle]="globalConditionalStyle"
            [globalVisualTransform]="globalVisualTransform"
            [showExportCsv]="showTareasFitoCsv"
            (emitSearchText)="updateFooter()">

        <ng-template #header>
            <div style="width: 100%; margin-right: 15px"
                 class="d-flex justify-content-end align-items-center">

                <span *ngIf="mostrarTratamientosAplicados"
                      style="color: white; margin-top: -5px;">
                  Aplicados
                </span>

                <span *ngIf="!mostrarTratamientosAplicados"
                      style="color: white; margin-top: -5px;">
                  No Aplicados
                </span>

                <div style="margin-left: 10px">
                    <p-inputSwitch
                            [(ngModel)]="mostrarTratamientosAplicados"
                            (onChange)="filtrarTratamientos()">
                    </p-inputSwitch>
                </div>
            </div>
        </ng-template>

        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center"
                   style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons
                        [hideEdit]="true"
                        [hideDelete]="true"
                        (deleteRowButtonEvent)="show('delete', data)"
                        (showRowButtonEvent)="show('info', data)">
                </app-action-buttons>
            </td>
        </ng-template>

        <ng-template #footer let-col>
            <div style="font-size: 0.75em; text-align: left" *ngIf="(['apellidos'].includes(col.field))">
                TOTAL:
            </div>
            <div style="font-size: 0.75em; text-align: right; font-weight: bold"
                 *ngIf="(['coste'].includes(col.field))">
                {{moneyFormat(col.footer)}} €
            </div>
        </ng-template>

    </app-common-list-table>
</div>

<p-dialog
        *ngIf="canShow['info']"
        header="Detalle del registro"
        [(visible)]="canShow['info']"
        (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <app-form-builder
                [fields]="formFields"
                [distributionColumns]="4"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>

        <div class="d-flex justify-content-center">
            <h4>Composición del Tratamiento</h4>
        </div>

        <div class="d-flex justify-content-center">
            <table class="table-style">
                <tr>
                    <th style="width: 15px; border: none"></th>
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th>Precio</th>
                </tr>
                <tr *ngFor="let item of model?.tratamiento?.linea; let i = index">
                    <td style="border: none">{{i + 1}}</td>
                    <td>{{item?.producto?.nombre}}</td>
                    <td>{{cuantityFormat(item?.cantidad_real ?? 0)}}</td>
                    <td>{{moneyFormat(item?.producto?.precio ?? 0)}}</td>
                </tr>
            </table>
        </div>

        <div class="d-flex justify-content-center" style="margin-top: 40px">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog>

<p-dialog
        *ngIf="canShow['delete']"
        header="Eliminar registro"
        [(visible)]="canShow['delete']"
        (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <app-form-builder
                [fields]="formFields"
                [distributionColumns]="4"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button
                    (click)="deleteRegister()"
                    [request]="deleteRequest"
                    [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
           style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>

</p-dialog>
