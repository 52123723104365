import { Injectable } from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {IdbServiceService} from '../OffService/idb-service.service';
import {RequestHandler} from '../OffService/request-handler';

@Injectable({
    providedIn: 'root'
})
export class StockApiService {

    public productos: RequestHandler = new RequestHandler();
    public productosPost: RequestHandler = new RequestHandler();
    public balances: RequestHandler = new RequestHandler();

    public entradas = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public salidas = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public stockage = {
        GET: new RequestHandler()
    };

    constructor(private syncService: SyncService,
        private api: ApiRequestService,
        private idb: IdbServiceService) {

        this.defineProductos();
        this.definePostProducto();
        this.defineEntradas();
        this.defineSalidas();
        this.defineBalances();
        this.defineStockage();
    }


    private defineProductos() {
        this.productos
            .fetch(HttpRequestType.GET, 'stock/productos');

        this.api
            .registerRequest(this.productos, this.idb.stock_productos);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.productos));
    }

    private definePostProducto() {
        this.productosPost
            .fetch(HttpRequestType.POST, 'generic/productos');

        this.api
            .registerRequest(this.productosPost);
    }


    private defineEntradas(){
        this.entradas.GET.fetch(HttpRequestType.GET, 'stock/get');
        this.entradas.POST.fetch(HttpRequestType.POST, 'generic/stock_new');
        this.entradas.PUT.fetch(HttpRequestType.PUT, 'generic/stock_new');
        this.entradas.DELETE.fetch(HttpRequestType.DELETE, 'generic/stock_new');

        this.api.registerRequest(this.entradas.GET);
        this.api.registerRequest(this.entradas.POST);
        this.api.registerRequest(this.entradas.PUT);
        this.api.registerRequest(this.entradas.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.entradas.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.entradas.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.entradas.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.entradas.DELETE));
    }

    private defineSalidas(){
        this.salidas.GET.fetch(HttpRequestType.GET, 'stock/salidas');
        this.salidas.POST.fetch(HttpRequestType.POST, 'generic/stock');
        this.salidas.PUT.fetch(HttpRequestType.PUT, 'generic/stock');
        this.salidas.DELETE.fetch(HttpRequestType.DELETE, 'generic/stock');

        this.api.registerRequest(this.salidas.GET);
        this.api.registerRequest(this.salidas.POST);
        this.api.registerRequest(this.salidas.PUT);
        this.api.registerRequest(this.salidas.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.salidas.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.salidas.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.salidas.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.salidas.DELETE));
    }

    private defineBalances(){
        this.balances
            .fetch(HttpRequestType.GET, 'stock/balances');

        this.api
            .registerRequest(this.balances);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.balances));
    }

    private defineStockage(){
        this.stockage.GET.fetch(HttpRequestType.GET, 'stock/stockage');

        this.api.registerRequest(this.stockage.GET);

        this.syncService.register(this.syncService.baseSyncPolicy(this.stockage.GET));
    }
}
