<br>

<div style="width: auto; overflow: auto">
    <app-common-list-table
        [request]="getRequest"
        [globalConditionalStyle]="globalConditionalStyle"
        [globalVisualTransform]="globalVisualTransform"
        [cols]="cols"
        
        [showExportCsv]="true">

        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center"
                    style="margin-bottom: 0">Info</p>
            </th>
            </ng-template>
        
            <ng-template #tdEnd let-data>
                <td>
                    <div class="d-flex justify-content-center align-items-center">
                        <button class="d-flex justify-content-center align-items-center"
                                (click)="show('info', data)"
                                style="
                                height: 20px;
                                width: 20px;
                                border-radius: 3px;
                                background-color: #007bff;
                                margin-right: 10px;
                                color: white;
                                cursor: pointer;">
                            <i class="fa fa-info"></i>
                        </button>
                    </div>
                </td>
        </ng-template>

    </app-common-list-table>
</div>

<p-dialog
        header="Detalle del registro"
        [(visible)]="canShow['info']"
        (onShow)="scrollToTop()">

  <div *ngIf="canShow['info']" style="width: 80vw !important">
    <app-form-builder
            [fields]="formFields"
            [distributionOrder]="[4,4,4,4,2,2,2,2]"
            [distributionColumns]="4"
            [model]="model">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
  <br>

</p-dialog>