import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { list } from '../../../../common/classes/request-builder';
import { InputType } from '../../../../common/components/form-builder/form-builder.component';
import { createCsv } from '../../../../common/createCsv';
import { RequestHandler } from '../../../../service/OffService/request-handler';
import { VisitasApiService } from '../../../../service/api/visitas-api.service';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { Filtering } from '../../../../service/filtering/filtering';
import { BaseView } from '../../../../view/base-view';
import { environment } from '../../../../../environments/environment';

import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { RequestButtonTypes } from 'src/app/common/components/request-button/request-button.component';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { SeguimientoModel } from 'src/app/models/visitas/seguimiento.model';

@Component({
    selector: 'app-visitas-viverospereira',
    templateUrl: './visitas-viverospereira.component.html',
    styleUrls: ['./visitas-viverospereira.component.scss']
})
export class VisitasViverospereiraComponent extends BaseView {

    public override filtering: Filtering<SeguimientoModel> = new Filtering<SeguimientoModel>();
    
    public etiquetas = list(['Estudio', '1'], ['Germinacíon', '2'], ['Plaga', '3'], ['Deficiencia', '4'],
        ['Bloqueo', '5'], ['Deformaciones', '6'], ['Marcas', '7'], ['Alteración color', '8'], ['Marchitamiento', '9'], ['Pequeña', '10'], 
        ['Grande', '11'], ['Espigada', '12'], ['Estrechamiento cuella', '13'], ['Problema raiz', '14'], ['Mejora', '15'], ['Novedad', '16'],
        ['Error', '17'], ['Lucha biologica', '18']);

    public showVisitasConsultasObservaciones = environment.features.showVisitasConsultasObservaciones;
    public hasClients = environment.features.hasClients;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public showVisitasHora = environment.features.showVisitasHora;
    public hasVisitasHoraInicioFin = environment.features.hasVisitasHoraInicioFin;
    public clienteName = environment.features.clienteName;
    public showMotivo = environment.features.showMotivo;
    public userCanSeeClientes = environment.features.userCanSeeClientes;

    public getRequest: RequestHandler = this.visitasApi.visitasPereira.GET;
    public deleteRequest: RequestHandler = this.visitasApi.visitasPereira.DELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;  

    public pathToForm = 'crear-seguimiento';
    public pathToFormEdit = 'editar-seguimiento';
  
    public imagenAdjunto = '';

    public formFieldsLeft: ItemInterface<object>[] = [
        {
            label: 'Año', 
            name: 'Año',
            field: 'año', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Semana', 
            name: 'Semana',
            field: 'semana', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Fecha',
            name: 'Fecha', 
            field: 'fecha', 
            inputType: {type: InputType.TEXT}
        },    
        {
            label: 'Planta', 
            name: 'Planta',
            field: 'cultivo', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Variedad', 
            name: 'Variedad',
            field: 'variedad', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Semana Siembra', 
            name: 'Semana Siembra',      
            field: 'semana_siembra', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Semana Entrega', 
            name: 'Semana Entrega',
            field: 'semana_entrega', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Remesa', 
            name: 'Remesa',
            field: 'remesa', 
            inputType: {type: InputType.TEXT},
            maxLength: 4
        },
        {
            label: 'Observaciones', 
            name: 'Observaciones',
            field: 'observaciones', 
            inputType: {type: InputType.TEXTAREA},
            rows: 10
        },
        {
            label: '', 
            field: 'adjunto', 
            inputType: {type: InputType.EMPTY}
        }
    ];

    public formFieldsMiddle: ItemInterface<object>[] = [
        {
            label: 'Cuestiones',
            name: 'Cuestiones', 
            field: 'cuestiones', 
            inputType: {type: InputType.TEXTAREA},
            rows: 10
        },
        {
            label: 'Medida',
            name: 'Medida', 
            field: 'medida', 
            inputType: {type: InputType.TEXTAREA},
            rows: 10
        },
        {
            label: 'Resultados', 
            name: 'Resultados',
            field: 'resultados', 
            inputType: {type: InputType.TEXTAREA},
            rows: 10
        }
    ];

    public formFieldsRight: ItemInterface<object>[] = [
        {
            label: 'Etiquetas', 
            name: 'Etiquetas',
            field: 'etiquetas_nombre', 
            inputType: {type: InputType.TEXTAREA}, 
            rows: 38
        }
    ];

    public cols: ColInterface[] = [
        { field: 'fecha', header: 'Fecha' },
        { field: 'cultivo', header: 'Planta' },
        { field: 'variedad', header: 'Variedad' },
        { field: 'remesa', header: 'Remesa' },
        { field: 'resultados', header: 'Resultados' },
        { field: 'observaciones', header: 'Observaciones' },
    ];

    constructor(dashboard: DashboardService, 
        private visitasApi: VisitasApiService,
        private router: Router) {
    
        super(dashboard, visitasApi.visitasPereira.GET);
    }

    edit(data: SeguimientoModel){
        this.router.navigate(['dashboard', this.pathToFormEdit , data.id]);
    }

    public override show(modal: string, data: SeguimientoModel) {
        let x;

        this.model = data;
        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }  

    public override hide(modal: string) {
        this.model = null;
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public add() {
        this.router.navigate(['dashboard', this.pathToForm]);
    }

    public override deleteRegister() {
        if (this.model['id']) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }  
  
    public globalConditionalStyle(_value: string, cell: ColInterface): ColStylesInterface {
        if (cell.header === 'Fecha') {
            return {
                textAlign: 'center'
            };
        }
        else {
            return {
                marginLeft: '40px;'
            };
        }
    }

    public globalVisualTransform() {
    }

    public verAdjunto() {
        if (this.model.adjunto) {
            const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
            if (myWindow) {
                myWindow.location.assign(environment.serverUrl + 'fotos_seguimiento/' + this.model.adjunto);
                myWindow.focus();
            }
        } else {
            alert('Esta máquina no tiene fotos.');
        }
    }

    public async downloadCsv() {
  
        const arrayFormFields = [
            this.formFieldsLeft,
            this.formFieldsMiddle,
            this.formFieldsRight
        ];

        const csvContent = createCsv(arrayFormFields, this.filtering.value, 'etiquetas_nombre');
  
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Seguimiento.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();
    }
}