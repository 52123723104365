

export class CrmModel {
    id: string = '';
    fecha: string = '';
    estado?: string;
    archivo?: string;
    realizado?: string;
    tarea_comercial?: string;
    estado_presupuesto?: string;
    observaciones?: string;
    cliente: {
        nombre: string;
        apellidos: string;
    } = {nombre: '', apellidos: ''};
    
}
