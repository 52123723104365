import { InputType } from '../../../../../../common/components/form-builder/form-builder.component';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { environment } from 'src/environments/environment';
import { list } from 'src/app/common/classes/request-builder';
import { StorageManager } from 'src/app/common/storage-manager.class';

import type { ItemInterface } from '../../../../../../common/components/form-builder/form-builder.component';

export class FitoLocalesFormDefinition {


    public hasClients = environment.features.hasClients;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;    

    public requestType: any;
    
    
    public fincas = list(); 
    public productos = list(['...', null]); 
    public clientes = list();          
    
    public formFields: ItemInterface<any>[] = [
        {
            field: 'id_finca',
            label: this.fincaName,
            inputType: {type: InputType.DROPDOWN},
            values: this.fincas,
            valuePrimaryKey: 'id',
            required: true,
            filter: (it: { value: { id_cliente: any; }; }) => {
                if (!this.hasClients || (this.requestType === FormRequestTypes.DUPLICATE)) {
                    return true;
                } else if (StorageManager.getClient()) {
                    return it.value.id_cliente === (StorageManager.getClient() || {} as any).id;
                } else if (this.clientes.selected) {
                    return it.value.id_cliente === (this.clientes.selected || {}).id;
                }
                return true;
            },
            
        },   
        {field: 'fecha', label: 'Fecha', inputType: {type: InputType.CALENDAR} , required: true},       
        {
            field: 'id_producto',
            label: 'Producto Fito',
            inputType: {type: InputType.DROPDOWN},
            values: this.productos,
            //filter: (it) => it.value.tipo === 'fitosanitario',
            valuePrimaryKey: 'id'
        },               
        {field: 'local', label: 'Local', inputType: {type: InputType.EDIT_TEXT}},      
        {field: 'superficie_tratada', label: 'Superficie tratada', inputType: {type: InputType.EDIT_NUMERIC}},      
        {field: 'motivo', label: 'Motivo fitosanitario', inputType: {type: InputType.EDIT_TEXT}},      
    ];

}

