<div>
    <br>
    <div style="width: auto; overflow: auto">
        <app-common-list-table 
            [showExportCsv]="this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName==='demogardengest'"
            [request]="getRequest" 
            [filtering]="filter" 
            [minWidth]="1000" 
            [cols]="cols"
            [globalConditionalStyle]="globalConditionalStyle" 
            [globalVisualTransform]="globalVisualTransform"
            [customExportCsv]="showTareasBioExportCsv"
            (emitSearchText)="updateFooter()" 
            [actionButtonText]="canAddTareas ? btnAnadir : ''"
            (actionButtonClick)="addFertilizante()">
            <ng-template #header>
                <div *ngIf="this.rolUser ==='responsable' || this.appName == 'benibal'"  style="margin-left: 20px;">
                    <button class="mx-2 btn" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white;">
                        <i class="fa fa-download"></i>
                        Exportar listado
                    </button>
                </div>
                <div *ngIf="appName ==='donjardin' || this.appName === 'coliberica' || appName === 'demogardengest' || showTareasPrioridad "
                    style="width: 100%; margin-right: 15px" class="d-flex justify-content-start align-items-center">
            
                    <div style="margin-left: 10px; margin-right: 10px">
                        <div style="font-size: 0.7em" class="btn-group">
                            <button (click)="filtrarTratamientos('aplicados', 'switch_aplicados')"
                                [ngClass]="aplicado.selected === 'aplicados' ? 'btn btn-primary' : 'btn btn-light'" style="
                                            height: 28px;
                                            width: 40px;
                                            border: 1px solid grey;
                                            border-right: 1px solid grey">
                                SÍ
                            </button>
                            <button (click)="filtrarTratamientos('no-aplicados', 'switch_aplicados')"
                                [ngClass]="aplicado.selected === 'no-aplicados' ? 'btn btn-primary' : 'btn btn-light'" style="
                                            height: 28px;
                                            width: 40px;
                                            border: 1px solid grey;
                                            border-right: 1px solid grey">
                                NO
                            </button>
                        </div>
                    </div>
                    <span style="color: white; margin-top: -0px;">
                        Aplicados
                    </span>
            
                    <div style="margin-left: 20px ;display: flex;" *ngIf="betweenDates">
                        <app-datepicker (sendFecha)="getFechaInicio($event)">
                        </app-datepicker>
                        <label style="color:white; margin-left: 10px;margin-bottom: 0;">F.Inicio</label>
                    </div>
                    <div style="margin-left: 20px ;display: flex;" *ngIf="betweenDates">
                        <app-datepicker (sendFecha)="getFechaFin($event)">
                        </app-datepicker>
                        <label style="color:white; margin-left: 10px;margin-bottom: 0;">F.Fin</label>
                    </div>

                    
                <div style="margin-left: 10px; margin-right: 10px">
                    <div style="font-size: 0.7em; display: flex;" class="btn-group">
                        <button (click)="filtrarRealizados('Realizada', 'prioridad')"
                            [ngClass]="prioridad.selected === 'Realizada' ? 'btn btn-primary' : 'btn btn-light'" style="
                                height: 28px;
                                width: 40px;
                                border: 1px solid grey;
                                border-right: 1px solid grey">
                            SÍ
                        </button>
                        <button (click)="filtrarRealizados('No-Realizada', 'prioridad')"
                            [ngClass]="prioridad.selected === 'No-Realizada' ? 'btn btn-primary' : 'btn btn-light'"
                            style="
                                height: 28px;
                                width: 40px;
                                border: 1px solid grey;
                                border-right: 1px solid grey">
                            NO
                        </button>
                    </div>
                </div>
                <span style="color: white; margin-top: -0px;">
                    Realizados
                </span>


                </div>
                <div *ngIf="showCampaign" id="campaign">
                    <label for="">Campaña</label>
                    <p-dropdown [options]="years" [(ngModel)]="selectedYears" (onChange)='selectedCampaign()'></p-dropdown>
                </div>
            </ng-template>
            <ng-template #thEnd>
                <th [style.width]="showPrescripcion ? '150px' : ''">
                    <p class="d-flex justify-content-center" style="margin-bottom: 0">Acciones</p>
                </th>
            </ng-template>


            <ng-template #tdEnd let-data>
                <td>
                    <div class="d-flex justify-content-center align-items-center">
                        <app-action-buttons [hideDelete]="false" [showDuplicate]="canDuplicateTareas" (duplicateRowButtonEvent)="duplicate(data)"
                            (editRowButtonEvent)="edit(data)" (deleteRowButtonEvent)="show('delete', data)" (showRowButtonEvent)="show('info', data)">
                        </app-action-buttons>
                        <div *ngIf="showPrescripcion" class="ml-2 d-flex justify-content-center align-items-center"
                            (click)="generarReceta(data)" tooltipPosition="top" pTooltip="{{this.appName === 'yagoaznar' ? 'Plan de Abonado' : 'Receta'}}" style="
                                height: 20px;
                                width: 20px;
                                border-radius: 3px;
                                background-color: #007b00;
                                color: white;
                                cursor: pointer;">
                            <i class="fa fa-file-pdf-o"></i>
                        </div>
                    </div>
                </td>
            </ng-template>

            <ng-template *ngIf="showFertilizantesNPK" #footer let-col>
                <div style="font-size: 0.75em; text-align: left" *ngIf="(['finca'].includes(col.field))">
                    TOTAL:
                </div>
                <div style="font-size: 0.75em; text-align: center; font-weight: bold">
                    <span
                        *ngIf="(['n','p','k','ca'].includes(col.field))">
                        {{decimalFormat(col.footer,2,'.',',',3)}}
                    </span>
                </div>
            </ng-template>
        </app-common-list-table>
    </div>
</div>

<p-dialog header="Detalle del registro" [(visible)]="canShow['info']" (onShow)="scrollToTop()">

    <div *ngIf="canShow['info']" style="width: 80vw !important">
        <app-form-builder [fields]="formFields" [distributionColumns]="1" [distributionOrder]="[4,5,1]" [model]="model">
        </app-form-builder>
        <br>
        <div *ngIf="this.applicationType === 'cropgest'">
            <h4>Datos PAC</h4>
            <app-form-builder [fields]="formFieldsPac" [distributionColumns]="6" [distributionOrder]="distributionOrderPac" [model]="model">
            </app-form-builder>
            <br>
        </div>
        <h4>Productos Abonado</h4>
        <app-form-builder [fields]="formFieldsProductos" [distributionColumns]="distributionColInfoProduct" [model]="model">
        </app-form-builder>
        <br>
        <div *ngIf="this.applicationType === 'cropgest'">
            <h4>Plan Abonado</h4>
            <app-form-builder [fields]="formFieldsAbonado" [distributionColumns]="6" [model]="model">
            </app-form-builder>
        </div>
        <div *ngIf="this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest'">
            <div class="row">
                <div class="col-lg-6">
                    <app-form-builder
                    [model]="model"
                    [distributionColumns]="1" 
                    [fields]="formFieldsObs">
                </app-form-builder>  
                </div>
                <div class="col-lg-6">
                    <div style="display: flex;justify-content: space-between;">
                        <app-upload-images 
                        [model]="model" 
                        folder="fertilizantes" 
                        tipo="list" 
                        ></app-upload-images>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <app-form-builder
                    [model]="model"
                    [distributionColumns]="1" 
                    [fields]="formFieldsOrdenTrabajo">
                </app-form-builder>  
                </div>
                <div class="col-lg-6">
                    <div style="display: flex;justify-content: space-between;">
                        <app-upload-images 
                        [model]="model" 
                        folder="fertilizantes" 
                        tipo="list"
                        nombre_imagen = 'image_after'  
                        ></app-upload-images>
                    </div>
                </div>
            </div>
            <div>
                <div *ngIf="this.model['signature_img']" style="display: flex; flex-direction: column;">
                    <label>Firma</label>
                    <img [src]="serverUrl + 'images/fertilizantes/'+this.model['signature_img'] " class="info-firma-img">
                </div>
            </div>
        </div>          
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog>

<p-dialog header="Eliminar registro" [(visible)]="canShow['delete']" (onShow)="scrollToTop()">

    <div *ngIf="canShow['delete']" style="width: 80vw !important">
        <app-form-builder [fields]="formFields" [distributionColumns]="2" [distributionOrder]="[4,4]" [model]="model">
        </app-form-builder>
        <br>
        <div *ngIf="this.applicationType === 'cropgest'">
            <h4>Datos PAC</h4>
            <app-form-builder [fields]="formFieldsPac" [distributionColumns]="6" [distributionOrder]="distributionOrderPac" [model]="model">
            </app-form-builder>
            <br>
        </div>
        <h4>Productos Abonado</h4>
        <app-form-builder [fields]="formFieldsProductos" [distributionColumns]="distributionColInfoProduct" [model]="model">
        </app-form-builder>
        <br>
        <div *ngIf="this.applicationType === 'cropgest'">
            <h4>Plan Abonado</h4>
            <app-form-builder [fields]="formFieldsAbonado" [distributionColumns]="6" [model]="model">
            </app-form-builder>
        </div>
        <div *ngIf="this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest'">
            <div class="row">
                <div class="col-lg-6">
                    <app-form-builder
                    [model]="model"
                    [distributionColumns]="1" 
                    [fields]="formFieldsObs">
                </app-form-builder>  
                </div>
                <div class="col-lg-6">
                    <div style="display: flex;justify-content: space-between;">
                        <app-upload-images 
                        [model]="model" 
                        folder="fertilizantes" 
                        tipo="list" 
                        ></app-upload-images>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <app-form-builder
                    [model]="model"
                    [distributionColumns]="1" 
                    [fields]="formFieldsOrdenTrabajo">
                </app-form-builder>  
                </div>
                <div class="col-lg-6">
                    <div style="display: flex;justify-content: space-between;">
                        <app-upload-images 
                        [model]="model" 
                        folder="fertilizantes" 
                        tipo="list"
                        nombre_imagen = 'image_after'  
                        ></app-upload-images>
                    </div>
                </div>
            </div>
            <div>
                <div *ngIf="this.model['signature_img']" style="display: flex; flex-direction: column;">
                    <label>Firma</label>
                    <img [src]="serverUrl + 'images/fertilizantes/'+this.model['signature_img'] " class="info-firma-img">
                </div>
            </div>
        </div>          
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button (click)="deleteRegister()" [request]="deleteRequest" [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
            style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>
</p-dialog>


<!-- VERSIÓN "Pascual Cabedo" -->
<p-dialog *ngIf="showRecetaFertiPascualCabedo" header="Generar receta" [(visible)]="showReceta"
    (onShow)="scrollToTop()">

    <div *ngIf="receta" style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">
        <div id="html2canvasDiv" class="registroDiv mx-auto"
            style="font-family: 'Times New Roman', Times, serif; font-size: 12pt; width: 800px;">
            <div class="text-center">
                <img [src]="logoUrl" style="width: 220px; height: auto;" />
            </div>

            <div class="mt-1 mb-4" style="border: 1px solid #8f8f8f; outline: 1px #c5c5c5;">
                <ul class="p-0 m-0 ml-1" style="list-style: none; color: #006600;">
                    <li>
                        <span class="font-weight-bold" style="text-decoration: underline;">FECHA:</span>
                        <span style="color: #0c0c0c; font-family: Arial, Helvetica, sans-serif;">
                            {{receta?.fecha_inicio}}
                        </span>
                    </li>
                    <li>
                        <span class="font-weight-bold" style="text-decoration: underline;">CLIENTE:</span>
                        <span style="color: #0c0c0c; font-family: Arial, Helvetica, sans-serif;">
                            {{receta?.cliente_nombre}}
                        </span>
                    </li>
                    <li>
                        <span class="font-weight-bold" style="text-decoration: underline;">FINCA:</span>
                        <span style="color: #0c0c0c; font-family: Arial, Helvetica, sans-serif;">
                            {{receta?.finca}}
                        </span>
                    </li>
                </ul>
            </div>

            <div class="my-3" style="border: 1px solid #8f8f8f; outline: 1px #c5c5c5;">
                <ul class="p-0 m-0 ml-1" style="list-style: none; color: #006600;">
                    <li>
                        <span class="font-weight-bold" style="text-decoration: underline;">VARIEDAD(ES):</span>
                        <!-- <span *ngIf="receta?.parcelas"
                            style="color: #0c0c0c;font-family: Arial, Helvetica, sans-serif;">
                            <span *ngFor="let parcela of (receta?.parcelas || []); let i = index">
                                {{parcela?.variedad}}
                                <span *ngIf="i < (receta?.parcelas || []).length - 1">, </span>
                            </span>
                        </span> -->
                        <span style="color: #0c0c0c; font-family: Arial, Helvetica, sans-serif;">
                            {{receta?.variedad}}
                        </span>
                    </li>
                    <li>
                        <span class="font-weight-bold" style="text-decoration: underline;">MOMENTO DE APLICACIÓN:</span>
                        <span style="color: #0c0c0c; font-family: Arial, Helvetica, sans-serif;">
                            {{receta?.fecha_fin}}
                        </span>
                    </li>
                </ul>
            </div>

            <!-- Datos de los productos -->
            <div>
                <table class="table-pascual-cabedo my-4 mx-auto">
                    <tr class="font-weight-bold">
                        <td class="p-1" style="background:dimgrey; color:white;">PRODUCTO</td>
                        <td class="p-1" style="background:dimgrey; color:white;">DOSIS</td>
                        <td class="p-1" style="background:dimgrey; color:white;">GR./ÁRBOL (DOSIS/HA)</td>
                    </tr>
                    <ng-container *ngFor="let i of [1, 2, 3, 4, 5, 6, 7]">
                        <tr *ngIf="receta['producto' + i]">
                            <td class="p-1">
                                {{receta['producto' + i]}}
                            </td>
                            <td class="p-1">
                                {{decimalFormat(receta['dosis' + i], 2)}}
                            </td>
                            <td class="p-1">
                                {{decimalFormat(receta['gramos' + i], 2)}}
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>

            <div class="my-1 p-1" style="color: #006600;">
                <div class="my-3">
                    <p style="font-style: italic; text-decoration: underline; font-size: 12pt;">
                        NOTAS:
                    </p>
                    <div class="p-3"
                        style="font-size: 10pt; color: #0c0c0c; font-family: Arial, Helvetica, sans-serif;">
                        {{receta?.observaciones}}
                    </div>
                </div>
            </div>

            <div>
                <p class="mb-2 font-weight-bold" style="
                    font-style: italic;
                    font-size: 12pt;
                    font-family: 'Times New Roman', Times, serif;
                    text-decoration: underline;
                    color: #ff0000;">
                    OBSERVACIONES Y RECOMENDACIONES IMPORTANTES:
                </p>

                <div style="font-family: Arial, Helvetica, sans-serif; font-size: 10pt;">
                    LEER SIEMPRE LAS ETIQUETAS ANTES DEL USO DE LOS PRODUCTOS RECOMENDADOS.
                    <br />
                    <br />
                    <b>N</b>o sobrepasar las dosis recomendadas sin consultar al servicio técnico de esta empresa.
                    <br />
                    <b>N</b>o llenar los depósitos con restos anteriores.
                    <br />
                    <b>L</b>lenar siempre la mitad del depósito antes de aplicar los fertilizantes.
                    <br />
                    <b>D</b>isolver los polvos previamente en un cubo de agua.
                    <br />
                    <b>D</b>epositar los envases una vez vaciados en el punto SIGFITO de esta empresa.
                    <br />
                    <br />
                </div>

                <div class="pl-1 mb-2 w-100" style="
                    border: 1px solid #0c0c0c;
                    font-size: 10pt;
                    font-family: Arial, Helvetica, sans-serif;">
                    <b>S</b>e dispondrán de todas las medidas de Seguridad e Higiene en el trabajo según la normativa
                    vigente.
                </div>

                <div class="d-flex mt-2" style="
                    font-size: 12pt;
                    font-family: 'Times New Roman', Times, serif;
                    font-style: italic;
                    color: #008000;">
                    <div style="flex: 2;">
                        PRECIO DE COSTE POR CUBA DE 1.000,00
                    </div>
                    <div style="flex: 1">
                        € iva incluido.
                    </div>
                </div>
            </div>


        </div>

        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirReceta();">Imprimir</button>
        </div>
    </div>
    <br>

</p-dialog>

<!-- VERSIÓN "AgroGEST" -->
<p-dialog *ngIf="showRecetaFertiAgrogest" header="{{this.appName === 'yagoaznar' ? 'Impreso del Plan de Abonado' : 'Impreso de la receta'}}" [(visible)]="showReceta"
    (onShow)="scrollToTop()">

    <div *ngIf="receta" style="margin-left: 20px; margin-right: 20px; width: 90vw !important;">
        <div id="html2canvasDiv" class="registroDiv mx-auto" style="max-width: 800px;">
            <div class="mb-4 text-left">
                <img [src]="logoUrl" style="width: 150px; height: auto;" />
            </div>

            <h6>Datos personales</h6>
            <table class="table table-bordered table-striped table-hover table-receta">
                <thead>
                    <th>{{fincaName}}</th>
                    <th>{{sectoresName}}</th>
                    <th>{{parcelasName}}</th>
                </thead>
                <tbody>
                    <tr>
                        <td>{{receta?.finca}}</td>
                        <td>{{receta?.sectores_nombres}}</td>
                        <td>{{receta?.parcelas_nombres}}</td>
                    </tr>
                </tbody>
            </table>

            <h6>Datos de la tarea</h6>
            <table class="table table-bordered table-striped table-hover table-receta">
                <thead>
                    <th>Tipo</th>
                    <!--                     <th *ngIf="appName==='armentia'">Albarán/factura</th> -->
                    <th *ngIf="showFertilizantesAlbaranFactura">Albarán/factura</th>
                    <th>Horas</th>
                    <th>Días/semana</th>
                    <th>Inicio</th>
                    <th>Fin</th>
                </thead>
                <tbody>
                    <tr>
                        <td>{{receta?.tipo_fertilizacion}}</td>
                        <!--                         <td *ngIf="appName==='armentia'">{{receta?.albaran_factura}}</td> -->
                        <td *ngIf="showFertilizantesAlbaranFactura">{{receta?.albaran_factura}}</td>
                        <td>{{decimalFormat(receta?.horas_totales, 2)}}</td>
                        <td>{{receta?.dias_semana_formatted}}</td>
                        <td>{{receta?.fecha_inicio}}</td>
                        <td>{{receta?.fecha_fin}}</td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex justify-content-between">
                <div>
                    <h6>Productos</h6>
                    <table class="table table-bordered table-striped table-hover table-receta">
                        <thead>
                            <th>Nombre</th>
                            <th>Cantidad</th>
                            <th *ngIf="showTareasFertiGramos">Gr./árbol (dosis/{{textoSuperficie}})</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let i of [1, 2, 3, 4, 5, 6, 7]">
                                <td *ngIf="receta['producto' + i]">{{receta['producto' + i]}}</td>
                                <td *ngIf="receta['producto' + i]">
                                    <span *ngIf="receta['dosis' + i] != 0">
                                        {{decimalFormat(receta['dosis' + i], 2)}}
                                    </span>
                                </td>
                                <td *ngIf="receta['producto' + i] && showTareasFertiGramos">
                                    <span *ngIf="receta['gramos' + i] != 0">
                                        {{decimalFormat(receta['gramos' + i], 2)}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div *ngIf="showFertilizantesNPK">
                    <h6>Abonado</h6>
                    <table class="table table-bordered table-striped table-hover table-receta">
                        <thead>
                            <th>N</th>
                            <th>P</th>
                            <th>K</th>
                            <th *ngIf="showFertilizantesCa">Ca</th>
                            <th *ngIf="showFertilizantesS">S</th>
                            <th *ngIf="showFertilizantesMg">Mg</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{decimalFormat(receta?.n, 2, '.', ',', 3)}}</td>
                                <td>{{decimalFormat(receta?.p, 2, '.', ',', 3)}}</td>
                                <td>{{decimalFormat(receta?.k, 2, '.', ',', 3)}}</td>
                                <td *ngIf="showFertilizantesCa">{{decimalFormat(receta?.ca, 2, '.', ',', 3)}}</td>
                                <td *ngIf="showFertilizantesS">{{decimalFormat(receta?.s, 2, '.', ',', 3)}}</td>
                                <td *ngIf="showFertilizantesMg">{{decimalFormat(receta?.mg, 2, '.', ',', 3)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>

            <br>

            <div *ngIf="appName==='esparragosgranada'">
                <table class="table table-bordered table-striped table-hover table-receta">
                    <thead>
                        <th style="width: 33%;">Firma técnico</th>
                        <th style="width: 33%;">Recibí</th>
                        <th style="width: 33%;">Firma respons/Fecha aplicación</th>
                    </thead>
                </table>
            </div>

        </div>

        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirReceta();">Imprimir</button>
            <button *ngIf="showEnviarWhatsapp" class="btn btn-success mx-2" (click)="enviarWhatsapp();">
                <img style="width: 24px; height: 24px" src="./assets/whatsapp_48x48.png">
                Enviar WhatsApp
            </button>
            <button *ngIf="showEnviarCorreo" [disabled]="tareasApi.enviarRecetaCaptura.isLoading || isGeneratingCanvas || 
                        !(receta?.finca_email || receta?.cliente_email)" (click)="enviarCorreo()" class="btn mx-2"
                [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'" tooltipPosition="top"
                pTooltip="Se enviará la receta a este email">

                <ng-container
                    *ngIf="!tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                    <i class="fa fa-envelope"></i>
                    <span *ngIf="isGeneratingCanvas">
                        Generando pdf...
                    </span>
                    <span *ngIf="!isGeneratingCanvas && (receta?.finca_email || receta?.cliente_email)">
                        Enviar a {{receta?.finca_email ? receta?.finca_email : receta?.cliente_email}}
                    </span>
                    <span *ngIf="!isGeneratingCanvas && !(receta?.finca_email || receta?.cliente_email)">
                        Enviar email
                    </span>
                </ng-container>
                <ng-container
                    *ngIf="tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                    <i class="fa fa-envelope"></i>
                    Enviando...
                </ng-container>
                <ng-container *ngIf="tareasApi.enviarRecetaCaptura.hasError">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{tareasApi.enviarRecetaCaptura.error}}
                </ng-container>
            </button>
        </div>
    </div>
    <br>

</p-dialog>

<a href="#" id="downloadReceta" style="visibility: hidden; position: fixed;"></a>
<!-- Modal filtrar campaña -->
<p-dialog [contentStyle]="{'min-height':'250px'}" header="Campaña" [closeOnEscape]="false" [closable]="false"
    [(visible)]="modalShowCampaign" (onShow)="scrollToTop()">

    <div>
        <div style="max-width: 200px;">
           
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="closeModalShowCampaign();">Cerrar</button>
        </div>
        <br>
    </div>
</p-dialog>