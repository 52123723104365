<div id="photo-camera">
  <div *ngIf="nombre_imagen == null && tipo !=='list'" class="photo-camera-distribution">
   <!--  <h4>Antes</h4> -->
    <i class="fa fa-picture-o icon-picture" aria-hidden="true" (click)="verImagen()" *ngIf="model.imagen"></i>
    <app-camera (sendCameraFile)="fileChange($event)"></app-camera>
  </div>
  <div *ngIf="tipo === 'list' && nombre_imagen == null" >
    <div *ngIf="model.imagen" >
      <!-- <h4>Antes</h4> -->
      <i class="fa fa-picture-o icon-picture-list" aria-hidden="true" (click)="verImagen()"></i>

    </div>
  </div>
  
  <div *ngIf="tipo === 'list' && nombre_imagen !== null">
    <div *ngIf="model.image_after" >
      <!-- <h4>Después</h4> -->
      <i class="fa fa-picture-o icon-picture-list" aria-hidden="true" (click)="verImagen()"></i>
    </div>
  </div>
  
  <div *ngIf="nombre_imagen !== null && tipo !== 'list'" class="photo-camera-distribution">
  
    <i class="fa fa-picture-o icon-picture" aria-hidden="true" (click)="verImagen()" *ngIf="model.image_after"></i>
    <app-camera (sendCameraFile)="fileChange($event)"></app-camera>
  
    <div *ngIf="tipo === 'list' ">
      <div *ngIf="model.image_after" >
        <button *ngIf="model?.image_after" class="btn btn-link" (click)="verImagen()">Ver</button>
      </div>
    </div>
  </div>
</div>
