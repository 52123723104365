import { Component, HostListener, Output, EventEmitter } from '@angular/core';

import type { OnInit } from '@angular/core';

declare const signaturePad: new (arg0: any, arg1: {
    backgroundColor: string; penColor: string; velocityFilterWeight: number; minWidth: number; maxWidth: number; throttle: number; // max x milli seconds on event update, OBS! this introduces lag for event update
    minPointDistance: number;
}) => any;

@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {


    @Output() sendFile: EventEmitter<File> = new EventEmitter<File>();
    @Output() sendTempImg: EventEmitter<string> = new EventEmitter<string>();

    public canvasWidth: number = 0;
    public canvasHeight: number = 0;
    public canvas: any;
    public visibleSignature = false;
    public available_signature = false;

    constructor(
    ) {
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        const size = window.innerWidth > 1000 ? 1000 : window.innerWidth;
        this.canvasHeight = (this.canvasWidth = size * 0.8) / 1.85;
    }


    ngOnInit() {
    
        this.onResize();
        this.canvas = document.getElementById('signature-pad');
   
        const intervalCanvas = setInterval( () => {
            this.canvas = document.getElementById('signature-pad');
            if ( this.canvas != null) {
                clearInterval(intervalCanvas);
                this.available_signature = true;
                const pad = new signaturePad(this.canvas, {
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    penColor: 'rgb(0, 0, 0)',
                    velocityFilterWeight: .7,
                    minWidth: 0.5,
                    maxWidth: 2.5,
                    throttle: 16, // max x milli seconds on event update, OBS! this introduces lag for event update
                    minPointDistance: 3,
                });
    
                const clearButton = document.getElementById('clear'),
                    showPointsToggle = document.getElementById('showPointsToggle');
    
                clearButton?.addEventListener('click',  () => {
                    pad.clear();
                    this.sendFile.emit(new File([], ''));
                });
    
                if (showPointsToggle) {showPointsToggle.addEventListener('click', function () {
                    pad.showPointsToggle();
                    showPointsToggle.classList.toggle('toggle');
                }); }
            }
        }, 1000);

    
    }

    async saveSign() {
        const file = this.dataURLtoFile(this.canvas.toDataURL(), 'signature.png');
    
        const img64 = await this.getBase64(file);

        if ( img64 != null ) {
            this.sendTempImg.emit(img64);
        }

        this.sendFile.emit(file);
        alert('Firma almacenada temporalmente');
    }

    public scrollToTop(_event: any) {
        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        if (elements && elements[0]) {
            setTimeout(() => {
                if (elements[0]) {
                    elements[0].scrollTop = 0;
                }
            }, 100);
        }
    }




    dataURLtoFile(dataurl: string, filename: string) {
 
        const arr = dataurl.split(',');
        const mime = arr[0]?.match(/:(.*?);/)?.[1];
        const bstr = atob(arr[1] ?? '');
        let n = bstr.length; 
        const u8arr = new Uint8Array(n);
        
        while (n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
    
        return new File([u8arr], filename, {type: mime ?? ''});
    }


    getBase64(file: Blob): Promise<any> {
        return new Promise( resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
  
            reader.onload = function () {
                //console.log(reader.result);
                resolve(reader.result);
            };
  
            reader.onerror = function () {
                resolve(null);
                //console.log('Error: ', error);
            };
        });
 
    }

}
