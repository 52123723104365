<div class="w-100 text-right">
  <div>
    {{mensaje}}
  </div>
  <div>
    <button class="btn mx-1" type="button" style="background-color: #16A2B7; color: white;" (click)="start()" [disabled]="grabandoMicrofono">
      <i aria-hidden="true" class="fa fa-microphone"></i>
    </button>
    <button class="btn btn-warning mx-1" type="button" [disabled]="!grabandoMicrofono" (click)="stop()">
      <i aria-hidden="true" class="fa fa-pause"></i>
    </button>
    <button class="btn btn-danger mx-1" type="button" (click)="deleteRecorded()">
      <i aria-hidden="true" class="fa fa-trash-o"></i>
    </button>
    <div *ngIf="grabandoMicrofono" style="font-size: 1.2em;">
      Escuchando<span class="one">.</span><span class="two">.</span><span class="three">.</span>
    </div>
  </div>
</div>