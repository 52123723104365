<br>

<div style="width: auto; overflow: auto">
    <app-common-list-table [request]="getRequest" [cols]="cols" [filtering]="filtering"
        [globalConditionalStyle]="globalConditionalStyle" [globalVisualTransform]="globalVisualTransform"
        [showExportCsv]="showCostesTareasOtrasCsv" (emitSearchText)="updateFooter()">

        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center" style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons [hideEdit]="true" [hideDelete]="true"
                    (deleteRowButtonEvent)="show('delete', data)" (showRowButtonEvent)="show('info', data)">
                </app-action-buttons>
            </td>
        </ng-template>

        <ng-template #footer let-col>
            <div style="font-size: 0.75em; text-align: left" *ngIf="(['finca'].includes(col.field))">
                TOTAL:
            </div>
            <div style="font-size: 0.75em; text-align: center; font-weight: bold">
                <span
                    *ngIf="(['coste','precio_total','coste_total','maquinaria_precio_total','coste_subcontrata'].includes(col.field))">
                    {{moneyFormat(col.footer)}} €
                </span>
                <span *ngIf="(['cantidad', 'horas'].includes(col.field))">
                    {{decimalFormat(col.footer,2,'.',',',3)}}
                </span>
            </div>
        </ng-template>

    </app-common-list-table>
</div>

<p-dialog *ngIf="canShow['info']" header="Detalle del registro" [(visible)]="canShow['info']" (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <app-form-builder [fields]="formFields" [distributionColumns]="4" [distributionOrder]="distributionOrder"
            [model]="model">
        </app-form-builder>
        <!-- <div class="d-flex justify-content-center" style="margin-top: 50px;">
            <h4>Costes de producto</h4>
        </div>
   
        <div class="d-flex justify-content-center">
            <table class="table-style">
                <tr>
                    <th style="width: 25%;">Producto</th>
                    <th style="width: 25%;">Cantidad</th>
                    <th style="width: 25%;">Precio</th>
                    <th style="width: 25%;">Total</th>
                </tr>
                <tr  >
                    <td>{{ model.producto }}</td>
                    <td style="text-align: right;">{{cuantityFormat(model.cantidad)}} {{model.producto_unidades}}</td>
                    <td style="text-align: right;">{{moneyFormat(model.producto_precio)}} €</td> 
                    <td style="text-align: right;">{{moneyFormat(model.producto_precio * model.cantidad)}} €</td> 
                </tr>
            </table>
        </div> -->

        <div class="d-flex justify-content-center" style="margin-top: 50px;">
            <h4>Costes de maquinaria</h4>
        </div>

        <div class="d-flex justify-content-center">
            <table class="table-style">
                <tr>
                    <th style="width: 25%;">Maquinaria</th>
                    <th style="width: 25%;">Horas</th>
                    <th style="width: 25%;">Precio</th>
                    <th style="width: 25%;">Total</th>
                </tr>
                <tr>
                    <td>{{ model.maquinaria }}</td>
                    <td style="text-align: right;">{{(model.horas)}}</td>
                    <td style="text-align: right;">{{moneyFormat(model.precio_hora_maquinaria ?? 0)}} €</td>
                    <td style="text-align: right;">{{moneyFormat(model.maquinaria_precio_total ?? 0 || model.precio_total ?? 0) }} €</td>
                </tr>
            </table>
        </div>

        <div class="d-flex justify-content-center" style="margin-top: 50px;">
            <h4>Costes de trabajadores</h4>
        </div>

        <div class="d-flex justify-content-center">
            <table class="table-style">
                <tr>
                    <th style="width: 25%;">Trabajador</th>
                    <th style="width: 25%;">Horas</th>
                    <th style="width: 25%;">Precio</th>
                    <th style="width: 25%;">Total</th>
                </tr>

                <tr>

                    <td>{{ model.trabajadores_nombres}} </td>
                    <td style="text-align: right;">{{(model.horas)}}</td>
                    <td style="text-align: right;">{{moneyFormat(model.precio_cargo ?? 0)}} €</td>
                    <td style="text-align: right;">{{moneyFormat( transformStringToNumber(model.coste ?? '') ) }} €</td>
                </tr>
                <!-- <tr >
                    <td style="border: none" ></td>
                    <td style="border: none" ></td>
                    <td style="border: none" ></td> 
                    <td style=" text-align: right;" >{{moneyFormat( sumaAuxTrabajadores(model.tiempo_total) ) }} €</td> 
                </tr> -->
            </table>
        </div>


        <!-- //////////////COSTES SUBCONTRATO EN INFO////////////// -->
        <div *ngIf="this.appName === 'mbcpomelos'">
            <div class="d-flex justify-content-center" style="margin-top: 50px;">
                <h4>Costes de Subcontratos</h4>
            </div>
    
            <div class="d-flex justify-content-center">
                <table class="table-style">
                    <tr>
                        <th style="width: 25%;">Finca</th>
                        <th style="width: 25%;">Proveedor</th>
                        <th style="width: 25%;">Precio</th>
                        <th style="width: 25%;">Total</th>
                    </tr>
    
                    <tr>
    
                        <td>{{ model.finca}} </td>
                        <td style="text-align: right;">{{(model.proveedor)}}</td>
                        <td style="text-align: right;">{{moneyFormat(model.coste_subcontrata ?? 0)}} €</td>
                        <td style="text-align: right;">{{moneyFormat(model.coste_subcontrata ?? 0)}} €</td>
                    </tr>
                    <!-- <tr >
                <td style="border: none" ></td>
                <td style="border: none" ></td>
                <td style="border: none" ></td> 
                <td style=" text-align: right;" >{{moneyFormat( sumaAuxTrabajadores(model.tiempo_total) ) }} €</td> 
            </tr> -->
                </table>
            </div>
    
            <div class="d-flex justify-content-center" style="margin-top: 40px">
                <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
            </div>
        </div>

        <!-- precio_total -->
        <div class="d-flex justify-content-center" style="margin-top: 10px;">
            <table class="table-style">
                <tr>
                    <th style="border: none"></th>
                    <th style="border: none"></th>
                    <th style="border: none; "></th>
                    <th style="border: none; width: 25% ">Total</th>
                    <th style="background-color: grey; color:white;width: 25%;text-align: right;">
                        {{moneyFormat(model.coste_total ?? 0)}} €</th>
                </tr>
            </table>
        </div>
    </div>
    <br>

</p-dialog>

<p-dialog *ngIf="canShow['delete']" header="Eliminar registro" [(visible)]="canShow['delete']" (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <app-form-builder [fields]="formFields" [distributionColumns]="4" [distributionOrder]="distributionOrder"
            [model]="model">
        </app-form-builder>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button (click)="deleteRegister()" [request]="deleteRequest" [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
            style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>

</p-dialog>


<!-- <p-dialog
        header="Detalle del registro"
        [(visible)]="canShow['info']"
        (onShow)="scrollToTop($event)">

    <div *ngIf="canShow['info']" style="width: 80vw !important">
        <form-builder
                [fields]="formFields"
                [distribution-columns]="4"
                [distribution-order]="distributionOrder"
                [model]="model">
        </form-builder>
  
        <app-costes-tareas-otros [model]="model" *ngIf="showTarea"></app-costes-tareas-otros>
        <app-costes-tareas-fitosanitarios [model]="model" *ngIf="showTareaFito"></app-costes-tareas-fitosanitarios>
        <app-costes-tareas-fertilizantes [model]="model" *ngIf="showTareaFerti"></app-costes-tareas-fertilizantes>
        <div class="d-flex justify-content-center" style="margin-top: 40px">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog> -->