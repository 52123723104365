import { Injectable } from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';

@Injectable({
    providedIn: 'root'
})
export class VisitasApiService {
    public visitasGET: RequestHandler = new RequestHandler();
    public visitasPOST: RequestHandler = new RequestHandler();
    public visitasPUT: RequestHandler = new RequestHandler();
    public visitasDELETE: RequestHandler = new RequestHandler();
    public visitasPLAGAS_GET: RequestHandler = new RequestHandler();
    public visitasPLAGAS_TRASIEGO_GET: RequestHandler = new RequestHandler();
    public visitasESTADISTICAS_GET: RequestHandler = new RequestHandler();

    public visitasTrasiego = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    public visitasPereira = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler()
    };

    constructor(private syncService: SyncService,
        private api: ApiRequestService) {

        this.defineVisitasGET();
        this.defineVisitasPOST();
        this.defineVisitasPUT();
        this.defineVisitasDELETE();
        this.defineVisitasPLAGAS_GET();
        this.defineVisitasPLAGAS_TRASIEGO_GET();
        this.defineVisitasESTADISTICAS_GET();

        this.defineVisitasTrasiego();
        this.defineVisitasPereira();
    }

    private defineVisitasGET() {
        this.visitasGET
            .fetch(HttpRequestType.GET, 'generic/visitas');

        this.api
            .registerRequest(this.visitasGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.visitasGET));
    }

    private defineVisitasPOST() {
        this.visitasPOST
            .fetch(HttpRequestType.POST, 'generic/visitas');

        this.api
            .registerRequest(this.visitasPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.visitasPOST));
    }

    private defineVisitasPUT() {
        this.visitasPUT
            .fetch(HttpRequestType.PUT, 'generic/visitas');

        this.api
            .registerRequest(this.visitasPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.visitasPUT));
    }

    private defineVisitasDELETE() {
        this.visitasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/visitas');

        this.api
            .registerRequest(this.visitasDELETE);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.visitasDELETE));
    }

    
    private defineVisitasPLAGAS_GET() {
        this.visitasPLAGAS_GET.fetch(HttpRequestType.GET, 'visitas/visitas_distinct_plagas_get');
        this.api.registerRequest(this.visitasPLAGAS_GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasPLAGAS_GET));
    }

    private defineVisitasPLAGAS_TRASIEGO_GET() {
        this.visitasPLAGAS_TRASIEGO_GET.fetch(HttpRequestType.GET, 'visitas/visitas_distinct_plagas_trasiego_get');
        this.api.registerRequest(this.visitasPLAGAS_TRASIEGO_GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasPLAGAS_TRASIEGO_GET));
    }

    private defineVisitasESTADISTICAS_GET() {
        this.visitasESTADISTICAS_GET.fetch(HttpRequestType.GET, 'visitas/estadisticas_visitas');
  
        this.api
            .registerRequest(this.visitasESTADISTICAS_GET);
  
        this.syncService
            .register(this.syncService.baseSyncPolicy(this.visitasESTADISTICAS_GET));
    }
      
    private defineVisitasTrasiego() {
        this.visitasTrasiego.GET.fetch(HttpRequestType.GET, 'visitas/trasiego_get');
        this.visitasTrasiego.POST.fetch(HttpRequestType.POST, 'generic/visitas_trasiego');
        this.visitasTrasiego.PUT.fetch(HttpRequestType.PUT, 'generic/visitas_trasiego');
        this.visitasTrasiego.DELETE.fetch(HttpRequestType.DELETE, 'generic/visitas_trasiego');

        this.api.registerRequest(this.visitasTrasiego.GET);
        this.api.registerRequest(this.visitasTrasiego.POST);
        this.api.registerRequest(this.visitasTrasiego.PUT);
        this.api.registerRequest(this.visitasTrasiego.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasTrasiego.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasTrasiego.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasTrasiego.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasTrasiego.DELETE));
    }

    private defineVisitasPereira() {
        this.visitasPereira.GET.fetch(HttpRequestType.GET, 'visitas/visitas_pereira_get');
        this.visitasPereira.POST.fetch(HttpRequestType.POST, 'generic/seguimiento');
        this.visitasPereira.PUT.fetch(HttpRequestType.PUT, 'generic/seguimiento');
        this.visitasPereira.DELETE.fetch(HttpRequestType.DELETE, 'generic/seguimiento');

        this.api.registerRequest(this.visitasPereira.GET);
        this.api.registerRequest(this.visitasPereira.POST);
        this.api.registerRequest(this.visitasPereira.PUT);
        this.api.registerRequest(this.visitasPereira.DELETE);
        
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasPereira.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasPereira.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasPereira.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasPereira.DELETE));
    }
}
