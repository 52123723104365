import { Injectable } from '@angular/core';
import {
    ApiRequestService,
    HttpRequestType,
} from '../OffService/api-request.service';
import { RequestHandler } from '../OffService/request-handler';
import { SyncService } from '../OffService/sync.service';

@Injectable({
    providedIn: 'root',
})
export class ConsultasApiService {
    public cajas = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler(),
    };

    public camaras = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler(),
    };
    public maestra = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler(),
    };


    public oscae = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler(),
    };

    public logs = {
        GET: new RequestHandler(),
        POST: new RequestHandler(),
        PUT: new RequestHandler(),
        DELETE: new RequestHandler(),  
    };


    constructor(
        private syncService: SyncService,
        private api: ApiRequestService) {
        this.consultaCajas();
        this.consultaCamaras();
        this.consultaMaestra();
        this.consultasOscae();
        this.consultasLogsVallmed();
    }

    private consultaCajas() {
        this.cajas.GET.fetch(HttpRequestType.GET, 'farmacias/cajas');
        this.cajas.POST.fetch(HttpRequestType.POST, 'generic/caja');
        this.cajas.PUT.fetch(HttpRequestType.PUT, 'generic/caja');
        this.cajas.DELETE.fetch(
            HttpRequestType.DELETE,
            'generic/caja'
        );

        this.api.registerRequest(this.cajas.GET);
        this.api.registerRequest(this.cajas.POST);
        this.api.registerRequest(this.cajas.PUT);
        this.api.registerRequest(this.cajas.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.cajas.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.cajas.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.cajas.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.cajas.DELETE));
    }

    private consultaCamaras(){
        this.camaras.GET.fetch(HttpRequestType.GET, 'farmacias/camaras');
        this.camaras.POST.fetch(HttpRequestType.POST, 'generic/camara');
        this.camaras.PUT.fetch(HttpRequestType.PUT, 'generic/camara');
        this.camaras.DELETE.fetch(HttpRequestType.DELETE, 'generic/camara');

        this.api.registerRequest(this.camaras.GET);
        this.api.registerRequest(this.camaras.POST);
        this.api.registerRequest(this.camaras.PUT);
        this.api.registerRequest(this.camaras.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.camaras.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.camaras.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.camaras.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.camaras.DELETE));
    }
  
    private consultaMaestra(){
        this.maestra.GET.fetch(HttpRequestType.GET, 'farmacias/maestra');
        this.maestra.POST.fetch(HttpRequestType.POST, 'generic/maestra');
        this.maestra.PUT.fetch(HttpRequestType.PUT, 'generic/maestra');
        this.maestra.DELETE.fetch(HttpRequestType.DELETE, 'generic/maestra');

        this.api.registerRequest(this.maestra.GET);
        this.api.registerRequest(this.maestra.POST);
        this.api.registerRequest(this.maestra.PUT);
        this.api.registerRequest(this.maestra.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.maestra.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.maestra.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.maestra.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.maestra.DELETE));
    }

    private consultasOscae() {
        this.oscae.GET.fetch(HttpRequestType.GET, 'consultas/get');
        this.oscae.POST.fetch(HttpRequestType.GET, 'generic/consultas');
        this.oscae.PUT.fetch(HttpRequestType.GET, 'generic/consultas');
        this.oscae.DELETE.fetch(HttpRequestType.GET, 'generic/consultas');
  
        this.api.registerRequest(this.oscae.GET);
        this.api.registerRequest(this.oscae.POST);
        this.api.registerRequest(this.oscae.PUT);
        this.api.registerRequest(this.oscae.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.oscae.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.oscae.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.oscae.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.oscae.DELETE));
    }

    private consultasLogsVallmed(){
        this.logs.GET.fetch(HttpRequestType.GET, 'logs/get');
        this.logs.POST.fetch(HttpRequestType.GET, 'generic/usuarios_logs');
        this.logs.PUT.fetch(HttpRequestType.GET, 'generic/usuarios_logs');
        this.logs.DELETE.fetch(HttpRequestType.GET, 'generic/usuarios_logs');
  
        this.api.registerRequest(this.logs.GET);
        this.api.registerRequest(this.logs.POST);
        this.api.registerRequest(this.logs.PUT);
        this.api.registerRequest(this.logs.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.logs.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.logs.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.logs.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.logs.DELETE));
    }
}
