import { InputType } from './components/form-builder/form-builder.component';

import type { ItemInterface } from './components/form-builder/form-builder.component';

export function createCsv( arrayFormFields: ItemInterface<any>[][], values: any[], last_field: string | undefined ) {
    const charset = 'windows-1252';
    
    let csvContent = 'data:text/csv;charset=' + charset + ',\ufeff';
    arrayFormFields.forEach( form => {
        form.forEach( (it ) => {
            if ( it.visible == null || it.visible === true) {
                if ( it.field === last_field ) {
                    csvContent += it.name + '\n';
                } else {
                    if ( it.inputType?.type === InputType.EMPTY ){
                        csvContent += '';
                    } else {
                        csvContent += it.name + ';';
                    }
                }
            } else {
                if ( it.field === last_field ) {
                    csvContent += '\n';
                }
            }
        });
    
    });

    (values || []).forEach((tareas ) => {
        for (const key in tareas) {
            if (tareas[key] == null ) {
                tareas[key] = '';
            }
        }
      
        arrayFormFields.forEach( form => {
            form.forEach( (it ) => {
                if (it.visible == null || it.visible === true) {
                  
                    if (typeof tareas[it.field ?? 0] === 'string') {
                    // Sí el valor empieza por "-" o "+" Excel lo toma como una formula.
                        tareas[it.field ?? 0] = tareas[it.field ?? 0].replace('-', '');
                        tareas[it.field ?? 0] = tareas[it.field ?? 0].replace('+', '');

                        // Los saltos de línea dan lugar a un salto de fila en Excel.
                        tareas[it.field ?? 0] = tareas[it.field ?? 0].replace('\n-', ', ');
                        tareas[it.field ?? 0] = tareas[it.field ?? 0].replace('\n', '');

                        // Los ';' dan lugar a un salto de columna en Excel.
                        tareas[it.field ?? 0] = tareas[it.field ?? 0].replaceAll(';', ',');
                    }
                  
                    if ( it.field === last_field ) {
                        if ( it.inputType?.type === InputType.RO_SWITCH || it.inputType?.type === InputType.RO_MD_SWITCH  ){
                            if (tareas[it.field ?? 0] === 0) {
                                csvContent += 'No\n';
                            } else if (tareas[it.field ?? 0] === 1) {
                                csvContent += 'Sí\n';
                            } else { 
                                csvContent += '\n';
                            }
                        } else {
                            csvContent += tareas[it.field ?? 0] + '\n';
                        }
                   
                    } else {
                
                        if ( it.inputType?.type === InputType.RO_SWITCH || it.inputType?.type === InputType.RO_MD_SWITCH  ){
                            if (tareas[it.field ?? 0] === 0) {
                                csvContent += 'No;';
                            } else if (tareas[it.field ?? 0] === 1) {
                                csvContent += 'Sí;';
                            } else { 
                                csvContent += ';';
                            }
                        } else if ( it.inputType?.type === InputType.EMPTY ){
                            csvContent += '';
                        } else {
                            csvContent += tareas[it.field ?? 0] + ';';
                        }
                    }
                }
                else {
                    if ( it.field === last_field ) {
                        csvContent += '\n';
                    }
                }
            });
        });
    });
    return csvContent;
}